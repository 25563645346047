
//************************************************************
// Special styling for Opentok integration
//************************************************************

// Confirm video modal settings

.confirm-video-wrap {
	color: $color-text;

	.confirm-video {
		background: $color-off-white;
		width: 100%;
		padding: $global-padding ($global-padding * 2);

		p.client-name {
			margin-top: 0;
			line-height: normal;
			font-size: $rem16px;

			&:first-child {
				margin-bottom: 15px;
			}

			small {
				font-size: $rem12px;
				font-weight: $semi-bold;
			}
		}

		table.video-quality {
			margin-top: 15px;
			background-color: $color-white;
			padding: 15px;
			width: 100%;
			border-radius: 0.357rem;
			border: $rem1px solid $color-field-border;
			
			.data {
				font-weight: bold;
				padding-left: 15px;

				&.red {
					color: $color-red;
				}

				&.orange {
					color: $color-carrot;
				}

				&.green {
					color: $color-green;
				}
			}
		}

		.selfview-portrait {
			margin: auto;
			
			.not-detected {
				background: $color-bg-dark;
				border-radius: $radius-medium;
				display: flex;
				color: $color-white;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				font-size: $rem12px;
				font-weight: $semi-bold;
				width: 100%;
				height: 168px;
				z-index: 1;
				object-fit: cover;

				&.webcam {
					object-fit: cover;
					transform: scaleX(-1);
				}

				&.screensharing {
					transform: scaleX(1);
				}

				> span {
					display: block;

					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}

			// .display-name {
			// 	font-size: $rem14px;
			// }
		}

		.video-options {
			display: flex;
			justify-content: space-between;
			margin: 20px auto 0;

			.video-option {
				text-align: center;
				width: calc(50% - 10px);

				> span {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: $rem12px;
					font-weight: $semi-bold;
					margin-bottom: 8px;

					> i {
						font-size: 20px;
						margin-right: 5px;
					}
				}

				.field-group {
					margin: 0;
				}
			}
		}

		.field-group {
			margin: 20px 0;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// Global OpenTok video styles

.ot-parent {
	position: relative;

	.OTPublisherContainer,
	.OTSubscriberContainer {
		background: black;
		position: relative !important;
		// border-radius: $radius-small;
		box-shadow: 0 0 0 0 transparent;
		transition: box-shadow $hover-transition-duration;
	}

	.OT_widget-container {
		background: transparent;
		position: static;
		width: 100%;
		
		&::after {
			display: block;
			content: '';
			padding-bottom: 56.25%;
		}

		.OT_video-element {
			position: static;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}

	.OT_edge-bar-item {
		background-color: rgba(0,0,0,0.65) !important;
		transition: top $hover-transition-duration !important;
		height: 32px !important;
		width: 100%;
		top: -32px !important;
		bottom: auto !important;
		opacity: 1 !important;
	}
	
	.OT_mute {
		background: none !important;
		color: $color-white;
		position: absolute;
		top: -32px !important;
		left: 0 !important;
		margin: 0 !important;
		height: 32px;
		width: 32px;
		font-size: 0;

		&::after {
			display: block;
			font-family: 'brandlive' !important;
			font-size: 20px;
			text-indent: 0;
		}
	}

	.OT_camera {
		margin-left: 40px !important;
	}

	// Show speaker on/off icon for subscribers
	.OTSubscriberContainer .OT_mute {
		&::after {
			content: "\e96e";
		}

		&.OT_active::after {
  			content: "\e9ae";
		}
	}

	// Show mic on/off icon for publishers
	.OTPublisherContainer .OT_mute {
		&::after {
			content: "\e973";
		}

		&.OT_active::after {
			content: "\e972";
		}
	}

	.audio-meter {
		position: absolute;
		left: 10px;
		bottom: 5px;
		transform: rotate(-90deg);
		transform-origin: left bottom;
		z-index: 10;
	}

	.icon-video-camera-off {
		color: $color-red;
		font-size: 32px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	// .display-name {
	// 	display: block;
	// 	font-size: $rem12px;
	// 	font-weight: $semi-bold;
	// 	width: calc(100% - 20px); // full width of the thumbnail minus the width of the volume canvas and margin
	// 	position: absolute;
	// 	right: 10px;
	// 	bottom: 4px;
	// 	text-align: right;
	// 	@include truncate();
	// 	//z-index: 11;
	// 	z-index: 88;
	// 	color: $color-white;
	// }
	
	&:hover {
		.OT_edge-bar-item {
			top: 0 !important;
		}

		.OT_mute {
			top: 0 !important;
		}
	}

	&.active {
		.OTPublisherContainer,
		.OTSubscriberContainer {
			box-shadow: 0 0 0 2px $color-black, 0 0 0 4px $color-fuscia;
		}
	}
}

// Main Video

.video-wrapper.ot-video-active {
	padding-bottom: 0;

	.video-main,
	.video-main video {
		position: static;
	}
}

.hide-opentok-mute-buttons {
	.OT_bar{
		display: none !important
	}
	.OT_mute{
		display: none !important
	}
}

.OT_audio-blocked-indicator{
	display: none !important
}

.ot-video-main {
	height: 100%;

	.ot-parent {
		height: inherit;

		&::after {
			border-radius: 0;
			height: 40px;
		}

		video {
			width: 100%;
			height: 100%;
		}
	}

	// .display-name {
	// 	font-size: $rem16px;
	// 	bottom: 10px;
	// 	right: 15px;
	// 	width: calc(100% - 30px);
	// }

	// User's presenter stream is always mirrored (selected thumbnail)
	.is-user{
		transform: scaleX(-1);
	}

	// Grid view
	&.ot-multiple {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		height: 100%;
		overflow: hidden;
		
		.ot-parent {
			position: relative;
			overflow: hidden;
			height: auto;
			@include grid(2, 0px); // Base is 2 columns, unles .ot-3c is passed in, then it's switched to 3 columns
		}

		.OTPublisherContainer {
			video {
				// User's presenter stream is always mirrored (grid view). OpenTok automatically does it in the thumbnails. This takes care of it in the main video view.
				transform: scaleX(-1);
			}
	}

		// 2 presenters
		&.ot-2p {
			padding-bottom: 56.25%;

			.ot-parent {
				height: 100%;
				position: absolute;

				&:first-child {
					left: 0;
				}

				&:last-child {
					right: 0;
				}

				> video {
					width: auto;
					transform: translateX(-25%);
				}

				.OTPublisherContainer {
					video {
						width: auto;
						// User's presenter stream is always mirrored. OpenTok automatically does it in the thumbnails. This takes care of it in the main video view.
						transform: translateX(-25%) rotateY(180deg);
					}
				}
			}

			// Added because with the mute button divs+classes added, video is no longer a direct child. Copied thise
			// from ot-parent directly above ^
			.OTSubscriberContainer,
			.OTPublisherContainer {
				height: 100%;
				position: absolute;

				&:first-child {
					left: 0;
				}

				&:last-child {
					right: 0;
				}

				> video {
					width: auto;
					transform: translateX(-25%);
				}
			}
		}

		// 3 columns
		&.ot-3c {
			.ot-parent {
				@include grid(3, 0px);
			}
		}
	}
}

// Thumbnail layout in right column

.module-wrapper.title-description {
	.ot-thumbs.hidden {
		display: none;
	}
	
	.tokbox-video-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		position: relative;
		.ot-parent {
			@include grid(2, 10px);
		}
	}

	.tokbox-video-wrapper > div:not(.ot-parent){
		display: none
	}
}

// OpenTok Tools

.opentok-tools {
	// flex: 1;
	// text-align: right;
	display: flex;
	// justify-content: flex-end;
	align-items: center;

	button.basic {
		// margin: 0 0 0 20px;

		.icon-record-stop {
			color: $color-red;
		}
	}
}