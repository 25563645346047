.dialog-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: $z-index-modal;
	transition: 150ms opacity;

	&.open {
		opacity: 1;

		.dialog-container {
			transform: translateY(10px);
		}
	}

	.dialog-background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		//background-color: rgba($darker-gray, 0.8; don't dim the whole page (because you're still capturing)
	}

	.dialog-container {
		background-color: #26242B;
		position: fixed;
		top: 40px;
		left: 40px;
		border-radius: $rem16px;
		padding: 22px 32px;
		z-index: 2;
		transform: none;
		transition: 150ms transform;
		@include shadow-popover;
		max-width: 600px;

		&.right {
			left: initial;
			right: 40px;
		}
		
		.dialog-content {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			label {
				font-size: 18px;
				line-height: 21px;
				font-weight: bold;
				color: $white;
				text-align: center;
			}

			.button-row {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;
			}

			button {
				@include button-greenroom-shadow;
				width: 100%;
				padding: 16px 24px;
			}
			
			.confirm {
				background-color: $pale-green;
				border-radius: $rem100px;
				color: #1A1A1F;
				transition: 150ms background-color;

				&:hover {
					background-color: lighten($pale-green, 5%);
				}
			}

			.dark {
				background: $element-background-highlight-gray;
				color: #BFBBCF;
				transition: 150ms color;
				border-radius: $rem100px;

				&:hover {
					background-color: lighten($element-background-highlight-gray, 5%);
				}
			}
		}
	}
}