$scroll-margin: 12px;
$select-label-position: 20px;
$body-height-max: 300px;

.custom-select-group {
	display: flex;
	flex-direction: column;
	position: relative;
    margin-top: $select-label-position;
	margin-bottom: 15px;
	flex: 1;

	.select-label {
        position: absolute;
        top: -$select-label-position;
        left: 16px;
        color: $text-gray;
        font-weight: 700;
        font-size: 12px;

		span {
			display: inline-block;
		}
	}

	span.main-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		opacity: 1;
		transition: opacity 150ms;
		cursor: pointer;
		transition: border-bottom-color 150ms;
		
		svg {
			path {
				transition: 150ms fill;
			}
		}

		&:hover {
			border-bottom-color: rgba($text-light, 0.5);

			svg {
				path {
					fill: $text-light;
				}
			}
		}

		&.open {
            z-index: 2;

            &.should-hide {
                opacity: 0;
            }
		}
	}

	&.invalid {
		.select-label {
			color: $red;
		}

		span.main-label {
			border-bottom: 1px solid $red;
		}
	}

	&.open {
		.select-background {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

        .caret {
            transform: rotateX(180deg);
            pointer-events: initial;
            cursor: pointer;
            z-index: 2;
        }
	}

	.select-body {
		@include shadow-popover();
		max-height: 0;
		opacity: 0;
        pointer-events: none;
		position: absolute;
		background-color: $dark-gray;
		z-index: 0;
		transition: opacity 150ms, max-height 150ms;
		top: 100%;
		width: 100%;
		border-radius: $radius-medium;
		overflow: hidden;

		&.open {
			max-height: $body-height-max;
			opacity: 1;
			z-index: 2;
            pointer-events: initial;
		}

		.select-body__inner {
			display: flex;
			flex-direction: column;
			gap: 6px;
			max-height: #{$body-height-max - ($scroll-margin * 2)};
			margin: $scroll-margin;
			border-radius: 12px;
		}
	}

    .caret {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        right: 16px;
        pointer-events: none;
        transition: transform 150ms;

        svg path {
            fill: #e5e4ec;
        }
    }

    span.main-label,
    .select-body {
        cursor: pointer;
    }
}
