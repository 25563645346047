@keyframes blip {
	0% {
		// transform: scale(1);
		fill: $text-light;
		r: 2.5;
	}

	20% {
		// transform: scale(1.4);
		fill: $near-white;
		r: 4;
	}

	100% {
		// transform: scale(1);
		fill: $text-light;
		r: 2.5;
	}
}

.waiting-wrapper {
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.waiting-indicator {
	circle {
		transform-origin: center center;
	}
	.circle-1 {
		animation-name: blip;
		animation-iteration-count: infinite;
		animation-delay: 0s;
		animation-duration: 1.4s;
	}

	.circle-2 {
		animation-name: blip;
		animation-iteration-count: infinite;
		animation-delay: 0.3s;
		animation-duration: 1.4s;
	}

	.circle-3 {
		animation-name: blip;
		animation-iteration-count: infinite;
		animation-delay: 0.6s;
		animation-duration: 1.4s;
	}
}

.waiting-indicator-telly {
	display: flex;
	align-content: center;
	justify-content: center;

	.telly {
		width: 120%;
		height: auto;
		transform: scaleX(-1);
		margin-bottom: -7.2px;
		margin-top: 16px;
	}
	img{
		background-color: transparent !important;
	}
}