.accordion-panel {
	height: 0;
	transition: all 150ms, opacity 1ms;
	opacity: 0;
	pointer-events: none;

	> div {
		max-height: 0;
	}

	&.active {
		pointer-events: auto;
		margin-top: 16px;
		height: auto;
		opacity: 1;

		> div {
			max-height: initial;
		}
	}
}
				


.accordion-title {
	cursor: pointer;
	
	.title-text {
		color: $text-gray;
		&.active {
			color: white;
		}
	}
	
	
	.accordion-indicator {
		font-size: 8px;
		svg {
			transform: rotate(-90deg);
			transition: all 0.2s;
			fill: $text-gray;
		}

		&.active {
			svg {
				transform: rotate(0deg);
				fill: white;
			}
		}
	}
}