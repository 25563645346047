.broadcast-controls-container {
    z-index: $z-index-broadcast-start-button;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 222px;
    height: 40px;
    border-radius: 100px;
    background: $bl-grayscale-700;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: $bl-grayscale-1200;
    cursor: pointer;

    span {
        white-space: nowrap;
        overflow: hidden;
        transition: all 250ms ease-in-out;
    }

    .broadcast-controls-main {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 12px 0 12px 9.5px;
        gap: 9.5px;
        z-index: $z-index-broadcast-start-button;

        span {
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .broadcast-controls-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 102px;
        padding: 12px 20px;
        gap: 6px;
        border-radius: 100px;
        transition: all 250ms ease-in-out;
        z-index: $z-index-broadcast-start-button;

        &.initializing {
            padding: 12px;
        }
    }

    .live-circle {
        display: flex;
        width: 6.5px;
        height: 6.5px;
        border-radius: 50%;
    }

    &.stream {
        .broadcast-controls-main {
            padding-left: 24px;

            span {
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(95.42deg, #A6FF79 42.36%, #FFF85C 99.68%);
            }
        }

        .broadcast-controls-button, .live-circle {
            background-color: $bl-gr-primary-500;

            &.initializing, &.ending {
                background-color: $bl-gr-primary-600;
                cursor: default;
            }
        }
    }

    &.record {
        span {
            color: $white;
        }

        .broadcast-controls-main {
            padding-left: 29.5px;
            
            span {
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(92.43deg, #FF0000 0.89%, #FF965A 161.7%);
            }
        }

        .broadcast-controls-button, .live-circle {
            background-color: $bl-primary-red-500;

            &.initializing, &.ending {
                background-color: $bl-primary-red-600;
                cursor: default;
            }
        }
    }

    &.showbuilder {
        position: relative;
        width: 120px;
        font-size: 13px;
        background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%), #3C3A42;
        background-clip: padding-box;

        .broadcast-controls-main {
            justify-content: center;
            padding-left: 0;
            gap: 10px;

            span {
                position: relative;
                padding-left: 5px;
                font-size: 11px;
                letter-spacing: 0.22px;
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
                user-select: none;

                &::before {
                    position: absolute;
                    content: "Showbuilder";
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(
                        108.66deg,
                        #FF6AE6 -4.67%,
                        #FFF85C 69.07%,
                        #A6FF79 120.95%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    opacity: 0;
                    transition: opacity $transition-fast;
                }
            }

            svg path {
                fill: $bl-grayscale-1200;
            }
        }

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-image: linear-gradient(
                0deg,
                $bl-grayscale-900,
                $bl-grayscale-900
            );
            background-clip: padding-box;
            opacity: 0;
            transition: opacity $transition-fast;
        }

        &.dropdown-open {
            .broadcast-controls-main {
                span::before {
                    opacity: 1;
                }

                svg path {
                    fill: $bl-gr-primary-500;
                }
            }

            &::before {
                transform: scale(1.01);
                opacity: 1;
            }
        }
    }

    &.is-live {
        width: 176px;
        background: $bl-grayscale-1000;

        .broadcast-controls-main {
            justify-content: flex-start;
            padding-left: 13.5px;
            gap: 8px;

            span {
                color: $white;
                background-image: unset;
                background-clip: unset;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
            }
        }

        .broadcast-controls-button {
            width: 74px;
            padding: 10px 8px;

            .timer-text {
                color: $white;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.02em;
            }
        }
    }

    &.is-live:not(.full-button) {
        .broadcast-controls-button {
            background: $bl-grayscale-700;
        }
    }

    &.full-button {
        .broadcast-controls-button {
            width: 100%;
            gap: 8px;

            .live-indicator {
                display: none;
            }
        }
    }
}

.broadcast-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 40px;
    width: 180px;
    user-select: none;
    cursor: pointer;

    svg {
        margin-right: 8px;
    }

    #icon-keyboard-arrow-down {
        position: relative;
        left: 8px;

        path {
            fill: $bl-grayscale-1200;
        }
    }

    &.recording {
        #icon-keyboard-arrow-down {
            path {
                fill: $white;
            }
        }
    }

    .broadcast-controls-primary-action {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        left: -12px;
        height: 40px;
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;

        .broadcast-controls-primary-action-child {
            position: relative;
            bottom: 0;
            left: 0;
            top:0;
            height: 40px;
            min-width: 148px;
            max-width: 148px;
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
            cursor: pointer;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $bl-grayscale-1200;

            display: flex;
            align-items: center;
            justify-content: center;

            &.recording {
                color: $white;
            }

            .animate {
                display: flex;
                align-items: center;

                svg {
                    animation: 2s rotate forwards infinite linear;
                }
            }

            .live-circle {
                height: 8px;
                width: 8px;
                border-radius: 18px;
                background-color: $red;
                margin-right: 8px;
                animation: 750ms blink alternate infinite linear;
            }
        }
    }

    .broadcast-controls-select-holder {
        position: relative;
        display: flex;
        align-items: center;

        height: 40px;
        right: -20px;

        cursor: pointer;

        .broadcast-controls-select-holder-child {
            height: 40px;
            min-width: 32px;
            max-width: 32px;

            display: flex;
            align-items: center;

            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;

            padding-left: 1px;

            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;

            .broadcast-controls-clock {
                position: relative;
                left: -30px;

                .live-indicator {
                    background-color: $border-gray;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: $rem50px;
                    transition: 150ms all;
                    opacity: 1;
                    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.3);
                    transform: scale(1);
                    transform-origin: revert;
                    white-space: nowrap;
                    font-weight: 700;
                    padding: 8px 8px;

                    margin-right: 4px;
                }

                &.hover {
                    display: none;
                }
            }

            .filler {
                margin-right: 22px;
            }
        }
    }

    .select-small {
        margin-top: 0 !important;

        .select-label {
            font-family: 'Poppins';
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 12px !important;
            line-height: 16px !important;
            display: flex !important;
            align-items: center;
            letter-spacing: 0.02em;

            &.dropdown {
                padding: 0 !important;
            }
        }

        .select-body {
            background-color: $bl-grayscale-800 !important;
            z-index: 49;
            .select-small-row {
                .select-option {
                    label {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.02em;
                        color: $white;
                    }

                    svg {
                        position: absolute;
                        right:  4px;
                        path {
                            fill: $white;
                        }
                    }
                }

                &.selected {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.broadcast-option-record {
        margin-left: -4px;
        margin-right: 4px;

        .broadcast-controls-primary-action {
            .broadcast-controls-primary-action-child {
                background-color: $bl-primary-red-500;
                border-right: 1px solid #EB8680;

                &:hover,
                &:active {
                    background-color: lighten($bl-primary-red-500, 10%) !important;
                }

                &.is-live {
                    background-color: $background-gray !important;
                    border-right: 1px solid $background-gray !important;
                }

                &.hover,
                &.going-live {
                    border-right: 0 solid $background-gray !important;
                    background-color: $bl-primary-red-500 !important;
                    max-width: 176px !important;
                    min-width: 176px !important;
                    border-radius: 100px !important;
                    left: 12px !important;
                    padding: 0 !important;
                }

            }
        }

        .select-small {
            .select-label {
                .select-label-text {
                    display: none !important;
                }
            }

            #icon-keyboard-arrow-down {
                display: block;
                left: 0;
                path {
                    fill: $white;
                }
            }

            .select-body {
                right: -42px !important;
            }
        }

        .broadcast-controls-select-holder {
            .broadcast-controls-select-holder-child {
                background-color: $bl-primary-red-500;

                &:hover,
                &:active {
                    background-color: lighten($bl-primary-red-500, 10%) !important;
                }

                &.is-live {
                    background-color: $background-gray !important;
                }

                &.hover {
                    display: none;
                }
            }
        }
    }

    &.broadcast-option-stream {
        margin-left: -4px;
        margin-right: 4px;

        .broadcast-controls-primary-action {
            .broadcast-controls-primary-action-child {
                background-color: $bl-gr-primary-500;
                border-right: 1px solid $bl-grayscale-600;

                .animate {
                    svg {
                        path {
                            fill: $bl-grayscale-600;
                        }
                    }
                }

                &:hover,
                &:active {
                    background-color: lighten($bl-gr-primary-500, 10%) !important;
                }

                &.is-live {
                    background-color: $background-gray !important;
                    border-right: 1px solid $background-gray !important;
                    color: $white !important;
                }

                &.hover,
                &.going-live {
                    border-right: 0 solid $background-gray !important;
                    background-color: $bl-gr-primary-500 !important;
                    max-width: 176px !important;
                    min-width: 176px !important;
                    border-radius: 100px !important;
                    left: 12px !important;
                    padding: 0 !important;
                }
            }
        }

        .select-small {
            .select-label {
                .select-label-text {
                    display: none !important;
                }
            }

            #icon-keyboard-arrow-down {
                display: block;
                left: 0;
            }

            .select-body {
                right: -42px !important;
            }
        }

        .broadcast-controls-select-holder {
            .broadcast-controls-select-holder-child {
                background-color: $bl-gr-primary-500;

                &:hover,
                &:active {
                    background-color: lighten($bl-gr-primary-500, 10%) !important;
                }

                &.is-live {
                    background-color: $background-gray !important;
                }

                &.hover {
                    display: none;
                }
            }
        }
    }

    &.broadcast-option-showbuilder {
        background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%), #3C3A42;

        .broadcast-controls-primary-action {
            left: -16px;
        }

        .broadcast-controls-select-holder {
            right: -16px;
        }

        .select-small {
            .select-label {
                color: $bl-grayscale-1200 !important;
                font-family: 'Poppins';
                font-style: normal !important;
                font-weight: 600 !important;
                font-size: 13px !important;
                line-height: 16px;
                letter-spacing: 0.02em !important;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.broadcast-dropdown-wrapper {
    top: 45px;
    right: 0;
    transition: opacity $transition-fast;
    visibility: visible;

    &.inactive {
        opacity: 0;
    }

    .broadcast-dropdown {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background: $bl-grayscale-800;
        border: 1px solid $bl-grayscale-700;
        border-radius: $rem12px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

        .broadcast-dropdown-option {
            display: flex;
            align-items: center;
            padding: 20px 12px;
            transition: opacity $transition-fast;

            &:first-of-type {
                padding-left: 16px;
            }

            &:last-of-type {
                padding-right: 24px;
            }

            .dropdown-image-wrapper {
                display: flex;
                margin-right: 8px;
                width: 50px;
                height: 50px;

                img {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    transition: opacity $transition-fast;
                }

                svg {
                    margin-right: auto;

                    .inactive-dropdown {
                        opacity: 1;
                        transition: opacity $transition-fast;
                    }

                    .active-dropdown {
                        opacity: 0;
                        transition: opacity $transition-fast;
                    }
                }
            }

            .title {
                position: relative;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: -0.01em;
                color: rgba(255, 255, 255, 0.75);
                transition: color 250ms linear;
            }

            .title::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                transition: opacity 250ms linear;
                opacity: 0;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.showbuilder .title::before {
                content: "Showbuild";
                background-image: linear-gradient(102.97deg, #FF6AE6 -7.63%, #FFF85C 53.98%, #A6FF79 165.47%);
            }

            &.record .title::before {
                content: "Record";
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
                    linear-gradient(95.42deg, #FF0000 11.34%, #FF965A 108.82%);
            }

            &.stream .title::before {
                content: "Stream";
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
                    linear-gradient(95.42deg, #A6FF79 42.36%, #FFF85C 99.68%);
            }

            .sub-title {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: $bl-grayscale-400;
                transition: 250ms color;
            }

            &.selected {
                .sub-title {
                    color: $white;
                }

                .title {
                    color: transparent;
                }

                .title::before {
                    opacity: 1;
                }

                svg {
                    .inactive-dropdown {
                        opacity: 0
                    }

                    .active-dropdown {
                        opacity: 1
                    }
                }
            }
        }
    }
}