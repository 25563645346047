.duration-input {
	input[type=text] {
		border: 1px solid $element-border-gray;
		background: $background-gray;
		color: #ffffff;
		font-size: 16px !important;
		height: 35px;
		text-align: center;
		width: 85px;
	}
}