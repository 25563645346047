// Regional variable

$fixed-video-toolbar-height: 34px;

//************************************************************
// Video module
//************************************************************

.video-wrapper {
	background: $color-black;
	padding-bottom: 56.25%;
	position: relative;
	// z-index: $z-index-video-wrapper; // This is to orient the z-index value of the video so it appears beneath modals and lightboxes from other modules

	.video-main {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}

		//for wowza / other hls streams
		video {
			position: absolute;

			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
	}

	// .affix class only appears in DOM when scroll offset
	// is beyond the video height + video y position + 30px

	.affix {
		width: 100%;
		height: auto;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		pointer-events: none;
		z-index: $z-index-video-overlay;

		@include break-min(961px) {
			padding: 0 calc($global-padding - 10px);
		}

		@include break-min($break-lrg + 1) {
			padding: 0 $global-padding;
		}

		// .affix-inner gets the same container behavior as .content-main by sharing the global-width() mixin.
		// this is so the video can be positioned appropriated over the comments column.
		.affix-inner {
			display: flex;
			@include global-width();

			&.padding {
				padding: 0 calc($global-padding / 2);

				@include break-min(961px) {
					padding: 0 calc($global-padding - 10px);
				}

				@include break-min($break-lrg + 1) {
					padding: 0 $global-padding;
				}
			}

			//for wowza / other hls streams
			video {		
				padding-bottom: 10%;
			}
		}

		.video-main {
			background: lighten($color-black, 15%);
			box-shadow: 0 11px 17px 0 rgba(0, 0, 0, 0.4);
			margin-left: auto;
			width: calc(#{$layout-column-s + 6} - #{calc($global-padding / 4)});
			height: auto;
			position: relative;
			pointer-events: initial;
			@include animation(video-overlay 0.2s);

			@include break-min(961px) {
				width: calc(#{$layout-column-s} - #{calc($global-padding - 20px)});
			}

			@include break-min($break-lrg + 1) {
				width: calc(#{$layout-column-s} - #{calc($global-padding / 2)});
			}

			&::after {
				content: '';
				display: block;
				padding-bottom: 56.25%;
			}

			iframe {
				height: calc(100% - #{$fixed-video-toolbar-height});
				top: $fixed-video-toolbar-height;
				z-index: 1;
			}
		}

		.video-placeholder {
			.video-placeholder-image {
				height: calc(100% - #{$fixed-video-toolbar-height});
				top: $fixed-video-toolbar-height;
			}

			.time-container {
				margin-top: calc($fixed-video-toolbar-height / 2);
			}
		}

		&.bottom {
			top: initial;
			bottom: 0;
		}
	}

	.video-scroll-tools {
		background: $color-black;
		box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: $fixed-video-toolbar-height;
		padding: 0 10px;
		position: relative;
		z-index: 2;

		a {
			color: $color-white;
			margin-left: 3px;
		}
	}
}

// Video only special styles

.video-only .video-wrapper {

	.affix {
		top: initial;
		left: initial;
		right: $global-padding;
		bottom: $global-padding;
		max-width: 320px;
		padding: 0;

		.affix-inner {
			display: block;
			padding: 0;
		}

		.video-main {
			@include animation(video-overlay-bottom 0.2s);
			width: auto;
		}
	}
}