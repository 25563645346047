
//************************************************************
// Input Text Fields
//************************************************************

@mixin field-base() {
	@include clear-appearance();
	background: $color-field-bg;
	border: $rem1px solid $color-field-border;
	border-radius: $radius-field;
	color: $color-text;
	display: block;
	font-size: $rem15px;
	line-height: normal;
	margin: $margin-field;
	width: 100%;
	padding: $padding-field;
	transition: $field-transition-duration border-color,
				$field-transition-duration padding,
				$field-transition-duration box-shadow;

	@include disabled();

	&.small {
		padding: $padding-field-small;
		line-height: normal; // Override line height for small field only
	}

	&.large {
		padding: $padding-field-large;
	}

	&.round {
		border-radius: 1.928rem;
	}

	&.minimal {
		background: none;
		border-radius: 0;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		padding: $padding-field-minimal;

		&:focus {
			box-shadow: none;
		}
	}

	&:hover {
		border-color: darken($color-field-border, 35%);
	}

	&:focus {
		border-color: $color-field-border-focus;
		// box-shadow: 
		// 	0 0 6px 0 rgba($color-field-border-focus, .25),
		// 	0 0 0 100px $color-white inset;
		&::-ms-clear {
			display:none;
		}
	}

	&.minimal.entered {
		box-shadow: none;
	}

	&.required {
		box-shadow: 0 0 1px 1px $color-red;

		&:focus {
			border-color: $color-field-border-focus;
			box-shadow: 0 0 6px 0 rgba($color-field-border-focus, .25);
		}

		&.entered {
			box-shadow: none;
			border-color: $color-field-border;
		}
	}
}

@mixin auto-complete() {
	border-color: $color-field-border;
	box-shadow: 0 0 0 100px $color-white inset;
	-webkit-text-fill-color: $color-text;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="url"],
input[type="tel"],
input[type="number"],
.input-text {
	@include field-base();

	&::-webkit-input-placeholder { // Chrome/Opera/Safari
		color: $color-text-placeholder;
		line-height: normal;
	}
	&::-moz-placeholder { // Firefox 19+
		color: $color-text-placeholder;
		// line-height: normal;
		opacity: 1; // Make color same in Firefox
	}
	&:-ms-input-placeholder { // IE 10+
		color: $color-text-placeholder;
		line-height: normal;
	}
	&:-moz-placeholder { // Firefox 18-
		color: $color-text-placeholder;
		// line-height: normal;
		opacity: 1; // Make color same in Firefox
	}

	// Autocomplete styles in Chrome
	&:-webkit-autofill:not(:focus) {
	// &:-webkit-autofill:hover, 
	// &:-webkit-autofill:focus {
		@include auto-complete();
	}
}

[contenteditable=true] {
	cursor: text;

	&:empty::before {
		color: $color-text-placeholder;
		content: attr(placeholder);
		// display: block; /* For Firefox? */
	}
}

textarea,
.textarea[contenteditable="true"] {
	@include field-base();
	border-radius: $radius-field;
	padding: $padding-field-textarea;
	line-height: $rem28px;
	min-height: 145px;

	&::-webkit-input-placeholder { // Chrome/Opera/Safari
		color: $color-text-placeholder;
	}
	&::-moz-placeholder { // Firefox 19+
		color: $color-text-placeholder;
		opacity: 1; // Make color same in Firefox
	}
	&:-ms-input-placeholder { // IE 10+
		color: $color-text-placeholder;
	}
	&:-moz-placeholder { // Firefox 18-
		color: $color-text-placeholder;
		opacity: 1; // Make color same in Firefox
	}

	// Autocomplete styles in Chrome
	&:-webkit-autofill,
	&:-webkit-autofill:hover
	&:-webkit-autofill:focus {
		@include auto-complete();
	}
}


// Autocomplete styles in Chrome
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	@include auto-complete();
}

.ellipses-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

//************************************************************
// Brandlive Design System V2
// https://www.figma.com/file/XXBoUbK52nPJ2CFMeU8lxk/Desktop---Brandlive-DS-v2?node-id=270%3A14358&mode=dev
//************************************************************

@mixin bl-input() {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.24px;
	color: $bl-grayscale-400;
	background: transparent;
	border: 1px solid $bl-grayscale-400;
	border-radius: 100px;
	padding: 16px;
	transition:
		border-color $transition-fast,
		color $transition-fast;

	&:focus,
	&:hover {
		border-color: $bl-grayscale-200;
	}

	&:active,
	&:focus {
		outline: none;
		color: $bl-grayscale-200;
	}
}
