
//************************************************************
// Buttons
//************************************************************

@mixin button-base() {
	@include clear-appearance();
	background-color: #26242B;
	border: none;
	border-radius: $rem16px;
	color: #908CA0;
	cursor: pointer;
	display: flex;
	font-weight: 600;
	justify-content: center;
	align-items: center;
	font-size: $rem14px;
	line-height: $rem16px;
	text-align: center;
	opacity: 1;
	padding: 0;
	transition: color $field-transition-duration,
				background $field-transition-duration,
				box-shadow $field-transition-duration,
				opacity .5s; // opacity transition for disabled / enabled

	&:hover, &:active {
		background-color: #4F4C57;
	}

	> i {
		margin-right: 5px;
	}

	&.round {
		border-radius: $rem100px;
	}

	&.basic {
		display: flex;
		align-items: center;
		background: none;
		border: none;
		border-radius: 0;
		margin: 0;
		height: auto;
		padding: 0;
		text-transform: initial;
		font-size: $rem12px;

		&:hover {
			background: none;
			color: $color-link;
		}

		&.light {
			color: $color-white;

			&:hover {
				color: $color-light-blue;
			}
		}
	}

	@include disabled();

	&.xsmall {
		font-size: $rem11px;
		min-width: 0;
		padding: $padding-button-xsmall;
		text-transform: uppercase;

		&.round {
			border-radius: calc($button-height-xsmall / 2);
		}
	}

	&.small {
		font-size: $rem12px;
		padding: $padding-button-small;

		&.round {
			border-radius: calc($button-height-small / 2);
		}
	}

	&.large {
		font-size: $rem18px;
		font-weight: normal;
		letter-spacing: 0;
		padding: $padding-button-large;

		&.round {
			border-radius: calc($button-height-large / 2);
		}

		&.positive:not([disabled]),
		&.action:not([disabled]) {
			box-shadow: 0 6px 10px 0 rgba(0,0,0,0.12);
		}
	}

	// Button color variations

	&.transparent {
		background: transparent;
		border-color: transparent;

		&:hover, &:active {
			background: transparent;
		}
	}

	&.border-dashed {
		border: $rem1px dashed $med-gray;
	}

	&.border-large-dashed {
		border: 1px dashed $med-gray;
	}

	&.border-dashed-green {
		border: $rem1px dashed $pale-green;
	}

	&.border-large-dashed-green {
		border: 1px dashed $pale-green;
	}

	&.neutral {
		background: $color-button-neutral;
		color: $color-text-light;

		&:hover {
			background: darken($color-button-neutral, 5%);

			&[disabled] {
				background: $color-button-neutral;
			}
		}

		&.outline {
			background: transparent;
			border-color: $color-text-light;
			color: $color-text-light;

			&:hover {
				background: $color-button-neutral;
				color: $color-text-light;

				&[disabled] {
					background: transparent;
					color: $color-white;
				}
			}
		}
	}

	&.medium {
		background: $color-button-medium;
		border-color: transparent;
		color: $color-white;

		&:hover {
			background: lighten($color-button-medium, 10%);

			&[disabled] {
				background: $color-button-medium;
			}
		}
	}

	&.background-dark {
		&:hover, &:active {
			background: #26242B;
		}
	}

	&.background-green {
		&:hover, &:active {
			background: lighten(#A6FF79, 10%);
		}
	}

	&.background-light {
		&:hover, &:active {
			background: $element-background-highlight-gray;
		}
	}

	&.background-lighter {
		&:hover, &:active {
			background: #BFBBCF;
		}
	}

	&.background-transparent {
		&:hover, &:active {
			background: rgba(255, 255, 255, 0.10);
		}
	}

	&.background-warning {
		&:hover, &:active {
			background: lighten(#EC0C00, 10%);
		}
	}

	&.positive {
		background: $color-button-positive;
		border-color: transparent;
		color: $color-white;

		&:hover {
			background: lighten($color-button-positive, 8%);

			&[disabled] {
				background: $color-button-positive;
			}
		}

		&.outline {
			background: transparent;
			border-color: $color-button-positive;
			color: $color-button-positive;

			&:hover {
				background: $color-button-positive;
				color: $color-white;

				&[disabled] {
					background: $color-white;
					color: $color-button-positive;
				}
			}
		}
	}

	&.action {
		background: $green;
		border-color: transparent;
		color: $color-white;

		&:hover {
			background: darken($color-button-action, 8%);
			&[disabled] {
				background: $color-button-action;
			}
		}

		&.outline {
			background: transparent;
			border-color: $color-button-action;
			color: $color-button-action;

			&:hover {
				background: $color-button-action;
				color: $color-white;

				&[disabled] {
					background: $color-white;
					color: $color-button-action;
				}
			}
		}
	}

	&.primary {
		background: var(--color-button);
		border-color: transparent;
		color: $color-white;

		&:hover {
			filter: brightness(92%);
			&[disabled] {
				background: var(--color-button);
			}
		}

		&.outline {
			background: transparent;
			border-color: var(--color-button);
			color: var(--color-button);

			&:hover {
				background: var(--color-button);
				color: $color-white;

				&[disabled] {
					background: $color-white;
					color: var(--color-button);
				}
			}
		}
	}

	&.centered {
		min-width: 340px;

		+ .cancel {
			min-width: 340px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.caution {
		background: $color-button-caution;
		color: $color-white;

		&:hover {
			background: lighten($color-button-caution, 5%);
		}
	}

	&.warning {
		background: $red;
		color: #ffffff;

		&:hover {
			background: lighten($red, 5%);
		}
	}

	&.cancel,
	&.delete {
		background: none;
		border: none;
		color: $color-text-light;
		padding: 0;
		margin: 0;
		height: auto;

		&:hover {
			background: none;
			color: $color-text;
		}

		&:active {
			box-shadow: none;
		}

		&.warn {
			color: $color-warn;

			&:hover {
				color: light($color-warn, 5%);
			}
		}

		&.caution {
			color: $color-button-caution;

			&:hover {
				color: lighten($color-button-caution, 5%);
			}
		}
	}

	+ {
		.cancel,
		.delete {
			margin-top: 20px;
		}
	}

	&.back-button {
		background: $color-dark-blue;
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		margin: 0;
		height: auto;
		padding: 0;
		font-size: 20px;
		width: 36px;
		height: 36px;
		text-transform: initial;
		border-radius: 50%;

		&:hover {
			background: lighten($color-dark-blue, 15%);
		}

		i {
			margin-right: 1px;
		}
	}

	&.signin-button.small {
		margin: 0;
		padding: 6px 15px;

		.icon-brandlive-logo {
			font-size: 15px;
			margin-right: 0;
			margin-left: 2px;
			position: relative;
			top: -2px;
		}

		@include break-max($break-sm) {
			padding: 4px 6px;
			font-size: 10px;

			.icon-brandlive-logo {
				font-size: 12px;
				top: -1px;
			}
		}
	}

	&.caret {
		border: none;
	}
}

// Buttons

.button,
button,
input[type="button"],
input[type="submit"] {
	@include button-base();
}

a.button {
	&.outline {
		background: transparent;

		&.action:hover {
			background: $color-button-action;
		}

		&.positive:hover {
			background: $color-button-positive;
		}
	}
}

// Increasing icon size in select buttons

.button,
button {
	user-select: none;

	.icon-eye,
	.icon-forward,
	.icon-theater-expand,
	.icon-theater-retract,
	.icon-save,
	.icon-unarchive,
	.icon-gear,
	.icon-logo,
	.icon-moderate,
	.icon-users,
	.icon-record,
	.icon-record-stop,
	.icon-grid {
		font-size: $rem18px;
		line-height: 15px;
		vertical-align: top;
		position: relative;
		top: 1px;
  	}

	&.icon-edit {
		display: flex;
		background: $border-gray;
		border-radius: $rem100px;
		padding: 8px;
	}
}