$event-row-avatar-size: 56px;
$event-row-border-radius: 12px;

.select-content {
	position: relative;

	.select-content-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow-y: auto;
		width: 924px;
		margin: auto;

		h1{
			color: #FCFCFC;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			margin: 0;
			padding: 0;
			width: 100%;
		}

		// hide scrollbar on loading wheel div
		&::-webkit-scrollbar {
    		width: 0;
		}

		.loading-container {
			height: calc(100vh - 227px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.empty {
			height: calc(100vh - 227px);
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: -60px;

			p {
				font-size: 24px;
				font-weight: bold;
				color: $text-gray;
			}
		}

		.featured-cards-container {
			width: 924px;
			height: 338px;
			flex-direction: column;
			margin-bottom: 12px;

			h1 {
				color: #FFFFFF;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
				margin: 0 0 24px 0;
				width: 100%;
			}
		}

		.select-content-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 21px;

			.all-shows{
				display: flex;

				h1 {
					color: #E5E4EC;
					font-weight: bold;
					font-size: 26px;
					line-height: 32px;
					margin: 0;
					padding: 0;
				}

				.new-greenroom-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $pale-green;
					min-width: 130px;
					height: 46px;
					border-radius: 100px;
					padding: 0px;
					margin-left: 10px !important;

					span {
						color: #1A1A1F;
						margin-left: 4px;
					}
				}
			}

			.select-content-controls {
				display: flex;
				align-items: center;

				.select-small {
					border-radius: $rem24px;
					background: none;
					border: $rem1px solid #908CA0;
					min-width: 120px;
					max-width: 120px;
					margin-right: 16px;
					position: absolute;
					right: 232px;
					
					.select-label {
						display: flex;
						width: 100%;
						justify-content: space-between;
						padding-left: 8px;

					}

					.select-body {
						min-width: 120px;
						max-width: 120px;
						border-radius: $rem16px;
						border: $rem1px solid #908CA0;
						left: -1px;
					}
				}

				.search-field {
					position: relative;
					padding-right: 8px;
					
					input {
						min-height: 38px;
						height: 32px;
						width: 160px;
						border-radius: $rem100px;
						background: none;
						border: $rem1px solid #908CA0;
						margin: 0;
						padding-left: 36px;
						color: $near-white;
						font-weight: normal;
						font-size: 14px;
						line-height: 20px;

						&::placeholder {
							color: darken($text-gray, 10%);
						}

						&:focus {
							&::placeholder {
								color: darken($text-gray, 20%);
							}
						}
					}

					&.disabled {
						input {
							cursor: default;
							border: $rem1px solid $border-gray;
						}
					}

					.search-icon {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						width: 44px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $text-gray;
					}
				}

				.calendar-switch-container {
					position: relative;

					.switch-wrapper {
						width: 64px;
						height: 32px;
						border-radius: 51px;

						.switch-container {
							background-color: $border-gray;
							-moz-box-shadow: 	none;
							-webkit-box-shadow: none;
							 box-shadow: 		none;

							&::after {
								height: 32px;
								width: 32px;
								background-color: #4F4C57;
								border-radius: 42px;
								top: 0px;
								left: 0px;
								-moz-box-shadow: 	none;
								-webkit-box-shadow: none;
								box-shadow: 		none;
							}

							&.checked {
								&::after {
									transform: translateX(32px);
								}
							}
						}
					}

					.calendar-icon, 
					.list-icon {
						display: flex;
						align-items: center;
						position: absolute;
						top: 0;
						height: 32px;
						pointer-events: none;
					}

					.calendar-icon {
						right: 42px;
					}

					.list-icon {
						right: 10px;
					}
				}
			}
		}

		
		.title-container {
			display: flex;
			width: 100%;
			height: 46px;
			justify-content: space-between;
			margin-bottom: 17px;

			h1 {
				color: #E5E4EC;
				font-weight: bold;
				font-size: 26px;
				line-height: 32px;
				margin: 0;
				padding: 0;
				width: 100%;
			}
		}

		
		.upcoming-past-container {
			margin-bottom: 33px;
			font-size: $rem16px;
			font-weight: 500;
			padding-left: 0;
		}
		
		.upcoming-past-container,
		.search-field {
			position: relative;
			display: flex;
			width: 100%;

			.tab-button {
				background: none;
				position: relative;
				display: flex;
				justify-content: space-between;
				transition: 150ms color;
				font-size: 16px;
				font-weight: 500;
				user-select: none;
				border: none;
				outline: none;
				padding: 0 40px 0 0;

				&.highlight {
					z-index: 2;
				}

				&.unseen {
					&::before {
						content: "";
						position: absolute;
						top: 8px;
						right: 14px;
						height: 5px;
						width: 5px;
						border-radius: 100%;
						background: $red;
					}
				}

				&:hover {
					color: darken($color-white, 30%);

					&::after {
						width: 20px;
					}
				}

				&.active {
					color: $color-white;

					&::after {
						width: 20px;
					}
				}
			}

			.tab-underline {
				height: 3px;
				background-color: $pale-green;
				transition: transform 150ms, opacity 150ms, width 150ms;
				transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.02);
				position: absolute;
				top: 29px;
				opacity: 1;
				border-radius: 2px;

				&.upcoming {
					width: 73px;
					transform: translateX(0);
				}

				&.past {
					width: 32px;
					transform: translateX(114px);
				}
			}
		}

		.events-wrapper {
			width: 100%;

			.events-header {
				width: 100%;
				display: grid;
				grid-template-columns: 130px 605px 1fr;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 14px;
				color: $text-gray;

				.sort {
					display: flex;
					cursor: pointer;
					user-select: none;

					&.time {
						justify-content: flex-end;
						padding-right: 24px;
					}

					&.show {
						padding-left: 23px;
					}

					&.active {
						label {
							color: $white;
						}
					}

					label {
						pointer-events: none;
						transition: 150ms color;
					}

					&:hover {
						label {
							color: $near-white;
						}
					}

					.sort-type-icon {
						display: flex;
						flex-direction: column;
						margin-left: 8px;
						position: relative;
						top: 1px;
						pointer-events: none;
						transition: color 150ms;

						span {
							padding: 0;
							margin: 0;
							line-height: 8px;
							font-size: 8px;
						}

						&.asc {
							.up {
								color: $near-white;
							}
						}

						&.desc {
							.down {
								color: $near-white;
							}
						}
					}
				}

				.presenters {
					padding-left: 23px;
				}
			}

			.row-separator {
				width: 100%;
				display: flex;
				justify-content: space-between;
				height: 30px;
				align-items: center;
				margin: 25px 0 5px 0;
				border-top: 1px solid rgba(178, 178, 191, 0.15);

				label {
					font-weight: bold;
					font-size: 14px;
					line-height: 16px;
					color: $text-gray;
					display: inline-block;
					text-transform: uppercase;
					font-weight: bold;
				}
			}

			.no-results {
				text-align: center;
				color: $text-light;
				margin-top: 15px;
			}

			.show-more {
				display: inline-block;
				margin-bottom: 24px;
				padding: 8px 0 8px 0;
				cursor: pointer;
				user-select: none;

				button {
					background: none;
					color: $pale-green;
					font-size: 14px;
					line-height: 16px;
					font-weight: 500;
					border: none;
					outline: none;
					padding: 0;
				}
			}
		}

		.event-row {
			display: grid;
			grid-template-columns: 130px 104px 501px 1fr;
			grid-template-rows: 1fr;
			background: #26242B;
			padding: 16px 0;
			border-radius: 16px;
			cursor: pointer;

			.event-time {
				text-align: right;
				padding: 11px 23px;
				border-right: 1px solid #4F4C57;

				span {
					display: inline-block;
					text-transform: capitalize;
				}

				.timestamp {
					font-size: 16px;
					font-weight: bold;
					line-height: 19px;
					color: $near-white;
				}

				.day {
					font-size: 14px;
					color: $text-gray;
					font-weight: 500;
					white-space: nowrap;
				}
			}

			.channel-logo {
				display: flex;
				align-items: center;
				justify-content: center;

				.avatar-container {
					.avatar {
						width: $event-row-avatar-size;
						height: $event-row-avatar-size;
						border-radius: $event-row-border-radius;
						border: none;
						background: #121114;
						object-fit: contain;
					}

					@include telly-bubble(auto, $event-row-avatar-size, auto, $event-row-border-radius);
				}
			}

			.event-titles {
				display: flex;
				flex-direction: column;
				justify-content: center;
				border-right: 1px solid #4F4C57;
				padding: 2px 0;

				h4 {
					padding: 0 24px 0 0;
					margin: 0 0 8px 0;
					font-weight: bold;
					font-size: 17px;
					line-height: 22px;
					color: $near-white;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.presenters-actions {
				display: flex;
				justify-content: center;

				.awaiting-producer-container {
					padding: 0px 30px;
					@include flex-center;

					.awaiting-producer {
						display: flex;
						align-items: center;
						justify-content: center;
						background: $dark-gray;
						border-radius: 100px;
						padding: 16px;
						font-size: 14px;
						font-weight: 500;
						white-space: nowrap;
					}

					svg {
						margin-left: 6px;
					}
				}

				.avatar-bubble-row {
					margin-left: 23px;
					pointer-events: none;

					.avatar-container {
						.avatar-placeholder {
							cursor: pointer;
							transition: 150ms background-color;

							&:hover {
								background-color: lighten($border-gray, 10%);
							}
						}
					}
				}

				.event-actions {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					padding-right: 22px;
					position: relative;

					.popover {
						top: 16px;
						right: 16px;
					}

					.details-button {
						position: absolute;
						bottom: 0;
					}
					
					.action-button {
						display: flex;
						align-items: center;
						background: none;
						border: none;
						margin: 0;
						width: 145px;
						border-radius: 100px;
						height: 46px;
						color: $text-light;
						background-color: $button-gray;
						transition: 150ms color, 150ms background, 60ms transform;
						outline: none;
						padding: 0;
						margin: 0;
						transition: 150ms color, 60ms transform;
						transform: scale(1);

						@include button-greenroom-shadow;
						&:active {
							transform: scale(0.95);
						}

						&:hover {
							fill: $near-white;
						}
					}

					.edit-round-button {
						display: flex;
						justify-content: center;
						align-items: center;
						background: $border-gray;
						border-radius: 100px;
						padding: 8px 16px;
						transition: background-color 150ms, color 150ms;
						height: 46px;
						color: $near-white;
						margin-right: 8px;

						svg{
							path{
								fill: $near-white !important;
							}
						}

						span {
							margin-left: 6px;
							white-space: nowrap;
							
						}

						&:hover {
							background: lighten($border-gray, 10%);
							

							
						}
					}
				}
			}

			&.presenter {
				.presenters-actions {
					.presenters {
						.show-all-container {
							right: 62px;
						}
					}
				}
			}
		}
	}
}







