@mixin absolute-fill {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin fixed-fill {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin button-greenroom-basic {
	&:hover {
		filter: brightness(105%);
	}

	&:active {
		filter: brightness(95%);
	}
}

@mixin button-greenroom-circle {
	@include button-greenroom-basic();

	box-shadow: 		inset 0 -2px 3px 0.5px rgba(0,0,0,.1);
	-moz-box-shadow: 	inset 0 -2px 3px 0.5px rgba(0,0,0,.1);
	-webkit-box-shadow: inset 0 -2px 3px 0.5px rgba(0,0,0,.1);

	&:active {
		box-shadow: 		inset 0 0 3px 1px rgba(0,0,0,.1);
		-moz-box-shadow: 	inset 0 0 3px 1px rgba(0,0,0,.1);
		-webkit-box-shadow: inset 0 0 3px 1px rgba(0,0,0,.1);
	}
}

@mixin button-greenroom-shadow {
	@include button-greenroom-basic();

	box-shadow: 		inset 0 -2px 3px rgba(0,0,0,.1);
	-moz-box-shadow: 	inset 0 -2px 3px rgba(0,0,0,.1);
	-webkit-box-shadow: inset 0 -2px 3px rgba(0,0,0,.1);

	&:active {
		-webkit-box-shadow:	inset 0 1px 3px 0 rgba(0,0,0,0.1);
		-moz-box-shadow: 	inset 0 1px 3px 0 rgba(0,0,0,0.1);
		box-shadow: 		inset 0 1px 3px 0 rgba(0,0,0,0.1);
	}
}

@mixin shadow-popover() {
	box-shadow: 		0px 2px 3px 1px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 	0px 2px 3px 1px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
}

@mixin volume-slider() {
	[type=range] {
		-webkit-appearance: none;
		background: transparent;
		width: 100%;
	}
	[type=range]::-moz-focus-outer {
		border: 0;
	}
	[type=range]:focus {
		outline: 0;
	}
	[type=range]::-webkit-slider-runnable-track {
		cursor: default;
		border-radius: $rem100px;
		height: 4px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-webkit-slider-thumb {
		cursor: default;
		background: white;
		border-radius: $rem100px;
		height: 12px;
		width: 12px;
		-webkit-appearance: none;
		margin-top: -4px;
		box-sizing: border-box;
		z-index: 1;
	}
	[type=range]::-moz-range-track {
		cursor: default;
		border-radius: $rem100px;
		height: 4px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-moz-range-thumb {
		cursor: default;
		background: white;
		border-radius: $rem100px;
		height: 12px;
		width: 12px;
		box-sizing: border-box;
	}
	[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-webkit-slider-runnable-track {
		cursor: not-allowed;
	}
}

@mixin scrubbar-slider() {
	[type=range] {
		-webkit-appearance: none;
		background: transparent;
		width: 100%;
	}
	[type=range]::-moz-focus-outer {
		border: 0;
	}
	[type=range]:focus {
		outline: 0;
	}
	[type=range]::-webkit-slider-runnable-track {
		border-radius: $rem100px;
		height: 4px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-webkit-slider-thumb {
		background: white;
		border-radius: $rem100px;
		height: 12px;
		width: 12px;
		-webkit-appearance: none;
		margin-top: -4px;
		box-sizing: border-box;
		z-index: 1;
	}
	[type=range]::-moz-range-track {
		border-radius: $rem100px;
		height: 4px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-moz-range-thumb {
		background: white;
		border-radius: $rem100px;
		height: 12px;
		width: 12px;
		box-sizing: border-box;
	}
	[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-webkit-slider-runnable-track {
		cursor: not-allowed;
	}
}

@mixin scale-slider() {
	[type=range] {
		-webkit-appearance: none;
		background: transparent;
		width: 40%;
	}
	[type=range]::-moz-focus-outer {
		border: 0;
	}
	[type=range]:focus {
		outline: 0;
	}
	[type=range]::-webkit-slider-runnable-track {
		cursor: default;
		background: $med-gray;
		border-radius: 7.071rem;
		min-height: 6px;
		max-height: 6px;
		height: 6px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-webkit-slider-thumb {
		cursor: pointer;
		background: white;
		border-radius: 7.071rem;
		height: 10px;
		width: 10px;
		-webkit-appearance: none;
		margin-top: -2px;
		box-sizing: border-box;
		z-index: 1;
	}
	[type=range]::-moz-range-track {
		cursor: default;
		background: $light-gray;
		border-radius: 97.071rem;
		min-height: 6px;
		max-height: 6px;
		height: 6px;
		width: 100%;
		transition: all 0.2s ease;
	}
	[type=range]::-moz-range-thumb {
		cursor: pointer;
		background: white;
		border-radius: 7.071rem;
		height: 10px;
		width: 10px;
		box-sizing: border-box;
	}
	[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-webkit-slider-runnable-track {
		cursor: not-allowed;
	}
}

@mixin telly-bubble($container-height, $size, $margin: auto, $border-radius: 16px) {
	.telly-channel-bubble-container {
		height: $container-height;
	
		.telly-channel-bubble {
			width: $size;
			height: $size;
			margin: $margin;
			border-radius: $border-radius;
			position: relative;
	
			img {
				position: absolute;
				bottom: 0;
				width: $size;
				height: $size;
				border-radius: $border-radius;
			}
		}
	}
}

@mixin line-clamp($lines: 1) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin ratio-width-height($width, $wRatio: 16, $hRatio: 9) {
	width: #{$width}px;
	height: #{($width / $wRatio) * $hRatio}px;
}

// Pretty simple mixin, but the idea is we can define what SVG children we want
// to fill here instead of just doing `svg *`.
@mixin svg-fill($color) {
	path,
	rect {
		fill: $color;
	}
}

@mixin alert-modern(
	$width: 412px,
	$force-width: false,
	$background: $bl-grayscale-900,
	$title-color: $white,
	$description-color: $bl-grayscale-400,
) {
	background: $background;
	padding: 16px;
	width: $width;

	@if $force-width {
		@media screen and (min-width: 769px) {
			max-width: $width;
		}
	}

	.close-alert {
		margin-left: 4px;
	}

	.alert-content {
		.alert-title {
			color: $title-color;
			font-family: Roboto;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 18px;
		}

		.alert-description-wrap {
			color: $description-color;
			font-family: Roboto;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}
	}
}

@mixin flex-scroll {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
