
//************************************************************
// CSS Animations
//************************************************************

// loading logo

@include keyframes(loadingLogo) {
	0% {
		top: -50px;
	}
	100% {
		top: 0;
	}
}

// General Fade in

@include keyframes(fadeIn) {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Loader

@include keyframes(loader) {
	0%, 100% {
		transform: scale(0.0);
	}

	50% {
		transform: scale(1.0);
	}
}

// Spinning loader

@include keyframes(loading) {
	//circle radius is 35
	//2πr -> 2 * π * 35 = 219.9 for circumference
	//stroke-dasharray="219.9" is the length of the dash in the svg
	//if increasing or decreasing radius of circle, set stroke-dasharray to 2 * π * radius, end of animation to circumference * -2
	0% {
		stroke-dashoffset: 0;
	} //end of animation moves dash backwards by 2 * circumference
	100% {
		stroke-dashoffset: -439.82;
	}
}

// Loader Refresh - Different from loader. This is for the refresh icon

@include keyframes(refreshing) {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

// Loader v2

@include keyframes(loader-spinner-right) {
	0%{transform:rotate(0deg)}
	25%{transform:rotate(180deg)}
	50%{transform:rotate(180deg)}
	75%{transform:rotate(360deg)}
	100%{transform:rotate(360deg)}
}

@include keyframes(loader-spinner-left) {
	0%{transform:rotate(0deg)}
	25%{transform:rotate(0deg)}
	50%{transform:rotate(180deg)}
	75%{transform:rotate(180deg)}
	100%{transform:rotate(360deg)}
}

// Modal pane transitions

@include keyframes(content-module-pane) {
	0% {
		opacity: 0;
		top: 40px;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}

@include keyframes(listpop) {
	0% {
		opacity: 0%;
		max-height: 0;
		overflow: hidden;
		padding: 0;
	}
	100% {
		opacity: 0%;
		max-height: 100px;
		padding: 13px 0;
		overflow: hidden;
	}
}

// 'Like' button

@include keyframes(like-button) {
	0% {
		transform: scale(1) rotate(0deg);
	}

	50% {
		transform: scale(1.3) rotate(-10deg);
	}

	100% {
		transform: scale(1) rotate(0deg);
	}
}

// Typing Indicator

@include keyframes(typing-indicator) {
	0% {
		transform: scaleY(.42);
	}
	25% {
		transform: scaleY(1);
	}
	50% {
		transform: scaleY(.42);
	}
	100% {
		transform: scaleY(.42);
	}
}

// Video overlay

@include keyframes(video-overlay) {
	0% {
		transform: translateY(-100%)
	}
	100% {
		transform: translateY(0);
	}
}

@include keyframes(video-overlay-bottom) {
	0% {
		transform: translateY(100%)
	}
	100% {
		transform: translateY(0);
	}
}

// Comment appearance

@include keyframes(commentAppear) {
	0% {
		max-height: 0;
		opacity: 0;
	}

	100% {
		max-height: 1000px;
		opacity: 1;
	}
}
