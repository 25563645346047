.notifications-wrapper {
	flex: 1;
	align-items: center;
	align-self: center;
    justify-content: center;
	display: flex;

	&.highlight{
		z-index: 2;
	}
	
	@keyframes arrowUp {
		0% {
			opacity: 0.5;
			transform: translateY(2px);
		}

		100% {
			opacity: 1;
			transform: translateY(0) scale(1);
		}
	}

	.notifications-container {
		display: flex;
		justify-self: flex-end;
		align-items: center;
		margin: 0 10px;

		.notification {
			display: flex;
			white-space: nowrap;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;

			.notification-left {
				border-top-left-radius: 100px;
				border-bottom-left-radius: 100px;
				padding: 8px 12px 8px 16px;
				margin-right: 2px;
				background-color: #EC0C00;

				.notification-title {
					display: flex;
					align-items: center;
				}
			}

			.notification-right {
				border-top-right-radius: 100px;
				border-bottom-right-radius: 100px;
				padding: 8px 16px 8px 14px;
				background-color: #4F4C57;

				.notification-subtitle {
					display: flex;
					align-items: center;
					
					svg {
						animation: arrowUp 250ms;
						margin-right: 10px;
					}
				}
			}

			.notification-center {
				border-radius: 100px;
				padding: 8px 12px 8px 16px;
				background-color: #4F4C57;
			}

			&.action {
				.notification-left {
					background-color: $border-gray;
					padding-right: 64px;
					margin-right: -50px;
					display: flex;
    				align-items: center;
				}

				.notification-right {
					padding: 0;
					background-color: transparent;
				}

				.notification-action-container {
					display: flex;

					button {
						background: none;
						margin: 0;
						padding: 15px 16px;
						border: none;
						outline: none;
						box-shadow: none;
						color: $near-white;
					}
					.notification-action {
						border-top-left-radius: 100px;
						border-bottom-left-radius: 100px;
						background: $red;

						svg {
							margin-right: 6px;
						}
					}

					.notification-close {
						background: $med-gray;
						border-top-right-radius: 100px;
						border-bottom-right-radius: 100px;
						padding: 15px 14px 14px 14px;
					}
				}
			}
		}

		.on-screen-presenting{
			@include font-poppins-14-500;
			line-height: 16px;
			margin-left: 9px;
		}
	}
}

@media only screen and (max-height: 600px) {
	.notifications-wrapper {
		.notifications-container {
			.on-screen-presenting{
				max-height: 32px;
				margin-left: 1.2vh;
				font-size: 2.35vh;
				overflow: hidden;
			}
		}
	}
}