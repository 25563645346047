.landing-page {
	.background{
		.text-container{
			display: flex;
			flex-direction: column;
			align-items: center;
			//justify-content: center;
			margin-top: 105px;
			background: transparent;

			.title, .sub-title {
				z-index: $z-index-max;
			}

			.title{
				font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 40px;
				line-height: 56px;

				background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%), #FFFFFF;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				//text-fill-color: transparent;
			}

			.sub-title{
				display: flex;
				flex-direction: column;
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 20px;
				line-height: 32px;
				text-align: center;
				letter-spacing: -0.2px;
				color: #FFFFFF;
				opacity: 0.8;

				.keyboard-key {
					display: flex;
					align-items: center;
					height: 20px;
					font-size: 12px;
					border-radius: 4px;
					padding: 2px 8px;
					margin-left: 8px;
					background: $element-background-highlight-gray;
				}

				a {
					text-decoration: underline;
					color: $bl-grayscale-100;
				}
			}
		}

		.telly-glow{
			position: absolute;
			width: 50%;
			height: 50%;
			left: 0px; 
			right: 0px;
			bottom: 0px;
			
			// top: 50%; 
			margin-left: auto; 
			margin-right: auto; 

			background: #A6FF79;
			opacity: 0.2;
			filter: blur(100px);
			transform: matrix(-1, 0, 0, 1, 0, 0);
		}

		.telly{
			position: absolute; 
			left: 0; 
			right: 0; 
			margin-left: auto; 
			margin-right: auto; 
			width: 100%; /* Need a specific value to work */
			bottom: 0px;
		}

		.big-pink-star{
			position: absolute;
			top: 198px;
			left: 152px;
		}

		.little-pink-star{
			position: absolute;
			bottom: 110px;
			right: 192px;
		}

		.blue-star{
			position: absolute;
			bottom: 100px;
			left: 163px;
		}

		.green-star{
			position: absolute;
			top: 263px;
			right: 385px;
		}

		.yellow-star-spikey{
			position: absolute;
			top: 387px;
			left: 402px;
		}

		.yellow-star-1-1{
			position: absolute;
			bottom: 350px;
			right: 155px;
		}

		.yellow-star-1-2{
			position: absolute;
			bottom: 104px;
			right: 416px;
		}

		.yellow-star-2-1{
			position: absolute;
			top: 71px;
			left: 331px;
		}

		.yellow-star-2-2{
			position: absolute;
			left: 249px;
			bottom: 358px;
		}

		.yellow-star-2-3{
			position: absolute;
			top: 152px;
			right: 144px;
		}

		.yellow-star-3{
			position: absolute;
			bottom: 411px;
			right: 436px;
		}
		
		.back-button{
			position: absolute;
			bottom: 13.5%;	// Eyeballed this number so that button stays in Telly's hands on different size screens (because Telly height dynamic)
			left: 0; 
			right: 0; 
			margin-left: auto; 
			margin-right: auto; 
			height: 46px;
			width: 165px;
			border-radius: 20px;

			background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 30.85%, rgba(255, 255, 255, 0) 49.85%, rgba(255, 255, 255, 0.15) 55.55%, rgba(255, 255, 255, 0) 121.58%), linear-gradient(180deg, #82F14A 27.5%, #EDE423 109.87%);
			background-blend-mode: screen, normal;
			box-shadow: inset -0.507491px 2.53746px 3.80618px rgba(255, 255, 255, 0.4), inset 0px -1.26873px 6.34364px rgba(40, 104, 2, 0.55);
		
			span {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;

				color: #09090A;
				letter-spacing: -0.002em
			}
		}
	}

	&.unhandled-thrown-error {
		.background {
			.telly-glow {
				height: 65%;
			}
		}
	}
}