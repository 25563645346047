.badges-wrapper {
    display: flex;

    .event-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        position: relative;
        height: 24px;
    
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            margin: 4px 0px;
            white-space: nowrap;
        }
    
        &.broadcast {
            color: #121114 !important;
            background: #A6FF79;
        }

        &.recording {
            background: #CE810A;
        }

        &.tech.check{
            background: #4F4C57;
        }

        &.rehearsal {
            background: #763A95;
        }
    }

    .countdown-badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        position: relative;
        height: 24px;
        background: #4F4C57;
        border-radius: 1.428rem;

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #FFFFFF;
        }
    }

    .live-badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        position: relative;
        height: 24px;
        background: $border-gray;
        border-radius: 1.428rem;

        .live-indicator {
            height: 6px;
            width: 6px;
            background-color: #EC0C00;
            border-radius: $rem100px;
            margin: 4px 4px 4px 0;
        }

        span {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #FFFFFF;
        }
    }
}
