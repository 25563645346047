html, body {
	@include custom-scrollbar;
	overflow-y: hidden;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: $white;
	background-color: #1A1A1F;
	cursor: default;
	overscroll-behavior: none;
}

.wrapper {
	@include absolute-fill;
	@include custom-scrollbar;
	overflow: hidden;
	display: flex;
}

.absolute-fill {
	@include absolute-fill;
}

.fixed-fill {
	@include fixed-fill;
}

@keyframes keepAnimating {
	from {
		transform: translateZ(0);
	}

	to {
		transform: translateZ(1px);
	}
}

.left-panel {
	background-color: #1A1A1F;
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;

	.left-panel-content {
		height: 100%;
		//margin: 0auto;
		//position: absolute;
		//top: 72px;
		// left: 0;
		// right: 0;
		// bottom: 0;
		// max-width: 120vh;
	}
}

.wrapper.embed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #1c1c1f;

	.left-panel {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		max-width: 100vw;
		width: 100vw;
	}

	&.animate {
		animation: 1000ms keepAnimating infinite; //we need this to keep the framerate painting while nothing is live
		animation-direction: alternate;
		animation-timing-function: linear;
		cursor: none;
	}

	&.framebuffer {
		background-color: #1c1c1f;
		z-index: 999;
	}
}

.right-panel {
	@include custom-scrollbar;
	background-color: #26242B;
	position: relative;
	overflow-x: hidden;
	overflow-y: hidden;
}

.noscroll {
	overflow: hidden;
}