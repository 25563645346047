
//************************************************************
// Global mixins
//************************************************************

// Global content width

@mixin global-width() {
    max-width: $content-max-width;
    margin: auto;
	width: 100%;
}

// Clear Appearance for form elements

@mixin clear-appearance() {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;

	// Clear appearance for <select> and <input> radio/checkboxes in IE10+
	&::-ms-expand {
		display: none;
	}
	&::-ms-check {
		display: none;
	}
}

// Absolute Position objects in middle or center of parent object

@mixin position($value) {
	position: absolute;

	@if $value == 'middle' {
		top: 50%;
		transform: translateY(-50%);
	}

	@else if $value == 'center' {
		left: 50%;
		transform: translateX(-50%);
	}
}

// Disable form elements

@mixin disabled() {
	&[disabled] {
		// cursor: not-allowed;
		background: $element-background-highlight-gray;
		pointer-events: none;
		opacity: 0.45;
	}

	&[readonly] {
		pointer-events: none;
	}

	&[disabled] {
		&:hover {
			background: $color-off-white;
			border-color: $color-field-border;
		}
	}
}

// Keyframes mixin (always a pain to write them multiple times)
	// For use in the _animations.scss file ONLY
	// Example usage: @include keyframes(myAnimation) { CSS }

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

// Animation mixin
	// For us in context with the object styles
	// Example usage with multiple arguments: @include animation(myAnimation 1s ease-in)

@mixin animation($nameAndProperties) {
	// -webkit-animation: $nameAndProperties;
	// -moz-animation: $nameAndProperties;
	animation: $nameAndProperties;
}

// Show / Hide

@mixin showhide($class: show, $duration: 0.2s) {
	visibility: hidden;
	opacity: 0;
	transition: $duration opacity,
				$duration visibility;

	&.#{$class} {
		opacity: 1;
		visibility: visible;
	}
}

// Modal mixins

@mixin modal-scroll() {
	max-height: 540px;
	overflow: auto;
}

// Dashed content block

@mixin dashed-block() {	
	background: $color-white;
	border-radius: $radius-container;
	border: $border-dashed-blue;
	color: $color-link;
	transition: background $hover-transition-duration,
				border-color $hover-transition-duration,
				color $hover-transition-duration;

	&:hover:not(.entered) {
		background: rgba($color-blue,.1);
	}
}

// Range Slider

@mixin slider-thumb() {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20);
	border: $rem1px solid $color-border-light;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: $color-white;
	cursor: pointer;
}

@mixin range-slider-thumb() {
	&::-webkit-slider-thumb {
		@content;
	}
	&::-moz-range-thumb {
		@content;
	}
	&::-ms-thumb {
		@content;
	}
}

@mixin range-slider-track() {
	&::-webkit-slider-runnable-track {
		@content;
	}
	&::-moz-range-track {
		@content;
	}
	&::-ms-track {
		@content;
	}
}

// Grid layout mixin
// Use these rules on the parent container:
/* 	
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
*/

@mixin grid($cols, $gutter) {
	@if $cols == 1 {		
		flex-basis: 1;
		width: 100%;
		margin: 0 0 calc(($gutter / 2));
	}

	@else {
		flex-basis: auto;
		width: calc(#{calc(100% / $cols)} - #{calc(($gutter * ($cols)) / $cols)});
		margin: 0 calc(($gutter / 2)) $gutter;
	}

	// &:nth-child(#{$cols}n+#{$cols}) {
	// 	margin-right: 0;
	// }
	// &:nth-child(1),
	// &:nth-child(#{$cols}n+#{$cols}) + * {
	// 	margin-left: 0;
	// }
}

// Tooltip caret style

@mixin caret($size) {
	background: $color-white;
	border: $border-popover;
	border-left: 0;
	border-bottom: 0;
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	position: absolute;

	@if $size == 'small' {
		top: -6px;
		width: 12px;
		height: 12px;
	}

	@else if $size == 'large' {
		top: -7px;
		width: 14px;
		height: 14px;
	}
}

// Fix for floating elements 
	// (this shouldn't be used much, it's an old school method)

@mixin clearfix() {
	&::after {
		display: block;
		content: '';
		clear: both;
	}
}

// Truncate text when the container is too small
	// (this is generally a last resort to text wrapping)

@mixin truncate() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Mobile horizontal scroll

@mixin mobile-hz-scroll() {
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	overflow-x: auto;
	scrollbar-width: none; /* Firefox */
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;  /* IE 10+ */

	&::-webkit-scrollbar {
		display: none;
		width: 0px;
		background: transparent; /* Chrome/Safari/Webkit */
	}

	> * {
		flex: 0 0 auto;
	}
}

@mixin custom-scrollbar($width: 16px, $isStyled: 0) {
	@if $isStyled == 0 {
		color-scheme: dark;
	}

	@if $isStyled != 0 {
		* {
			scrollbar-width: 16px;
			scrollbar-color: #3C3A42;
		}
	
		/* Chrome, Edge, and Safari */
		*::-webkit-scrollbar {
			width: 16px;
		}
	
		*::-webkit-scrollbar-track {
			background-color: transparent;
			background: transparent;
			width: 16px;
		}
	
		*::-webkit-scrollbar-thumb {
			background-color: #3C3A42;
			border-radius: 99px;
			width: auto;
			border: 4px solid transparent;
			background-clip: content-box;
		}
	
		/* Handle on hover */
		*::-webkit-scrollbar-thumb:hover {
			background-color: #403e47;
		}
	
		*::-webkit-scrollbar-thumb:active {
			background-color: #3b3940;
		}
		
		*::-webkit-scrollbar-button {
			display: none;
		}
	}
}

.custom-scrollbar {
	@include custom-scrollbar(24px, 1);
}

// Font mixins
// Roboto
@mixin font-roboto-12() {
	font-family: Roboto; 
	font-style: normal; 
	font-weight: normal; 
	font-size: 12px;
}

@mixin font-roboto-12-500() {
	font-family: Roboto; 
	font-style: normal; 
	font-weight: 500; 
	font-size: 12px;
}

@mixin font-roboto-14() {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
}

@mixin font-roboto-14-500() {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

@mixin font-roboto-14-bold() {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
}

@mixin font-roboto-16() {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
}

@mixin font-roboto-16-500() {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
}

@mixin font-roboto-18-bold() {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
}

// Poppins
@mixin font-poppins-12() {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: $rem12px;
}

@mixin font-poppins-12-500() {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: $rem12px;
}

@mixin font-poppins-14() {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: $rem14px;
}

@mixin font-poppins-14-500() {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: $rem14px;
}

@mixin font-poppins-14-bold() {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: $rem14px;
}

@mixin font-poppins-16() {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: $rem16px;
}

@mixin font-poppins-16-500() {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: $rem16px;
}

@mixin font-poppins-18-bold() {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: $rem18px;
}