
//************************************************************
// Overlay
//************************************************************

@keyframes fadeOverlayIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.7;
	}
}

@keyframes fadeOverlayOut {
	0% {
		opacity: 0.7;
	}
	100% {
		opacity: 0;
	}
}

.overlay {
	background: $color-overlay;
	position: fixed;
	top: $sizing-header-onboarding;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-index-meta-menu-bg;
	opacity: 0;
	animation: fadeOverlayIn 100ms ease-in-out forwards;
	
	
	&.closed-menu {
		animation: fadeOverlayOut 100ms ease-in-out forwards;
	}
}

//************************************************************
// Modals
//************************************************************

// Modal wrap and interior transitions

.modal-wrap {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	padding: 44px 10px 10px;
	z-index: $z-index-modal;

	@include break-min($break-med + 1) {
		padding: 50px 20px;
    }
    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        align-items: flex-start;
    }

	&:not(.full) > .modal {
		opacity: 0;
		top: 50px;
		transition: opacity $modal-transition-duration ease-in-out,
					top $modal-transition-duration ease-in-out;
	}
	
	&.full {
		overflow: hidden;
		padding: 0;

		> .modal {
			border-radius: 0;
			max-width: none;
			margin: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 50px;
			opacity: 0;
			transition: opacity $modal-transition-duration ease-out,
						top $modal-transition-duration ease-out;

			> .modal-header {
				align-items: center;
				background: darken($color-dark-gray, 10%);
				display: flex;
				height: $header-size;
				padding: 0;
				position: relative;

				h3 {
					color: $color-white;
					font-size: $rem21px;
					font-weight: $light;
					position: relative;
					left: $global-padding;
					z-index: 2;
				}

				input[type="text"].small {
					background: rgba($color-dark-gray, 0.5);
					border-color: transparent;
					box-shadow: none;
					color: $color-white;
					font-size: $rem18px;
					font-weight: $light;
					margin: 0;
					position: relative;
					left: $global-padding;
					width: 100%;
					max-width: 300px;
					z-index: 2;

					&:focus {
						border-color: rgba($color-white, 0.12);
					}
				}

				.modal-actions {
					display: flex;
					justify-content: flex-end;
					margin-left: auto;
					align-items: center;
					position: relative;
					right: 76px;
					z-index: 2;

					// Buttons in modal actions should be 'small' or 'xsmall'
					button {
						margin: 0 0 0 10px;
					}

					// Links in modal actions take the same height as 'small' buttons
					a {
						color: $color-white;
						height: $button-height-small;
						line-height: $button-height-small;
						font-size: $rem16px;
						font-weight: $semi-bold;
						margin-left: 20px;

						i {
							margin-right: 5px;
						}

						&:hover {
							color: $color-light-blue;
						}
					}

					.sync-filters {
						margin-right: 10px;
						font-size: 12px;
						color: $color-gray;
					}
				}

				.modal-close {
					top: 18px;
					right: 22px;
				}

				.toggle-device {
					z-index: 1;
				}
			}

			> .modal-body {
				border-radius: 0;
				padding: 0;
				position: absolute;
				top: $header-size;
				left: 0;
				width: 100%;
				height: calc(100% - #{$header-size});
				overflow-y: auto;

				.datalist,
				.table {
					padding-left: $global-padding;
					padding-right: $global-padding;
				}

				.modal-wrap:not(.full) .datalist,
				.modal-wrap:not(.full) .table {
					padding: 0;
				}
			}

			.create-settings-section {
				margin-top: $global-padding + 10px;
				margin-bottom: ($global-padding + 10px) * 2;
			}
		}

		// For modal within a modal
		.modal-wrap {
			.modal {
				z-index: 2;
			}

			// Faux overlay
			&::after {
				content: '';
				display: block;
				@extend .overlay;
				visibility: visible;
				@include animation(fadeIn 0.2s);
				opacity: 1;
				z-index: 1;
			}
		}
	}

	&.show .modal {
		opacity: 1;
		top: 0;
	}

	&.alert {
		> .modal {
			box-shadow: none;
			background: none;
			width: auto;
			text-align: center;
			pointer-events: none;

			i {
				display: block;
				font-size: 90px;
			}

			h1,
			p {
				color: $color-white;
				margin: 15px 0;

				+ button {
					margin-top: 50px;
				}
			}

			p {
				font-size: $rem16px;
				line-height: $rem28px;
			}

			button {
				margin: 30px auto 0;
				pointer-events: auto;

				// &.cancel:hover {
				// 	color: $color-blue;
				// }

				&.caution {
					min-width: 200px;
				}

				&.neutral.outline {
					color: $color-white;
					border-color: $color-white;

					&:hover {
						color: $color-text-light;
					}
				}
			}

			i.icon-checkmark-outline,
			i.icon-rocket {
				color: $color-green;
			}

			i.icon-warn-outline {
				color: $color-red;

				&.orange {
					color: $color-brand-orange;
				}
			}
		}
	}

	&.uploading {
		z-index: 100;
		background: $color-overlay !important;
		pointer-events: none;

		.progress-mb {
			margin-top: 20px;
			color: $color-med-gray;
			font-weight: bold;
		}

		.progress-wrap {
			height: 1px;
			margin-top: 10px;
			background: $color-metric;
			width: 100%;
			overflow: hidden;
			position: relative;
			min-width: 240px;

			.progress-wipe {
				background: $color-med-gray;
				height: 1px;
				width: 100px;
				animation: progressWipe 1.5s infinite;
				position: absolute;
			}

			.progress-bar {
				height: 1px;
				background: $color-med-blue;
				position: absolute;
			}
		}
	}

	&.image {

		padding: 0;

		img {
			height: fit-content;
			margin: 0 auto;
			max-height: 80%;
			max-width: 90%;
			margin-top: 2%;
		}
	}
}

// Modal base Styles

.modal {
	background: $color-white;
	border-radius: $radius-modal;
	box-shadow: $shadow-modal;
	width: 100%;
	margin: auto;
	max-width: 420px;
	position: relative;

	.modal-icon {
		background: $color-white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 52px;
		height: 52px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -26px;

		> i {
			color: $color-brand-blue;
			font-size: 24px;
		}
	}

	&.small {
		max-width: $modal-size-small;
	}

	&.medium {
		max-width: $modal-size-medium;
	}

	&.large {
		max-width: $modal-size-large;

		@include break-max($break-med) {
			&:not(.registration) {
				max-width: $break-sm !important; // override modal max width globally for mobile, except for registration
			}
		}
	}

	h1,h2,h3,h4,h5,h6 {
		color: $color-dark-blue;
		margin: 0 0 15px;
		text-align: center;
	}

	h1 {
		font-weight: $xbold;

		+ p {
			margin: 0;
		}
	}

	p {
		text-align: center;
		line-height: $rem26px;
		font-size: $rem16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	hr {
		border-bottom-color: $color-border-light;
		margin: 30px 0;
	}

	.close {
		position: absolute;
		top: -40px;
		right: -8px;
		z-index: 2;
	}

	.tab-nav {
		background: none;
		border-bottom: 0;

		> a {
			font-size: $rem14px;
			padding: 0 20px 16px;
		}
	}

	.create-settings-section {
		hr {
			@include break-min($break-xlrg) {
				margin: 40px 0;
			}
		}
	}
}

// Modal Header

.modal-header {
	padding: 20px;

	@include break-min($break-sm + 1) {
		padding: $modal-padding;
	}

	.icon-warn {
		color: $color-warn;
	}
}

// Global modal body styles

.modal-body {
	padding-left: calc($modal-padding-horizontal / 2);
	padding-right: calc($modal-padding-horizontal / 2);
	border-radius: 0 0 $radius-container $radius-container;
	overflow: hidden;

	@include break-min($break-sm + 1) {
		padding-left: $modal-padding-horizontal;
		padding-right: $modal-padding-horizontal;
	}

	h5 {
		margin: 20px 0 10px !important; // Important to override styles inherited from h5 styles in settings
		text-align: left;

		&:nth-of-type(1) {
			margin-top: 0 !important;
		}

		+ p {
			margin: 0 0 20px;
			text-align: left;
		}
	}

	&.dark {
		background: $color-off-white;
		// border-top: 1px solid $color-border-lighter;
		padding: $modal-padding;

		+ .modal-footer {
			border-top: 1px solid $color-border-lighter;
		}

		.searchbar {
			margin: -$modal-padding-vertical 0 0;
			padding: 5px 0 0;
			position: relative;
			z-index: 1;

			input {
				background: transparent;
			}

			+ .content-module-body {
				padding-top: 20px;
				margin-top: 0;
			}
		}

		.content-module-body {
			margin-top: -$modal-padding-vertical;
			margin-bottom: 0;
			padding: $modal-padding;
		}

		// Override bottom padding if .content-module-body is a direct descendant of .modal-body.dark

		> .content-module-body {
			padding-bottom: 0;
		}

		.content-module-pane {
			margin-top: -$modal-padding-vertical;
		}

		.table {
			margin-top: -15px;
		}
	}

	.email-valid {
		text-align: center;
		width: 100%;
		display: block;
		font-weight: lighter;
		color: $color-green;
	}

	.email-invalid {
		text-align: center;
		width: 100%;
		display: block;
		font-weight: lighter;
		color: $color-red;
	}

	.field-group,
	textarea {
		margin: 0 0 20px;
	}

	// Styles specifically for when a component is loaded into a modal

	.content-module-pane {
		// Transition for toggling between windows in content module
		@include animation(content-module-pane 0.3s ease-out);
	}

	.content-module-body {
		padding: 0 $modal-padding-horizontal;
		margin: 0 (-$modal-padding-horizontal);
		max-height: $modal-body-max-height;
		overflow: auto;
		position: relative;
		z-index: 0;

		> *:not(figure):not(textarea):last-child {
			margin-bottom: 0;
		}

		.selection-list {
			margin-top: 0;
		}

		> p:nth-of-type(1) {
			margin-top: 0;
		}
	}

	.content-module-footer {
		position: relative;
		z-index: 1;

		&.light {
			background: $color-white;
			border-top: 1px solid $color-border-lighter;
			margin: 0 (-$modal-padding-horizontal) (-$modal-padding-vertical);
			padding: $modal-padding;
		}

		> * {
			margin: 0 auto 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		> button {
			margin-bottom: 15px;
			min-width: 200px;
		}

		.field-group + .action {
			margin-top: $modal-padding-vertical;
		}
	}
}

// Modal Footer

.modal-footer {
	padding: $modal-padding;

	.button,
	button,
	input[type="button"],
	input[type="submit"] {
		margin: auto;
		min-width: 200px;

		+ .cancel {
			margin-top: 20px;
		}
	}
}