$transition-property: all 0.2s;

@mixin darkText {
	text-transform: none !important;
	background: $bl-grayscale-900;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin lightText {
	text-transform: none !important;
	background: $pink-lemonade;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.style-packs-bubble-filters {
	@include darkText();
	background: none;
	
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;

	min-width: 100%;
	max-width: 100%;
	overflow: hidden;

	margin-bottom: 16px;

	.style-packs-bubble-filter {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
		border-radius: 99rem;
		
		flex: 1;
		background: #FFFFFF0D;
		max-width: 20%;
		transition: $transition-property;
		position: relative;
		cursor: pointer;

		svg {
			path {
				transition: $transition-property;
				fill: $bl-grayscale-300 !important;
			}
		}

		background-image: none;
		color: rgba(0, 0, 0, 0);
		z-index: 2;

		* {
			z-index: 2;
		}
		
		.text-container {
			overflow: hidden;
			white-space: nowrap;
			background: none;
			padding: 0;
			margin: 0;
	
			max-width: 0;
			transition: $transition-property;
			position: relative;
			left: 8px;			
		}
	
		&:hover {
			max-width: 100%;
			padding: 8px 24px 8px 16px;
			.text-container {
				// margin-left: 8px;
				max-width: 100%; 
			}
		}

		.text-container {
			@include lightText();
		}
		
		&.filter-bubble-highlighted,
		&:hover {
			.text-container {
				@include lightText();
			}
			svg { 
				path {
					fill: #FFF97D !important;
				}
			}
			// before == bright
			&::before { 
				opacity: 0;
			}
			&::after { 
				opacity: 1; 
			}
		}
		
		&:active {
			.text-container {
				@include darkText();
			}
			svg { 
				path {
					fill: $bl-grayscale-900 !important;
				}
			}
			// before == bright
			&::before { 
				opacity: 1;
			}
			&::after {
				opacity: 0;
			}
		}
	}
}


@mixin baseGradientBg() {
	position: absolute;
	content: "";
	inset: 0;
	z-index: 1;
	opacity: 0;
	transition: $transition-property;
	border-radius: 99rem;
}

.style-packs-bubble-filter::before {
	@include baseGradientBg();
	background-image: $pink-lemonade;
}

.style-packs-bubble-filter::after {
	@include baseGradientBg();
	background-image: $dullSherbert;
}

.style-packs-bubble-filter-tabbed {
	margin-bottom: 16px;
	padding-left: 0;
	.sliding-menu-underline {
		background: $pink-lemonade;
	}

	.sliding-menu-option {
		transition: all 200ms;
		&:hover {
			color: white;
		}
		&.selected {
			@include lightText();
		}
	}
}

.background-pink-lemonade {
	background: $pink-lemonade-rev
}