@include scrubbar-slider;

$shot-thumbnail-padding: 3px;

.shot-drawer-header {
	display: flex;
	align-items: center;
	margin: 10px 24px;

	.header-text {
		flex: 1;
	}

	.next-countdown {
		color: $bl-grayscale-300;
	}

	.header-status {
		padding: 0 18px 0 12px;
	}
}

.shots-tab-wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0 $drawer-padding;

	.video-thumbs {
		margin-bottom: $rem12px;
		margin-top: $rem12px;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

		
	.autoplay-toggle {
		margin-left: auto;
		@include button-greenroom-basic();
		background-color: none;
		border-radius: 99px;
		padding: 10px;
		
		&.on {
			padding: 8px;
			background-color: $border-gray;
		}
	}

	label {
		font-family: Poppins;
		font-weight: bold;
		color: $text-gray;
	}

	.switch-row {
		background: $border-gray;
		outline: 0px solid transparent;
		transition: height 200ms, margin 200ms, padding 200ms;
		opacity: 1;
		height: 72px;

		.title-play {
			opacity: 0;
			transition: opacity 150ms;
			padding: 8px 16px;
			min-width: 68px;
		}

		&:hover {
			.title-play {
				opacity: 1;
			}
		}

		&.toggled-on {
			outline: 2px solid $pale-green;
		}

		&.collapsed {
			height: 0;
			padding: 0;
			outline: none;
			margin: 0;
			display: none;
		}
	}

	input {
		background: none;
		border: none;
		color: white;
		font-size: 1rem;
		padding: 0;

		&::placeholder {
  			color: #908CA0;
  			opacity: 1;
		}

		&:focus {
			outline: none;
		}

		&.shot-cue {
			line-height: normal;
		}
	}

	.edit-duration {
		justify-content: center;
		background: none;
		box-sizing: border-box;
		margin: 0;
		padding: 0;

		input {
			width: 78px;
			height: 32px;
			background: #1A1A1F;
			border: $rem1px solid #54545A;
			box-sizing: border-box;
			border-radius: $rem100px;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.224px;
			color: #FFFFFF;
			text-align: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre;
			padding: 8px 10px;

			&:focus {
				border: $rem2px solid $pale-green;
				outline: none;
			}
		}
	}

	.edit-button {
		@include button-greenroom-basic;
		background: #4F4C57;
		padding: 12px;
		pointer-events: auto;

		&.disabled {
			opacity: 1;
			background-color: $element-background-gray;
		}
	}

	.thumbnail-edit-button {
		padding: 0 16px;
		height: 44px;
	}

	.shot-note-container {
		flex: 1;
		margin-bottom: 64px;

		.shot-note {
			height: 100%;
			line-height: 1.5;
		}
	}

	.shot-tab-shot-thumbnail-container {
		width: 100%;
		padding: 0 12px;

		&.shot-drawer-improvements,
		&.shot-drawer-improvements .shot-thumbnail .shot-thumbnail-inside {
			padding: 0;
		}
	}

	.shot-tab-button-container {
		position: absolute;
		bottom: 0;
		margin-bottom: 16px;
	}

	.shot-thumbnail {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		margin-bottom: 20px;
		border: $rem2px solid transparent;
		border-radius: 12px;

		.shot-thumbnail-inside {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			padding: $shot-thumbnail-padding;
			border-radius: $rem8px;
			overflow: hidden;
			z-index: 0;

			&.selectable {
				cursor: pointer;

				&:active {
					transform: translate(1px, 1px);
				}
			}

			.custom-graphics-canvas-container {
				.custom-graphics-canvas {
					border-radius: $rem8px;
				}
			}

			.shot-thumbnail-overlay {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0;
				left: 0;
				border-radius: 8px;
				background: rgba(26, 26, 31, 0);
				opacity: 0;
				transition: background $transition-fast, opacity $transition-fast;

				&:active {
					top: 0px;
					left: 0px;
				}

				.edit-shot {
					display: flex;
					width: 32px;
					height: 32px;
					background: #4F4C57;
					border-radius: 100px;
					padding: 10px;
				}

				.play-shot {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 32px;
					background: #A6FF79;
					border-radius: 100px;
					padding: 8px 16px;
					font-weight: bold;
					font-size: 12px;
					letter-spacing: 0.4px;
					color: #121114;

					span {
						margin-right: 9.5px;
						white-space: nowrap;
					}

					&:active {
						transform: scale(0.95);
					}
				}
			}

			.custom-graphics-canvas-container {
				position: absolute;
				inset: $shot-thumbnail-padding;
			}
		}

		.stream-media-thumbnail {
			width: 72px;
			height: 40px;
			margin: 3px;
			pointer-events: none;
			position: relative;
			border-radius: 8px;
			cursor: pointer;
			background-color: #151516;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				border-radius: 8px;
			}

			video {
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				border-radius: 8px;
			}
		}

		.main-video {
			position: relative;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-clip: padding-box;
			border-radius: 8px;

			&.layer2 {
				position: absolute;
				padding: 5px;
			}

			.video-container-presenter {
				display: flex;
				justify-content: center;
				align-items: center;

				.empty-video {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #1d1b21;
					height: 100%;
					width: 100%;

					.placeholder-icon {
						@include flex-center;
						max-width: 100%;
						min-width: 100%;
						padding-left: 35%;
						padding-right: 35%;
					}
				}

				.poster {
					.no-image-placeholder {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						width: 100%;

						span {
							font-size: calc(48px * .5);
							height: 10px;
							width: 10px;
						}
					}
				}

				.title-card {
					margin: calc(8px * .5);

					&.max-width {
						min-width: calc(100% - 16px * .5);
					}

					&.no-left-margin {
						margin-left: 0;
					}

					.card-3 {
						height: calc(42px * .5);

						.user-data {
							font-size: calc(12px * .5);
							margin-left: calc(8px * .5);
							text-shadow: none;

							.job-title, .location {
								font-size: calc(11px * .5);
							}
						}
					}
				}

				.poster {
					.no-image-placeholder {
						span {
							font-size: calc(48px * .5);
							height: 50px;
							width: 50px;
						}
					}
				}
			}
		}

		&.selected {
			border-color: $pale-green;
		}

		&:hover {
			.shot-thumbnail-overlay {
				background: rgba(26, 26, 31, 0.5);
				opacity: 1;
			}
		}

		.duration-bar {
			opacity: 0;
			left: 99%;
			position: absolute;
			top: -3%;
			width: 6.16px;
			height: 106%;
			background: #A6FF79;
			box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			pointer-events: none;

			&.playing {
				opacity: 1;
				left: -1%;
				animation-name: movePlayLine;
				animation-timing-function: linear;
				animation-iteration-count: 1;

				@keyframes movePlayLine {
					from { left: -1%; }
					to { left: 99%; }
				}
			}
		}
	}

	.shot-note-container {
		margin-bottom: 36px;

		&[contentEditable=true]:empty:before {
			content:attr(placeholder-text);
			color: #908CA0;
			opacity: 1;
		}
	}

	.bottom {
		bottom: 18px;
	}

	.shots-label {
		color: $text-light-gray;
	}

	.bottom-action-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 0;
		justify-content: flex-start;
		width: 90%;
		pointer-events: none;

		.play-shot-btn {
			height: 44px;
			width: 19.5rem;
			pointer-events: auto;
		}

		&.shot-drawer-improvements {
			bottom: 0;
			padding: 12px 24px;
			margin-left: -24px;
			width: $global-drawer-width;
			background-color: $element-background-gray;

			.play-shot-btn {
				height: $drawer-action-button;
				flex: 1;
			}

			.edit-button {
				height: $drawer-action-button;
				width: $drawer-action-button;
			}
		}
	}

	.overlays {
		margin: 0 0 16px 0;
		transition: height 300ms, margin 200ms, padding 200ms;

		&.allCollapsed {
			margin: 0;
			padding: 0;
			height: 0;
		}

		.image-card-toggle {
			display: flex;
			flex: 1;
			background-color: $border-gray;
			border-radius: $rem16px;
			position: relative;
			margin: 10px 0 0 0;

			.thumbnail {
				@include ratio-width-height($thumbnail-width, 16, 9);

				margin: 8px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #151516;
				border-radius: $rem8px;
				overflow: hidden;

				.thumbnail-container {
					@include flex-center;
					width: 100px;
					height: 56.25px;
					border-radius: $rem8px;
					overflow: hidden;

					img {
						max-width: 100%;
						max-height: 100%;
						min-width: calc(100% + 0.5px);
						min-height: calc(100% + 0.5px);
						object-fit: contain;
					}

					&.fill {
						img {
							object-fit: cover;
						}
					}
				}
			}

			.image-card-details{
				display: flex;
				flex: 1;
				margin: 8px;
				position: relative;
				align-items: center;

				.image-card-details-metadata {
					display: flex;
					flex-direction: column;
					flex: 1;
				}

				.image-card-title {
					@include font-poppins-16-500;
					line-height: 1.4rem;
					color: $near-white;
					margin: 0px;
					user-select: none;
				}

				.image-card-subtitle {
					margin: 0px;
					font-size: 13px;
					line-height: 1.2rem;
					color: $text-gray;
					.bold {
						font-weight: 700;
					}
				}

				.image-card-title,
				.image-card-subtitle {
					max-width: 150px;
				}

				.image-card-children {
					display: flex;

					.title-play-container {
						position: absolute;
						display: flex;
						justify-content: flex-end;
						left: 0;
						right: 110px;
					}
				}

				.button-toggle {
					padding: 6px 6px;
					margin-right: 8px;
					min-width: 77px;
				}
			}

			.title-play {
				opacity: 0;
				transition: opacity 150ms;
			}

			&:hover {
				.title-play {
					opacity: 1;
				}
			}

			&.showing {
				outline: 2px solid $pale-green;

				.button-toggle {
					color: #000000;
				}
			}
		}
	}
}

.shots-tab-wrapper {
	.video-thumbs {
		gap: 8px;
	}
}

.shot-drawer-thumbnail {
	img, .video-thumb {
		height: 104px !important;
		width: 184px !important;

		.video-thumb {
			.video-wrapper {
				height: 104px !important;
				width: 184px !important;
				padding: 0;
			}
		}

		.video-wrapper {
			height: 104px !important;
			width: 184px !important;

			.media-name {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(1, 1, 1, 0.0001) 0%, #000000 93.16%);
				position: absolute;
				left: 0;
				right: 0;
				bottom: -1px;
				top: 58.42%;

				&.no-background {
					background: none;
				}

				label {
					color: $near-white;
					text-align: left !important;
					position: absolute;
					bottom: 8px;
					left: 8px;
					right: 20px;
					padding: 0 !important;
					margin: 0 !important;
					text-overflow: ellipsis !important;
					overflow: hidden;
					white-space: nowrap;
					font-weight: 600;
					line-height: 16px;
					font-size: 13px;
					width: 90%;
				}
			}

			.video-overlay {
				.live-time-red {
					display: none;
				}

				.video-overlay-bottom {
					label {
						color: $near-white;
						text-align: left !important;
						position: absolute;
						bottom: 8px;
						left: 8px;
						right: 16px;
						padding: 0 !important;
						margin: 0 !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
						white-space: nowrap;
						font-weight: 600;
						line-height: 16px;
						font-size: 13px;
						width: 90%;
					}

					.onsite-location {
						label {
							left: 4px;
						}
					}
				}

				.media-thumb-container {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
					background-color: rgba(0, 0, 0, .0);
					transition: background-color $transition-fast;
					user-select: none;

					&.hover {
						background-color: rgba(0, 0, 0, .4);
					}

					.media-thumb-controls {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 36px;
					}

					.media-thumb-volume {
						display: flex;
						align-items: center;
						justify-content: left;
						position: absolute;
						bottom: 10px;
						right: 20px;
						left: 10px;
						text-align: left;
						padding: 0;
						margin: 0;

						input {
							border-radius: 100px;
							-webkit-appearance: none;
							margin-left: 9px;
							cursor: default;
							width: 100%;
						}
					}

					.media-thumb-badge-row {
						position: absolute;
						top: 6px;
						left: 6px;
						gap: 4px;

						&.is-audio-only {
							left: 34px;
						}

						&,
						.media-thumb-time {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
						}

						.media-thumb-time {
							color: $white;
							background: rgba(23, 23, 23, 0.7);
							padding: 4px 8px;
							border-radius: $rem12px;
							gap: 10px;
							font-weight: 600;
							font-size: 11px;
							line-height: 16px;
							letter-spacing: 0.02em;

							&.small-gap {
								gap: 3px;
							}
						}
					}
				}
			}
		}
	}


	.empty-shot {
		border-radius: $rem12px;
		background-color: $bl-grayscale-800;
		.screenshare {
			text-align: center;
		}
	}

	.has-aof-icon {
		.video-wrapper {
			.producer-label {
				left: 32px;
				top: 6px;
			}
		}
	}

	.video-thumb {
		.video-wrapper {
			.empty-shot {
				height: 104px !important;

				.empty-video {
					svg {
						margin-top: 30px;
					}

					.name-label {
						color: $near-white;
						text-align: left !important;
						position: absolute;
						bottom: 8px;
						left: 8px;
						right: 16px;
						padding: 0 !important;
						margin: 0 !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
						white-space: nowrap;
						font-weight: 600;
						line-height: 16px;
						font-size: 13px;
						width: 90%;
					}
				}
			}
		}
	}

	.video-wrapper {
		.video-thumb {
			.video-wrapper {
				.video-overlay {

					label {
						color: $near-white;
						text-align: left !important;
						position: absolute;
						bottom: 8px;
						left: 8px;
						right: 20px;
						padding: 0 !important;
						margin: 0 !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
						white-space: nowrap;
						font-weight: 600;
						line-height: 16px;
						font-size: 13px;
						width: 90%;
					}
				}
			}
		}

		&.no-selection-border {
			.video-thumb {
				.video-wrapper {
					.selection-border {
						display: none;
					}
				}
			}
		}

		img {
			object-fit: cover;
		}
		&.fit-thumbnail {
			text-align: center;
			img {
				object-fit: contain;
			}
		}
	}
}

.aof-icon-wrapper {
	background-color: rgba(23, 23, 23, 0.7);
	border-radius: $rem12px;
	width: $rem24px;
	height: $rem24px;
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 6px;
	left: 6px;

	&.in-shot {
		background-color: $pale-green;
	}
}

.media-thumb-controls-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	gap: 4px;
	padding: 0;
	margin-right: 4px;
	border-radius: 100px;

	height: 32px;
	width: 32px;

	&.gray {
		background-color: $bl-grayscale-600;
	}

	&.disabled {
		background-color: $bl-grayscale-transparent-600;
	}
}

.button-toggle-eye {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-top-right-radius: $rem16px;
	border-bottom-right-radius: $rem16px;
	width: 95px;
}

.button-toggle-eye:hover {
	background-color: $bl-grayscale-700;
}