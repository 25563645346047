.animate-height {
    display: grid;
}

.animate-height-enter {
    grid-template-rows: 0fr;
}

.animate-height-enter-active {
    grid-template-rows: 1fr;
    transition: grid-template-rows $transition-fast;
}

.animate-height-exit {
    grid-template-rows: 1fr;
}

.animate-height-exit-active {
    grid-template-rows: 0fr;
    transition: grid-template-rows $transition-fast;
}
