//COLORS (so they dont interfere with events colors)
$trueBGcolorEmbed: #26242B;
$hotpinkembed: rgba(251, 3, 244);
$blueembed: rgba(3, 159, 251);
$greenembed: rgba(166, 255, 121);


$fadeCosmic: conic-gradient(from 315deg at 50% 50%,
		rgba($trueBGcolorEmbed, 1) 0%,
		rgba($trueBGcolorEmbed, 1) 20%,
		rgba($hotpinkembed, 0.1) 30%,
		rgba($hotpinkembed, 0.7) 50%,
		rgba($blueembed, 1) 70%,
		rgba($greenembed, 1) 90%,
		rgba($greenembed, 1) 100%);


$fadeGreenBlue: conic-gradient(from 315deg at 50% 50%,
		rgba($trueBGcolorEmbed, 1) 0%,
		rgba($trueBGcolorEmbed, 1) 20%,
		rgba($blueembed, 0.1) 30%,
		rgba($blueembed, 0.7) 50%,
		rgba($blueembed, 1) 60%,
		rgba($greenembed, 1) 90%,
		rgba($greenembed, 1) 100%);

$figma: linear-gradient(118deg, #FF6AE6 -4.20%, #FFF85C 69%, #A6FF79 120%);

$activeGradientEmbed: $figma;
//TRY changing me to $fadeCosmic, like:
//$activeGradientEmbed: $fadeGreenBlue;

//sizing
$lengthConstEmbedLoading: 100px;
$borderSize: $lengthConstEmbedLoading/2.5;

//size when fully shown
$wobbleSizeFrom: 60%;
//size when circle is collapsed
$wobbleSizeTo: 75%; //if you want it to not grow/shrink set * 1

//animations
$baseAnimationTickEmbed: 1.4s;
$spinTickmultiplierEmbed: 2; //you can play around with these for some fun effects
$spinAdjustedTimeEmbed: (
	$baseAnimationTickEmbed * $spinTickmultiplierEmbed
);

.embed-chat-loading-info {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $activeGradientEmbed;

	.box1-embed {
		position: absolute;
		width: $lengthConstEmbedLoading;
		height: $lengthConstEmbedLoading/2;
		background: #FFF85C;
		top: $lengthConstEmbedLoading;
		left: -$lengthConstEmbedLoading/4;
		opacity: 0.5;
		filter: blur(75px);
	}

	.box2-embed {
		position: absolute;
		width: $lengthConstEmbedLoading;
		height: $lengthConstEmbedLoading/2;
		top: -$lengthConstEmbedLoading/4;
		left: $lengthConstEmbedLoading/4;
		background: #FF6AE6;
		opacity: 0.5;
		filter: blur(75px);
		opacity: 0.5;
	}

	.container {
		position: absolute;
		width: $lengthConstEmbedLoading;
		height: $lengthConstEmbedLoading;
	}

	.spinner {
		position: absolute;
		width: $lengthConstEmbedLoading;
		height: $lengthConstEmbedLoading;
		top: 0;
		left: 0;
		border-radius: 50%;
		position: absolute;
		animation: spin $baseAnimationTickEmbed linear infinite;

		height: $lengthConstEmbedLoading + 6px;
		width: $lengthConstEmbedLoading + 6px;

		left: -3px;
		top: -3px;
	}

	.spinner::before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		inset: -3px;
		border-radius: 50%;
		border: $borderSize solid $trueBGcolorEmbed;
		animation: clipBorder $spinAdjustedTimeEmbed linear infinite;
		animation-direction: alternate;
	}

	.loader {
		//border: 1px solid red;
		height: $lengthConstEmbedLoading;
		width: $lengthConstEmbedLoading;
		left: 0;
		top: 0;
		background: $activeGradientEmbed;
		border-radius: 50%;
		position: absolute;
		animation: spin $baseAnimationTickEmbed linear infinite;
	}

	//big black circle right in the middle
	.emptyspace {
		opacity: 1;
		position: absolute;
		content: "";
		background: $trueBGcolorEmbed;
		border-radius: 50%;
		animation: wobble $baseAnimationTickEmbed infinite ease-out alternate;
	}

	@keyframes spin {
		100% {
			transform: rotate(360deg)
		}
	}

	@keyframes wobble {
		0% {
			height: $wobbleSizeFrom;
			width: $wobbleSizeFrom;
			top: 50% - $wobbleSizeFrom/2;
			left: 50% - $wobbleSizeFrom/2;
		}

		100% {
			height: $wobbleSizeTo;
			width: $wobbleSizeTo;
			top: 50% - $wobbleSizeTo/2;
			left: 50% - $wobbleSizeTo/2;
		}
	}

	@keyframes clipBorder {
		0% {
			clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
		}

		25% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
		}

		50% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
		}

		75% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
		}

		100% {
			clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 10%)
		}
	}
}