.start-end-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		.input-header {
			display: grid;
			grid-template-columns: 292px 1fr;

			&.small{
				grid-template-columns: 266px 1fr;
			}

			span{
				font-family: Poppins;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				color: #908CA0;
				margin-left: 16px;
				margin-bottom: 4px;
			}

			span::after{
				content: attr(data-end) ;
				color: #FFFFFF ;
			}
		}

		.start-end {
			display: grid;
			grid-template-columns: 148px 120px 24px 148px 120px;

			&.small{
				grid-template-columns: 136px 114px 16px 136px 114px;
			}

			input {
				height: 48px;
				background: $dark-gray;
				border: $rem1px solid $element-background-highlight-gray;
				box-sizing: border-box;
				border-radius: $rem100px;
				font-size: 14px;
				line-height: 20px;
				display: flex;
				align-items: center;
				color: $white;
				margin: 0;
			}

			.date {
				position: relative;
				height: 48px;

				input {
					width: 140.25px;
					margin-right: 7.75px;
				}

				&.small{
					input {
						width: 130px;
						margin-right: 7.75px;
					}
				}

				svg {
					position: absolute;
					top: 18px;
					right: 27.33px;
				}

				&.small{
					svg {
						position: absolute;
						top: 18px;
						right: 22.33px;
					}
				}
			}

			.time {
				position: relative;
				height: 48px;

				.react-datepicker__time{
					background-color: transparent;
				}

				input {
					width: 120px;
				}

				&.small{
					input {
						width: 110px;
						margin-right: 7.75px;
					}
				}

				svg {
					position: absolute;
					top: 17.33px;
					right: 17.33px;
				}

				&.small{
					svg {
						position: absolute;
						top: 17.33px;
						right: 17.33px;
					}
				}
			}

			.start-end-divider {
				display: flex;
				justify-content: center;
				align-items: center;
				top: 10px;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				text-align: center;
				color: $text-gray;
				height: 48px;

				&.small{
					width: 12px;
				}
			}
		}
	}