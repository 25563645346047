.input-search {
    position: relative;
    padding: 0 8px 0 0;
    width: 100%;

    /*
        TODO: (Titles) Remove `.no-upload` from here after making sure this
        exists in a media drawer specific scss file when replacing current
        file search with this component.
    */
    &.no-upload {
        padding: 0;
    }

    input {
        height: $search-filter-height;
        width: 100%;
        border-radius: $rem100px;
        background: none;
        border: $rem1px solid $bl-grayscale-600;
        margin: 0;
        padding-left: $rem36px;
        padding-right: $rem8px;
        color: $near-white;
        background-color: $bl-grayscale-1000;
        transition: $field-transition-duration border-color,
                    $field-transition-duration padding,
                    $field-transition-duration box-shadow;

        /*
            This would normally be handled by `bl-font-p2`, but because of
            default input styling, it's either override using `!important` or
            dig into old CSS rules. This seemed like a pragmatic alternative.
        */
        font-size: $rem12px;
        font-weight: $semi;
        line-height: $rem16px;
        letter-spacing: 0.02em;

        &::placeholder {
            color: $bl-grayscale-400;
        }

        &:hover {
            border: $rem1px solid $bl-grayscale-400;
        }

        &:active,
        &:focus {
            border: $rem1px solid $bl-grayscale-300;
            outline: none;

            &::placeholder {
                color: $bl-grayscale-500;
            }
        }
    }

    &.searching {
        animation: grow-search 150ms ease-out;
        animation-fill-mode: forwards;
    }

    &.disabled {
        input {
            cursor: default;
            border: $rem1px solid $border-gray;
        }
    }

    .search-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: $rem44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-gray;
    }
}
