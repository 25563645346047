.media-wrapper {
    .media-loading {
        @include absolute-fill;
        @include flex-center;
    }

    .media-container {
        @include custom-scrollbar(24px, 1);
        width: 100%;
        height: 100%;

        .virtualized-events-list {
            margin-bottom: 40px;

            &.expanded {
                margin-bottom: 56px;
            }
        }

        .title-container {
            display: flex;
            justify-content: space-between;
            color: $text-gray;
            padding: 0 40px;

            .supported-types {
                display: flex;

                span {
                    display: block;
                    margin-right: 3px;
                }

                &:hover {
                    &::after {
                        opacity: 1;
                        right: 40px;
                    }
                }

                &::after {
                    content: ".pdf, .pptx, .key, .jpg, .jpeg, .png, .gif, .mp3, .mp4, .mov, .wav, .webm \A\A Also, you can only upload PDF, PPTX, and\A KEY files one at a time. You can select \Amultiple images and videos at once. ";
                    position: absolute;
                    width: 255px;
                    padding: 12px 16px;
                    background: $border-dark-gray;
                    color: $text-gray;
                    border-radius: $rem12px;
                    opacity: 0;
                    font-size: 12px;
                    margin-top: 20px;
                    pointer-events: none;
                    white-space: pre;
                }
            }
        }

        .media-header {
            color: $text-gray;
            margin-bottom: 16px;
            display: block;
        }

        .media-group-divider {
            display: flex;
            align-items: center;
            padding: 8px 0 0 24px;
            margin: 0;
            color: $light-gray;

            &.grouped {
                padding: 16px 0 4px 24px;
            }

            .media-owner-divider {
                margin-left: 8px;
            }

            &.screenshare {
                padding: 20px 0 12px;
            }

            &.virtuoso-scroller-glow:not(.grouped) {
                background-color: $bl-grayscale-900;
            }
        }

        .screenshare-container {
            margin: 20px 24px 0;

            .start-screenshare-button {
                @include flex-center;
                width: 100%;

                span {
                    margin-left: 7px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                }

                &.sharing {
                    background: $red;
                }

                .button-content {
                    height: 18px;
                }
            }
        }



        .virtuoso-scroller {
            flex: 1;
            position: relative;
            z-index: $z-index-media-container-virtuoso-scroller;
            overflow-x: hidden;

            div[data-testid="virtuoso-scroller"] {
                overflow-x: hidden;
            }
        }

        .add-margin {
            margin-top: 5px;
        }
    }
}

.audience-drawer-content {
    height: 100%;
    background-color: #26242B;

    .coming-soon {
        margin-top: 125px;
        width: 100%;
    }

    &.audience-chat {
        height: 100%;
        width: 100%;
        display: flex;
        background-color: #26242B;

        iframe {
            background-color: #26242B;
            flex: 1;
        }
    }
}

@keyframes halfOpacity {
    to {
        opacity: 0.4;
    }
}

@keyframes fullOpacity {
    to {
        opacity: 1;
    }
}

.link-is-dragging {
    display: none;
    border-radius: $rem12px;
}

.media-link {
    position: relative;
    width: 379px;
    min-height: 64px;
    cursor: grab;
    box-sizing: border-box;
    transition: all 0.15s;
    margin: 0 8px 0 24px;

    padding: 6px;
    border: 2px solid $bl-grayscale-800;
    background-color: $bl-grayscale-800;
    border-radius: $rem12px;
    clip-path: inset(0 0 0 0 round 12px);

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.recording-media {
        z-index: $z-index-media-link;
    }

    &.ellipses-open {
        clip-path: none;
    }

    &.screenshare {
        cursor: default;
    }

    .go-to-slides {
        @include button-greenroom-basic;
        display: flex;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: $rem8px;
        background-color: rgba($border-dark-gray, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;

        span {
            color: $near-white;
        }

        opacity: 0;
        transition: 0.2s opacity ease-out;

        &:hover {
            opacity: 1;
        }
    }

    span {
        display: block;
        margin-right: 3px;
    }

    .attachment-waiting {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $bl-grayscale-800;
        flex-direction: column;

        span {
            color: $text-gray;
            font-size: 10px;
            margin-bottom: 6px;
            text-align: center;
        }

        &.for-telly {
            align-self: flex-end;
        }
    }

    .thumbnail {
        @include ratio-width-height(84);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $rem8px;
        overflow: hidden;

        .processing-container {
            @include flex-center;
            width: 100%;
            height: 100%;
            background-color: $bl-grayscale-1000;
        }

        .thumbnail-container {
            @include flex-center;
            border-radius: 0.357rem;
            overflow: hidden;
            line-height: 0;

            img {
                @include ratio-width-height(84);
                object-fit: contain;
            }

            &.fill {
                img {
                    object-fit: cover;
                }
            }
        }
    }

    .media-details {
        display: flex;
        flex: 1;
        margin: 0 0 0 12px;
        position: relative;
        align-items: center;
        justify-content: space-between;
        min-height: 47.25px;
        overflow: hidden;

        .media-details-metadata {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 95%;
            transition: max-width 0.1s ease-out;
        }

        &.screenshare {
            justify-content: left;
            margin-left: 8px;

            .media-details-metadata {
                max-width: 91%;
            }
        }

        .media-dots-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
            transition: all 0.15s;
            position: absolute;
            right: 8px;
            background-color: $bl-grayscale-800;

        }

        .media-download-spinner {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .last-edited {
            @include font-poppins-12;
            line-height: 14px;
            color: $light-gray;
            margin: 0px;
        }

        .media-title,
        .media-description {
            color: white;
            margin: 0;
            user-select: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            min-width: 0;
            width: 100%;
            max-width: 100%;
        }

        .media-description {
            color: $bl-grayscale-300;
        }

        .bottom {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: flex-end;

            .avatars {
                display: flex;

                .avatar-container {
                    margin-left: -4px;
                }

                .avatar-placeholder {
                    width: 32px;
                    height: 32px;
                    border: 1px solid $border-dark-gray;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $border-gray;
                    color: $text-gray;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }

    &.short {
        .media-details-metadata {
            max-width: 80%;
        }

        .media-dots-wrap {
            opacity: 1;
        }
    }

    .media-ellipses-menu-container {
        display: none;
        position: relative;
        z-index: 9999;
        border-radius: $rem8px;
        transform: none;
        opacity: 1;

        &.open {
            display: flex;
        }

        .media-ellipses-menu {
            box-shadow: 0px 13px 30px 0px rgba(0, 0, 0, 0.251);
            border-radius: $rem8px;
            background-color: $bl-grayscale-800;
            display: flex;
            flex-direction: column;
            z-index: 9999;
            opacity: 1;
            border-radius: 12px;

            .media-ellipses-option {
                @include button-greenroom-basic;
                transition: 150ms all;
                background-color: $bl-grayscale-800;
                padding: 10px 16px;
                cursor: pointer;
                color: $near-white;

                &:first-child {
                    border-radius: 12px 12px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 12px 12px;
                }
            }

            .media-ellipses-menu-row {
                align-items: center;
                display: flex;
                justify-content: left;

                .media-ellipses-menu-icon {
                    display: flex;
                    align-items: center;
                    margin-right: $rem8px;
                }
            }

            .media-ellipses-option-disabled {
                padding: 8px 16px;
                cursor: default;
                pointer-events: none;

                .media-ellipses-menu-text {
                    color: $text-gray !important;
                }

                svg {
                    path {
                        fill: $text-gray !important;
                    }
                }
            }

            .media-ellipses-option-special-download {
                pointer-events: all;
                cursor: pointer;
            }
        }
    }

    &:hover:not(.screenshare, .no-shrink, .short) {
        // overlays and recordings squish the textbox to make room for ellipses
        .media-details {
            .media-dots-wrap {
                opacity: 1;
            }

            .media-details-metadata {
                max-width: 87%;

                &.uploading {
                    max-width: 83%;
                }
            }
        }

        &::after {
            animation-name: fullOpacity;
            animation-duration: 0.15s;
            animation-fill-mode: forwards;
        }
    }

    &:hover:not(.screenshare),
    &.is-expanded {
        background-color: $bl-grayscale-700;
        border: 2px solid $bl-grayscale-700;

        .media-dots-wrap {
            background-color: $bl-grayscale-700;
        }
    }

    &:active:not(.screenshare) {
        background-color: darken($bl-grayscale-800, 0.5%);
        border: 2px solid darken($bl-grayscale-800, 0.5%);

        .media-dots-wrap {
            background-color: darken($bl-grayscale-800, 0.5%);
        }
    }
}

.link-is-highlighted {
    border-radius: $rem12px;

    .media-link {
        border: 2px solid $pale-green;
    }

    &:active {
        .media-link {
            border: 2px solid $pale-green;
        }
    }
    &:hover {
        .media-link {
            border: 2px solid $pale-green;
        }
    }
}

.expanded-media-item {
    background-color: $border-gray;
    border-bottom-right-radius: $rem16px;
    border-bottom-left-radius: $rem16px;
    margin: -14px 8px 0 24px;
    padding: 8px 0;
}

.media-modal {
    width: 70%;
    min-width: 680px;
    max-height: calc(100% - 60px);
    aspect-ratio: 16 / 9;
    border-radius: $rem24px;
    display: grid;
    position: relative;
    overflow: inherit;
    background-color: #26242b;
    grid-template-columns: 227px 1fr;
    grid-template-rows: auto 1fr auto;
    justify-items: center;

    .modal-overlay {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        overflow: hidden;
    }

    .pages-container {
        overflow: hidden;
        margin: 16px 0;
        padding: 24px 0;
        width: 227px;
        grid-row: 2 / span 2;
        grid-column: 1 / 2;

        .pages {
            @include custom-scrollbar;
            overflow-y: auto; // Necessary for drag and drop to work ("overflow-y: overlay" in custom-scrollbar breaks drag and drop")
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 0 16px 16px 32px;
            box-sizing: content-box;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 10px;
            }

            .page {
                display: flex;
                align-items: center;
                margin-right: 7px;
                margin-top: 16px;

                .number {
                    @include font-poppins-14;
                    line-height: 20px;
                    min-width: 30px;
                    text-align: right;
                }

                .thumbnail {
                    width: 172px;
                    height: 101px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #1e1d22;
                    border-radius: $rem8px;
                    overflow: hidden;
                    cursor: pointer;

                    .thumbnail-container {
                        @include flex-center;
                        width: 162px;
                        height: 91.125px;
                        border-radius: $rem8px;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            min-width: calc(100% + 0.5px);
                            min-height: calc(100% + 0.5px);
                            object-fit: contain;
                        }

                        &.fill {
                            img {
                                object-fit: cover;
                            }
                        }
                    }

                    .selected {
                        @include absolute-fill;
                        border: 2px solid #a6ff79;
                        border-radius: $rem8px;
                    }

                    .hover-gradient {
                        @include absolute-fill;
                        top: 5px;
                        bottom: 5px;
                        left: 5px;
                        right: 5px;
                        border-radius: 0.357rem;
                        background: linear-gradient(180deg,
                                rgba(0, 0, 0, 0.4) 0%,
                                rgba(255, 255, 255, 0) 100%);
                    }

                    .trash {
                        position: absolute;
                        top: 12px;
                        right: 12px;

                        svg {
                            -webkit-filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
                            filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.5));
                        }
                    }

                    .attachment-waiting {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex: 1;

                        .add-page {
                            display: flex;
                            justify-content: center;

                            span {
                                display: block;
                                color: $text-gray;
                                font-size: 14px;
                                margin-left: 3px;
                                margin-top: -1px;
                                text-align: center;
                            }
                        }
                    }
                }

                .progress-wrap {
                    height: 2px;
                    margin-top: 10px;
                    background: $dark-gray;
                    top: unset;
                    bottom: 0;

                    .progress-bar {
                        height: 2px;
                        background: $pale-green;
                    }
                }
            }

            .page:first-child {
                margin-top: 0px;
            }

            .page.upload {
                padding-bottom: 40px;

                svg {
                    path {
                        transition: fill;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: $near-white;
                        }
                    }
                }
            }
        }
    }

    .media-modal-top {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 32px 40px 0 40px;
        grid-column: 1 / span 2;

        .close-modal-btn {
            position: absolute;
            top: 39px;
            right: 40px;

            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        .avatars {
            display: flex;
            align-items: center;
            margin-right: 32px;

            .avatar-placeholder {
                border: none;
            }
        }

        .name {
            @include font-poppins-14-500;
            line-height: 16px;
            color: #ffffff;
            margin: 0px;
            background: transparent;
            border: none;
            padding: 0;
            flex: 1;
            font-size: 18px;

            &.not-creator {
                pointer-events: none;
            }
        }

        .delete-media-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $border-gray;
            border-radius: 100%;
            padding: 0;
            height: 32px;
            width: 32px;
            transition: background-color 150ms, color 150ms;

            &:hover {
                background: lighten($border-gray, 10%);
                color: $near-white;

                svg {
                    path {
                        fill: $near-white !important;
                    }
                }
            }
        }

        .media-title-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $border-gray;
            border-radius: 100%;
            padding: 0;
            height: 32px;
            width: 32px;
            transition: background-color 150ms, color 150ms;

            &:hover {
                background: lighten($border-gray, 10%);
                color: $near-white;

                svg {
                    path {
                        fill: $near-white !important;
                    }
                }
            }
        }
    }

    .featured-page {
        border-radius: $rem16px;
        background-color: #151516;
        overflow: hidden;
        aspect-ratio: 16 / 9;
        position: relative;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        margin: 16px 24px 32px;
        max-height: 100%;
        max-width: fill-available;

        img,
        video {
            max-width: 100%;
            max-height: 100%;
            min-width: calc(100% + 0.5px);
            min-height: calc(100% + 0.5px);
            object-fit: contain;
        }

        &.not-loaded {
            video {
                object-fit: cover;
            }
        }

        &.fill {

            img,
            video {
                object-fit: cover;
            }
        }

        svg {
            align-self: center;
        }

        .switch-page-btn-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 200ms ease;

            &:hover {
                opacity: 1;
            }

            .switch-page-counter {
                margin: 0 16px;
            }

            .switch-page-btn {
                background-color: #4f4c57;
                width: 46px;
                height: 46px;
                border-radius: $rem100px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.disabled {
                    opacity: 0.5;
                }
            }
        }
    }

    .media-modal-bottom {
        display: flex;
        justify-content: center;
        margin: 0 0 32px 0;
        padding: 0 8px 0 8px;
        grid-column: 2;

        .controls {
            display: flex;
            align-items: center;

            .direction-container {
                width: 144px;
                height: 36px;
                background: $dark-gray;
                border-radius: $rem8px;
                border: 1px solid $border-gray;
                margin-left: 14px;
                overflow: hidden;

                display: flex;
                cursor: pointer;

                .direction {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    &.selected {
                        background-color: $white;

                        svg {
                            path {
                                fill: #151516;
                            }
                        }
                    }
                }

                .divider {
                    height: 36px;
                    width: 1px;
                    background-color: $border-gray;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .fit-fill-dropdown {
            width: 80px;
            height: 32px;
            margin-right: 12px;
            background: $element-background-highlight-gray;
            border-radius: 100px;
            transition: 150ms background-color;

            &:hover,
            &:active {
                background-color: lighten($element-background-highlight-gray,
                        5%);
            }

            .select-label {
                padding: 8px 16px !important;
                display: flex;
                justify-content: space-between;

                .select-label-text {
                    font-weight: 500;
                    color: $white;
                }

                svg {
                    width: 12px;
                    height: 12px;

                    path {
                        fill: $white;
                    }
                }
            }

            .select-body {
                min-width: 80px;
                border-radius: $rem12px;

                .select-option {

                    &:hover,
                    &.selected {
                        &:first-of-type {
                            border-radius: $rem12px $rem12px 0 0;
                            clip-path: inset(0 0 0 0 round 12px 12px 0 0);
                        }

                        &:last-of-type {
                            border-radius: 0 0 $rem12px $rem12px;
                            clip-path: inset(0 0 0 0 round 12px 12px 0 0);
                        }
                    }
                }
            }
        }

        .color-picker-container {
            width: 416px;
            bottom: 20px;
            left: 238.75px;
            overflow-y: hidden;

            .color-label {
                font-family: $font-family-opensans;
                font-weight: 700;
                text-transform: none;
                margin-bottom: 16px;
            }

            .select-container {
                .right-side {
                    height: 120px;
                    justify-content: space-between;
                    padding: 20px 0 20px 32px;

                    .value-container {
                        margin-bottom: 0;

                        label {
                            margin-right: 29px;
                            color: $text-light-gray;
                        }

                        input {
                            color: $white;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .ready-to-share {
            width: 176px;
            height: 46px;
            border-radius: 27.5px;
            background-color: #a6ff79;
            display: flex;
            align-items: center;
            margin-left: auto;

            span {
                margin-left: 10px;
                color: #121114;
                @include font-poppins-14-500;
                line-height: 16px;
            }
        }
    }
}

.custom-graphics-modal {
    width: 70%;
    min-width: 680px;
    max-height: calc(100% - 60px);
    border-radius: $rem24px;
    position: relative;
    overflow: inherit;
    background-color: #26242b;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    padding: 24px;

    .custom-graphics-modal-top {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 16px 16px 16px;

        .close-modal-btn {
            position: absolute;
            top: 39px;
            right: 40px;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }

        .name {
            @include font-poppins-14-500;
            line-height: 16px;
            color: #ffffff;
            margin: 0px;
            background: transparent;
            border: none;
            padding: 0;
            flex: 1;
            font-size: 18px;

            &.not-creator {
                pointer-events: none;
            }
        }
    }

    .custom-graphics-modal-center {
        grid-row: 2;
        border-radius: $rem16px;
        background-color: #151516;
        overflow: hidden;
        aspect-ratio: 16 / 9;
        position: relative;
        max-height: 100%;
        max-width: fill-available;

        img,
        video {
            max-width: 100%;
            max-height: 100%;
            min-width: calc(100% + 0.5px);
            min-height: calc(100% + 0.5px);
            object-fit: contain;
        }
    }
}

.media-modal-background {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-media-modal;
}

@keyframes progressWipe {
    from {
        transform: translateX(-100px);
    }

    to {
        transform: translateX(250px);
    }
}

.progress-wrap {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;

    .progress-wipe {
        background: $border-gray;
        height: 2px;
        width: 50px;
        animation: progressWipe 1.5s infinite;
        position: absolute;
    }

    .progress-bar {
        height: 100%;
        background: $pale-green;
        position: absolute;
        transition: width 100ms linear;
    }
}

.play-button {
    transition: background-color 500ms, fill 300ms;

    &.play-button-canvas {
        padding: 10px;
        margin: 0 4px;

        &.disabled {
            background-color: $bl-grayscale-transparent-600;
        }
    }
}

.media-controls {
    position: relative;
    height: 41px;
    margin: 0 8px 8px 8px;
    background: $border-gray;
    border-radius: $rem100px;
    pointer-events: auto;

    .vol-wrap {
        @include volume-slider;
        margin-right: 16px;

        &.deck {
            margin-right: 8px;
        }

        .volume {
            border-radius: 100px;
        }

        .volume::before {
            position: absolute;
            content: " ";
            left: 40px;
            right: 0;
            top: -1px;
            bottom: -1px;
        }

        .vol-icon-wrap {
            max-height: 19px;
            padding-left: 1px;

            &.muted {
                padding-right: 1px;
                padding-left: 0px;
            }
        }
    }

    .scrub-wrap {
        @include scrubbar-slider;
        position: relative;
        overflow-x: visible;

        .scrubbar {
            border-radius: 100px;
        }

        .scrubbar::before {
            position: absolute;
            content: " ";
            left: 0;
            right: 0;
            top: -4px;
            bottom: -8px;
        }

        .time-bubble {
            position: absolute;
            opacity: 0;
            transition: opacity 100ms;
            margin: -75px 0 0 0;
            padding: 4px 8px;
            background-color: $border-gray;
            border-radius: 4px;
            white-space: nowrap;
            border-radius: 99px;
            min-width: 48px;
            display: flex;
            justify-content: center;

        }

        &:hover {
            .time-bubble {
                opacity: 1;
            }
        }

        &.presenter {
            margin-right: 12px;

            &.deck {
                margin-right: 6px;
            }
        }
    }

    .control {
        background-color: transparent;
        transition: 150ms background-color;

        &.left {
            padding: 12px 9px 12px 12px;
            border-radius: $rem100px 0 0 $rem100px;
            border-right: 1px solid $bl-grayscale-600;
            margin-right: 4px;
        }

        &.right {
            padding: 12px 12px 12px 9px;
            border-radius: 0 $rem100px $rem100px 0;
            border-left: 1px solid $bl-grayscale-600;
        }

        &:hover,
        &:active {
            background-color: lighten($border-gray, 5%);
        }
    }

    .controls-pages {
        position: absolute;
        height: 100%;
        margin-top: -2px;
        pointer-events: none;

        span {
            padding: 0;
            font-size: 12px;
            background: none;
        }
    }
}

.media-slides-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .media-slides-pages-view {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding-left: 32px;

        .virtuoso-scroller {
            height: 100%;
            margin-top: 0;

            &>div {
                height: 100%;
            }
        }
    }

    .media-slides-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 32px;
        margin: 24px 0 24px 0;

        .media-slides-header-all {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;

            span {
                margin-left: 8px;
            }
        }

        .media-slides-header-preview {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;

            span {
                margin-left: 8px;
                color: #a6ff79;
            }
        }
    }

    .media-slide-container {
        display: flex;
        align-items: center;

        .media-slides-handle {
            display: flex;
            align-items: center;
            margin-right: 16px;
            min-width: 32px;
        }

        .media-slide {
            min-width: 300px;
            max-width: 300px;
            height: 150px;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #151516;
            overflow: hidden;
            margin-bottom: 8px;

            &.selected {
                border: 1px solid #a6ff79;
            }

            &.no-action {
                cursor: initial;
            }

            .presenting-badge {
                display: flex;
                align-items: center;
                padding: 0 8px;
                height: 24px;
                border-radius: 99px;
                position: absolute;
                top: 16px;
                left: 16px;
                background-color: #f03d33;
                opacity: 0.8;
                font-size: 12px;

                .presenting-dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 99px;
                    background-color: white;
                    margin-right: 8px;
                }
            }

            .thumbnail-container {
                @include flex-center;
                width: 266.66px;
                height: 150px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    min-width: calc(100% + 0.5px);
                    min-height: calc(100% + 0.5px);
                    object-fit: contain;
                }

                &.fill {
                    img {
                        object-fit: cover;
                    }
                }
            }

            .selected {
                @include absolute-fill;
                border: 2px solid #a6ff79;
                border-radius: 8px;
            }

            .media-slide-overlay {
                background-color: rgba(0, 0, 0, 0.3);
                opacity: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .green-slide-button {
                    padding: 16px 24px;
                    background: #a6ff79;
                    color: black;
                    border-radius: 99px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.media-share-container {
    overflow-y: auto;
    @include custom-scrollbar;

    .share-popover {
        position: static;
        box-shadow: none;
        width: 100%;
        padding: 0 24px 24px 24px;

        .share-people-container {
            padding-bottom: 16px;

            .share-people {
                max-width: 100%;

                .share-person {
                    max-width: 100%;
                }
            }
        }

        .share-note {
            display: flex;
            position: static;
            border-radius: $rem100px;
            background-color: $pale-green;
            color: $dark-gray;
            padding: 16px;

            &:hover,
            &:active {
                background-color: lighten($pale-green, 10%) !important;
            }
        }
    }
}

.media-link-drag-placeholder {
    height: 64px;
    background-color: #151516;
    border-radius: 12px;
    box-shadow: inset 0 1px 7px #141415;
    margin: 8px 6px 0 22px;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.hover-icon-icon {
    background-color: rgba(22, 22, 22, 0.75);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    transition: opacity 300ms;

    &.is-opaque {
        opacity: 0;
    }
}

.virtuoso-scroller div {
    @include custom-scrollbar(24px, 1);
}

.virtuoso-label {
    // I know naughty naughty important, but needed...
    padding-bottom: 4px !important;
}

.virtuoso-scroller-glow {
    position: relative;
    box-shadow: 0 4px 8px #26242b;
    position: relative;
    z-index: $z-index-virtuoso-gradient;

    &.grouped {
        background-color: #26242b;
        color: #b2b2bf;
        padding-top: 8px;
    }
}

.shot-builder-modal-media-container {
    overflow-y: auto;
}

// Apply fit fill styles to both media / main video and media modal
.media,
.featured-page,
.custom-graphics-modal-center {

    .media-img,
    .media-video,
    .media-video-container {
        width: 100%;
        height: 100%;
        min-width: calc(100% + 0.5px);
        min-height: calc(100% + 0.5px);
        object-fit: contain;
    }

    &.fill {
        .media-video-container {
            max-width: 100%;
            max-height: 100%;
        }

        .media-video,
        .media-img,
        .media-video-container,
        .media-video-poster img {
            object-fit: cover;
        }
    }
}

.media-img[src=""],
.media-video[src=""] {
    //better to display nothing to a user
    //than the broken image link if urlOfCurrentPage
    //is a '' string
    display: none;
}

.media-filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
}

.filter-container {
    position: relative;
    width: 100%;
    justify-content: space-between;
    background: $element-background-gray;

    &.recording {
        width: 60%;
    }

    .filter-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $bl-grayscale-400;
        padding: 8px 16px;
        color: $bl-grayscale-400;
        border-radius: 100px;
        cursor: pointer;
        white-space: nowrap;
        gap: 8px;
        transition: 150ms background-color,
            150ms color,
            150ms border-color;

        max-height: 32px;
        min-height: 32px;
        height: 32px;

        span:first-letter {
            text-transform: capitalize;
        }

        &.sorting {
            padding: 0 16px 0 0;
        }

        .sort-icon {
            display: flex;
            align-items: center;
            margin-right: 4px;
            padding: 12px 4px 12px 12px;
            height: 32px - 2px; //borders
            border-radius: 100px 0 0 100px;

            &:hover {
                background-color: lighten($bl-grayscale-800, 2%);
                color: darken($near-white, 10%);
            }
        }

        svg {
            path {
                fill: $bl-grayscale-400 !important;
            }
        }

        &:hover,
        &.isopen,
        &.sorting,
        &.isapplied {
            border: 1px solid white;
            color: white;

            svg {
                path {
                    fill: $near-white !important;
                }
            }
        }
    }

    .popover {
        top: 34px;
        right: 0;
        width: 100%;
        z-index: 1000;
        background-color: $bl-grayscale-800;

        .filter-options {
            min-width: 100%;

            .filter-option-header {
                padding: 8px 16px 4px;

                span {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: #908ca0;
                }
            }

            .filter-option {
                display: flex;
                align-items: center;
                padding: 10px 16px 10px 36px;
                cursor: pointer;
                background-color: $bl-grayscale-800;
                transition: all 150ms;
                color: $near-white;

                @include button-greenroom-basic();

                &:first-child {
                    border-radius: 12px 12px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 12px 12px;
                }

                span {
                    line-height: 20px;
                    margin-left: 0px;
                }

                &.selected {
                    color: darken($near-white, 5%);

                    &.file-type {
                        padding: 10px 16px 10px 16px;
                    }
                }


            }
        }
    }

    &.stylepacks {
        // width: 200px;
        transition: all 150ms;
        flex: 1;
        max-width: 50%;
        margin-left: 8px;

        &.squish {
            max-width: 36%;
        }

        .popover {
            .filter-options {
                max-width: 230px;
            }
        }

        .filter-label,
        .popover > .filter-options > .filter-option {
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}


$resultsIndicationHeight: 22px;
@mixin noHeightNoOpacity {
    height: 0;
    opacity: 0;
}
@mixin heightOpacity {
    height: $resultsIndicationHeight;
    opacity: 1;
}

.media-filters-results-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 4px 24px;
    margin-bottom: -8px;
    z-index: $z-index-media-filter-results-container;
    position: relative;

    button {
        display: flex;
        align-items: center;
        background: transparent;
        border-color: transparent;
    }

    &.stylepacks {
        width: 100%;
        margin-bottom: 8px;
        flex-direction: column;
        
        transition: height 150ms, opacity 20ms, padding 50ms;
        height: 0px;
        opacity: 0;
        padding: 0 12px;

        &.show-loading-results {
            height: 22px;
            opacity: 1;
            padding: 0 12px;
        }

        .stp-results-indicator,
        .stp-loading-indicator {
            min-width: 100%;
            transition: opacity 100ms, height 100ms;
            overflow: hidden;
            @include noHeightNoOpacity;
        }

        .stp-loading-indicator {
            @include heightOpacity;
        }
        
        .stp-results-indicator {
            display: flex;
            justify-content: space-between;
            @include noHeightNoOpacity;
        }

        &.show-results-string {
            .stp-loading-indicator {
                @include noHeightNoOpacity;
            }
            
            .stp-results-indicator {
                @include heightOpacity();
            }
        }
    }
}

.search-and-upload-container {
    display: flex;
    padding: 20px 24px 8px;

    &.stylepacks {
        padding: 0 0 8px 0;

        .search-field-media {
            flex: 9;
        }
    }

    &.recording {
        padding: 20px 24px 0 24px;
    }

    @keyframes grow-search {
        to {
            width: 100%;
            padding: 0;
        }
    }

    .popover {
        top: 32px;
        right: 0;
    }

    .search-field-media {
        position: relative;
        width: 100%;
        transition: 150ms all;
        
        input {
            min-height: 32px;
            max-height: 32px;
            height: 32px;
            border-radius: $rem100px;
            background: none;
            border: $rem1px solid #908ca0;
            margin: 0;
            padding-left: 36px;
            color: $near-white;

            &::placeholder {
                transition: 150ms all;
                color: darken($text-gray, 10%);
            }

            &:focus {
                border-color: white;
                &::placeholder {
                    color: darken($text-gray, 20%);
                }
            }

            &:hover {
                border-color: white;
            }
        }

        &.searching {
            animation: grow-search 150ms ease-out;
            animation-fill-mode: forwards;
        }

        &.disabled {
            input {
                cursor: default;
                border: $rem1px solid $border-gray;
            }
        }

        .search-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-gray;
        }

        &:hover,
        &:focus,
        &.filtered {
            input {
                color: $near-white !important;
                &::placeholder {
                    color: $near-white !important;
                }
            }
            .search-icon {
                svg {
                    path {
                        fill: $near-white !important;
                    }
                }
            }
        }
    }

    @keyframes shrink-upload-btn {
        to {
            opacity: 0;
            width: 0;
            padding: 0;
        }
    }

    .upload-media {
        margin-left: 12px;
        @include button-greenroom-basic;
        &.uploading {
            cursor: default;
            pointer-events: none;
        }

        &.searching {
            animation: shrink-upload-btn 150ms ease-out;
            animation-fill-mode: forwards;
        }
    }
}

.media-footer {
    height: 16px;
}