//************************************************************
// Dialog slidedown
//************************************************************

.dialog-wrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-index-dialog;

	&.fullwidth {
		right: 0;
	}

	.dialog-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-overlay;
		opacity: 0;
		transition: opacity $modal-transition-duration;

		&.fullwidth {
			width: 100%;
		}

		&.show {
			opacity: 1;
		}
	}

	.dialog {
		border-radius: 0 0 $radius-large $radius-large;
		position: fixed;
		top: 0;
		left: calc((100% - #{$dialog-size-medium}) / 2);
		width: 100%;
		max-width: $dialog-size-medium;
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		padding: $global-padding ($global-padding + 10);
		transform: translateY(-323px);
		transition: transform $modal-transition-duration;
		z-index: 3;

		&.small {
			left: calc((100% - #{$dialog-size-small}) / 2);
			max-width: $dialog-size-small;
		}

		&.medium {
			left: calc((100% - #{$dialog-size-medium}) / 2);
			max-width: $dialog-size-medium;
		}

		&.large {
			left: calc((100% - #{$dialog-size-large}) / 2);
			max-width: $dialog-size-large;
		}

		.dialog-header {
			text-align: center;
			padding-top: 20ox;

			h3 {
				color: $color-ballpoint;
				font-weight: $xbold;
				margin: 0 auto 20px;
				max-width: 340px;
			}

			p {
				font-size: $rem16px;
				color: $color-text;
				margin: 0 0 20px;

				&:last-child {
					margin-bottom: $global-padding;
				}
			}
		}

		.dialog-body {
			background: $color-off-white;
			padding: 12px 0;
			margin: 0 (-#{$global-padding + 10});

			+ .dialog-actions {
				margin-top: $global-padding;
			}
		}

		ul.dialog-list {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-weight: $semi-bold;
				cursor: pointer;
				padding: 12px ($global-padding + 10);

				&:hover {
					background-color: $color-light-gray;
				}

				> * {
					pointer-events: none;
				}

				label {
					flex: 1;
					margin: 0 15px;
				}

				i {
					&.icon-mic {
						color: $color-green;
						font-size: 20px;
					}

					&.icon-mic-muted {
						font-size: 20px;
					}

					&.icon-checkmark {
						color: $color-green;
					}
				}
			}
		}

		.dialog-actions {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			button + button,
			button + .field-group {
				margin-top: 0;
			}

			.field-group.dropdown {
				max-width: 200px;
			}

			.cancel {
				color: $color-text-light;

				&:hover {
					color: $color-text;
				}
			}
		}
	}

	&.show {
		.dialog {
			transform: translateY(0);
		}
	}
}
