.live-indicator {
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;

	&.presenter-main-video {
		background-color: #EC0C00;
		border-radius: $rem100px;
		padding: 8px 14px;
	}

	svg {
		font-size: 6px;
		position: relative;
		top: -2px;
	}

	&.not-live {
		svg {
			color: $red;
		}
	}
}

.countdown {
	padding: 9px 14px;
	margin-right: 16px;
	background-color: $border-dark-gray;
	border-radius: $rem100px;
	font-weight: 500;
	font-size: 14px;
}

.timer-text{
	@include font-roboto-14-500;
	line-height: 14px;
	white-space: pre;
}

.timer-text-presenter-live{
	@include font-roboto-14-500;
	line-height: 20px;
	white-space: pre;

	&.flashing {
		animation: flashing 1s infinite;
	}

	@keyframes flashing {
		0% {
			color: $white;
		}
		49% {
			color: $white;
		}
		60% {
			color: transparent;
		}
		99% {
			color: transparent;
		}
		100% {
			color: $white;
		}
	}
}