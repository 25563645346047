.media-video-container {
    position: relative;

    &.ivs-player-container {
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    .quality-span {
        visibility: hidden;
        position: absolute;
    }

    .media-video {
        &.ivs-player-video {
            max-height: 100%;
            max-width: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    
        &.cc-container {
            // Hiding this for now, needs product input
            display: none;
            position: absolute;
            color: #ffffff;
    
            p {
                white-space: pre-line;
            }
        }
    }

    .media-video-poster {
        @include absolute-fill;

        img {
            object-fit: contain;
            min-height: 100%;
            max-height: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }
}
