.radio-container {
	display: block;
	position: relative;
	padding-left: 22px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $text-light !important;
	font-weight: normal !important;
	transition: color 150ms;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		&:checked ~ .checkmark {
			transform: scale(1);
			transition: opacity 50ms, transform 100ms;
			opacity: 1;
		}

		&:checked ~ .unchecked {
			opacity: 1;
		}
	}

	.checkmark {
		position: absolute;
		top: 5px;
		left: 5px;
		height: 6px;
		width: 6px;
		background-color: #eee;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 200ms, transform 200ms;
		transform: scale(0.5);
	}

	.unchecked {
		position: absolute;
		top: 0;
		left: 0;
		border: $rem1px solid $text-gray;
		border-radius: 100%;
		height: 16px;
		width: 16px;
		opacity: 1;
		display: block;
		transform: scale(1);
		transition: 200ms border, 100ms transform;

		&.checked{
			border: $rem1px solid $near-white;
		}
	}

	&:hover {
		color: $color-white !important;

		.unchecked {
			border: $rem1-5px solid $color-white;
		}
	}

	&:active {
		.unchecked {
			transform: scale(0.9);
		}
	}

	&.checked {
		color: $color-white !important;
	}
}