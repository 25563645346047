.coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;

    .coming-soon-image {
        position: relative;
        margin-bottom: 12px;
        min-height: 228px;

        .coming-soon-gradient {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $bl-gr-primary-500;
            opacity: 0.2;
            filter: blur(130px);
        }
    }

    .coming-soon-title,
    .coming-soon-text {
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
    }

    .coming-soon-title {
        margin: 0 0 10px 0;
        color: $bl-gr-primary-500;
    }

    .coming-soon-text {
        color: $bl-grayscale-400;
    }
}
