/* Brandlive Design System V2 */
// https://www.figma.com/file/XXBoUbK52nPJ2CFMeU8lxk/Desktop---Brandlive-DS-v2?node-id=111%3A112&t=SCNFABVS4vBWye15-0

// Font Styles - Header
.h1.bl-font-header,
h1.bl-font-header,
.h2.bl-font-header,
h2.bl-font-header,
.h3.bl-font-header,
h3.bl-font-header {
	font-weight: $bold !important;
}

.h4.bl-font-header,
h4.bl-font-header,
.h5.bl-font-header,
h5.bl-font-header,
.h6.bl-font-header,
h6.bl-font-header {
	font-weight: $semi-bold !important;
}

.h3.bl-font-header,
h3.bl-font-header,
.h4.bl-font-header,
h4.bl-font-header {
	line-height: $rem32px !important;
}

.h5.bl-font-header,
h5.bl-font-header,
.h6.bl-font-header,
h6.bl-font-header {
	line-height: $rem20px !important;
}

.h1.bl-font-header,
h1.bl-font-header {
	/* H1 • Header • 44/64 */
	font-size: $rem44px !important;
	line-height: $rem64px !important;
}

.h2.bl-font-header,
h2.bl-font-header {
	/* H2 • Header • 30/40 */
	font-size: $rem30px !important;
	line-height: $rem40px !important;
}

.h3.bl-font-header,
h3.bl-font-header {
	/* H3 • Header • 23/32 */
	font-size: $rem23px !important;
}

.h4.bl-font-header,
h4.bl-font-header {
	/* H4 • Header • 20/32 */
	font-size: $rem20px !important;

	/* identical to box height, or 160% */
	letter-spacing: -0.01em !important;
}

.h5.bl-font-header,
h5.bl-font-header {
	/* H5 • Header • 16/20 */
	font-size: $rem16px !important;
}

.h6.bl-font-header,
h6.bl-font-header {
	/* H6 • Header • 14/20 */
	font-size: $rem14px !important;
}

// Font Styles - Main Text
.bl-font-s1,
.bl-font-s2,
.bl-font-s3 {
	font-weight: $semi-bold !important;
	line-height: $rem20px !important;
}

.bl-font-s2,
.bl-font-s3 {
	font-size: $rem12px !important;
	letter-spacing: 0.02em !important;
}

.bl-font-s1 {
	/* S1 • Big Main Text • 14/20 */
	font-size: $rem14px !important;
}

// Font Styles - Plain
.bl-font-p1,
.bl-font-p2 {
	font-weight: $semi !important;
}

.bl-font-p1 {
	/* P1 • Big Plain Text • 14/24 */
	font-size: $rem14px !important;
	line-height: $rem24px !important;
}

.bl-font-p2 {
	/* P1 • Big Plain Text • 14/24 */
	font-size: $rem12px !important;
	line-height: $rem16px !important;
	letter-spacing: 0.02em !important;
}

// Font Styles - Plain
.bl-font-c1,
.bl-font-c2 {
	/* C1 • Bold Additional Text • 11/16 */
	font-size: $rem11px !important;
	line-height: $rem16px !important;
	letter-spacing: 0.02em !important;
}

.bl-font-c1,
.bl-font-c3 {
	font-weight: $semi-bold !important;
}

.bl-font-c2 {
	/* C2 • Additional Text • 11/16 */
	font-weight: $semi !important;
}

.bl-font-c3 {
	/* C3 • Small Additional Text • 11/16 */
	font-size: $rem10px !important;
	line-height: $rem14px !important;
	letter-spacing: -0.02em !important;
}

// Font Styles - Special
.bl-font-l1,
.bl-font-l2,
.bl-font-l3 {
	font-weight: $semi-bold !important;
	line-height: $rem16px !important;
}

.bl-font-l2,
.bl-font-l3 {
	font-size: $rem11px !important;
}

.bl-font-l1 {
	/* L1 • Default Buttons/Actions • 13/16 */
	font-size: $rem13px !important;
}

.bl-font-l2 {
	/* L2 • Small Buttons/Actions • 11/16 */
	letter-spacing: 0.02em !important;
}

.bl-font-l3 {
	/* L3 • CHIPS/Additional • 11/16 */
	letter-spacing: 1.3px !important;
}

// Font Styles - Special
.bl-font-q1 {
	/* Q1 Quote Text 14/24 */
	font-weight: $regular !important;
	font-size: $rem14px !important;
	line-height: $rem24px !important;
}