.meta-menu-content {
	@include custom-scrollbar(24px, 1);
	color: $white;
	position: relative;
	width: $full-drawer-width;
	position: fixed;
	top: 72px;
	right: -$full-drawer-width;
	bottom: 0;
	background: $element-background-gray;
	overflow-x: hidden;
	z-index: $z-index-meta-menu;
	transition: all 150ms ease-out;
	box-shadow: -1px 1px 3px rgba(0,0,0,0.2);
	
	&.opening, &.open {
		right: 0;
	}

	label {
		color: $white;
	}

	.title-text {
		color: $white;
	}

	.text-input-wrapper > label {
		color: $text-gray;
	}

	&.below-right-tab-menu {
		// making this a class as a new drawer will go back to the old style
		// it is on top of the room's drawer UI
		width: $global-drawer-width;
		right: -$global-drawer-width;

		z-index: $z-index-recessed-meta-menu;

		&.opening,
		&.open {
			right: $drawer-menu-width;

			&.home-view {
				right: 0;
			}
		}
	}

	.style-pack-over-tab {
		position: relative;
		width: calc(2 * $full-drawer-width);
		display: flex;
		

		transition: right 150ms ease-out;
		
		.show-over-tab {
			right: -$full-drawer-width;
		}

		.hide-over-tab {
			right: $full-drawer-width;
		}
	}
}

.overlay-below {
	z-index: $z-index-tabs;
}

.meta-menu-overlay {
	background: none;
}

.meta-panel-container {
	position: relative;
	margin: 24px 24px 0;
	height: calc(100% - 24px);

	.meta-panel-header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		h5 {
			margin: 1px 8px -1px 0;
		}
	}

	.bg-image-picker {
		position: relative;

		.upload-button-profile {
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}

	.meta-panel-badge {
		color: #BFBBCF;
		margin: 16px auto 16px auto;
		width: fit-content;
		border: 1px solid $bl-grayscale-700;
		border-radius: 88px;
		padding: 6px 16px;
		font-size: 13px;
	}

	.meta-panel-input-container {
		input {
			font-size: 12px;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0.24px;
			background-color: $bl-grayscale-900;

			&:disabled {
				border: none;
				background-color: $bl-grayscale-800;
				color: $bl-grayscale-400;
			}
		}

		.copy-input {
			input {
				padding-right: 36px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.hide-label-wrapper {
			.icon-wrapper {
				top: 14px;

				svg {
					path {
						fill: $bl-grayscale-100;
					}
				}
			}
		}

		.previous-locations-popover {
			position: absolute;
			top: 62px;
			display: flex;
			flex-direction: column;
			width: 100%;
			border-radius: 12px;
			background: $bl-grayscale-800;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
			z-index: $z-index-meta-menu;

			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: $popover-transition-duration all,
			$popover-transition-duration opacity,
			$popover-transition-duration visibility;

			overflow-y: auto;
			overflow-x: hidden;
			max-height: calc(100vh - 492px);

			.option {
				display: flex;
				padding: 10px 16px;
				background: $bl-grayscale-800;
				min-height: 36px;;

				&.header {
					padding: 8px 16px;
					color: $bl-grayscale-400;
				}

				&:not(.header) {
					cursor: pointer;

					&:hover {
						background: $bl-grayscale-700;
					}
				}
			}

			&.open {
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}
		}
	}

	.meta-panel-connect-button-container {
		position: absolute;
		bottom: 12px;
		width: 100%;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 16px 24px;
			flex: 1 0 0;
			width: 100%;
			background-color: $bl-grayscale-600;
			border-radius: 100px;
			font-size: 13px;
			font-weight: 600;
			line-height: 16px;
			color: $bl-grayscale-200;
			border: none;
			@include button-greenroom-basic;

			&:active,
			&:focus {
				box-shadow: none;
			}
		}
	}
}