.session-card {
	background-color: $border-gray;
	border-radius: $rem8px;
	margin: 16px 0;
	padding: 16px;
	color: $near-white;
	position: relative;
	
	h1{
		margin: 8px 0;
		font-size: 18px;
		font-weight: 600;
		color: $white;
	}
	
	h3 {
		color: $text-light-gray;
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 16px;
		text-transform: capitalize;
	}

	.row {
		h3 {
			margin: 0;
		}

		display: flex;
		align-items: center;
		width: 100%;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.date-row {
		margin-top: 8px;
	}
	
	.row-space-between {
		h3 {
			margin: 0;
		}

		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.top-row {
		.popover {
			max-width: fit-content;
			position: absolute;
			top: 16px;
			left: 72%;
			right: 0;
		}
	}

	.clickable-row {
		@include button-greenroom-shadow;
		transition: background-color 150ms, color 150ms;
		border-radius: $rem8px;
		padding: 8px 16px;
		margin-bottom: 8px;
		margin-top: 8px;
		background-color: $border-gray;
		cursor: pointer;
	}

	.datepicker {
		font-weight: bold;
		margin-top: 8px;
		position: relative;

		.calendar-icon {
			position: absolute;
			right: 9px;
			top: 13px;
			pointer-events: none;
		}
	}

	.edit-wrapper {
		width: 100%;

		.edit-buttons {
			display: flex;
			width: 100%;

			.edit-button {
				@include button-greenroom-shadow;
				border-radius: 7.071rem;
				background-color: $border-gray;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $near-white;
				margin-top: 16px;
		
				&.cancel {
					width: 30%;
					margin-right: 16px;
				}
				
				&.save {
					width: 70%;
					background-color: $green;
				}

			}
		}
	}

	.circle-button {
		display: flex;
		justify-content: center;
		align-items: center;
		background: $border-gray;
		border-radius: 100%;
		padding: 0;
		height: 32px;
		width: 32px;
		transition: background-color 150ms, color 150ms;

		&:hover {
			@include button-greenroom-shadow;
			color: $near-white;

			svg{
				path{
					fill: $near-white !important;
				}
			}
		}
	}

	&.add-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 0;

		&:hover {
			background-color: lighten($border-gray, 1%);
		}
	
		&:active {
			background-color: darken($border-gray, 1%);
		}
	
		h3 {
			display: inline-block;
			line-height: 32px;
			margin-bottom: 0;
			vertical-align: top;
		}
	
		svg {
			margin-right: 16px;
		}
	
		.content {
			display: flex;
			padding: 24px;
			width: 100%;
		}
	}

	.popover {
		position: absolute;
		top: 0;
		left: 0;

		&.right {
			right: 0;
			left: auto;
		}
	}
}