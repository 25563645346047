@import "../base/colors";
@import "../base/vars-font";

.tutorial-video-modal-right-corner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    bottom: 88px;
    right: 16px;
    border-radius: 24px;
    background-color: $element-border-gray;

    .tutorial-video-header {
        align-items: center;
        background-color: $element-background-gray;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        color: $near-white;
        display: flex;
        font-family: $font-family-poppins;
        font-size: 22px;
        font-style: normal;
        line-height: 30px;
        height: 70px;
        max-height: 70px;
        padding: 24px 40px 16px;
    }

    .tutorial-video-body {
        padding: 16px;
        width: 38vw;
        height: 25vw;
    }

    iframe {
        border-radius: 8px;
    }

    .tutorial-video-close-icon {
        align-items: center;
        background: linear-gradient(
             133.12deg,
             rgba(255, 248, 92, 1) 16.71%,
             rgba(255, 106, 230, 1) 100%
        );
        border-radius: 50%;
        bottom: 24px;
        right: 14px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 56px;
        position: fixed;
        width: 56px;
    }
}

.tutorial-video-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;

    .tutorial-video-body {
        padding: 0;
        width: 60vw;
        height: 38vw;

        .tutorial-video-close-icon {
            position: relative;
            right: 8px;
            top: 36px;
            text-align: right;

            .icon-pointer {
                cursor: pointer;
            }
        }
    }

    &.product-tour {
        background-color: $bl-grayscale-900;

        .tutorial-video-body {
            @include flex-center;
            position: relative;
            width: 1150px;
            height: 700px;
        
            .tutorial-video-close-icon {
                position: absolute;
                top: 12px;
                right: 16px;
        
                svg {
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.74));
                }
            }
        }
    }

    iframe {
        border-radius: 8px;
    }
}