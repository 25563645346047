.invite-presenters-container {
	border-bottom: 1px solid rgba( $text-gray, .1 );
}

.join-requests-container {
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 30px;
}

.invite-request {
	display: flex;
	width: 100%;
	background-color: $border-gray;
	color: $text-light;
	align-items: center;
	border-radius: 100px;
	margin-right: 8px;
	height: 44px;
	transition: background-color 150ms;
	margin-bottom: 8px;
	animation-fill-mode: forwards;

	&.small {
		width: initial;
		height: 36px;
		margin-right: 0;

		.invite-actions {
			margin-left: 16px;
			button {
				height: 32px;
				width: 32px;
				min-height: 32px;
				min-width: 32px;
			}
		}
	}

	label {
		flex: 1;
		font-size: 14px;
		font-weight: bold;
		color: $near-white;
		padding-left: 10px;
	}

	.invite-actions {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 2px;

		button {
			padding: 0;
			margin: 0;
			height: 44px;
			width: 44px;
			min-height: 44px;
			min-width: 44px;
			border-radius: 100%;
			@include flex-center;
		
			&.accept {
				background-color: $pale-green;
				color: $button-gray;
				transition: background-color 150ms;
				display: flex;
				align-items: center;
				font-weight: 500;

				&:hover {
					background-color: lighten($pale-green, 5%);
				}
			}

			&.block {
				background-color: #4F4C57;
				color: $near-white;
				transition: background-color 150ms;
				margin-right: 4px;

				&:hover {
					background-color: rgba($near-white, 0.15);
				}

				svg {
					position: relative;
					top: -1px;
				}
			}
		}
	}
}