@import "vars-font";

//************************************************************
//
// Global Layout sizing (Width, Height, Breakpoints, etc..)
//
//************************************************************

// Breakpoints

$break-xxs: 320px;
$break-xs: 480px;
$break-sm: 640px;
$break-med: 768px;
$break-lrg: 1024px;
$break-xlrg: 1120px;

// Layouts - Large / Small

$content-max-width: 1240px;
$layout-column-l: 64%;
$layout-column-s: 36%;

//************************************************************
// Global Element z-indexes (by order of z-index)
//************************************************************

$z-index-max: 2147483647;
$z-index-right-click-menu: 3002;
$z-index-custom-graphics-menu: 3001;
$z-index-tt-wrapper: 1111;
$z-index-multi-account-modal: 801;
$z-index-fullscreen-load: 800;
$z-index-debug-panel: 777;
$z-index-alert: 103;
$z-index-lightbox: 102;
$z-index-media-modal: 102;
$z-index-modal: 101;
$z-index-broadcast-start-button: 100;
$z-index-meta-menu: 99;
$z-index-meta-menu-bg: 99;
$z-index-right-sidebar: 99;
$z-index-recessed-meta-menu: 98;
$z-index-tabs: 97;
$z-index-right-drawer: 97;
$z-index-virtuoso-gradient: 96;
$z-index-overlay: 95;
$z-index-video-overlay: 94;
$z-index-drawer: 93;
$z-index-loader: 92;
$z-index-user-menu-btn: 92;
$z-index-notification-drawer: 91;
$z-index-user-menu: 90;
$z-index-missing-feed-tooltip: 90;
$z-index-header: 89;
$z-index-video-wrapper: 88;
$z-index-tabbar: 88;
$z-index-dialog: 88;
$z-index-popover: 87;
$z-index-color-picker: 50;
$z-index-dnd-canvas-highlight: 49;
$z-index-media-controls-hidden: 49;
$z-index-recording-options: 49;
$z-index-media-controls: 48;
$z-index-shot-thumbnail-menu: 39;
$z-index-shot-thumbnail-overlay: 38;
$z-index-media-controls-container: 38;
$z-index-custom-graphics: 37;
$z-index-media-menu: 11;
$z-index-media-link: 10;
$z-index-expanded-media-link: 9;
$z-index-video-debug: 4;
$z-index-missing-feed: 3;
$z-index-chat-link-wrapper: 2;
$z-index-media-filter-results-container: 2;

$z-index-media-container-virtuoso-scroller: 1;
$z-index-modal-scroll-shadow: 1;

//************************************************************
// Global Padding and Margins
//************************************************************

// Containers

$global-padding: 40px;

// Text items

$margin-standard: 20px 0;
$margin-heading: 40px 0 20px;
$margin-text: $margin-standard;
$margin-list: $margin-standard;
$padding-list: 0 0 0 30px;

// dialog sizes

$dialog-size-small: 400px;
$dialog-size-medium: 500px;
$dialog-size-large: 600px;

// Fields

$margin-field: 20px 0;

$padding-field-small: 5px 15px;
$padding-field-top: 10px;
$padding-field: $padding-field-top 15px;
$padding-field-dynamic-focused: 16px 15px 4px;
$padding-field-large-top: 16px;
$padding-field-large: $padding-field-large-top 15px;
$padding-field-large-dynamic-focused: 25px 15px 7px;
$padding-field-minimal: 0;
$padding-field-textarea: 12px 15px;
$padding-field-textarea-dynamic: $padding-field-large-top 15px;

// Buttons

$padding-button-xsmall: 5px 12px;
$padding-button-dropdown: 6px 20px 5px 10px;
$padding-button-small: 9px 15px;
$padding-button: 11px 20px;
$padding-button-large: 14px 20px;

$button-height-xsmall: 27px;
$button-height-small: 37px;
$button-height: 42px;
$button-height-large: 54px;

//************************************************************
// Special Element Sizing
//************************************************************

$header-size-mobile: 48px;
$header-size: 108px;
$mobile-tabbar-size: 48px;
$avatar-size-sm: 32px;
$avatar-size-md: 42px;
$avatar-size-lg: 62px;
$avatar-size-xlg: 80px;

//************************************************************
// Border Radius
//************************************************************

$radius-small: $rem3px;
$radius-medium: $rem16px;
$radius-large: $rem24px;

$radius-modal: $radius-large;
$radius-container: $radius-medium;
$radius-field: $radius-small;

//************************************************************
// Global Transition Variables
//************************************************************

// Times
$field-transition-duration: 0.15s;
$popover-transition-duration: 0.1s;
$hover-transition-duration: 0.18s;
$modal-transition-duration: 0.28s;
$alert-transition-duration: 0.3s;
$transition-duration-fast: 250ms;
$transition-duration-med: 500ms;
$transition-duration-slow: 1000ms;

// Timing function
$transition-timing-default: ease-in-out;

// Time and function combined
$transition-very-fast: $popover-transition-duration $transition-timing-default;
$transition-fast: $transition-duration-fast $transition-timing-default;
$transition-med: $transition-duration-med $transition-timing-default;
$transition-slow: $transition-duration-slow $transition-timing-default;

//************************************************************
// Modals
//************************************************************

$modal-body-max-height: 540px;
$modal-padding-vertical: 30px;
$modal-padding-horizontal: 40px;
$modal-padding: $modal-padding-vertical $modal-padding-horizontal;
$modal-size-small: 420px;
$modal-size-medium: 640px;
$modal-size-large: 800px;

//************************************************************
// Drawer
//************************************************************

//full drawer width
$full-drawer-width: 509px;
//visible section of most drawers when partially obscured by tab menu
$global-drawer-width: 425px;
$content-card-m-height: 104px;
$content-card-m-width: 184px;
$drawer-padding: 24px;
$drawer-action-button: 48px;

//************************************************************
// Animation
//************************************************************
$cb-animation-out: cubic-bezier(0.7, 0, 0.99, 0.21);
$cb-animation-in: cubic-bezier(0.01, 0.79, 0.3, 1);

//************************************************************
// Drop Shadow
//************************************************************
$drop-shadow-context-menu: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7));

//************************************************************
// Thumbnails
//************************************************************
$thumbnail-width: 100;

//************************************************************
// Search and Filter
//************************************************************
$search-filter-height: 32px;
