.switch-wrapper {
	width: 32px;
	height: 16px;
	border-radius: $rem16px;
	position: relative;
	overflow: hidden;

	.switch-container {
		@include absolute-fill;
		@include button-greenroom-basic;
		cursor: pointer;
		background-color: $white;
		opacity: 0.5;
		transition: 300ms opacity;

		-moz-box-shadow:    inset 0 0.5px 2px #000000;
   		-webkit-box-shadow: inset 0 0.5px 2px #000000;
		box-shadow:         inset 0 0.5px 2px #000000;
		
		&::after {
			content: '';
			height: 12px;
			width: 12px;
			background-color: $dark-gray;
			border-radius: 100%;
			position: relative;
			display: block;
			top: 2px;
			left: 2px;
			transform: translateX(0px);
			transition: 300ms transform;
			transition-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1.08);
			-moz-box-shadow: 	0 0.5px 2px #000000;
   			-webkit-box-shadow: 0 0.5px 2px #000000;
			box-shadow: 		0 0.5px 2px #000000;
		}

		&.checked {
			transition: 150ms opacity;
			opacity: 1;
			&::after {
				transition: 150ms transform;
				transform: translateX(16px);
			}
		}

		input {
			display: none;
		}
	}

	&.green {
		overflow: visible;

		.switch-container {
			background: #878793;
			box-shadow: none;
			border-radius: 0.714rem;
			opacity: 1;

			&::after {
				top: -1px;
				left: 0px;
				width: 18px;
				height: 18px;
				background: #FFFFFF;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}

			&.checked {
				transition: 150ms background-color;
				background: #2AAE3E;
				&::after {
					transform: translateX(13px);
				}
			}
		}
	}

	&.pink {
		.switch-container {
			&.checked {
				background: #ff61e5;
			}
		}
	}
}