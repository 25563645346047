#teleprompter-popout {
	height: 80%;
	width: 20%;
	z-index: $z-index-max;
}

.teleprompter-editor-controls {
	margin: 0 24px;
	display: none;
	position: absolute;
	bottom: 20px;
	width: calc(100% - 48px);
	border-radius: 100px;
	height: 56px;
	background-color: #4F4C57;
	z-index: $z-index-meta-menu-bg;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		@include flex-center;
		background: none;
		border: none;
		padding: 6px;
		margin: 0 3px;
		text-transform: uppercase;
		border-radius: 4px;
		transition: 150ms background;
		overflow: hidden;

		svg {
			path {
				transition: 150ms fill;
			}
		}

		&.active {
			background: $med-gray;

			svg {
				path {
					fill: $near-white !important;
				}
			}
		}

		&:hover {
			svg {
				path {
					fill: $near-white !important;
				}
			}
		}
	}

	.editor-controls-container {
		@include flex-center;
		overflow: hidden;

		&.editor-left {
			justify-content: flex-end;
		}

		&.editor-center {
			border-left: 1px solid #4F4C57;
			border-right: 1px solid $border-gray;
		}

		&.editor-right {
			justify-content: flex-start;
		}

		.font-popover {
			height: 112px;
			width: 267px;
			background-color: $border-dark-gray;
			border-radius: 12px;
			opacity: 0;
			transition: 250ms transform, 90ms opacity;
			transform: translateY(40px);
			position: absolute;
			flex-direction: column;
			bottom: 70px;
			right: 0px;
			z-index: 200;
			
			&.hidden {
				display: none;
			}

			.range-container {
				display: flex;
				justify-content: space-between;
				padding: 6px 10px 0;
				align-items: center;
				border-bottom: 1px solid $med-gray;
				height: 41px;

				span {
					color: $text-gray;
					font-weight: 500;
					padding: 6px 0;
					display: inline-block;
				}

				.font-small {
					font-size: 14px;
				}
				
				.input-wrapper {
					width: calc(100% - 80px);
					
					input {
						position: relative;
						top: -5px;
						flex: 1;
						margin: 0;
						-webkit-appearance: none;
						appearance: none;
						outline: none;
						background: $med-gray;
						height: 3px;
						border-radius: 30px;
						width: 100%;
						padding: 0;

						&::-webkit-slider-thumb {
							-webkit-appearance: none; /* Override default look */
							appearance: none;
							width: 24px;
							height: 24px;
							background: $near-white;
							border-radius: 100%;
							cursor: pointer;
						}
						
						&::-moz-range-thumb {
							width: 24px;
							height: 24px;
							background: $near-white;
							border-radius: 100%;
							cursor: pointer;
						}
					}
				}

				.font-large {
					font-size: 24px;
				}
			}

			.colors-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 12px 24px;
				border-top: 1px solid $med-gray;
				margin-top: 3px;
				button {
					height: 41px;
					width: 41px;
					border-radius: 100%;

					&.active {
						border: 2px solid $dark-gray;
						box-shadow: 0 0 0 1px $near-white;
					}
				}
			}

			&.open {
				display: flex;
				opacity: 1;
				transform: translateY(0);

				&::after {
					content: " ";
					display: block;
					position: absolute;
					height: 17px;
					width: 17px;
					transform: rotate(45deg);
					right: 27px;
					bottom: -9px;
					background: $border-dark-gray;
				}
			}
		}
	}
}

.teleprompter-wrapper {
	padding: 24px 24px 96px 24px;

	.teleprompter-header {
		.back {
			background: none;
		}

		input, span {
			background-color: transparent;
			border: none;
			color: $near-white;
			font-weight: bold;
			margin: 0 0 0 8px;
			padding: 0;
			font-size: 14px;
			line-height: 16px;
			width: 100%;

			&::placeholder {
				color: $med-gray;
			}
		}
	}

	.section-data {
		display: grid;
		grid-template-columns: 1fr 3fr;
		border-radius: 12px;
		border: 2px solid transparent;

		&.playing {
			border: 2px solid $pale-green;
		}

		.media-thumbnail {
			position: relative;
			height: 0;
			padding-top: 56.25%;
			border-radius: 12px;
			background-color: $darker-gray;

			img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				border-radius: 12px;
				overflow: hidden;

				&.fill {
					object-fit: cover;
				}
			}
		}

		.shot-thumbnail {
			position: relative;
			height: 0;
			padding-top: 56.25%;
			border-radius: 12px;
		
			.shot-thumbnail-inside {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 12px;
				overflow: hidden;

				.shot-thumbnail-overlay {
					display: none;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					align-items: center;
					justify-content: center;
					background: rgba(26, 26, 31, 0.5);
					border-radius: 12px;

					&:active {
						top: 0px;
						left: 0px;
					}

					.edit-shot {
						display: flex;
						width: 32px;
						height: 32px;
						background: #4F4C57;
						border-radius: 100px;
						padding: 10px;
					}

					.play-shot {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 32px;
						background: #A6FF79;
						border-radius: 100px;
						padding: 8px 16px;
						font-weight: bold;
						font-size: 12px;
						letter-spacing: 0.4px;
						color: #121114;

						span {
							margin-right: 9.5px;
							white-space: nowrap;
						}

						&:active {
							transform: scale(0.95);
						}
					}
				}
			}

			.main-video {
				position: relative;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-clip: padding-box;
				border-radius: 12px;

				&.layer2 {
					position: absolute;
				}

				.video-container-presenter {
					display: flex;
					justify-content: center;
					align-items: center;

					.empty-video {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #26242B;
						height: 100%;
						width: 100%;

						.placeholder-icon {
							@include flex-center;
							max-width: 100%;
							min-width: 100%;
							padding-left: 35%;
							padding-right: 35%;
						}
					}

					.poster {
						.no-image-placeholder {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100%;
							width: 100%;

							span {
								font-size: calc(48px * .12);
								height: 12px;
								width: 12px;
							}
						}
					}

					.title-card {
						margin: calc(8px * .12);

						&.max-width {
							min-width: calc(100% - 16px * .12);
						}

						&.no-left-margin {
							margin-left: 0;
						}

						.card-3 {
							height: calc(42px * .12);
						
							.user-data {
								font-size: calc(12px * .12);
								margin-left: calc(8px * .12);
								text-shadow: none;
								
								.job-title, .location {
									font-size: calc(11px * .12);
								}
							}
						}
					}

					.poster {
						.no-image-placeholder {
							span {
								font-size: calc(48px * .12);
								height: 12px;
								width: 12px;
							}
						}
					}
				}
			}

			&.selected {
				border-color: $pale-green;
			}

			&:hover {
				.shot-thumbnail-overlay {
					display: flex;
					transition: opacity 300ms;
					opacity: 1;
				}
			}

			.duration-bar {
				opacity: 0;
				left: 99%;
				position: absolute;
				top: -3%;
				width: 6.16px;
				height: 106%;
				background: #A6FF79;
				box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				pointer-events: none;

				&.playing {
					opacity: 1;
					left: -1%;
					animation-name: movePlayLine;
					animation-timing-function: linear;
					animation-iteration-count: 1;
					
					@keyframes movePlayLine {
						from { left: -1%; }
						to { left: 99%; }
					}
				}
			}
		}
	}

	.editor {
		.DraftEditor-root {

			figure {
				margin: 0;
			}

			img {
				max-width: 100%;
				border-radius: 4px;
			}

			.align-right {
				* {
					text-align: right;
				}
			}

			.align-left {
				* {
					text-align: left;
				}
			}

			.align-center {
				* {
					text-align: center;
				}
			}

			li {
				margin-bottom: 8px;
			}
		}

		.public-DraftEditorPlaceholder-root {
			color: $med-gray;
			width: initial;
		}

		::-moz-selection { /* Code for Firefox */
			color: #fff;
			background: $green;
		}

		::selection {
			color: #fff;
			background: $green;
		}
	}
}
