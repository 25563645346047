.audio-only-feeds-wrapper {
    min-width: 178px;
    gap: 8px;
    pointer-events: auto;
    z-index: $z-index-video-wrapper;

    // The button
    .audio-only-feeds-drop-area {
        width: 300px;
        height: 80px;
        overflow: hidden;
        &.highlight {
            background: #445b3b;
        }
    }

    .audio-only-feeds-drop-area-disabled {
        overflow: hidden;
        background: #503c48;
        color: #ff5f7f;
        border-radius: 16px;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .audio-only-feeds-drop-area-disabled-span {
        color: #ff5f7f;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
    }

    .audio-only-feeds-button {
        gap: 8px;

        padding: 8px 16px;
        &.avatars {
            padding: 4px 12px 4px 4px;
        }
        .avatar-bubble-row {
            position: static;
        }
    }

    .main-feeds-container {
        display: none;
        position: absolute;
        bottom: 0;
        height: auto;
        width: 300px;
        border-radius: 16px;
        box-shadow: 0px 32px 84px rgba(0, 0, 0, 0.25);
        background-color: $border-gray;
        transition: height 500ms ease-in-out;
        overflow: hidden;


        &.visible {
            display: block;
        }
    
        &.expanded {
            max-height: 300px;
        }
    
        &.button {
            height: 80px;
        }

        .feed-container {
            padding: 16px 18px 16px;
            max-height: 300px;
            position: relative;

            animation-name: fade-in;
            animation-duration: 400ms;
            opacity: 1;

            &.fadeout {
                animation-name: fade-out;
                animation-duration: 70ms;
                opacity: 0;
                position: absolute; // moves feed out of the way so it doesn't block the button
                pointer-events: none;
                text-align: left;
            }
        }
        // When the container changes to the main button
        &.audio-only-feeds-drop-area {
            // padding: 16px 16px 10px;
            border: 1px dashed $med-gray;

            &.isover {
                border: 1px dashed $pale-green;
                background: #445b3b;
                color: $pale-green;
                div {
                    color: $pale-green;
                }
            }
        }

        .audio-only-feeds-drag-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 24px 16px;
            background: none;
            transition: opacity 700ms ease-in;

            pointer-events: auto;
            position: relative;

            .button-text {
                font-weight: normal;
                opacity: 1;
            }

            &.invisible {
                pointer-events: none;
                position: absolute;
                .button-text {
                    opacity: 0;
                }
            }

            .button-text {
                display: block;
                transition: opacity 700ms ease-in;
                opacity: 1;
            }
        }

        .title-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .audio-only-feeds-title {
                font-weight: $bold;
                color: $text-platinum;
                margin: 0;
            }
        }

        .feed-list {
            // Manually setting this height to prevent the list from overflowing into the parent's padding.
            // I couldn't get the parent to enforce overflow on this element.
            max-height: 244px;

            &::-webkit-scrollbar-thumb {
                background-color: $element-background-highlight-gray;
            }

            .audio-only-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                border-radius: $rem16px;
                clip-path: inset(0 0 0 0 round 16px);
                position: relative;
                padding: 6px 0px 6px 0;

                &:first-child {
                    padding-top: 16px;
                }

                .media-type {
                    color: $text-light-gray;
                    text-transform: capitalize;
                }

                .control-container {
                    display: flex;
                    flex-direction: column;
                    opacity: 0;
                }

                &:hover {
                    .control-container {
                        opacity: 1;
                    }

                    .text-container {
                        width: 126px;
                    }
                }

                &.audio:hover {
                    .text-container {
                        width: 85px;
                    }
                }

                .text-container {
                    display: flex;
                    flex-direction: column;

                    .media-name {
                        max-width: 140px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .media-type {
                        color: $text-light-gray;
                    }
                }

                .thumbnail {
                    flex: 0 0 84px;
                    height: 48px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-black;
                    border-radius: $rem8px;
                    overflow: hidden;

                    .thumbnail-container {
                        @include flex-center;
                        border-radius: 0.357rem;
                        overflow: hidden;

                        .srt-feed {
                            display: block;
                            @include absolute-fill;
                        
                            img {
                                object-fit: cover;
                                min-height: 101%;
                                max-height: 101%;
                                max-width: 101%;
                                min-width: 101%;
                            }
                        }

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            min-width: calc(100% + 0.5px);
                            min-height: calc(100% + 0.5px);
                            object-fit: contain;
                        }

                        &.fill {
                            img {
                                object-fit: cover;
                            }
                        }
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: $rem8px;
                            font-weight: $bold;
                            white-space: nowrap;
                            padding: 10px;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            font-size: 18px;
                        }
                        .empty-video.screenshare {
                            .placeholder-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                svg {
                                    max-width: 55px;
                                    max-height: 55px;
                                }
                            }
                        }
                    }
                }

                .button-container {
                    display: flex;
                    margin-left: auto;
                    gap: 6px;
                }
            }
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
            display: block;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            display: none;
        }
    }
}
