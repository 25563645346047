
$base-unit-size: 16;
$input-width-size: 72;

.audio-media-settings {
    height: calc(100% - 56px);
    position: relative;
    display: flex;
    flex-direction: column;

    .audio-header-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: ($base-unit-size / 4) + px;
        flex: 1 0 0;
        margin-left: $base-unit-size + px;
        white-space: nowrap;

        .audio-info {
            display: flex;
            width: 100%;
            margin-bottom: ($base-unit-size / 4) + px;
        }
    }

    .slider-input {
        width: 100%;
        height: $base-unit-size + px;

        input {
            margin: 0 ($base-unit-size / 4) + px;
        }
    }

    .grid-layout {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: auto;
        gap: $base-unit-size + px;

        .full-width {
            grid-column: span 12;
        }
        .half-width {
            grid-column: span 6;
        }
        .third-width {
            grid-column: span 3;
        }
    }

    .footer {
        margin-top: $base-unit-size + px;
    
        button {
            color: $bl-grayscale-1200;
            padding: 16px 24px;
            border-radius: 24px;
            white-space: nowrap;
            overflow: hidden;

            label {
                margin-left: ($base-unit-size / 2) + px;
            }
        }
    }

    audio {
        display: none;
    }

    .third-width.inputs {
        margin-left: auto;
    }

    .duration-picker {
        overflow: hidden;
        border-width: 1px;

        &:focus-within {
            border-width: 1px;
        }
    }

    .duration-picker,
    .third-width.inputs .pixel-input,
    .third-width.inputs .select-small {
        width: $input-width-size + px;
    }

    .duration-picker:hover {
        border-color: $bl-grayscale-200;
    }

    div.duration-picker,
    .third-width.inputs .select-label.dropdown {
        border-color: $bl-grayscale-400;
        box-sizing: border-box;
        height: 34px;
    }
    div.duration-picker:focus-within,
    .third-width.inputs .open .select-label.dropdown,
    .third-width.inputs div.pixel-input:focus-within {
        border-color: $bl-grayscale-200;
    }
    
    .duration-picker:focus-within {
        //This is needed because the default duration picker is keyed
        //  for 2px border, margine left -1px for diff styling that makes sense
        //  outside a right panel config
        margin-left: 0px;
    }
}

.audio-player {
    display: flex;
    align-self: stretch;
    align-items: center;
    border-radius: ($base-unit-size / 2) + px;
    padding: $base-unit-size + px;
}