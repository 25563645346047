@import "../base/colors";

$home-header-height: 72px;
$home-sidebar-width: 100px;
$home-menu-width: 192px;
$home-channel-bubble-size: 52px;

.home-wrapper {
	min-height: 100vh;
	max-width: 100vw;
	height: 100%;
	background: #1A1A1F;
	overflow-y: auto;
}

.home-body {
	&.producer {
		background: linear-gradient(90deg, $element-background-gray 94px, rgba(135, 135, 147, 0.0) 1px),
			linear-gradient(90deg, rgba(0, 0, 0, 0.0) 1px, rgba(0, 0, 0, 0.0));
	}
	margin-top: 72px;
	scrollbar-gutter: stable;
	display: flex;
	overflow-y: auto;
	width: 100%;
	height:100%;
}

.top-left-corner-logo {
	background-color: $element-background-gray;
	// border-right: 1px solid rgba(135, 135, 147, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 78px;
	min-height: 78px;
	min-width: 94px;
	max-width: 94px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;

	.telly-channel-bubble {
		margin-top: 6px;
		img {
			border-radius: 16px;
			width: 52px;
			height: 52px;
		}
	}
	.small-separator {
		margin-top: 4px;
		width: 56px;
		border-bottom: 1px solid rgba(135, 135, 147, 0.1);
	}
}

.home-sidebar-wrapper {
	min-width: 94px;
	max-width: 94px;
	background: #26242B;
	flex-direction: column;
	align-items: center;
	//border-right: 1px solid rgba(135, 135, 147, 0.1);
	z-index: 100;

	@include telly-bubble(auto, $home-channel-bubble-size, 8px 0);

	.small-separator {
		width: 56px;
		border-bottom: 1px solid rgba(135, 135, 147, 0.1);
		margin-bottom: 8px;
	}
	
	.home-channel-bubble-container {
		width: 100%;
		background-color: #26242B;
		flex-direction: column;
		align-items: center;
		border: none;
		border-left: 3px solid #26242B;
		cursor: pointer;
		
		&.selected {
			background-color: $border-gray;
			border-left: 3px solid #A6FF79;
		}

		.home-channel-bubble {
			background: #121114;
			width: $home-channel-bubble-size;
			height: $home-channel-bubble-size;
			margin: 8px 0;
			border-radius: 16px;
			object-fit: contain;
		}

		&::before {
			content: '';
			position: absolute;
			left: 72px;
			width: 8px;
			height: 8px;
			margin-top: 29.5px;
			background-color: #4F4C57;
			opacity: 0;
			transform: translateX(0px) rotate(-45deg);
			transition: opacity 150ms, transform 150ms;
			pointer-events: none;
			z-index: $z-index-user-menu;
		}

		&::after {
			content: attr(data-name);
			position: absolute;
			left: 76px;
			max-width: 250px;
			padding: 8px 8px;
			margin-top: 17px;
			background-color: #4F4C57;
			color: $white;
			border-radius: 4px;
			font-size: 14px;
			opacity: 0;
			transform: translateX(0px);
			transition: opacity 150ms, transform 150ms;
			pointer-events: none;
			z-index: $z-index-user-menu;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:hover {
			&::before {
				opacity: 1;
				transform: translateX(7px) rotate(-45deg);				
			}

			&::after {
				opacity: 1;
				transform: translateX(7px);
			}
		}
	}
}

.home-menu-content-header-wrapper {
	flex-direction: column;
	width: 100%;

	.home-header {
		height: $home-header-height;
		background: #26242B;
	}

	.home-menu-content-wrapper {
		overflow-y: auto;
		display: flex;
		flex: 1;
		.home-menu {
			border-left: 1px solid #333;
			max-width: $home-menu-width;
			height: 100%;
			flex-direction: column;
			padding: 16px;
			gap: 4px;
			flex: 1;

			@include break-min(1210px) {
				flex: 1;
			}

			@include break-min(1211px) {
				width: 15vw;
			}

			button {
				padding: 8px;
				transition: 150ms background, 150ms color;
				white-space: nowrap;
				justify-content: flex-start;

				&.selected {
					background: #26242B;
					color: $white;

					svg {
							path {
								transition: 150ms fill;
							}
					}
				}

				@media screen and (max-width: 1209px) {
					svg.margin-right-small {
						margin-right: 0;
					}
				}

				.button-text {
					display: none;

					@include break-min(1210px) {
						display: block;
					}
				}
			}
		}

		.home-shows {
			flex-direction: column;
			align-items: center;
			min-height: calc(100vh - $home-header-height - 16px);

			&.presenter {
				margin: auto;
			}
		}

		.home-team {
			display: flex;
			justify-content: center;
			.team-view {
				width: 100%;
				min-width: 700px;

				@include break-min(1160px) {
					width: 924px;
				}
			}
		}

		.home-shows,
		.home-team,
		.home-settings {
			flex: 1;
			margin: 0 auto;
			width: 100%;
		}

		.home-settings {
			display: flex;
			justify-content: center;

			.settings-view {
				width: 100%;
				min-width: 700px;

				@include break-min(1160px) {
					width: 924px;
				}

				h1 {
					margin: 0 0 30px 0;
				}

				.channel-icon-container {
					padding: 24px;

					.channel-icon-upload {
						width: 116px;
						height: 116px;
						margin-top: 12px;
						border: 1px dashed #4F4C57;
						@include button-greenroom-shadow;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;

						&.has-icon {
							box-shadow: inset 116px 116px rgba(0, 0, 0, 0.6);
							-moz-box-shadow: inset 116px 116px rgba(0, 0, 0, 0.6);
							-webkit-box-shadow: inset 116px 116px rgba(0, 0, 0, 0.6);

							&:active {
								box-shadow: inset 116px 116px rgba(0, 0, 0, 0.51);
								-moz-box-shadow: inset 116px 116px rgba(0, 0, 0, 0.51);
								-webkit-box-shadow: inset 116px 116px rgba(0, 0, 0, 0.51);
							}
						}
					}
				}

				.channel-title-container {
					width: 100%;
					padding: 24px;

					span:first-of-type {
						margin-bottom: 12px;
					}

					input[type="text"] {
						border: 1px solid #908CA0;
						border-radius: 100px;
						background-color: transparent;
						color: $white;
					}
				}
			}
		}

		.home-show-details {
			width: 98vw;
			justify-content: center;
		}
	}

	&:nth-child(2) {
		.home-header {
			max-width: calc(100vw - 92px);
		}
	}
}

.team-row {
	border-bottom: 1px solid #3C3A42;

	span {
		&.arrow {
			font-size: 6px;
			line-height: 8px;
		}

		&.active {
			color: white;
		}
	}
}

.search-field-teams {
	position: relative;
	
	input {
		border-radius: 100px;
		background: none;
		border-color: #908CA0 !important;
		padding-left: 32px;
		margin: 0;
		color: $near-white;
		height: 32px;

		&::placeholder {
			color: darken($text-gray, 10%);
		}
	}

	.search-icon {
		position: absolute;
		top: 9px;
		left: 13px;
	}
}