@keyframes showTooltip {
    from {
        visibility: visible;
        opacity: 0;
        transform: translateY(0px);
    }

    to {
        visibility: visible;
        opacity: 1;
        transform: translateY(-4px);
    }
}

@keyframes hideTooltip {
    from {
        visibility: visible;
        opacity: 1;
        transform: translateY(-4px);
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: translateY(0px);
    }
}

.tooltip-portal {
    opacity: 0;

    &.has-message {
        opacity: 1;
    }
}

.tooltip-wrapper {
    position: absolute;
    z-index: $z-index-tt-wrapper;
    min-width: 900px;
    opacity: 0;
    visibility: hidden;
    
    .tooltip-message {
        max-width: 290px;
        width: fit-content;
        
        padding: 12px 12px 12px 12px;
        border-radius: $rem4px;
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        
        line-height: 20px;
        text-align: center;
        white-space: pre-wrap;
        
        background: $bl-grayscale-600;
    }

    .tooltip-triangle {
        position: absolute;
        min-width: 8px;
        min-height: 8px;
        transform: rotate(-45deg);
        background: $bl-grayscale-600;
    }
}

.tooltip-message {
    &.dark {
        color: $text-gray;
        background: $border-dark-gray;
    }

    &.green {
        color: $border-dark-gray;
        background: $pale-green;
    }
}
    
.generic-tooltip {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    width: auto;
    z-index: 3001;
}

.info-i-tooltip {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: auto;

    svg {
        path {
            transition: 150ms fill;
        }
    }

    &:hover {
        svg {
            path {
                fill: $near-white;
            }
        }

    }
}