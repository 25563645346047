.video-selector-wrapper {
	display: flex;
	flex-direction: column;
	

	.video-selector-option {
		display: flex;
		padding: 18px 20px;
		border-radius: 12px;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.radio-button-container {
			display: flex;
			align-items: center;
			justify-content: center;
			

			.radio-button {
				width: 16px;
				height: 16px;
				border: $rem1px solid $near-white;
				border-radius: 100%;
				position: relative;
			}
		}

		.video-selector-meta {
			flex-direction: column;
			display: flex;
			padding: 0 16px;
   			width: 76%;
			label {
				color: $text-light;
				font-weight: 500;
				cursor: pointer;
				transition: 150ms color;
			}

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-bottom: 1px solid #3E3E40;
				padding-bottom: 6px;
				line-height: 20px;
				padding-top: 8px;
			}
		}

		.video-preview {
			width: 76px;
			height: 42px;

			video {
				width: 76px;
				height: 42px;
				border-radius: 4px;
				object-fit: cover;
			}

			.placeholder {
				width: 76px;
				height: 42px;
				border-radius: 4px;
				background: $dark-gray;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 12px;
					color: $med-gray;
				}
			}
		}

		

		&:hover {
			.video-selector-meta {
				label {
					color: $near-white;
				}
			}
		}
	
		&.selected {
			background: $border-gray;

			.radio-button-container {
				.radio-button {
					&::after {
						content: "";
						position: absolute;
						width: 6px;
						height: 6px;
						background-color: $near-white;
						border-radius: 100%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						animation: 150ms fadeIn;
					}
				}
			}
		}
	}

	
}