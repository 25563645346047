
//************************************************************
// Font definitions and variables
//************************************************************

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

$font-family-opensans: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;
// PX to REM conversion - based on a 14px body base font

// Moving above REM vars so it can be used in calculations
$font-size-body: 14px; // Must be in pixels

$rem1px: 0.071rem;
$rem1-5px: 0.107rem;
$rem2px: 0.142rem;
$rem3px: 0.214rem;
$rem4px: 0.285rem;
$rem8px: 0.5714rem;
$rem9px: 0.6429rem;
$rem10px: 0.7143rem;
$rem11px: 0.7857rem;
$rem12px: 0.8571rem;
$rem13px: 0.9286rem;
$rem14px: 1rem;
$rem15px: 1.071rem;
$rem16px: 1.143rem;
$rem17px: 1.214rem;
$rem18px: 1.286rem;
$rem19px: 1.357rem;
$rem20px: 1.429rem;
$rem21px: 1.5rem;
$rem22px: 1.571rem;
$rem23px: 1.642rem;
$rem24px: 1.714rem;
$rem25px: 1.785rem;
$rem26px: 1.857rem;
$rem27px: 1.928rem;
$rem28px: 2rem;
$rem29px: 2.071rem;
$rem30px: 2.143rem;
$rem32px: 2.286rem;
$rem34px: 2.429rem;
$rem36px: 2.571rem;
$rem38px: 2.714rem;
$rem40px: 2.857rem;
$rem44px: get-rem-from-px(44px, $font-size-body);
$rem50px: 3.571rem;
$rem64px: get-rem-from-px(64px, $font-size-body);
$rem100px: 7.142rem;

$line-height-body: normal;

$font-size-h1: $rem28px;
$font-size-h2: $rem26px;
$font-size-h3: $rem24px;
$font-size-h4: $rem21px;
$font-size-h5: $rem18px;
$font-size-h6: $rem16px;
$font-size-p: $rem14px;
$font-size-p-small: $rem12px;
$line-height-p: $rem24px;

$light: 300;
$regular: 400;
$semi: 500;
$semi-bold: 600;
$bold: 700;
$xbold: 800;