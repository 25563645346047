.stream-media-container {
	.expand-icon {
		position: absolute;
		bottom: 16px;
		right: 20px;
	}

	.create-full-dotted-button-stream-media {
		@include flex-center;
		background: $dark-gray;
		border-radius: $rem12px;
		width: 100%;
		height: 100%;
		padding: 0 8px;
		border: $rem1px dashed $med-gray;
		color: $text-light;
		
		span {
			font-size: 12px;
		}
		
		&.open {
			padding: 0 16px;
			height: 75%;
			
			span {
				font-size: 16px;
			}
		}

		svg {
			margin-right: 8px;
		}
	}

	.attachment-waiting{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 4px;
		width: 100%;
		height: 100%;
		border-radius: $rem8px;
		background-color: $darker-gray;
		flex-direction: column;
		color: $text-gray;
		
		span {
			font-size: 8px;
		}
		
		&.open {
			padding-top: 16px;
			height: 75%;

			span {
				font-size: 12px;
			}
		}
	}

	.thumbnail {
		min-width: 164px;
		min-height: 92px;

		img {
			width: 100%;
			height: 100%;
			max-width: 164px;
			max-height: 92px;
			object-fit: contain;
		}
	}
}

.settings-stream-media {
	.create-full-dotted-button-stream-media {
		span {
			font-size: 16px;
		}
	}

	.info-i-premedia, .info-i-postmedia {
		height: 19px;
		
		&::after {
			top: initial;
			left: 96px;
		}
	}
}

.stream-media-overlay{
	background-color: rgba(0,0,0, .6);
	z-index: 100;
	overflow: hidden;

	.stream-media-modal{
		max-height: 540px;
		max-width: 960px;

		.stream-media-top {
			height: 10%;

			.title {
				font-size: 18px;
			}

			.audio-container {
				height: 48px;
				width: 280px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: $darker-gray;
				padding: 8px 8px 8px 0;
				border-radius: $rem50px;
				position: relative;

				.audio-row-container {
					width: 100%;
					margin: 0 16px;

					.audio-title {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: pre;
						font-weight: 500;
						font-size: 14px;
						max-width: 163px;
					}

					.audio-progress-wrap {
						height: 4px;
						margin: 5px 0 5px 0;
						background: $dark-gray;
						width: 100%;
						overflow: hidden;
						bottom: 0;
						border-radius: $radius-medium;

						.audio-progress-bar {
							border-radius: $radius-medium;
							height: 4px;
							background: $red;
							transition: width 100ms linear;
						}
					}
				}

				.audio-play {
					width: 10%;
				}

				.audio-delete {
					width: 10%;
					margin: 0 12px 0 0;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.audio-play-button {
					.bottom-circle-btn{
						width: 48px;
						height: 48px;
					}
				}
			}

			.dotted-button-audio {
				transition: 150ms background;
				overflow: hidden;
			}
		}

		.stream-media-middle {
			width: 80%;
			height: 80%;
			padding: 64px;
			
			.media-container {
				max-width: calc(100%);
				max-height: calc(100%);
				overflow: hidden;
				padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
			}

			.trash {
				right: 8px;
				top: 8px;
			}
		}

		.stream-media-bottom {
			height: 10%;

			.scale {
				@include scale-slider;
				
				[type=range] {
					-webkit-appearance: none;
					background: transparent;
					width: 100px;
				}
			}
			
			.background {
				.color-toggle {
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					height: 32px;
					width: 32px;
				}

				.color-picker-container {
					&.open {
						bottom: 32px;
						max-width: 480px;
						left: 90px;
					}
				}
			}
		}
	}
}

.countdown-clock-container {
	z-index: 2;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.countdown-time-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.clock-svg {
			height: 40%;
			width: auto;

			filter: drop-shadow(0 0.6rem 0.25rem rgba(0, 0, 0, 0.2));
		}
		
		.countdown-time {
			position: absolute;
			top: 42%;
			left: 0;
			width: 100%;
			height: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			
			svg {
				width: 40%;
				height: 100%;
				filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.8));
			}
		}
		
		.countdown-time-message {
			position: absolute;
			top: 36%;
			left: 0;
			width: 100%;
			height: 20%;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 100%;
				height: 100%;
			}
		}

	}
}

.info-i-premedia{
	display: flex;

	span{
		display: block;
		margin-right: 3px
	}

	&:hover {
		&::after {
			opacity: 1;
			right: 100px;
		}
	}

	&::after {
		z-index: 2;
		content: ".jpg, .jpeg, .png, .gif, .mp4 \A\A Cannot be edited once the pre-stream \A has started or within 5 minutes of event time.";
		position: absolute;
		width: 290px;
		padding: 12px 16px;
		background: $border-dark-gray;
		color: $text-gray;
		border-radius: 12px;
		opacity: 0;
		font-size: 12px;
		margin-top: 20px;
		pointer-events: none;
		white-space: pre;
		top: -14px;
		left: 120px;
	}
}

.info-i-postmedia{
	display: flex;

	span{
		display: block;
		margin-right: 3px
	}

	&:hover {
		&::after {
			opacity: 1;
			right: 100px;
		}
	}

	&::after {
		z-index: 2;
		content: ".jpg, .jpeg, .png, .gif, .mp4 \A\A Cannot be edited once the event time \A has started.";
		position: absolute;
		width: 290px;
		padding: 12px 16px;
		background: $border-dark-gray;
		color: $text-gray;
		border-radius: 12px;
		opacity: 0;
		font-size: 12px;
		margin-top: 20px;
		pointer-events: none;
		white-space: pre;
		top: -14px;
		left: -140px;
	}
}