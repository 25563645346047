.select-small {
	display: flex;
	flex-direction: column;
	position: relative;
	cursor: pointer;
	user-select: none;
	
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.select-label {
		font-weight: 500 !important;
		font-size: 14px !important;
		line-height: 16px !important;
		color: $near-white !important;
		padding: 7px 30px;
		background: transparent;
		border: $rem1px solid $border-gray;
		border-radius: 100px;
		margin: 0 !important;
		display: flex !important;
		align-items: center;
		justify-content: center;
		min-width: 97px;
		flex: inherit !important;
		transition: 150ms background-color;
		white-space: nowrap;

		.small-select-icon {
			position: relative;
			top: 1px;
			left: -4px;
		}
		
		svg {
			path {
				transition: 150ms fill;
			}
		}

		&:hover {
			background-color: lighten($dark-gray, 2.5%);
			
			svg {
				path {
					fill: $near-white;
				}
			}
		}

		&.capitalize {
			text-transform: capitalize;
		}
		
	
		span {
			display: inline-block;
		}

		&.dropdown {
			border: none;
			min-width: initial;
			background: none;
			padding: 10px;

			svg {
				margin-left: 8px;
			}
		}
	}

	span.main-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba($text-light, 0.15);
		padding: 6px 0;
		opacity: 1;
		transition: opacity 150ms;

		&.open {
			opacity: 0;
		}
	}

	&.invalid {
		.select-label {
			color: $red;
		}

		.select-label-text {
			color: $red;
		}

		span.main-label {
			border-bottom: 1px solid $red;
		}
	}
	&.open {
		.select-background {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}

	.select-body {
		@include shadow-popover();
		max-height: 0;
		opacity: 0;
		position: absolute;
		background-color: $bl-grayscale-800;
		border-top: $rem1px solid rgba(black, 0);
		z-index: 0;
		transition: opacity 150ms, max-height 150ms;
		width: 100%;
		border-radius: $radius-medium;
		pointer-events: none;

		&.drop-left {
			right: -5px;
		}
		&.drop-right {
			left: -5px;
		}
		&.drop-down-left {
			top: 50px;
			right: -5px;
		}
		&.drop-down-right {
			top: 50px;
		}

		.select-small-row {
			padding-left: 16px;
			display: flex;
			align-items: center;
			justify-content: left;

			.select-option {
				&:hover {
					background-color: transparent !important;
				}
			}

			&:hover {
				background-color: rgba($near-white, 0.04);

				label {
					color: $color-white;
				}

				&:first-of-type {
					border-radius: $radius-medium $radius-medium 0 0;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}

			.has-icon {
				display: flex;
				align-items: center;
			}

			&.selected {

				label {
					color: $color-white;
				}

				.checkbox-outer {
					background-color: $near-white;
					border: $rem2px solid $near-white;
				}

				.select-option {
					svg {
						opacity: 1;

					}
				}

				&:first-of-type {
					border-radius: $radius-medium $radius-medium 0 0;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}

			&.selected-no-right-border-radius {
				border-bottom-right-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}

			.select-option {
				padding: 8px 16px;
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 36px;
				flex-direction: row-reverse;

				&:first-of-type {
					padding-top: 16px
				}

				&:last-of-type {
					padding-bottom: 16px;
				}

				label {
					color: $text-light;
					font-weight: 500;
					pointer-events: none;
					margin-bottom: 0;
				}

				&.checkbox {
					flex-direction: row;
				}

				.checkbox-outer {
					background-color: transparent;
					border: $rem2px solid $border-gray;
					border-radius: $rem3px;
					height: 16px;
					width: 16px;

					svg {
						font-size: 12px;
						color: $dark-gray;
						margin-left: 0;
						position: relative;
						top: -2px;
					}
				}

				svg {
					margin-left: 8px;
					opacity: 0;
					transition: 60ms opacity;

				}
			}
		}

		input.select-option {
			background: none !important;
			border: none;
			color: $color-white;
			width: 100%;
			outline: none;
		}

		.select-option {
			padding: 8px 16px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 36px;
			flex-direction: row-reverse;

			&:first-of-type {
				padding-top: 16px
			}

			&:last-of-type {
				padding-bottom: 16px;
			}

			label {
				color: $text-light;
				font-weight: 500;
				pointer-events: none;
				margin-bottom: 0;
			}

			&.checkbox {
				flex-direction: row;
			}

			.checkbox-outer {
				background-color: transparent;
				border: $rem2px solid $border-gray;
				border-radius: $rem3px;
				height: 16px;
				width: 16px;

				svg {
					font-size: 12px;
					color: $dark-gray;
					margin-left: 0;
					position: relative;
					top: -2px;
				}
			}

			svg {
				margin-left: 8px;
				opacity: 0;
				transition: 60ms opacity;

			}

			&:hover {
				background-color: $bl-grayscale-700;

				label {
					color: $color-white;
				}

				&:first-of-type {
					border-radius: $radius-medium $radius-medium 0 0;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}

			&.selected {

				label {
					color: $color-white;
				}

				.checkbox-outer {
					background-color: $near-white;
					border: $rem2px solid $near-white;
				}

				svg {
					opacity: 1;

				}

				&:first-of-type {
					border-radius: $radius-medium $radius-medium 0 0;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}

			&.selected-no-right-border-radius {
				border-bottom-right-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}
		}


		&.open {
			max-height: 300px;
			opacity: 1;
			z-index: 2;
			pointer-events: initial;
			overflow-y: auto;
		}
	}

	&.right {
		.select-body {
			transform: translateX(60%);
		}	
	}

	&.title {
		margin: 20px 0;

		.select-title {
			position: absolute;
			top: -22px;
			left: 16px;
			color: $text-gray;
			font-weight: 500;
			font-size: 12px;
		}
	}

	.select-small-carrot {
		position: absolute;
		top: 8px;
		right: 16px;
		pointer-events: none;
	}
}

.select-small.enclosed {
	.select-label {
	  justify-content: space-between;
	  border: 1px solid $element-border-gray;
	  background: transparent;
	  padding: 10px 15px !important;
	}

	.select-option {
	  label {
		line-height: 20px;
		margin-left: 0px;
		color: #ffffff;
	  }
	}
  }