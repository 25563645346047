.tabs-wrapper {
	@include absolute-fill;
	display: flex;
	flex-direction: column;
}

.tabs-header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba( $text-gray, .1 );
	padding-left: $global-padding - 15px;
	height: 72px;
	min-height: 72px; //redundant, but it wasn't behaving and showing a 72px height, this forces it
	width: 100%;
	z-index: $z-index-tabs;
	background-color: $darker-gray;
	position: -webkit-sticky; /* for Safari */
	position: sticky;
	top: 0;
	align-self: flex-start;

	.tab-button {
		background: none;
		position: relative;
		display: flex;
		justify-content: space-between;
		transition: 150ms color;
		font-size: 14px;
		user-select: none;
		border: none;
		outline: none;
		padding: 10px;
		min-width: 60px;
		min-height: 62px;

		&.highlight {
			z-index: 2;
		}

		&.unseen {
			&::before {
				content: "";
				position: absolute;
				top: 8px;
				right: 14px;
				height: 5px;
				width: 5px;
				border-radius: 100%;
				background: $red;
			}
		}

		&.host {
			&::after {
				background-color: $red;
			}
			&::before {
				background: $green;
			}
		}

		&:hover {
			color: darken($color-white, 30%);

			&::after {
				width: 20px;
			}
		}

		&.active {
			color: $color-white;

			&::after {
				width: 20px;
			}
		}

		&.icons {
			flex-direction: column;
			align-items: center;

			svg {
				margin-bottom: 3px;
			}

			&.active {
				svg {
					path {
						fill: $near-white !important;
					}
				}
			}

			.icon-small-container {
				position: relative;
				left: 4px;
			}

			&.invite {
				display: flex;
				flex-direction: row;
				justify-content: center;
			}
		}
	}

	.tab-underline {
		width: 20px;
		height: 3px;
		background-color: $green;
		transform: translateX(0);
		transition: transform 150ms, opacity 150ms;
		transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.02);
		position: absolute;
		bottom: -2px;
		left: 60px;

		&.host {
			left: 45px;
		}

		opacity: 1;
		border-radius: 2px;

		&.two {
			transform: translateX(74px);
			&.host {
				transform: translateX(60px);
			}
		}

		&.three {
			transform: translateX(134px);
			&.host {
				transform: translateX(120px);
			}
		}

		&.four {
			transform: translateX(194px);
			&.host {
				transform: translateX(180px);
			}
		}

		&.five {
			transform: translateX(240px);
			// opacity: 0;
		}

		&.host {
			background-color: $red;
		}

		&.hidden {
			transform: translateX(600px);
			opacity: 0;
		}
	}

	.screen-share-btn {
		margin-left: 20px;
	}

	.avatar-button {
		position: absolute;
		right: 30px;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding-right: 10px;
		width: 60px;
		min-height: 64px;

		.icon-arrow-container {
			position: relative;
			top: -3px;
			left: 7px;
		}

		// transform: scale(1);
		// transition: transform 150ms;

		// &:hover {
		// 	transform: scale(1.05);
		// }

		// &:active {
		// 	transform: scale(0.95);
		// }
	}
}

.tabs-container {
	position: relative;
	min-width: 450px;
	height: 100%;

	&.highlight{
		z-index: 2;
	}
}

@keyframes animateTabIn {
	0% {
		display: block;
		opacity: 0;
		left: $global-drawer-width;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes animateTabOut {
	0% {
		opacity: 1;
		left:0;
	}
	
	99% {
		opacity: 0;
		left: $global-drawer-width;
	}

	100% {
		display: none;
	}
}

.tab {
	@include absolute-fill;
	@include custom-scrollbar;

	position: absolute;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	background: $bl-grayscale-900;
	background-color: $bl-grayscale-900;
	left: $global-drawer-width;

	&.active {
		z-index: $z-index-right-drawer;
		display: flex;
		flex-direction: column;
		animation: animateTabIn 150ms ease-in-out forwards;
	}

	&.wasActive {
		animation: animateTabOut 150ms ease-in-out forwards;
	}

	&.noscroll {
		overflow: hidden;
	}

	&.static {
		position: static;
	}

	&.overflow-y-scroll {
		scrollbar-gutter: stable;
	}

	&.no-overflow-y {
		overflow-y: hidden;
	}

	.tab-header-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 32px 24px 8px;

		.tab-header-title {
			.text {
				font-weight: bold;
				font-size: 16px;
				color: #FFFFFF;
			}

			/*
				For now, we want to ensure that the Titles tab / drawer
				matches the designs in Figma. Eventually, all drawers
				should probably match and this will not be necessary.
			*/
			.drawer-header.titles  {
				margin: 0;
				color: $bl-grayscale-200;
			}
		}

		.tab-exit-button {
			cursor: pointer;
		}
	}

	.tab-row {
		display: flex;
		align-items: center;
		padding: 8px 16px;
	}

	.popover {
		top: 32px;
		right: 16px;
	}

	.audio-mixer-wrapper {
		.audio-mixer-value {
			min-width: 4.5ch;
			text-align: right;
		}
	
		.locked {
			opacity: 0;
		}
	}
}
