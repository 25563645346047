.field-group.select {
	z-index: 1;
	width: 100%;
}

.field-group.select::after {
	content: none;
}

.field-group.select .select-container {
	border-radius: 100px;
	position: relative;
	display: flex;
	height: 48px;
	border: 1px solid #625e70;
	cursor: pointer;
}

.field-group.select .select-container .selected-option {
	position: absolute;
	top: 0;
	left: 16px;
	bottom: 0;
	display: flex;
	align-items: center;
	font-size: 12px !important;
	font-weight: 500;

	img, svg {
		margin-right: 8px;
	}
}

.field-group.select .select-container .select-dropdown-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.select-dropdown-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 15px;
	z-index: 1;

	.main-icon {
		position: absolute;
		right: 14px;
		top: 38px;
	}
}

.field-group.select .select-container .select-dropdown {
	position: fixed;
	margin-top: 54px;
	display: none;
	z-index: 999;
	transform: translate(-2px, 24px);

	&.open {
		display: unset;
		opacity: 1;
	}
}

.field-group .tag-select-dropdown, .field-group .select-dropdown, .field-group .enter-select-dropdown {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	max-height: 300px;
	margin-top: -16px;
	opacity: 0;
	overflow-y: auto;
	background-color: #26242b;
	border-radius: 24px;
	box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.25), 0 8px 24px rgba(0, 0, 0, 0.25);
	transform: translateY(-8px);
	transition: 150ms opacity, 150ms transform;
}

.field-group .tag-select-dropdown .tag-option, .field-group .tag-select-dropdown .select-option, .field-group .select-dropdown .tag-option, .field-group .select-dropdown .select-option, .field-group .enter-select-dropdown .tag-option, .field-group .enter-select-dropdown .select-option {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1 1;
	padding: 16px;
	font-size: 14px;
	cursor: pointer;

	span {
		display: flex;
		align-items: center;

		img, svg {
			margin-right: 8px;
		}
	}
}
