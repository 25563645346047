@mixin base-font {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-style: normal !important;
}

@mixin font-style-s1 {
    @include base-font();
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

@mixin font-style-s2 {
    @include base-font();
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.24px;
}

/* H3 */
@mixin font-style-h3 {
    @include base-font();
    font-size: 23px !important;
    font-weight: 700 !important;
    line-height: 32px !important; /* 139.13% */
}

@mixin font-style-h4 {
    @include base-font();
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.2px;
}

@mixin font-style-p2 {
    @include base-font();
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.24px;
}


.style-packs-tab {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* Standard */

    @include custom-scrollbar(24px, 1);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 24px 8px 12px 24px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .magic-telly-wand-gradient {
        z-index: 1;

        width: 436px;
        height: 149px;

        position: absolute;
        left: -104px;
        top: -100px;

        border-radius: 436px;
        opacity: 0.1;
        background: #FFF85C;
        filter: blur(32px);
    }

    .tab-header-row {
        padding: 38px 24px 16px;
    }

    .style-packs-filters {
        margin-bottom: 24px;

        .style-packs-filter-search {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;

            .input-search {
                padding: 0;
                input {
                    border: 1px solid $bl-grayscale-400;
                    width: 100%;

                    @include font-style-p2();
                }
            }
        }

        .style-packs-filter-sort-options {
            display: flex;
            justify-content: center;
            align-items: center;

            .style-packs-filter-content {
                margin-right: 8px;
                z-index: 3;
            }
            
            .style-packs-filter-content, 
            .style-packs-filter-sort {
                flex: 1;

                .select-small {
                    .select-label {
                        border: 1px solid $bl-grayscale-400;
                        justify-content: left;
                        padding: 7px 16px !important;

                        .select-label-text {
                            @include font-style-p2();
                        }
                    }
                }
            }
        }
    }
}

.style-packs-tab-header {
    margin: 0;
    z-index: 6;
    padding-bottom: 0;

background: linear-gradient(268.52deg, #FFFD62 -2.21%, #FF6AE6 99.37%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.style-pack-info-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
        margin: 0;
    }

    svg {
        cursor: pointer;
        margin-right: 8px;
    }
}

.style-packs {
    .style-pack {
        margin-bottom: 16px;

        .style-pack-header {
            display: flex;
            align-content: center;
            width: 100%;
            justify-content: space-between;
            margin-bottom: -8px;

            .style-pack-header-title {
                align-content: center;

                color: var(--grayscale-200-light-grey, #E5E4EC);
                @include font-style-s1();

                .style-pack-header-lock-icon {
                    margin-right: 4px;
                    margin-top: 1px;
                }
            }

            .style-pack-view-style {
                margin-top: 3px;
                display: flex;
                align-content: center;
                cursor: pointer;

                color: var(--grayscale-400-dark-grey, #908CA0);
                @include font-style-s2();

                .style-pack-view-style-arrow {
                    margin-top: 2px;
                    margin-left: 6px;
                }
            }
        }
    }

    .carousel {
        .style-pack-carousel-content-item {
            display: flex;
            width: 134.5px;
            height: 76px;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            flex-shrink: 0;

            border-radius: 8px;
            background: #312F38;

            margin-right: 8px;

            .style-pack-carousel-lock-icon {
                position: relative;
                margin-left: 8px;
                margin-top: -68px;
            }

            .style-pack-carousel-image {
                border-radius: 8px;
            }

            .content-card {
                width: 100%;
                height: 100%;

                .image-container {
                    width: 100%;
                    height: 100%;
                    cursor: grab;

                    video,
                    img {
                        border-radius: 8px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &.filtered {
        width: auto;

        .style-pack-header {
            margin-bottom: 16px;
        }
    }
}

.style-pack-info-content-item {
    position: relative;

    .content-card {
        width: 100%;
        aspect-ratio: 16 / 9;

        .image-container {
            &.title {
                margin: 0 6px;
                height: 100%;
            }

            video,
            img {
                border-radius: 8px;
                width: 100%;
                height: 100%;
            }
        }
    }

    .style-pack-info-image {
        border-radius: 8px;
    }

    &.extra-padding {
        justify-content: center;
        align-items: center;
    }

    .style-pack-info-lock-icon {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 2;
    }
}

@keyframes renderIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes renderOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.style-pack-info {
    z-index: 6;
    position: absolute;
    background: $bl-grayscale-900;
    @include custom-scrollbar(24px, 1);
    overflow-y: auto;
    width: 100%;
    
    height: 100%;
    padding: 24px 8px 24px 24px;

    &.has-request-button {
        padding-bottom: 78px;
        height: 100%;
    }

    .tab-header-row {
        padding-bottom: 16px;

        .tab-header-title {
            overflow: hidden;
            color: var(--grayscale-100-white, #FFF);
            text-overflow: ellipsis;
            @include font-style-h4();
        }
    }

    .style-pack-info-about {
        margin-right: 24px;
        margin-left: 24px;

        color: $white;
        @include font-style-p2();
    }

    .style-pack-sliding-menu {
        margin-bottom: 16px;
    }

    .style-pack-info-options {
        position: relative;
        display: flex;
        border-radius: $rem12px;
        background-color: $bl-grayscale-800;
        margin-bottom: 16px;
        overflow: hidden;

        .color-swatch {
            display: flex;
            justify-content: space-between;
            border-radius: $rem12px;
            height: 10px;
            margin-top: 10px;
            overflow: hidden;
        }

        .font-preview {
            max-height: 20px;
        }

        .coming-soon-colorways,
        .coming-soon-fonts {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 14px 16px 9px 81px;
            cursor: pointer;
            background-color: $bl-grayscale-800;
            opacity: 0;
            transition: opacity $transition-fast;

            .coming-soon-background {
                width: 45%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: $bl-gr-primary-500;
                filter: blur(100px);
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
            }

            .bl-font-header {
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &:hover {
                opacity: 1;
            }
        }

        .coming-soon-colorways {
            .coming-soon-background {
                background: #FF6AE6;
            }

            .bl-font-header {
                background-image: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, $bl-gr-primary-500 120.95%);
            }
        }

        .coming-soon-fonts {
            .coming-soon-background {
                background: $bl-gr-primary-500;
            }

            .bl-font-header {
                background-image: linear-gradient(90deg, #5AE4FF 0%, #FFFD62 100%);
            }
        }
    }

    .style-pack-info-content {

        .style-pack-info-content-title {
            color: $white;
            @include font-style-s1();

            margin-bottom: 8px;
        }

        .style-pack-info-content-items {
            margin-bottom: 16px;
            margin-right: 12px;
            display: inline-grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 8px;
            row-gap: 8px;

            &.title {
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.misc {
                background-color: $bl-grayscale-800;
                border-radius: $rem12px;

                .style-pack-info-content-item {
                    margin-bottom: 0;

                    &.extra-padding {
                        .content-card {
                            .image-container {                    
                                video,
                                img {
                                    padding: 0;
                                    margin-top: 24px;
                                }
                            }
                        }
                    }
                }

                .misc-options-container {
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    gap: 12px;

                    .style-pack-info-options {
                        background-color: $bl-grayscale-900;
                        border-radius: $rem12px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.style-pack-info-animation-container {
    transition: all 300ms cubic-bezier(.56,.99,.66,1.01);
    position: relative;
    
    opacity: 0;
    pointer-events: none;
    height: 0;

    &.sp-render-in {
        opacity: 1;
        pointer-events: all;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
    }
    
    &.sp-render-out {
        opacity: 0;
        pointer-events: none;
        height: 0;
    }
}

.style-pack-content-request-styling {
    position: fixed;
    bottom: 0;
    z-index: $z-index-media-link;
    padding: 16px 0;
    width: $global-drawer-width;

    background: rgb(38, 36, 43);
    background: linear-gradient(0deg, rgba(38, 36, 43, 1) 40%, rgba(38, 36, 43, 1) 82%, rgba(31, 7, 91, 0) 100%);

    .style-pack-content-request-styling-button {
        padding: 20px 40px;
        margin: 0 24px;

        /* L1 */
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 123.077% */
        text-align: right;
        color: $bl-grayscale-1200;
        font-feature-settings: 'clig' off, 'liga' off;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        border-radius: 100px;
        background: var(--gradient-sherbet-3, linear-gradient(109deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%));
        cursor: pointer;
        user-select: none;
    }
}

.style-pack-filtered-list {
    overflow: hidden;
    display: flex;
    gap: 16px;

    .style-pack-info-content-item {
        max-width: calc(50% - 8px);
        min-width: calc(50% - 8px);
    }
}

@media screen and (max-height: 1015px) {
    .style-packs-tab {
        .carousel {
            .carousel-right-gradient {
                right: 14px;
            }
        }
    }
}

@media screen and (min-height: 1016px) {
    .style-packs-tab {
        .carousel {
            .carousel-right-gradient {
                right: 24px;
            }
        }
    }
}