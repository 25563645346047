//************************************************************
// Color Variables
//************************************************************

// Brand colors

$color-brand-blue: #026a93;
$color-brand-orange: #ef731a;
$color-ballpoint: #28374e;
$color-metric: #576377;
$color-cloud: #dce9ea;

// Secondary Colors

$color-white: #ffffff;
$color-nearblack: #232323;
$color-black: #000000;
$color-off-white: #f7f7f7;
$color-silver: #f2f4f6;
$color-light-gray: #eeeeee;
$color-gray: #bababa;
$color-med-gray: #8b939f;
$color-dark-gray: $color-metric;
$color-dark-blue: $color-ballpoint;

// Alternate Colors

$color-pale-green: #e2f8d3;
$color-light-green: #b4dd97;
$color-green: #62b45c;
$color-aqua: #45d5ce;
$color-teal: #42b590;
$color-light-teal: #45d5a7;
$color-blue: #00a4e4;
$color-light-blue: lighten($color-blue, 10%);
$color-med-blue: #0084b8;
$color-carrot: #ff9f4c;
$color-pale-red: #f7deda;
$color-light-red: #ff7979;
$color-red: #d3112c;
$color-fuscia: #f02361;

//************************************************************
// Third party brand colors
//************************************************************

$color-facebook: #334f8a;
$color-google: #dc4e41;
$color-twitter: #1da1f2;
$color-linkedin: #0077b5;

//************************************************************
// Text Color
//************************************************************

$color-text: #4a4a4a;
$color-text-dark: #232323;
$color-text-light: #696969;
$color-text-lighter: #9b9b9b;
$color-text-placeholder: #9b9b9b;
$color-text-heading: $color-text;
$color-link: $color-brand-blue;
$color-link-hover: lighten($color-brand-blue, 10%);
$color-active: $color-blue;

//************************************************************
// Special Colors
//************************************************************

// Layout

$color-bg-dark: #1f2733;
$color-bg-darker: #0a1320;
$color-theme-body-default: #e7e7e7;

// App Colors

$color-primary: $color-brand-blue;

// Header Color

$color-logo: $color-white;
$color-header: $color-dark-blue;

// Borders

$color-border: rgba(255, 255, 255, 0.15);
$color-border-light: rgba(0, 0, 0, 0.12);
$color-border-lighter: rgba(0, 0, 0, 0.08);

// Overlay

$color-overlay: rgba(14, 19, 26, 0.88);

// Text Fields / Buttons

$color-field-bg: #ffffff;
// $color-field-border: $color-border-light;
$color-field-border: #e0e0e0;
$color-field-border-focus: $color-blue;
$color-button-shadow-active: rgba(0, 0, 0, 0.2);
$color-button: #ffffff;
$color-button-hover: $color-off-white;
$color-button-border: $color-border-light;
$color-button-neutral: $color-light-gray;
$color-button-trans: rgba(0, 0, 0, 0.05);
$color-button-medium: $color-dark-gray;
$color-button-dark: $color-dark-gray;
$color-button-positive: $color-blue;
$color-button-action: $color-green;
$color-button-primary: $color-primary;
$color-button-caution: $color-carrot;

$color-video-placeholder: rgba(0, 0, 0, 0.5);

// Checkmarks / Radio buttons / Switches

$color-checkbox-empty: $color-gray;
$color-switch-off: $color-gray;
$color-switch-on: $color-green;

// Other colors (one-off)

$color-avatar-empty: $color-gray;
$color-has-updates: $color-brand-orange;
$color-passed: $color-green;
$color-failed: $color-red;
$color-gift: $color-fuscia;
$color-comment-bubble: $color-off-white;

// Alert / Warning colors

$color-warn: $color-red;
$color-required: $color-red;
$color-caution: $color-carrot;

$color-alert-bg-positive: $color-green;
$color-alert-bg-warn: $color-red;

$color-alert-positive: $color-brand-blue;
$color-alert-warn: $color-red;

//************************************************************
// Other values: Shadows, borders, etc..
//************************************************************

// Shadows

$shadow-global-user: 0 7px 40px 0px rgba(0, 0, 0, 0.34);
$shadow-popover: 0 0 4px 2px rgba(0, 0, 0, 0.2);
$shadow-alert: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
$shadow-modal: 0px 40px 30px -25px rgba(0, 0, 0, 0.85);
$shadow-login-container: 0 8px 15px 0 rgba(0, 0, 0, 0.18);
$shadow-header-fixed: 0 2px 10px 1px rgba(0, 0, 0, 0.1);

// Misc border values

$border-popover: 1px solid #e7e7e7;
$border-dashed-blue: 2px dotted $color-light-blue;

%border-figure-selected {
	box-shadow: 0 0 0 2px $color-blue, 0 1px 2px rgba(0, 0, 0, 0.2);
}

// theme colors
:root {
	--color-link: #026a93; // $color-brand-blue -> $color-link;
	--color-button: #026a93; // $color-brand-blue;
	--color-accent: #00a4e4; // $color-blue;
}

// Brand colors

$color-powerpoint: #F65314;
$color-excel: #7CBB00;
$color-word: #00A1F1;
$color-pdf: #ff0000;