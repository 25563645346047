.carousel-track {
    display: flex;
    align-content: center;
    overflow: hidden;
    margin-top: 16px;
    position: inherit;
}

.carousel {
    position: relative;
    cursor: grab;

    .carousel-gradient {
        pointer-events: none;
        position: absolute;
        width: 32px;
        height: 76px;
        flex-shrink: 0;
        transition: opacity 0.5s;
        opacity: 0;
        top: 0;
        z-index: 1;
        
        &.visible {
            opacity: 1;
        }
        
        &.left {
            background: linear-gradient(-270deg, #26242B 0%, rgba(38, 36, 43, 0.00) 100%);
            left: 0;
        }
        
        &.right {
            background: linear-gradient(270deg, #26242B 0%, rgba(38, 36, 43, 0.00) 100%);
            right: 0;
        }
    }

    .carousel-arrow {
        display: inline-flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        max-width: 32px;
        max-height: 32px;
        top: calc(50% - 16px);
        z-index: 2;

        border-radius: 100px;
        background: $pale-green;
        /* Dropdown shadow */
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);

        position: absolute;

        cursor: grab;
        pointer-events: none;

        &.visible {
            cursor: pointer;
            pointer-events: auto;
        }
        
        opacity: 0;
        transition: opacity 300ms;
        
        &.left {
            left: 16px;
        }
        
        &.right {
            right: 16px;
        }
    }

    &:hover {
        .carousel-arrow {
            &.visible {
                opacity: 1;
            }
        }
    }
}