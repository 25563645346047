.sliding-button-group {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    .sliding-button-background {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent; // Must be set elsewhere for slider to be seen.
        transform: translateX(0%);
        transition: transform $transition-fast;
    }

    .sliding-button {
        position: relative;
        flex: 1;
        background-color: transparent !important;

        path {
            transition: fill $transition-fast;
        }

        .sliding-button-text {
            .bl-font-header {
                transition: color $transition-fast;
            }
        }
    }
}
