.scheduled-stream-card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 72px;
    padding: 16px;
    border-radius: 12px;
    color: $bl-grayscale-1200;
    background:
        linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.20) 0%,
            rgba(255, 255, 255, 0.20) 100%
        ),
        linear-gradient(
            90deg,
            #FF6AE6 0%,
            #55D6F0 100%
        );

    .scheduled-stream-card__badge {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.40);
        user-select: none;
    }

    .scheduled-stream-card__content {
        flex: 1;
        color: $bl-grayscale-1200;
 
        .scheduled-stream-card__body {
            opacity: 0.5;
        }
    }

    .scheduled-stream-card__action {
        .scheduled-stream-card__action-button {
            // Give users a larger click target without changing the design
            padding: 10px;
            margin-right: -10px;
            background-color: transparent;
        }
    }
}
