.color-picker-wrapper, .image-picker-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	display: none;

	&.open {
		display: initial;
	}

	.color-picker-background, .image-picker-background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: $z-index-color-picker;
	}

	.color-picker-container, .image-picker-container {
		@include shadow-popover();
		max-height: 0;
		position: absolute;
		width: 100%;
		background-color: $element-border-gray;
		padding: 24px;
		opacity: 0;
		display: flex;
		flex-direction: column;
		z-index: $z-index-color-picker;
		border-radius: $radius-medium;
		overflow-y: hidden;

		&::-webkit-scrollbar {
			display: none;
		}

		&.open {
			max-height: 576px;
			opacity: 1;
		}

		.color-label {
			margin-bottom: 12px;
			font-weight: $bold;
		}

		.images-container {
			@include custom-scrollbar(24px, 1);
			overflow-y: scroll;
			padding-bottom: 24px;
			
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			flex: 0.20 0.20 0.20;

			.image {
				flex-basis: calc(25% - 8px);
				margin: 4px;
				position: relative;
				width: 100%;
				border-radius: 8px;	
				aspect-ratio: 16/9;
				min-width: 40px;
				min-height: 15px;
				display: flex;
				align-items: center;
				justify-content: center;

				&.no-image {
					font-size: $rem12px;
					border: $rem1px solid $gray;
					cursor: pointer;

					&.selected {
						box-shadow: 0 0 0 2px $red;
					}
				}

				img {
					aspect-ratio: 16/9;
					min-width: 100%;
					object-fit: contain;
					border-radius: 8px;	
					cursor: pointer;

					&.selected {
						box-shadow: 0 0 0 2px $red;
					}
				}

				button {
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					color: $near-white;
					position: absolute;
					top: 4px;
					right: 4px;
					transition: 150ms color;

					svg {
						transform: rotate(45deg);

						&:hover {
							color: $red;
						}
					}
					
					&:hover {
						color: $red;
					}
				}
			}


			.image-uploader {
				width: 100%;
				height: 44px;
				color: rgba(84, 84, 84, 0.5);
				color: $text-light;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				font-weight: normal;
				cursor: pointer;
				transition: background-color 150ms;
				label {
					pointer-events: none;
				}
				&:hover {
					background-color: rgba(84, 84, 84, 0.4);
				}
			}
		}

		.select-container {
			display: flex;
			align-items: center;
		
			.saturation {
				position: relative;
				height: 120px;
				width: 120px;
				border-radius: 8px;
				overflow: hidden;
			}

			.right-side {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-left: 16px;

				.value-container {
					display: flex;
					margin-bottom: 32px;

					label {
						margin-right: 17px;
						display: flex;
						justify-content: center;
    					align-items: center;
					}

					.color-value {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $dark-gray;
						border: $rem1px solid $border-gray;
						height: 36px;
						border-radius: $rem100px;
						text-align: center;
						color: $white;
						padding: 0;
					}
				}

				.hue {
					position: relative;
					height: 8px;
					width: 100%;
					border-radius: $rem8px;
				}
			}
		}
	}

	.image-picker-container {
		min-width: 470px;
		margin : 24px 8px 0 0;
		overflow-y: auto;
		@include custom-scrollbar(16px, 1);
		background-color: $bl-grayscale-900;
		padding: 24px 8px 0 24px;

		.image-label {
			margin-bottom: 4px;
			font-weight: $bold;
		}

		.gradient-fade {
			position: relative;
			top: 8px;
			height: 12px;
			background: linear-gradient(360deg, rgba(38, 36, 43, 0) 0%, $bl-grayscale-900 100%);
			z-index: $z-index-color-picker + 1;
		}
	}

	.color-pointer {
		height: 24px;
		width: 24px;
		border-radius: $rem12px;
		border: $rem2px solid $white;
		transform: translate(-12px, -12px);

		&.hue-pointer {
			transform: translate(-12px, -8px);
		}
	}
}