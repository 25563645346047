.layout-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    .layout-selector-wrapper {
        width: 100%;
        height: 0;
        padding-bottom: 56%;

        .layout-selector {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
        }
    }
}

.selected-thumbnail-queue-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    z-index: 1;
    padding: 8px 0 32px; // bottom padding to account for gradient on volume controls

    .selected-thumbnail-row-container {
        border-top: 1px solid rgba(135, 135, 147, 0.3);
        background-color: $element-background-gray;
        width: 100%;
        padding: 0 24px;
    }

    .selected-thumbnail-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .start-live-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        height: 46px;
        border-radius: 23px;
        margin-left: 40px;
        margin-right: 40px;

        cursor: default;
        background-color: $gray;
        color: $light-gray;

        &.active {
            @include button-greenroom-shadow;
            background-color: $red;
            cursor: pointer;
            color: $near-white;
        }

        > span {
            @include font-poppins-14-500;
            line-height: 16px;
            margin-left: 11px;
            white-space: nowrap;
        }
    }

    .selected-thumbnail-buttons {
        display: flex;

        .button-icon {
            display: flex;
        }

        .thumbnail-row-circle-btn {
            @include button-greenroom-circle;
            height: 46px;
            width: 46px;
            border-radius: 23px;
            background: $border-gray;
            margin-right: 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            &.muted {
                background: $red;
            }

            &.backstage-chat {
                &:hover {
                    background: $element-background-subtle-text;
                }

                &.active {
                    background: $element-border-gray;
                    border: $rem1px solid $pale-green;
                }
            }
        }

        .remove-row-btn {
            display: flex;
            align-items: center;

            background: transparent;
            border: transparent;
            padding: 0px;
            margin-left: 5px;

            font-family: SF Pro Display;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: $default-gray;
        }
    }

    .change-shot {
        margin: 0 24px;
    }
}

.thumbnail-row-left-side {
    display: flex;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .row-number-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .row-number-icon {
            margin-left: 7px;
            margin-right: 20px;
        }

        > span {
            @include font-poppins-14;
            line-height: 20px;
            color: $light-gray;
        }
    }

    .display-name-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .display-name {
            @include font-poppins-12-500;
            line-height: 20px;
            color: $near-white;
            margin: 0px 16px 0px 0px;
            padding-right: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        .internet-speed-selected-thumbnail {
            display: flex;
            align-items: center;

            > span {
                color: $light-gray;
                margin-left: 4px;

                @include font-poppins-12-500;
                line-height: 20px;
            }

            .media-page-count {
                margin-left: 0;
                line-height: 16px;
            }
        }
    }
}
