.loading-right-panel {
	display: 'flex';
	align-items: 'center';
	justify-content: 'flex-end';
	padding-right: '50%';
	background-color: '#151516';
}

.loading-app {
	display: 'flex';
	align-items: 'center';
	justify-content: 'flex-end';
	padding-right: '15%';
	background-color: '#151516';
}

.loading-fullscreen {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: $z-index-fullscreen-load;

	video {
		min-width: 100%; 
		min-height: 100%; 
		width: auto;
		height: auto;
		object-fit: cover;
	}
}