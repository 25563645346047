.overlay-drop-area {
	@include absolute-fill;
	pointer-events: auto;
	z-index: 33;
}

.green-box {
	background-color: rgba(166, 255, 121, 0.2);
	border: 1px dashed #A6FF79;
	// border-radius: 5px;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 9000;
}

.custom-graphics-canvas {
	@include absolute-fill;
	z-index: $z-index-custom-graphics;
	overflow: hidden;
	pointer-events: none;
	outline: none;

	.graphic-wrapper {
		position: absolute;
		display: flex;
		justify-content: center;
		align-content: center;
		pointer-events: auto;
		user-select: none;
		visibility: visible;

		&.pointer-events-none {
			pointer-events: none;
		}

		.custom-graphics-child {
			width: 100%;
			height: 100%;

			.media-video-container {
				width: 100%;
				height: 100%;
			}

			img, video {
				position: absolute;
				width: inherit;
				height: inherit;
				max-width: 100%;
				max-height: 100%;
				object-fit: fill;
				pointer-events: none;
			}
		}

		@keyframes dissolve {
			from { opacity: 0; }
			to { opacity: 1; }
		}

		@keyframes dissolve-out {
			from { opacity: 1; }
			to { opacity: 0; }
		}

		@keyframes move-in-left-1 {
			from {
				opacity: 1;
				transform: translateX(100vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-left-2 {
			from {
				opacity: 1;
				transform: translateX(75vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-left-3 {
			from {
				opacity: 1;
				transform: translateX(50vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-left-4 {
			from {
				opacity: 1;
				transform: translateX(25vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-left-out-1 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(-25vw);
				opacity: 1;
			}

			100% {
				transform: translateX(-25vw);
				opacity: 0;
			}
		}

		@keyframes move-in-left-out-2 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(-50vw);
				opacity: 1;
			}

			100% {
				transform: translateX(-50vw);
				opacity: 0;
			}
		}

		@keyframes move-in-left-out-3 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(-75vw);
				opacity: 1;
			}

			100% {
				transform: translateX(-75vw);
				opacity: 0;
			}
		}

		@keyframes move-in-left-out-4 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(-100vw);
				opacity: 1;
			}

			100% {
				transform: translateX(-100vw);
				opacity: 0;
			}
		}

		@keyframes move-in-right-1 {
			from {
				opacity: 1;
				transform: translateX(-25vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-right-2 {
			from {
				opacity: 1;
				transform: translateX(-50vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-right-3 {
			from {
				opacity: 1;
				transform: translateX(-75vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-right-4 {
			from {
				opacity: 1;
				transform: translateX(-100vw);
			}

			to {
				opacity: 1;
				transform: translateX(0);
			}
		}

		@keyframes move-in-right-out-1 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(100vw);
				opacity: 1;
			}

			100% {
				transform: translateX(100vw);
				opacity: 0;
			}
		}

		@keyframes move-in-right-out-2 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(75vw);
				opacity: 1;
			}

			100% {
				transform: translateX(75vw);
				opacity: 0;
			}
		}

		@keyframes move-in-right-out-3 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(50vw);
				opacity: 1;
			}

			100% {
				transform: translateX(50vw);
				opacity: 0;
			}
		}

		@keyframes move-in-right-out-4 {
			0% {
				transform: translateX(0);
				opacity: 1;
			}

			99% {
				transform: translateX(25vw);
				opacity: 1;
			}

			100% {
				transform: translateX(25vw);
				opacity: 0;
			}
		}

		@keyframes move-in-up-1 {
			from {
				opacity: 1;
				transform: translateY(100vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-up-2 {
			from {
				opacity: 1;
				transform: translateY(75vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-up-3 {
			from {
				opacity: 1;
				transform: translateY(50vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-up-4 {
			from {
				opacity: 1;
				transform: translateY(25vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-up-out-1 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(-25vh);
			}

			100% {
				opacity: 0;
				transform: translateY(-25vh);
			}
		}

		@keyframes move-in-up-out-2 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(-50vh);
			}

			100% {
				opacity: 0;
				transform: translateY(-50vh);
			}
		}

		@keyframes move-in-up-out-3 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(-75vh);
			}

			100% {
				opacity: 0;
				transform: translateY(-75vh);
			}
		}

		@keyframes move-in-up-out-4 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(-100vh);
			}

			100% {
				opacity: 0;
				transform: translateY(-100vh);
			}
		}

		@keyframes move-in-down-1 {
			from {
				opacity: 1;
				transform: translateY(-25vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-down-2 {
			from {
				opacity: 1;
				transform: translateY(-50vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-down-3 {
			from {
				opacity: 1;
				transform: translateY(-75vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-down-4 {
			from {
				opacity: 1;
				transform: translateY(-100vh);
			}

			to {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes move-in-down-out-1 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(100vh);
			}

			100% {
				opacity: 0;
				transform: translateY(100vh);
			}
		}

		@keyframes move-in-down-out-2 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(75vh);
			}

			100% {
				opacity: 0;
				transform: translateY(75vh);
			}
		}

		@keyframes move-in-down-out-3 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(50vh);
			}

			100% {
				opacity: 0;
				transform: translateY(50vh);
			}
		}

		@keyframes move-in-down-out-4 {
			0% {
				opacity: 1;
				transform: translateY(0);
			}

			99% {
				opacity: 1;
				transform: translateY(25vh);
			}

			100% {
				opacity: 0;
				transform: translateY(25vh);
			}
		}

		&.dissolve-250 {
			animation: dissolve 250ms forwards;
			animation-timing-function: $cb-animation-in;
		}

		&.dissolve-500 {
			animation: dissolve 500ms forwards;
			animation-timing-function: $cb-animation-in;
		}

		&.dissolve, &.dissolve-1000 {
			animation: dissolve 1000ms forwards;
			animation-timing-function: $cb-animation-in;
		}

		&.dissolve-5000 {
			animation: dissolve 5000ms forwards;
			animation-timing-function: $cb-animation-in;
		}

		&.dissolve-10000 {
			animation: dissolve 10000ms forwards;
			animation-timing-function: $cb-animation-in;
		}

		&.dissolve-out-250 {
			animation: dissolve-out 250ms forwards;
			animation-timing-function: $cb-animation-out

		}

		&.dissolve-out-500 {
			animation: dissolve-out 500ms forwards;
			animation-timing-function: $cb-animation-out

		}

		&.dissolve-out, &.dissolve-out-1000 {
			animation: dissolve-out 1000ms forwards;
			animation-timing-function: $cb-animation-out

		}

		&.dissolve-out-5000 {
			animation: dissolve-out 5000ms forwards;
			animation-timing-function: $cb-animation-out

		}

		&.dissolve-out-10000 {
			animation: dissolve-out 10000ms forwards;
			animation-timing-function: $cb-animation-out

		}

		&.move-in-up-1-250 {
			animation: move-in-up-1 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-2-250 {
			animation: move-in-up-2 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-3-250 {
			animation: move-in-up-3 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-4-250 {
			animation: move-in-up-4 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-1-500 {
			animation: move-in-up-1 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-2-500 {
			animation: move-in-up-2 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-3-500 {
			animation: move-in-up-3 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-4-500 {
			animation: move-in-up-4 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-1, &.move-in-up-1-1000 {
			animation: move-in-up-1 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-2, &.move-in-up-2-1000 {
			animation: move-in-up-2 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-3, &.move-in-up-3-1000 {
			animation: move-in-up-3 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-4, &.move-in-up-4-1000 {
			animation: move-in-up-4 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-1-5000 {
			animation: move-in-up-1 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-2-5000 {
			animation: move-in-up-2 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-3-5000 {
			animation: move-in-up-3 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-4-5000 {
			animation: move-in-up-4 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-1-10000 {
			animation: move-in-up-1 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-2-10000 {
			animation: move-in-up-2 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-3-10000 {
			animation: move-in-up-3 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-4-10000 {
			animation: move-in-up-4 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-up-out-1-250 {
			animation: move-in-up-out-1 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-2-250 {
			animation: move-in-up-out-2 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-3-250 {
			animation: move-in-up-out-3 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-4-250 {
			animation: move-in-up-out-4 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-1-500 {
			animation: move-in-up-out-1 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-2-500 {
			animation: move-in-up-out-2 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-3-500 {
			animation: move-in-up-out-3 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-4-500 {
			animation: move-in-up-out-4 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-1, &.move-in-up-out-1-1000 {
			animation: move-in-up-out-1 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-2, &.move-in-up-out-2-1000 {
			animation: move-in-up-out-2 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-3, &.move-in-up-out-3-1000 {
			animation: move-in-up-out-3 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-4, &.move-in-up-out-4-1000 {
			animation: move-in-up-out-4 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-1-5000 {
			animation: move-in-up-out-1 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-2-5000 {
			animation: move-in-up-out-2 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-3-5000 {
			animation: move-in-up-out-3 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-4-5000 {
			animation: move-in-up-out-4 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-1-10000 {
			animation: move-in-up-out-1 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-2-10000 {
			animation: move-in-up-out-2 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-3-10000 {
			animation: move-in-up-out-3 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-up-out-4-10000 {
			animation: move-in-up-out-4 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-1-250 {
			animation: move-in-down-1 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-2-250 {
			animation: move-in-down-2 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-3-250 {
			animation: move-in-down-3 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-4-250 {
			animation: move-in-down-4 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-1-500 {
			animation: move-in-down-1 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-2-500 {
			animation: move-in-down-2 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-3-500 {
			animation: move-in-down-3 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-4-500 {
			animation: move-in-down-4 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-1, &.move-in-down-1-1000 {
			animation: move-in-down-1 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-2, &.move-in-down-2-1000 {
			animation: move-in-down-2 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-3, &.move-in-down-3-1000 {
			animation: move-in-down-3 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-4, &.move-in-down-4-1000 {
			animation: move-in-down-4 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-1-5000 {
			animation: move-in-down-1 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-2-5000 {
			animation: move-in-down-2 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-3-5000 {
			animation: move-in-down-3 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-4-5000 {
			animation: move-in-down-4 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-1-10000 {
			animation: move-in-down-1 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-2-10000 {
			animation: move-in-down-2 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-3-10000 {
			animation: move-in-down-3 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-4-10000 {
			animation: move-in-down-4 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-down-out-1-250 {
			animation: move-in-down-out-1 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-2-250 {
			animation: move-in-down-out-2 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-3-250 {
			animation: move-in-down-out-3 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-4-250 {
			animation: move-in-down-out-4 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}


		&.move-in-down-out-1-500 {
			animation: move-in-down-out-1 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-2-500 {
			animation: move-in-down-out-2 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-3-500 {
			animation: move-in-down-out-3 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-1, &.move-in-down-out-1-1000 {
			animation: move-in-down-out-1 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-2, &.move-in-down-out-2-1000 {
			animation: move-in-down-out-2 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-3, &.move-in-down-out-3-1000 {
			animation: move-in-down-out-3 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-4, &.move-in-down-out-4-1000 {
			animation: move-in-down-out-4 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-1-5000 {
			animation: move-in-down-out-1 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-2-5000 {
			animation: move-in-down-out-2 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-3-5000 {
			animation: move-in-down-out-3 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-4-5000 {
			animation: move-in-down-out-4 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-1-10000 {
			animation: move-in-down-out-1 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-2-10000 {
			animation: move-in-down-out-2 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-3-10000 {
			animation: move-in-down-out-3 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-down-out-4-10000 {
			animation: move-in-down-out-4 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-1-250 {
			animation: move-in-left-1 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-2-250 {
			animation: move-in-left-2 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-3-250 {
			animation: move-in-left-3 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-4-250 {
			animation: move-in-left-4 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-1-500 {
			animation: move-in-left-1 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-2-500 {
			animation: move-in-left-2 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-3-500 {
			animation: move-in-left-3 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-4-500 {
			animation: move-in-left-4 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-1, &.move-in-left-1-1000 {
			animation: move-in-left-1 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-2, &.move-in-left-2-1000 {
			animation: move-in-left-2 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-3, &.move-in-left-3-1000 {
			animation: move-in-left-3 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-4, &.move-in-left-4-1000 {
			animation: move-in-left-4 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-1-5000 {
			animation: move-in-left-1 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-2-5000 {
			animation: move-in-left-2 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-3-5000 {
			animation: move-in-left-3 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-4-5000 {
			animation: move-in-left-4 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-1-10000 {
			animation: move-in-left-1 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-2-10000 {
			animation: move-in-left-2 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-3-10000 {
			animation: move-in-left-3 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-4-10000 {
			animation: move-in-left-4 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-left-out-1-250 {
			animation: move-in-left-out-1 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-2-250 {
			animation: move-in-left-out-2 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-3-250 {
			animation: move-in-left-out-3 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-4-250 {
			animation: move-in-left-out-4 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-1-500 {
			animation: move-in-left-out-1 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-2-500 {
			animation: move-in-left-out-2 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-3-500 {
			animation: move-in-left-out-3 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-4-500 {
			animation: move-in-left-out-4 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-1, &.move-in-left-out-1-1000 {
			animation: move-in-left-out-1 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-2, &.move-in-left-out-2-1000 {
			animation: move-in-left-out-2 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-3, &.move-in-left-out-3-1000 {
			animation: move-in-left-out-3 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-4, &.move-in-left-out-4-1000 {
			animation: move-in-left-out-4 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-1-5000 {
			animation: move-in-left-out-1 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-2-5000 {
			animation: move-in-left-out-2 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-3-5000 {
			animation: move-in-left-out-3 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-4-5000 {
			animation: move-in-left-out-4 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-1-10000 {
			animation: move-in-left-out-1 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-2-10000 {
			animation: move-in-left-out-2 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-3-10000 {
			animation: move-in-left-out-3 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-left-out-4-10000 {
			animation: move-in-left-out-4 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-1-250 {
			animation: move-in-right-1 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-2-250 {
			animation: move-in-right-2 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-3-250 {
			animation: move-in-right-3 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-4-250 {
			animation: move-in-right-4 250ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-1-500 {
			animation: move-in-right-1 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-2-500 {
			animation: move-in-right-2 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-3-500 {
			animation: move-in-right-3 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-4-500 {
			animation: move-in-right-4 500ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-1, &.move-in-right-1-1000 {
			animation: move-in-right-1 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-2, &.move-in-right-2-1000 {
			animation: move-in-right-2 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-3, &.move-in-right-3-1000 {
			animation: move-in-right-3 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-4, &.move-in-right-4-1000 {
			animation: move-in-right-4 1000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-1-5000 {
			animation: move-in-right-1 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-2-5000 {
			animation: move-in-right-2 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-3-5000 {
			animation: move-in-right-3 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-4-5000 {
			animation: move-in-right-4 5000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-1-10000 {
			animation: move-in-right-1 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-2-10000 {
			animation: move-in-right-2 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-3-10000 {
			animation: move-in-right-3 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-4-10000 {
			animation: move-in-right-4 10000ms forwards;
			animation-timing-function: $cb-animation-in;

		}

		&.move-in-right-out-1-250 {
			animation: move-in-right-out-1 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-2-250 {
			animation: move-in-right-out-2 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-3-250 {
			animation: move-in-right-out-3 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-4-250 {
			animation: move-in-right-out-4 250ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-1-500 {
			animation: move-in-right-out-1 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-2-500 {
			animation: move-in-right-out-2 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-3-500 {
			animation: move-in-right-out-3 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-4-500 {
			animation: move-in-right-out-4 500ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-1, &.move-in-right-out-1-1000 {
			animation: move-in-right-out-1 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-2, &.move-in-right-out-2-1000 {
			animation: move-in-right-out-2 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-3, &.move-in-right-out-3-1000 {
			animation: move-in-right-out-3 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-4, &.move-in-right-out-4-1000 {
			animation: move-in-right-out-4 1000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-1-5000 {
			animation: move-in-right-out-1 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-2-5000 {
			animation: move-in-right-out-2 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-3-5000 {
			animation: move-in-right-out-3 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-4-5000 {
			animation: move-in-right-out-4 5000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-1-10000 {
			animation: move-in-right-out-1 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-2-10000 {
			animation: move-in-right-out-2 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-3-10000 {
			animation: move-in-right-out-3 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.move-in-right-out-4-10000 {
			animation: move-in-right-out-4 10000ms forwards;
			animation-timing-function: $cb-animation-out;
		}

		&.hidden {
			visibility: hidden;
		}
	}

	.moveable-control{
		border: 3px solid $pale-green !important;
		background: white !important;
		pointer-events: auto;
	}

	.moveable-line{
		background: $pale-green !important;
		pointer-events: auto;
	}

	.moveable-custom-graphic-buttons {
		position: absolute;
		display: flex;
		transform: translate(-50%, 0px);
		pointer-events: auto;

		.graphic-button {
			width: 32px;
			height: 32px;
			padding: 10px;
			border-radius: 100px;
			@include button-greenroom-shadow;
		}
	}

	&.can-edit-false {
		.moveable-line{
			background: none !important;
		}
	}
}

.graphic-menu-container {
	position: fixed;
	z-index: $z-index-custom-graphics-menu;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.graphic-menu {
		position: absolute;
		display: flex;
		flex-direction: column;
		width: 164px;
		border-radius: $rem8px;
		background-color: #212026;
		box-shadow: $shadow-popover;
		pointer-events: auto;
		bottom: 0;
		font-size: 12px;

		&.sub-menu {
			position: absolute;
			bottom: -40px;
			left: 155px;
			width: 164px;
		}

		.graphic-menu-option {
			padding: 12px 16px;
			cursor: pointer;
			color: $near-white;
			font-weight: 500;

			&.only-item{
	border-radius: $rem8px;
			}

			&.multiple-items{
	&:first-of-type {
		border-radius: $rem8px $rem8px 0 0;
	}

	&:last-of-type {
		border-radius: 0 0 $rem8px $rem8px;
	}
			}

			&:hover {
	background-color: #4F4C57;
	color: darken($near-white, 10%);
			}

			&:active {
	background-color: darken(#4F4C57, 10%);
	color: darken($near-white, 10%);
			}
		}

		.graphic-menu-row {
			align-items: center;
			display: flex;
			justify-content: left;

			.graphic-menu-icon {
	display: flex;
	align-items: center;
	margin-right: $rem8px;
			}

			.open-sub {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
			}

			.graphic-menu-checkbox {
				border: 1px solid $text-light-gray;
				border-radius: 2px;
				height: 12px;
				width: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 150ms border;
				margin-right: $rem8px;

				&.selected {
					border: 1px solid $near-white;

					svg {
						opacity: 1;
					}
				}

				svg {
					font-size: 8px;
					position: relative;
					left: 0px;
					opacity: 0;
					transition: 150ms opacity;
				}
			}
		}

		.graphic-menu-option-disabled {
			padding: 8px 16px;
			cursor: pointer;
			color: $border-gray !important;
		}
	}
}