.producer-label {
	height: 24px;
	background-color: rgba(23, 23, 23, 0.7);
	position: absolute;
	left: 6px;
	top: 6px;
	border-radius: $rem12px;
	display: flex;
	justify-content: center;
	align-items: center;

	> span {
		@include font-poppins-12-500;
		line-height: 14px;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.thumbnail-gradient {
	position: absolute;
	z-index: 88;
	bottom: 0px;
	height: 42px;
	width: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(1, 1, 1, 0.0001) 0%, #000000 80.16%);
	mix-blend-mode: normal;
	opacity: 0.85;
}

.selected-outline {
	position: absolute !important; 
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	mix-blend-mode: normal !important; 
	border: $rem2px solid $red !important;
	box-sizing: border-box !important; 
	border-radius: $rem16px !important;
	z-index: 101;
}

.live-opacity {
	position: absolute !important; 
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.5;
	border-radius: $rem16px;
}

.live-time-red {
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
	// margin-top: 10px;
	// margin-left: 10px;
	z-index: $z-index-video-overlay;
}

.live-time-red-left-half {
	display: flex;
    align-items: center;
    background-color: rgba(255, 68, 58, .4);
    height: 20px;
    width: 47px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	.red-dot {
		height: 6px;
		width: 6px;
		border-radius: $rem3px;
		background-color: rgb(255, 68, 58);
		margin-left: 6px;
	}
	
	> span {
		@include font-poppins-12-500;
		line-height: 14px;
		margin-left: 4px;
		color: $near-white;
	}
}

.live-time-red-dot-full {
	display: flex;
    align-items: center;
    background-color: rgba(255, 68, 58, .4);
    height: 20px;
	border-radius: 0.714rem;
	padding: 7px ;

	.red-dot {
		height: 6px;
		width: 6px;
		border-radius: $rem3px;
		background-color: rgb(255, 68, 58);
	}
	
	> span {
		@include font-poppins-12-500;
		line-height: 14px;
		margin-left: 4px;
		margin-right: 4px;
		color: $near-white;
	}
}

.live-time-red-right-half {
	display: flex;
    align-items: center;
    background-color: rgb(255, 68, 58);
    height: 20px;
	padding-right: 8px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;

	
	@include font-poppins-12-500;
	line-height: 14px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* White_Dark Mode */

	color: #FCFCFC;

	> span {
		margin-left: 4px;
		color: $near-white;
	}
}

.opaque-full{
	position: absolute;
	left: 0%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	z-index: 100;
}

.opaque-left-quarter {
	position: absolute;
	left: 0%;
	right: 75%;
	top: 0%;
	bottom: 0%;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	z-index: 100;
}

.opaque-right-quarter {
	position: absolute;
	left: 75%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	z-index: 100;
}

.opaque-left-third {
	position: absolute;
	left: 0%;
	right: 66.67%;
	top: 0%;
	bottom: 0%;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	z-index: 100;
}

.opaque-right-third {
	position: absolute;
	left: 66.67%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	background: #000000;
	mix-blend-mode: normal;
	opacity: 0.6;
	z-index: 100;
}

.producer-thumbnail-large{
	grid-row-start: 1;
	grid-column-start: 1;
	grid-column-end: 3;
}

.producer-thumbnail-last{
	grid-row-start: 1;
	grid-column-start: 1;
	grid-column-end: 3;


}