.pixel-input {
    padding: 8px 16px !important;
    background: transparent;
    border: 1px solid $bl-grayscale-400;
    border-radius: $rem100px;
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    // min-width: 97px;
    flex: inherit !important;
    cursor: pointer;
    transition: 150ms background-color;
	white-space: nowrap;
	text-align: center;

	input {
		background: none;
		border: none;
		font-weight: normal !important;
		font-size: 14px !important;
		line-height: 16px !important;
        color: $bl-grayscale-200;
		min-width: 10px;
		margin: 0 !important;
		padding: 0 !important;

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	&:hover {
        border-color: $bl-grayscale-200;
        color: $bl-grayscale-400;
	}

	&:focus-within {
        color: $bl-grayscale-200;
	}
}