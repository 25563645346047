.select-users {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.select-users-label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 20px 7px 11px;
		background: $bl-grayscale-1000;
		border: 1px solid $bl-grayscale-400;
		border-radius: 100px;
		cursor: pointer;

		transition: color 0.1s;
		color: $bl-grayscale-100;
		&:hover {color: $bl-grayscale-200;}
		&:active {color: $bl-grayscale-300;}

		.selected-users {
			display: flex;
			height: 32px;
			flex-wrap: wrap;
			overflow: hidden;

			.all-placeholder,
			.selected-user-chip {
				padding: 0 8px 0 0;
				align-items: center;
				display: flex;
				gap: 8px;
			}
			
			.selected-user-chip {
				border-radius: 100px;
				border: 2px solid $bl-grayscale-700;
				margin-right: 8px;

				svg {
					margin-top: .75px;
				}
			}
		}

		.open-users {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
			cursor: pointer;

			transition: transform 0.1s ease-in-out;
			&.is-open {
				transform: rotate(180deg);
			}
			svg {
				margin-left: 0;
			}
		}
	}

	.select-users-body {
		@include shadow-popover();
		position: absolute;
		max-height: 0;
		width: 100%;
		opacity: 0;
		background-color: $bl-grayscale-800;
		border-radius: 12px;
		z-index: 2;
		transition: opacity 300ms, max-height 150ms;
		overflow-y: auto;
		pointer-events: none;

		&.drop-left {
			right: -5px;
		}

		&.drop-right {
			left: -5px;
		}

		&::-webkit-scrollbar {
			width: 0;
		}
		
		.select-users-option {
			cursor: pointer;
			display: flex;
			gap: 8px;
			align-items: center;
			padding: 4px 16px;
			max-width: 100%;
			margin: 0;


			label,
			span {
				color: $bl-grayscale-100;
				display: flex;
				align-items: center;
				height: 32px;
				
				&.first-label {
					flex: 1;
				}
				&.second-label {
					justify-self: flex-end;
				}
			}
			

			.checkbox-outer {
				display: flex;
				flex: 1;
				justify-content: center;
				align-items: center;
				border: 1px solid $border-gray;
				border-radius: 1px;
				justify-self: flex-end;
				max-width: 16px;

				svg {
					top: 0;
					left: 0;
				}
			}
			
			&.suggested-label {
				cursor: default;
				pointer-events: none;
				max-height: 32px;
				label {
					color: $bl-grayscale-400;
				}
			}
			
			&:hover:not(.suggested-label) {
				background-color: rgba($near-white, 0.04);
			}

			&:active:not(.suggested-label) {
				background-color: rgba($near-white, 0.02);
			}

			&.selected {
				background-color: rgba($near-white, 0.04);

				.checkbox-outer {
					background-color: $near-white;
					border: 2px solid $near-white;
				}

				svg {
					opacity: 1;
				}
			}

			&.selected,
			&:active:not(.suggested-label),
			&:hover:not(.suggested-label) {
				&:first-of-type {
					border-radius: 12px 12px 0 0;
				}

				&:last-of-type {
					border-radius: 0 0 12px 12px;
				}
			}
		}

		&.open {
			max-height: 320px;
			opacity: 1;
			pointer-events: initial;
		}
	}

	&.single {
		// styling for the view where only 1 user can be selected
		width: fit-content;
		max-width: calc($global-drawer-width - 48px); //drawer padding

		.select-users-label {
			background: none;
			padding: 0 12px 0 0;
			border: 2px solid $bl-grayscale-700;
			transition: border .1s ease-in;

			.selected-users {
				height: auto;
				min-height: 28px;
			}

			.selected-user-chip {
				margin-right: 0;
				border: none;
				white-space: pre;
				overflow: hidden;
				text-overflow: ellipsis;

				&.single-placeholder {
					display: flex;
					flex-direction: row;
					span {
						transition: color .1s ease-in;
						padding: 0 8px;
						color: $bl-grayscale-400;
					}
					
				}
			}
		}
		&:hover {
			.select-users-label {
				border: 2px solid $bl-grayscale-400;
			}
		}
		&:active,
		&.open {
			.select-users-label {
				border: 2px solid $bl-grayscale-400;
				.selected-user-chip.single-placeholder > span {
					color: $bl-grayscale-500;
				}
			}
		}
		
		.select-users-body {
			width: calc($global-drawer-width - 48px); //drawer padding
			max-width: calc($global-drawer-width - 48px);
		}	
		
		.question-mark {
			background-color: rgba(255, 255, 255, 0.05);
			width: 28px;
			height: 28px;
			border-radius: 99px;
			
			&.sm {
				width: 24px;
				height: 24px;
			}
		}
	}
}
