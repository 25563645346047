.user-menu {
	height: auto;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 24px 8px 24px 24px;
	scrollbar-gutter: stable;
	flex: 1;

	.header {
		display: flex;
		justify-content: flex-end;
		padding: 32px 0 16px;
	}

	.notes-wrapper {
		.notes-container {
			padding: 0;
		}
	}

	.user-account-actions {
		border-radius: $radius-large;
		background-color: $dark-gray;
		overflow: hidden;
		margin-bottom: 33px;

		.user-account-info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;

			.avatar-container {
				width: 54px;
				height: 54px;
			}

			.username {
				flex: 1;
				padding: 0 16px;

				h4 {
					@include font-poppins-16-500;
					color: $near-white;
					line-height: 28px;
					margin: 0;
				}

				span {
					font-weight: normal;
					font-size: 14px;
					color: $text-gray;
				}
			}

			.role {
				color: $text-gray;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				font-size: 12px;
				margin-right: 15px;
				background-color: $darker-gray;
				border-radius: 12px;
				padding: 5px 9px;
			}
		}

		.user-account-action-buttons {
			display: flex;
			margin: 0;
			background-color: $dark-gray;

			button {
				@include button-greenroom-basic;
				background-color: $border-dark-gray;
				margin: 0;
				padding: 14px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $text-light;
				transition: background-color 150ms;

				svg {
					margin-right: 8px;
				}
			}
		}
	}
}

section.settings-section {
	p {
		display: flex;
		align-items: center;

		color: $text-gray;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 38px;

		svg {
			margin-bottom: 1px;
		}

		span {
			margin-left: 8px;
		}
	}
}

.dialog {
	@include absolute-fill;
	display: none;
	background-color: $darker-gray;
	align-items: center;
	flex-direction: column;

	&.visible {
		display: flex;
	}

	.dialog-content {
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		padding: 24px;
		text-align: center;
		background-color: $dark-gray;
		margin-top: 93px;

		h5 {
			font-size: 18px;
			line-height: 21px;
			color: $red;
			font-weight: bold;
		}

		p {
			margin: 0;
			line-height: 20px;
		}

		.dialog-actions {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 24px;

			button {
				background-color: $red;
				border-radius: 100px;
				color: $near-white;
				margin: 0 8px;

				&.dark {
					background-color: $border-dark-gray;					
				}
			}
		}
	}
}

.bg-image-label {
	font-weight: bold;
	text-transform: uppercase;
	color: $text-gray;
	margin-bottom: 8px;
	display: block;
}

.bg-image-picker {
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	background-color: $color-black;
	width: 100%;
	aspect-ratio: 16/9;

	.no-image-placeholder {
		@include flex-center;
		height: 200px;
		font-size: 48px;
		font-weight: bold;
		background-color: $color-black;

		span {
			height: 100px;
			width: 100px;
			border-radius: 60px;
			@include flex-center;
		}
	}

	img {
		cursor: pointer;
		object-fit: cover;
		min-width: 100%;
		object-fit: cover;
	}

	.pick-image {
		width: 100%;
		border-radius: 0;
		color: $text-light;
		background: $dark-gray;
		font-weight: 500;
		transition: 150ms color, 150ms background;

		&:hover {
			color: $near-white;
			background: $border-dark-gray;
		}
	}

	.uploading-bg-image {
		@include absolute-fill;
		@include flex-center;
		background-color: rgba($dark-gray, 0.5);
	}

	.safety-photo-info {
		display: flex;
		position: absolute;
		top: 20px;
		left: 16px;
		-webkit-filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.30));
		filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.30));
		cursor: pointer;

		label {
			font-size: 13px;
			font-weight: 600;
			line-height: 24px;
			margin-right: 4px;
			cursor: pointer;
		}
	}

	
}

// should become standard styles when cleaning up ff
.avatar-image-picker {
	position: absolute;
	width: 96px;
	height: 96px;
	bottom: -16px;
	right: calc(50% - 48px);
	background-color: black;
	border-radius: 999px;
	
	.avatar {
		cursor: pointer !important;
	}
}

.bg-image-picker, .avatar-image-picker {
	&:hover {
		.upload-button-profile {
			filter: brightness(105%);
			background-color: lighten(#4F4C57, 10%);
		}
	}
	
	&:active {
		.upload-button-profile {
			filter: brightness(95%);
		}
	}
}

.upload-button-profile {
	width: 32px;
	height: 32px;
	background-color: #4F4C57;
	box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.profile-fields {
	.text-input-wrapper input {
		border: 1px solid #908CA0;
		color: #E5E4EC;
		font-size: 12px;
		padding: 12px 16px;
		line-height: 12px;
		background-color: $bl-grayscale-900;
		transition: all 250ms;
		
		&:hover, &:focus {
			border-color: $white !important;
		}		
	}
}

.profile-delete-button {
	color: $bl-grayscale-500;
	font-size: 13px;
	font-weight: 600;
	margin: 16px auto 16px auto;
	padding: 8px 16px;
	cursor: pointer;
	width: fit-content;
	justify-content: center;
	display: flex;
	transition: color 250ms;

	&:hover {
		color: $bl-grayscale-400;

		path {
			fill: $bl-grayscale-400 !important;
		}
	}
	
	&:active {
		color: $bl-grayscale-600;

		path {
			fill: $bl-grayscale-600 !important;
		}
	}
}

.select-devices {
	top: 0;
	bottom: 0;
	.select-small {
		transition: all 350ms;
		border: 1px solid $bl-grayscale-400;

		label {
			font-size: 12px;
		}

		&.open,
		&:hover {
			border: 1px solid $white;

			.icon {
				path {
					fill: $white !important;
				}
			}
		}

		.select-label {
			font-size: 12px !important;
			padding: 12px 16px !important;
			background-color: transparent !important;
		}

		.select-body {
			clip-path: inset(0 0 0 0 round 16px);
			background-color: #312F37;
			margin-top: 46px;
		}
	}
}

.delete-account-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #BFBBCF;
	height: 56px;
	border-radius: 100px;
	margin-top: 16px;

	width: 100%;
	align-self: center;

	&:hover {
		background: #625E70;
		color: #FFFFFF;
	}

	&:active {
		background: #3C3A42;
		color: #BFBBCF;
	}
}