.date-time-picker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &.date-picker input {
        width: 144px;
    }

    &.time-picker input {
        width: 120px;
    }

    input {
        @include bl-input();

        height: 40px;
        margin: 0;
        padding: 12px 16px 12px 28px;
        gap: 4px;
        text-align: center;
    }

    .date-time-picker__label {
        color: $bl-grayscale-300;
        white-space: nowrap;
    }

    .date-time-picker__selector {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .date-time-picker__icon {
            position: absolute;
            display: flex;
            left: 16px;
            pointer-events: none;
            z-index: $z-index-media-container-virtuoso-scroller;
        }
    }
}
