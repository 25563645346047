.left-panel-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;

    &.embed {
        padding: 0;
        max-width: initial;
        top: 0;

        .left-panel-above-video {
            display: none;
        }

        .left-panel-below-video {
            display: none;
        }
    }
}

#video-wrapper {
    flex: 1;
    position: relative;
    margin: 32px 24px 0 24px;

    &.embed {
        margin: 0;
    }

    &.include-audio-only-feeds {
        margin-bottom: 40px;
    }

    &.sixteenbynine {
        aspect-ratio: 16 / 9;
    }
    #curtain {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-black;
        z-index: 1;
    }
    &.include-audio-only-feeds-edit {
        padding-bottom: 91px; // the padding top/bottom and height of video setup buttons

        .left-panel-below-video-setup {
            position: absolute;
            bottom: -56px; // leaves 24px above
            width: 100%;
            pointer-events: all; // Need to set pointer events since parent is set to none.
            z-index: $z-index-media-controls;
            overflow-x: clip;
        }
    }
}

.left-panel-above-video {
    display: flex;
    align-items: center;
    max-height: 12.2vh;
    min-height: 60px;
    height: 100%;
    padding: 12px 0;

    .shared-media-thumbnail-filler-div {
        width: 137px;
    }

    .shared-media-switch-view-thumbnail {
        position: relative;
        // right: 40px;
        width: 137px;
        max-width: calc(28vh - 137px);
        min-width: 58px;
        height: 77px;
        max-height: 100%;
        border-radius: $rem8px;
        border: $rem2px solid $red;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 16px;
        background-color: #151516;

        .media-thumbnail-img-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            border-radius: 8px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

        .fullscreen-icon {
            position: absolute;
            top: 3px;
            right: 3.5px;
        }

        .three-lines-triangle-icon {
            position: absolute;
            bottom: 3px;
            left: 3.5px;
        }
    }

    .stream-media-thumbnail {
        pointer-events: none;
        position: relative;
        width: 100px;
        max-width: calc(23vh - 100px);
        min-width: 61.5px;
        height: 56px;
        max-height: 100%;
        border-radius: 8px;
        border: 2px solid $red;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 16px;
        background-color: #151516;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 8px;
        }

        video {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border-radius: 8px;
        }
    }
}

.left-panel-below-video {
    margin: 8px 0 24px 0;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;

    .button-container {
        flex: 1;
        margin-top: 8px;
    }

    &.highlight {
        z-index: 2;
    }
}

.left-panel-below-video-setup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 8px 0 24px 0;

    /*
        Have you ever wanted to do media queries based on the size of a
        container instead of the viewport? Now you can! This is a relatively new
        feature supported by the browsers we use that avoids the mess of trying
        to watch element sizes / window resizing with JS.

        References:
        https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
        https://caniuse.com/css-container-queries
        https://github.com/microsoft/vscode/issues/170589
    */
    container-type: size;

    .lower-setup-button-container {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-column-gap: 8px;
        justify-items: center;
        align-items: center;
        margin-top: 8px;
        padding: 8px 8px 0 8px;
        width: 100%;

        &.hide-audio-only-button {
            grid-template-columns: auto;
            justify-content: center;
        }

        &.video-buttons {

            // See note above about container queries
            @container (max-width: 843px) {
                grid-template-columns: 1fr 1fr;

                #portal-unmute-snackbar-2 {
                    display: none;
                }
            }
        }

        &.in-modal {
            grid-template-columns: 1fr 1fr;
        }

        .audio-only-feeds-wrapper {
            position: relative;
            margin-right: auto;
        }

        .setup-button-container {
            margin-left: auto;
        }

        .setup-button-container {
            display: flex;
            align-items: center;
            flex-direction: row;

            .setup-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 32px;
                padding: 8px 16px;
                margin-right: 8px;
                background: #4f4c57;
                border-radius: 100px;
                pointer-events: all;

                &.layout {
                    @include button-greenroom-shadow;

                    &:active {
                        transform: scale(0.95);
                    }
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    white-space: nowrap;
                }

                svg {
                    margin-left: 9.5px;
                }

                .switch-wrapper {
                    margin-left: 8px;
                }

                &.duration {
                    justify-content: center;
                    background: none;
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;

                    input {
                        width: 78px;
                        height: 32px;
                        background: #1a1a1f;
                        border: 1px solid #54545a;
                        box-sizing: border-box;
                        border-radius: 100px;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.224px;
                        color: #ffffff;
                        text-align: center;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: pre;
                        padding: 8px 10px;

                        &:focus {
                            border: 2px solid $pale-green;
                            outline: none;
                        }
                    }
                }

                &.titles {
                    transition: padding 100ms, margin 100ms;
                    &.collapsed {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }

        .duration-label {
            width: 73px;
            margin-right: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: #ffffff;
        }
    }
}

.extra-droppable-area {
    background: $border-gray;
    height: 24px;
    width: 115px;
    margin: 16px 0 0 16px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding: 0 16px 0 0;
    border-radius: 99px;

    &.isOver {
        border: 1px dashed #a6ff79;
        border-left: none;
    }

    span {
        color: $light-gray;
    }

    .empty-user-profile {
        display: flex;
        align-items: center;
        gap: 3px;
    }

    .two-up-profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1 auto;
        gap: 4px;
        overflow: hidden;

        .two-up-profile__image {
            display: flex;
            align-items: center;
            height: 24px;
            width: 24px;

            img {
                height: 100%;
                width: auto;
                border-radius: 50%;
            }
        }
    }

    .empty-user-profile,
    .two-up-profile {
        .profile-name {
            font-size: 0.85rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &::after {
        content: "This feed location will only send audio through your broadcast";
        position: absolute;
        width: 162px;
        padding: 12px 16px;
        background: darken($border-dark-gray, 5%);
        color: $text-gray;
        border-radius: 12px;
        opacity: 0;
        transition: opacity 150ms, transform 150ms;
        font-size: 12px;
        margin-top: 20px;
        transform: translateX(0) translateY(-65px);
        pointer-events: none;
    }

    &:hover {
        &::after {
            opacity: 1;
            transform: translateX(0) translateY(-70px);
        }
    }
}

.live-stats {
    display: flex;
    margin-bottom: 37px;
}

.live-time {
    display: flex;
    align-items: center;
    background-color: rgb(42, 42, 42);
    height: 32px;
    width: 108px;
    border-radius: 16px;

    .red-dot {
        height: 6px;
        width: 6px;
        border-radius: 3px;
        background-color: rgb(255, 68, 58);
        margin-left: 10px;
    }

    >span {
        @include font-poppins-12-500;
        line-height: 14px;
        margin-left: 4px;
        color: $near-white;
    }
}

.num-watching {
    display: flex;
    align-items: center;
    background-color: rgb(42, 42, 42);
    height: 32px;
    border-radius: 16px;
    margin-left: 13px;
    padding: 10px;

    >span {
        @include font-poppins-12;
        line-height: 20px;
        margin-left: 5px;
        color: $near-white;
    }
}

.main-video-wrapper {
    position: relative;
    height: 0;
}

.main-video-container {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;

    .curtain-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        display: flex;
        justify-content:center;
        align-items: center;
    }

    &.highlight {
        z-index: 2;
    }

    .live-media-view {
        position: absolute;
        display: flex;
        top: 0px;
        background-color: black;
        width: 100%;
        height: 100%;

        .pages-container {
            width: 228px;
            height: inherit;
            overflow: hidden;

            .pages {
                height: 100%;
                width: 100%;
                scrollbar-gutter: stable;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-right: 17px;
                padding-top: 40px;
                padding-bottom: 40px;
                box-sizing: content-box;

                .page {
                    display: flex;
                    align-items: flex-end;
                    margin-right: 7px;
                    margin-top: 16px;

                    .number {
                        @include font-poppins-14;
                        line-height: 20px;
                        color: $light-gray;
                        margin-right: 8px;
                    }

                    .thumbnail {
                        position: relative;
                        cursor: pointer;
                        opacity: 0.9;
                        width: 172px;
                        height: 94px;
                        display: flex;
                        justify-content: center;
                        background-color: #151516;
                        border-radius: 8px;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            background-color: white;
                        }

                        .selected {
                            @include absolute-fill;
                            border: 2px solid $red;
                            border-radius: 8px;
                            opacity: 1;
                        }
                    }
                }

                .page:last-child {
                    padding-bottom: 88px;
                }
            }
        }

        .featured-page-container {
            margin-right: 24px;
            margin-top: 72px;
            margin-bottom: 72px;
            flex: 1;
            overflow: hidden;
            border-radius: 8px;
            background-color: #151516;
            position: relative;

            .featured-page {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                box-shadow: none;
                display: flex;
                justify-content: center;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }

                video {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    &.embed {
        animation: 1000ms keepAnimating infinite; //we need this to keep the framerate painting while nothing is live
        animation-direction: alternate;
        animation-timing-function: linear;
    }
}

.main-video-stream-media {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
        height: 100%;
    }

    &.embed {
        animation: 1000ms keepAnimating infinite; //we need this to keep the framerate painting while nothing is live
        animation-direction: alternate;
        animation-timing-function: linear;
    }
}

.rounded-border {
    border-radius: 16px;
}

.rounded-border-thumbnail {
    border-radius: 7px;
}

.red-border {
    border: 2px solid $red;
}

.going-live-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 1;
}

.opaque-third {
    flex: 1;
    background: rgba(0, 0, 0, 0.3);
    mix-blend-mode: normal;
}

.transparent-third {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.transparent-overlay {
    width: 50px;
    height: 50px;
}

.face-finder-container {
    margin: 40px;
    display: flex;
    flex: 1;
    justify-content: center;
}

.fit-borders-text-container {
    justify-content: center;
    align-items: flex-end;
    flex: 1;
    display: flex;
}

.fit-borders-text {
    @include font-poppins-14;
    line-height: 20px;
    text-align: center;
    color: #fcfcfc;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.embed-view-debug-log {
    @include absolute-fill;
    color: red;
    font-size: 24px;
    z-index: 999;

    span {
        top: 50px;
        left: 50px;
    }
}

.dnd-video-feed-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-dnd-canvas-highlight;
}

#controls-container-live,
#controls-container-editing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    overflow: hidden;
    z-index: $z-index-media-controls-container;
}

#controls-container-live {
    pointer-events: auto;
}

.main-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    overflow: hidden;
    transform: translateZ(0);
    will-change: contents;

    .media {
        @include flex-center;
        background-color: #151516;
        width: 100%;
        height: 100%;
        pointer-events: auto;

        .media-img,
        .media-video,
        .media-video-container {
            object-fit: contain;
        }
    }

    &.is-presenter {
        border-radius: $radius-medium;
        overflow: hidden;
        border: 2px solid $red;
    }

    .video-container-presenter,
    .blank-video-feed {
        overflow: hidden;
        position: relative;

        &:nth-child(1) {
            grid-area: first;
        }

        &:nth-child(2) {
            grid-area: second;
        }

        &:nth-child(3) {
            grid-area: third;
        }

        &:nth-child(4) {
            grid-area: fourth;
        }

        &:nth-child(5) {
            grid-area: fifth;
        }

        &:nth-child(6) {
            grid-area: sixth;
        }

        &:nth-child(7) {
            grid-area: seventh;
        }

        &:nth-child(8) {
            grid-area: eighth;
        }

        video {
            object-fit: cover;
            min-height: 100%;
            max-height: 100%;
            max-width: 100%;
            min-width: 100%;
        }

        .mirrored {
            transform: scaleX(-1);
        }

        .poster {
            display: none;
            background-color: $color-black;

            &.active {
                display: block;
            }

            @include absolute-fill;

            img {
                object-fit: cover;
                min-height: 101%;
                max-height: 101%;
                max-width: 101%;
                min-width: 101%;
            }

            .no-image-placeholder {
                @include absolute-fill;
                @include flex-center;
                font-size: 48px;
                font-weight: bold;
                background-color: #000;

                span {
                    height: 100px;
                    width: 100px;
                    border-radius: 60px;
                    @include flex-center;
                }
            }
        }

        .empty-shot {
            @include absolute-fill;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            overflow: hidden;
            position: relative;
            min-height: 101%;
            max-height: 101%;
            max-width: 101%;
            min-width: 101%;
            background-color: #26242b;
            pointer-events: auto;

            .empty-video {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 24px 24px;

                svg {
                    margin-bottom: 12px;
                    max-width: 32px;
                    max-height: 32px;
                    width: 100%;
                    height: auto;
                }

                span {
                    width: 100%;
                    color: #908ca0;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &.screenshare {
                    svg {
                        margin-bottom: 0;
                        max-width: 76px;
                        max-height: 76px;
                    }

                    span {
                        color: $white;
                    }
                }
            }
        }
    }

    .video-container {
        @include absolute-fill;

        .display-name {
            font-size: $rem16px;
            bottom: 10px;
            right: 15px;
            width: calc(100% - 30px);
            position: absolute;
        }
    }

    &.cover {
        video {
            object-fit: cover;
        }
    }

    &.double {
        grid-template-areas: "second";
        grid-template-columns: auto;
    }

    &.vertical {
        &.two {
            grid-template-areas: "first second";
            grid-template-columns: 1fr 1fr;

            &.contain {
                grid-template-areas: ". . first second . .";
                grid-template-columns: auto auto;
            }
        }

        &.three {
            grid-template-areas: "first second third";
            grid-template-columns: 1fr 1fr 1fr;
        }

        &.four {
            grid-template-areas: "first second third fourth";
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &.horizontal {
        &.two {
            grid-template-areas:
                "first"
                "second";
            grid-template-rows: 1fr 1fr;

            &.contain {
                grid-template-areas:
                    ". first first ."
                    ". second second .";
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;

                .video-container-presenter,
                .blank-video-feed {
                    // margin-left: auto;
                    // margin-right: auto;
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            &.spaced {
                grid-template-areas:
                    ". . . . . . ."
                    ". first first . second second . "
                    ". first first . second second . "
                    ". . . . . . .";
                grid-template-rows: 1fr 1fr 1fr 1fr;
                grid-template-columns: 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr;
            }

            &.spaced-alt {
                grid-template-areas:
                    ". . . . . ."
                    ". first first second second . "
                    ". first first second second . "
                    ". . . . . .";
                grid-template-rows: 0.3fr 1fr 1fr 0.3fr;
                grid-template-columns: 0.6fr 1fr 1fr 1fr 1fr 0.6fr;
            }
        }

        &.two-shifted {
            grid-template-areas:
                ". first first first . ."
                ". . second second second .";
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

            .first {
                min-height: 100%;
                max-height: 100%;
                min-width: 100%;
                max-width: 100%;
                margin: auto;

                video {
                    max-height: initial;
                }
            }

            .second {
                min-height: 100%;
                max-height: 100%;
                min-width: 100%;
                max-width: 100%;
                margin: auto;

                video {
                    max-height: initial;
                }
            }
        }

        &.three.spaced {
            grid-template-areas:
                ". . . . . . . . . ."
                ". first first . second second . third third . "
                ". . . . . . . . . .";
            grid-template-rows: 0.5fr 1fr 0.5fr;
            grid-template-columns: 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr;
        }
    }

    &.grid {
        &.four {
            grid-template-areas:
                "first second"
                "third fourth";
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
        }

        &.six {
            grid-template-areas:
                "first second third"
                "fourth fifth sixth";
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
        }

        &.eight {
            grid-template-areas:
                "first second third fourth"
                "fifth sixth seventh eighth";

            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &.spiral {
        &.four {
            grid-template-areas:
                "first first first first first . . . ."
                "first first first first first second second second second"
                "first first first first first second second second second"
                "third third third third fourth fourth fourth fourth fourth"
                "third third third third fourth fourth fourth fourth fourth"
                ". . . . fourth fourth fourth fourth fourth";

            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

            .video-container-presenter,
            .blank-video-feed {
                height: 100%;
                width: 100%;
            }

            .second {
                margin-top: auto;
                width: 100%;
            }

            .third {
                margin-bottom: auto;
                width: 100%;
            }
        }
    }

    &.media {
        &.two {
            &.four-by-three {
                grid-template-areas:
                    ". . . . ."
                    ". first . second ."
                    ". . . . .";

                grid-template-columns: 2fr 29fr 2fr 65fr 2fr;
                grid-template-rows: 3.75fr 48.75fr 3.75fr; //56.25
            }

            &.sixteen-by-nine {
                grid-template-areas:
                    ". . . . ."
                    ". first . second ."
                    ". . . . .";

                grid-template-columns: 2fr 27fr 2fr 75fr 2fr;
                grid-template-rows: 16.5fr 75fr 16.5fr;
            }
        }

        &.three {
            &.four-by-three {
                grid-template-areas:
                    ". . . . ."
                    ". first . third ."
                    ". . . third ."
                    ". second . third ."
                    ". . . . .";

                grid-template-columns: 2fr 29fr 2fr 65fr 2fr;
                grid-template-rows: 3.75fr 23.5fr 1.75fr 23.5fr 3.75fr; 
            }
        }

        &.four {
            &.four-by-three {
                grid-template-areas:
                    ". . . . ."
                    ". first . fourth ."
                    ". . . fourth ."
                    ". second . fourth ."
                    ". . . fourth ."
                    ". third . fourth ."
                    ". . . . .";

                grid-template-columns: 2fr 29fr 2fr 65fr 2fr;
                grid-template-rows: 3.75fr 15fr 1.875fr 15fr 1.875fr 15fr 3.75fr;
            }

            &.sixteen-by-nine {
                grid-template-areas:
                    ". . . . ."
                    ". first . fourth ."
                    ". . . fourth ."
                    ". second . fourth ."
                    ". . . fourth ."
                    ". third . fourth ."
                    ". . . . .";

                grid-template-columns: 2fr 18fr 2fr 75fr 2fr;
                grid-template-rows: 12fr 23fr 3fr 23fr 3fr 23fr 12fr;
            }
        }
    }

    &.stack {
        &.two {
            &.left {
                grid-template-areas:
                    "first second second"
                    "first second second";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.right {
                grid-template-areas:
                    "first second second"
                    "first second second";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;

                .first {
                    height: 33%;
                    width: 100%;
                    margin: auto;
                }

                .second {
                    height: 66%;
                    width: 100%;
                    margin: auto;
                }
            }
        }

        &.three {
            &.left {
                grid-template-areas:
                    "first second second"
                    "first third third";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.right {
                grid-template-areas:
                    "first first second"
                    "third third second";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
            }

            &.top {
                grid-template-areas:
                    ". first first ."
                    "second second third third";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        &.five {
            &.right {
                grid-template-areas:
                    "first second fifth"
                    "third fourth fifth";
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }

            &.top-left {
                grid-template-areas:
                    "first first second"
                    "third fourth fifth";
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }

            &.two-left {
                grid-template-areas:
                    "first first first third third"
                    "first first first third third"
                    "first first first fourth fourth"
                    "second second second fourth fourth"
                    "second second second fifth fifth"
                    "second second second fifth fifth";
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            &.four-top {
                grid-template-areas:
                    "second third fourth fifth"
                    ". first first .";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        &.six {
            &.right {
                grid-template-areas:
                    "first second third third"
                    "fourth fifth sixth sixth";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &.left {
                grid-template-areas:
                    "first first second third"
                    "fourth fourth fifth sixth";
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &.random {
                grid-template-areas:
                    "first second third third"
                    "first fourth fifth sixth";

                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }
}

@keyframes keepAnimating {
    from {
        transform: translateZ(0);
    }

    to {
        transform: translateZ(1px);
    }
}

.video-placeholder-black-overlay {
    background-color: black;
    //padding-top: 56.25%;
    position: absolute;
    //position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.7;
    z-index: 100;

    &.highlight {
        z-index: 2;
    }

    &.embed {
        animation: 1000ms keepAnimating infinite; //we need this to keep the framerate painting while nothing is live
        animation-direction: alternate;
        animation-timing-function: linear;
    }

    .without-producer-container {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(194, 194, 194, 0.6);

        .without-producer {
            width: 59.25%;
            height: 14.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            background-color: $dark-gray;

            span {
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 400;
                font-size: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.video-placeholder-inner-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-logo-container {
    position: "relative";
}

.video-logo {
    max-height: 60px;
}

.bottom-buttons {
    display: flex;
    flex: 1;
    justify-content: center;
}

.bottom-buttons:first-child>div {
    margin-right: auto;
}

.bottom-buttons:last-child>div {
    margin-left: auto;
}

.disable-live-btn {
    @include button-greenroom-shadow;
    display: flex;
    align-items: center;
    background: $red;
    height: 56px;
    border-radius: 28px;
    margin: 0;
    margin-left: 10px;

    .disable-live-icon {
        color: $near-white;
    }

    >span {
        @include font-poppins-14-500;
        line-height: 16px;
        margin-left: 11px;
        white-space: nowrap;
        color: $near-white;
    }
}

.mute-all-btn,
.backstage-chat-btn,
.prev-shot-btn,
.next-shot-btn {
    @include button-greenroom-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 100px;

    >span {
        @include font-poppins-14-500;
        line-height: 16px;
        white-space: pre;
        text-overflow: ellipsis;
    }
}

.mute-all-btn {
    background: #4f4c57;
    width: 127px;
    margin-right: 16px;

    &:hover {
        background: #625e70;
    }

    &.active {
        background: $red;
        width: 140px;
    }

    >span {
        margin-left: 10px;
        color: $near-white;
    }
}

.backstage-chat-btn {
    background: #4f4c57;
    width: 168px;
    margin-right: 16px;

    &:hover {
        background: #625e70;
    }

    &.active {
        border: 1px solid $pale-green;
    }

    >span {
        margin-left: 10px;
        color: $near-white;
    }
}

.bottom-circle-btn {
    @include button-greenroom-circle;
    display: flex;
    justify-content: center;
    height: 56px;
    width: 56px;
    border-radius: 28px;
    background: $border-gray;
    padding: 0;
    align-items: center;

    &.muted,
    &.sharing {
        background: $red;
        color: $near-white;
    }

    &.sharing {
        position: relative;

        .context {
            content: "You are presenting to everyone";
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 60px;
            border-radius: 100px;
            background-color: gray;
            width: 200px;
            bottom: 0;
            z-index: 1;
        }
    }
}

.presentation-controls {
    height: 41px;
    margin: 0 8px 4px 8px;
    pointer-events: auto;

    &.lifted {
        bottom: 60px;
        padding-bottom: 0;
    }

    .control {
        margin-bottom: 4px;

        &:disabled {
            opacity: 0.5;
        }

        &:hover {
            path {
                fill: $white !important;
            }
        }
    }
}

@media only screen and (max-height: 545px) {

    .stop-sharing,
    .presentation-controls {
        svg {
            visibility: hidden;
        }
    }

    .presentation-controls {
        .prev-btn {
            svg {
                visibility: visible;
            }
        }
    }
}

.margin-left-16 {
    margin-left: 16px;
}

@media only screen and (max-height: 660px) {
    .left-panel-above-video {
        .live-indicator {
            height: 5.5vh;
        }
    }

    .left-panel-below-video {
        justify-content: center;

        .stop-sharing {
            height: 8.5vh;
            width: 24vh;
        }
    }

    .bottom-buttons {
        .bottom-circle-btn {
            height: 8.5vh;
            width: 8.5vh;
        }

        .stop-sharing {
            height: 8.5vh;
            width: 24vh;
        }
    }

    .disable-live-btn {
        margin-left: 10px;
        height: 100%;
        width: 24vh;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
    }

    .margin-left-16 {
        margin-left: 2.2vh;
    }
}

.internet-speed-container {
    display: flex;
    height: 56px;
    align-items: center;
}

.internet-speed {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(42, 42, 42);
    height: 32px;
    width: 108px;
    border-radius: 16px;

    .green-triangle-up {
        margin-top: 5px;
    }

    >span {
        @include font-poppins-14;
        line-height: 20px;
        margin-left: 4px;
        color: $light-gray;
    }
}

.virtual-background-icon-container {
    width: 21px;
    height: 17px;
    background: #b2b2bf;
    border-radius: 2px;
}

.dnd-highlighted {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-dnd-canvas-highlight;
    border: 1px dashed #a6ff79;
}

.spotlight {
    width: 100%;
}



.spotlight-container {
    img {

    }
}
.media-controls-layer {
    display: flex;
    align-items: flex-end;
    opacity: 0;
    z-index: $z-index-media-controls;
    transition: opacity 200ms ease;
    overflow: hidden;

    &.hover,
    &:hover {
        opacity: 1;
    }

    &.first {
        grid-area: first;
    }

    &.second {
        grid-area: second;
    }

    &.third {
        grid-area: third;
    }

    &.fourth {
        grid-area: fourth;
    }

    &.fifth {
        grid-area: fifth;
    }

    &.sixth {
        grid-area: sixth;
    }

    &.seventh {
        grid-area: seventh;
    }

    &.eighth {
        grid-area: eighth;
    }

    &.hidden-feed {
        position: absolute;
        top: 0;
        right: 0;
        height: 57px;
        width: 50%;
        z-index: $z-index-media-controls-hidden;
    }

    .controls-pages {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 100%;

        span {
            padding: 4px 8px;
            text-align: center;
            white-space: nowrap;
            background: $element-background-highlight-gray;
            border-radius: $rem100px;
        }
    }
}

.feed-video-use-camera {
    position: absolute;
    right: 4px;
    bottom: -6px;
}

.feed-drop-area {
    pointer-events: auto;
    height: 100%;
}

.button-row-below-video {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -40px;
    left: 0;
    height: 32px;
    width: 100%;

    &.show-audio-only-button {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-column-gap: 8px;
        justify-items: center;
    }

    .audio-only-feeds-wrapper {
        margin-right: auto;
    }
}