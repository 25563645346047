header {
	display: flex;
	border-bottom: 1px solid $gray;
	align-items: center;
	border-bottom-color: rgba(135, 135, 147, .15);
	padding: 0 40px;
	height: 72px;
	
	&.event-header {
		position: relative;
		width: 100%;
		padding: 0 0 0 24px;
		background-color: $border-gray;

		.avatar-button{
			display: flex;
			align-items: center;
			justify-content: space-evenly;
		}

		.debug-opentok-session {
			position: absolute;
			top: 0;
			right: 7px;
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			color: $white;
			-webkit-text-stroke: .5px $darker-gray;
			letter-spacing: -0.05em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.header-mode-line {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: $z-index-missing-feed;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 3px;
				background: transparent;
				opacity: 0;
				transition: opacity $transition-fast;
			}

			&.stream,
			&.record {
				&::after {
					opacity: 1;
				}
			}

			&.stream {
				&::after {
					background:
						linear-gradient(
							95.42deg,
							$bl-gr-primary-500 42.36%,
							$bl-gr-bright-yellow 99.68%
						),
						linear-gradient(
							0deg,
							rgba(255, 255, 255, 0.4),
							rgba(255, 255, 255, 0.4)
						);
				}
			}

			&.record {
				&::after {
					background:
						linear-gradient(
							92.43deg,
							$color-pdf 0.89%,
							$bl-gr-medium-orange 161.7%
						),
						linear-gradient(
							0deg,
							rgba(255, 255, 255, 0.4),
							rgba(255, 255, 255, 0.4)
						);
				}
			}

			&.fade-enter {
				opacity: 0;
			}
			
			&.fade-enter-active {
				opacity: 1;
			}
			
			&.fade-enter-active,
			&.fade-exit-active {
				transition: opacity $transition-fast;
			}
		}

		.header-left {
			.header-title {
				margin-right: 16px;
			}
		}
	}

	.go-to-settings-container {
		display: flex;
		align-items: center;
		margin-left: 9px;

		button {
			background: $border-gray;
			border: none;
			height: 32px;
			width: 32px;
			border-radius: $rem32px;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background 150ms;

			&:hover {
				background: lighten($border-gray, 5%);
			}
		}

		#icon-keyboard-arrow-right {
			margin-left: 4px;
			path {
				fill: $near-white;
			}
		}
	}

	.room-state-switch-button {
		white-space: nowrap;
		padding: 8px 16px;
		margin: 0 20px;
		font-weight: 500;
		line-height: $rem16px;
		border-radius: 7.071rem;
		@include button-greenroom-basic;
		cursor: pointer;
		
		&.editing {
			background-color: $pale-green;	
			color: $border-dark-gray;
		}
		
		&.showtime {
			background-color: $element-background-highlight-gray;
			color: $near-white;
		}
	}
}

@media only screen and (max-height: 455px) {
	.go-to-settings-container {
		display: flex;
		align-items: center;
		margin-left: 9px;

		button {
			background: $border-gray;
			border: none;
			height: 32px;
			width: 32px;
			border-radius: $rem32px;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background 150ms;

			&:hover {
				background: lighten($border-gray, 5%);
			}
		}

		#icon-keyboard-arrow-right {
			margin-left: 4px;
			path {
				fill: $near-white;
			}
		}
	}
}

.header-back-btn-container {
	margin-right: 17px;
	max-width: 2vh;
}

.header-back-btn {
	color: $light-gray;
}

.header-back-arrow {
	font-size: 14px;
}

.header-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 80px;
	flex: 0 0 auto;

	> img {
		display: block;
		width: auto;
		max-height: 48px;
		max-width: 100%;
	}
}

.show-builder-logo {
	@include flex-center;
	width: 125px;
	height: 72px;

	img {
		height: auto;
		width: 100%;
		image-rendering: -moz-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
	}
}
	
.header-title {
	margin: 0 24px 0 0;
	text-overflow: ellipsis;
	white-space: pre;
}

.header-time {
	@include font-roboto-12;
	color: $near-white;
	line-height: 20px;
	white-space: pre;
	//background-color: #A6FF79;
	display: flex;
	align-items: center;
	height: 30px;
	padding: 8px 10px;
	border-radius: $rem100px;
	font-weight: bold;
	font-size: 14px;

	svg {
		margin-right: 8px;
	}
}

.header-left {
	display: flex;
	align-items: center;
	overflow: hidden;

	&.show-notifications {
		flex: 1;
	}

	.viewer-count {
		display: flex;
		align-items: center;
		background-color: $border-dark-gray;
		border-radius: $rem100px;
		padding: 9px 14px;
		font-size: 14px;
		font-weight: 500;
		margin-left: 16px;
		margin-right: 24px;
		white-space: pre;
		max-width: 10.3vh;
		
		svg {
			margin-right: 5px;
		}
	}
}

.home-header {
	position: fixed;
	left: 94px;
	right: 0;
	top:0;
	z-index: 100;
	border-left: 1px solid #333;

	header {
		.show-details-header-left {
			display: flex;
			align-items: center;
			max-width: 65%;
			margin-left: -16px;

			.channel-logo {
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 16px;
				
				img {
					display: block;
					width: auto;
					max-height: 48px;
					max-width: 80px;
				}
			}

			.back-home-btn {
				margin-right: 17px;
			}

			p {
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: #FFFFFF;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.divider {
			width: 1px;
			height: 40px;
			background: #4F4C57;
			margin: 0 24px;
		}

		.avatar-bubble-row {
			margin-right: 24px;
			
			.avatar-container {
				.avatar-placeholder {
					cursor: pointer;
					transition: 150ms background-color;

					&:hover {
						background-color: lighten($border-gray, 10%);
					}
				}
			}
		}
	}
}

.header-center {
}

.header-dividing-line {
	border-left: 0.5px solid #4F4C57;
	min-height: 32px;

	&.left {
		margin: 0 24px;
	}
}

.header-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;

	button.notes {
		background: none;
		border: none;
		display: flex;
		justify-content: center;
		padding: 0;
    	margin-right: 40px;

		&::after {
			content: "";
			height: 3px;
			width: 0px;
			background-color: $green;
			border-radius: $rem3px;
			position: absolute;
			transition: 150ms width, 150ms color;
			bottom: -1.5px;
			z-index: 10;
			transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.35);
		}

		&.active, &:hover {
			&::after {
				width: 20px;
			}
		}

		&.active {
			color: $near-white;
		}
	}

	.countdown {
		max-width: 21vh;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.live-indicator {
		max-width: 17.4vh;
		overflow: hidden;
		text-overflow: ellipsis;

		.timer-text {
			overflow: hidden;
			text-overflow: ellipsis;
		}	
	}

	.broadcast-indicator {
		.broadcast-status {
			//max-width: 12.4vh;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.clock {
			p {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

@media only screen and (max-height: 500px) {
	.header-back-btn-container {
		margin-right: 3.5vh;
	}

	.header-logo {
		margin: 0 .3vh;
	}

	.header-left {
		.viewer-count {
			margin-left: 2.9vh;
			margin-right: 4.85vh;
		}
	}

	.header-right {
		.countdown {
			.timer-text {
				overflow: hidden;
				text-overflow: ellipsis;
			
				span {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

.app-entry {
	header {
		border-bottom: 1px solid rgba( $text-gray, .1 );
		display: flex;
		align-items: center;
		padding: 0 40px;
		position: relative;

		img.logo {
			width: 130px;
			margin-left: -22px;
		}

		h1 {
			color: $color-white;
			font-size: 18px;
			line-height: 21px;
			color: $near-white;
			font-weight: bold;
			margin: 0;
			margin-left: 24px;
			
		}

		.header-right {
			position: absolute;
			right: 40px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			
			.header-date {
				margin-right: 40px;
				color: $text-light;
				font-weight: bold;
				font-size: 14px;
				flex: 1;
			}
		}
	}
}

.header-small-button {
	background-color: $border-gray;
	border-radius: $rem8px;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
	cursor: pointer;

	&:hover {
		background-color: #26242B;
	}
	
	&:active {
		background-color: lighten(#26242B, 1%);
	}

	&.drawer-open {
		background-color: #4F4C57;
	}
}

.presenter-home-header {
	left: 0;
	right: 0;
}