.video-hover-options {
    z-index: $z-index-media-controls;
    transition: opacity 200ms ease;
    user-select: none;

    .video-hover-options-buttons {
        position: absolute;
        top: -16px;
        right: 8px;
        opacity: 0;

        &.hover,
        &:hover {
            opacity: 1;
        }

        .video-hover-options-button {
            border-radius: 100px;
            background-color: $bl-grayscale-600;
            display: flex;
            width: 32px;
            height: 32px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            flex-shrink: 0;
            z-index: 99999;
            pointer-events: auto;
            cursor: pointer;
        }
    }
}