$transition-duration: 0.1s;

@mixin normalText {
	background: $bl-grayscale-400;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin darkText {
	background: $bl-grayscale-900;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin lightText {
	background: $pink-lemonade-rev;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin gradientFilterBase {
    border-radius: 99px;
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity $transition-duration;
}

.gr-input-dropdown-container {
    background-color: $bl-grayscale-800;
    border-radius: 16px;
	position: absolute;

    display: flex;
    flex-direction: column;
    gap: 16px;

	margin-top: 4px;


	//24px margins
	width: calc(100% - 48px); 

	//shows over other fields below
	z-index: 2;

    box-shadow: 0px 13px 30px 0px #00000040;
    
    overflow: hidden;
    padding: 16px;

    transition: max-height 0.3s, opacity 0.4s;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    
    &.expanded {
        pointer-events: all;
        max-height: 500px;
        opacity: 1;
    }

    h6 {
        margin: 0;
        letter-spacing: 0;
    }
}

.gr-input-chip-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .gr-input-chip {
        position: relative;
		cursor: pointer;
        
        width: fit-content;
        padding: 8px 12px;
        border-radius: 99px;
        
        text-transform: uppercase;
        
        display: flex;
        align-items: center;
        gap: 8px;

        background-color: $bl-grayscale-700;
        
        .text-container {
            color: $bl-grayscale-200;
        }
        svg { path, rect { 
            fill: $bl-grayscale-200; 
            transition: fill $transition-duration;
        } }
		
        &:hover {
            background-color: $bl-grayscale-600;
            .text-container {
                color: $bl-grayscale-100;
            }
            path, rect { 
                fill: $bl-grayscale-100;
            }
		}
    

        ::before {
            @include gradientFilterBase();
            padding: 1px; 
            background: $pink-lemonade-rev;
            mask: 
                linear-gradient(#000 0 0) content-box, 
                linear-gradient(#000 0 0);
            mask-composite: exclude; 
        }

        ::after {
            @include gradientFilterBase();
            background: linear-gradient(90deg, rgba(255, 106, 230, 0.15) 0%, rgba(255, 248, 92, 0.15) 100%);
        }
		
		&.chip-active,
		&:active {
            ::after,
            ::before {
                opacity: 1;
            }

			.text-container {
				@include lightText();
			}
            
            path, rect { 
                fill: $pink-lemonade-pink !important;
            }
		}
    }
}
