.content-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bl-grayscale-800;
    border-radius: 8px;

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;

        video,
        img {
            width: $content-card-m-width;
            height: $content-card-m-height;
            object-fit: contain;
            pointer-events: none;
        }
    }
}
