$darker-gray: #151516;
$dark-gray:  #1A1A1F;
$gray: #1A1A1F;
$med-gray: #908CA0;
$light-gray: #B2B2BF;
$purple: #362A68;
$green: #019C00;
$red: #FF443A;
$red-active: #ff746d;
$text-gray: #908CA0;
$text-light: #B2B2BF;
// class for text-light-gray overrides this color
$text-light-gray: #BFBBCF;
$text-light-gray2: #BFBBCF;
$popover-background: #151516;
$button-gray: #1A1A1F;
$near-white: #FCFCFC;
$peach: rgb(207, 184, 176);
$border-gray: #312F38;
$border-dark-gray: #1A1A1F;
$teal-green: #125554;
$button-green: #118060;
$button-red: #F04D4E;
$border-light: #38383A;
$dark-green: #219653;
$gray-presenter: #C0CBC5;
$white-presenter: #F2F6F5;
$white: #FFFFFF;
$share-gray: #212123;
$error-background: #551212;
$divider: #3E3E40;
$eggplant: #522967;
$eggplant-light: #763A95;
$loading-blue: #093A6C;
$loading-blue-light: #0A539D;
$ready-green: #195B24;
$ready-green-light: #228531;
$ready-green-highlight: #32D74B;
$lavendar: #F2DEFC;
$green-light: #D6F7DB;
$blue-light: #CEE6FF;
$error-yellow: rgb(158,131,35);
$error-yellow-light: rgb(253,213,57);
$pale-green: #A6FF79;
$background-gray: #1A1A1F;
$element-background-gray: #26242b;
$element-border-gray: #3C3A42;
$text-red: #ff5f7f;
$element-background-highlight-gray: #4F4C57;
$element-background-subtle-text: #625E70;
$text-platinum: #E5E4EC;
$text-dark-red: #CC1512;
$text-orange: #FF9F0A;
$default-gray: #878793;
$glow-green: rgba(166, 255, 121, 0.35);
$bl-gr-bright-yellow: #FFF85C;
$bl-gr-medium-orange: #FF965A;
$bl-hot-pink: #EE79B7;

/* Brandlive Design System V2 */
// https://www.figma.com/file/ET5Nc3if8EeBm2a5TsTcN8/Base-FIle---Brandlive-DS-v2?node-id=1%3A23

// Primary Colors - SR
$bl-sr-primary-100: #FFFEDE;
$bl-sr-primary-200: #FFFCBE;
$bl-sr-primary-300: #FFFB9D;
$bl-sr-primary-400: #FFF97D;
$bl-sr-primary-500: #FFF85C;
$bl-sr-primary-600: #CEC84C;
$bl-sr-primary-700: #9D983B;
$bl-sr-primary-800: #6B692B;
$bl-sr-primary-900: #3A391A;

// Primary Colors - Greenroom
$bl-gr-primary-100: #EDFFE4;
$bl-gr-primary-200: #DBFFC9;
$bl-gr-primary-300: #CAFFAF;
$bl-gr-primary-400: #B8FF94;
$bl-gr-primary-500: #A6FF79;
$bl-gr-primary-600: #87CE63;
$bl-gr-primary-700: #679D4D;
$bl-gr-primary-800: #486B36;
$bl-gr-primary-900: #283A20;

// Secondary Colors - Orange
$bl-secondary-orange-100: #FFECCE;
$bl-secondary-orange-200: #FFD99D;
$bl-secondary-orange-300: #FFC56C;
$bl-secondary-orange-400: #FFB23B;
$bl-secondary-orange-500: #FF9F0A;
$bl-secondary-orange-600: #CE810A;
$bl-secondary-orange-700: #9D630A;
$bl-secondary-orange-800: #6B450A;
$bl-secondary-orange-900: #3A270A;

// Secondary Colors - Yellow
$bl-secondary-yellow-100: #FFF7CE;
$bl-secondary-yellow-200: #FFEF9D;
$bl-secondary-yellow-300: #FFE66C;
$bl-secondary-yellow-400: #FFDE3B;
$bl-secondary-yellow-500: #FFD60A;
$bl-secondary-yellow-600: #CEAD0A;
$bl-secondary-yellow-700: #9D840A;
$bl-secondary-yellow-800: #6B5B0A;
$bl-secondary-yellow-900: #3A320A;

// Secondary Colors - Green
$bl-secondary-green-100: #D7FCEC;
$bl-secondary-green-200: #AFF8D9;
$bl-secondary-green-300: #87F5C7;
$bl-secondary-green-400: #5FF1B4;
$bl-secondary-green-500: #37EEA1;
$bl-secondary-green-600: #2EC083;
$bl-secondary-green-700: #259265;
$bl-secondary-green-800: #1B6447;
$bl-secondary-green-900: #123628;

// Secondary Colors - Light Blue
$bl-secondary-light-blue-100: #E0F6FF;
$bl-secondary-light-blue-200: #C1EDFF;
$bl-secondary-light-blue-300: #A2E4FF;
$bl-secondary-light-blue-400: #83DBFF;
$bl-secondary-light-blue-500: #64D2FF;
$bl-secondary-light-blue-600: #52AACE;
$bl-secondary-light-blue-700: #40829D;
$bl-secondary-light-blue-800: #2D596C;
$bl-secondary-light-blue-900: #1B313B;

// Secondary Colors - Blue
$bl-secondary-blue-100: #CEE6FF;
$bl-secondary-blue-200: #9DCEFF;
$bl-secondary-blue-300: #6CB5FF;
$bl-secondary-blue-400: #3B9DFF;
$bl-secondary-blue-500: #0A84FF;
$bl-secondary-blue-600: #0A6BCE;
$bl-secondary-blue-700: #0A539D;
$bl-secondary-blue-800: #093A6C;
$bl-secondary-blue-900: #09223B;

// Secondary Colors - Violet
$bl-secondary-violet-100: #E0E0FE;
$bl-secondary-violet-200: #C2C1FD;
$bl-secondary-violet-300: #A3A1FB;
$bl-secondary-violet-400: #8582FA;
$bl-secondary-violet-500: #6663F9;
$bl-secondary-violet-600: #5351C9;
$bl-secondary-violet-700: #413F99;
$bl-secondary-violet-800: #2E2D6A;
$bl-secondary-violet-900: #1C1B3A;

// Secondary Colors - Pink
$bl-secondary-pink-100: #F2DEFC;
$bl-secondary-pink-200: #E5BDFA;
$bl-secondary-pink-300: #D99CF7;
$bl-secondary-pink-400: #CC7BF5;
$bl-secondary-pink-500: #BF5AF2;
$bl-secondary-pink-600: #9B4AC4;
$bl-secondary-pink-700: #763A95;
$bl-secondary-pink-800: #522967;
$bl-secondary-pink-900: #2D1938;

// Grayscale Colors
$bl-grayscale-100-disabled: rgba(255, 255, 255, 0.4);
$bl-grayscale-100: #FFFFFF;
$bl-grayscale-200: #E5E4EC;
$bl-grayscale-300: #BFBBCF;
$bl-grayscale-400: #908CA0;
$bl-grayscale-500: #625E70;
$bl-grayscale-600: #4F4C57;
$bl-grayscale-700: #3C3A42;
$bl-grayscale-800: #312F38;
$bl-grayscale-900: #26242B;
$bl-grayscale-1000: #1A1A1F;
$bl-grayscale-1100: #121114;
$bl-grayscale-transparent-600: rgba(79, 76, 87, 0.7);
$bl-grayscale-1200: #09090A;

.grayscale-100 { color: $bl-grayscale-100 !important; }
.grayscale-200 { color: $bl-grayscale-200 !important; }
.grayscale-300 { color: $bl-grayscale-300 !important; }
.grayscale-400 { 
	color: $bl-grayscale-400 !important;
	path {fill: $bl-grayscale-400 !important;}
}
.grayscale-500 { color: $bl-grayscale-500 !important; }
.grayscale-600 { color: $bl-grayscale-600 !important; }
.grayscale-700 { color: $bl-grayscale-700 !important; }
.grayscale-800 { color: $bl-grayscale-800 !important; }
.grayscale-900 { color: $bl-grayscale-900 !important; }
.grayscale-1000 { color: $bl-grayscale-1000 !important; }
.grayscale-1100 { color: $bl-grayscale-1100 !important; }
.grayscale-1200 { color: $bl-grayscale-1200 !important; }

// Semantic Colors - Red
$bl-semantic-red-100: #FFD7DF;
$bl-semantic-red-200: #FFAFBF;
$bl-semantic-red-300: #FF879F;
$bl-semantic-red-400: #FF5F7F;
$bl-semantic-red-500: #FF375F;
$bl-semantic-red-600: #CE2E4E;
$bl-semantic-red-700: #9D253D;
$bl-semantic-red-800: #6B1B2C;
$bl-semantic-red-900: #3A121B;
$bl-primary-red-500: #DE352C;
$bl-primary-red-600: #B32C25;

.semantic-red-100 { color: $bl-semantic-red-100 !important; }
.semantic-red-200 { color: $bl-semantic-red-200 !important; }
.semantic-red-300 { color: $bl-semantic-red-300 !important; }
.semantic-red-400 { color: $bl-semantic-red-400 !important; }
.semantic-red-500 { color: $bl-semantic-red-500 !important; }
.semantic-red-600 { color: $bl-semantic-red-600 !important; }
.semantic-red-700 { color: $bl-semantic-red-700 !important; }
.semantic-red-800 { color: $bl-semantic-red-800 !important; }
.semantic-red-900 { color: $bl-semantic-red-900 !important; }

.semantic-red-bg-100 { background-color: $bl-semantic-red-100 !important; }
.semantic-red-bg-200 { background-color: $bl-semantic-red-200 !important; }
.semantic-red-bg-300 { background-color: $bl-semantic-red-300 !important; }
.semantic-red-bg-400 { background-color: $bl-semantic-red-400 !important; }
.semantic-red-bg-500 { background-color: $bl-semantic-red-500 !important; }
.semantic-red-bg-600 { background-color: $bl-semantic-red-600 !important; }
.semantic-red-bg-700 { background-color: $bl-semantic-red-700 !important; }
.semantic-red-bg-800 { background-color: $bl-semantic-red-800 !important; }
.semantic-red-bg-900 { background-color: $bl-semantic-red-900 !important; }

// Semantic Colors - Green
$bl-semantic-green-100: #D6F7DB;
$bl-semantic-green-200: #ADEFB7;
$bl-semantic-green-300: #84E793;
$bl-semantic-green-400: #5BDF6F;
$bl-semantic-green-500: #32D74B;
$bl-semantic-green-600: #2AAE3E;
$bl-semantic-green-700: #228531;
$bl-semantic-green-800: #195B24;
$bl-semantic-green-900: #113217;

// Semantic Colors - Link (Blue)
$bl-semantic-blue-100: #E3E8FA;
$bl-semantic-blue-200: #C7D0F5;
$bl-semantic-blue-300: #ABB9EF;
$bl-semantic-blue-400: #8FA1EA;
$bl-semantic-blue-500: #738AE5;
$bl-semantic-blue-600: #5E70B9;
$bl-semantic-blue-700: #49568D;
$bl-semantic-blue-800: #333D62;
$bl-semantic-blue-900: #1E2336;


$sherbert: linear-gradient(109deg, #A6FF79 -20%, #FFF85C 30%, #FF6AE6 105%);

$pink-lemonade-pink: #FF6AE6;
$pink-lemonade-yellow: #FFFD62;
$pink-lemonade: linear-gradient(90deg, $pink-lemonade-yellow 0%, $pink-lemonade-pink 100%);
$pink-lemonade-rev: linear-gradient(90deg, $pink-lemonade-pink 0%, $pink-lemonade-yellow 100%);

$dullSherbert: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
	linear-gradient(90deg, rgba(255, 253, 98, 0.2) 0%, rgba(255, 106, 230, 0.2) 100%);