.featured-cards {
	width: 924px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	overflow: visible;

	.card {
		position: relative;
		width: 452px;
		height: 254px;
		border-radius: 16px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: visible;
		transition: 150ms transform;

		&:hover:not(.placeholder) {
			cursor: pointer;
			transform: scale(1.015);
		}

		.card-top {
			display: flex;
			justify-content: space-between;
			height: 64px;

			.card-badges {
				margin: 20px 24px;

				.countdown-badge {
					border-radius: 20px;
					padding: 4px 8px 4px 8px;
					background: rgba(38, 36, 43, 0.7);
				}
			}

			.card-presenters {
				display: flex;
				align-items: center;
				margin: 16px 24px;

				.presenters {
					flex: 1;
					display: flex;
					justify-content: flex-end;
					margin-right: 8px;
					// margin-right: 16px; when three dots are reintroduced
					position: relative;

					.avatar-container {
						margin-right: -8px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-top: 0;

						.avatar-placeholder {
							width: 32px;
							height: 32px;
							border: $rem1px solid $border-dark-gray;
							border-radius: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: $border-gray;
							color: $text-gray;
							font-size: 14px;
							font-weight: 500;
						}
					}
				}

				.edit-ellipses-container {
					padding: 10px 0 10px 10px;

					.edit-ellipses-menu {
						top: 40px;
						right: 16px;
					}
				}
			}
		}

		.card-bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 0 24px 24px 24px;
			height: 190px;
			overflow: hidden;

			h2 {
				@include line-clamp(2);

				height: 3em;
				line-height: 1.5em;
				text-overflow: ellipsis;
				color: $white;
				font-weight: 700;
				font-size: 26px;
				margin: 4px 0;
				padding: 0;
				width: 100%;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
				max-height: 96px;
			}

			h3 {
				color: $white;
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				margin: 0;
				padding: 0;
				width: 100%;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
			}

			.card-bottom-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				margin-top: 14px;

				.card-buttons {
					display: flex;
					align-items: center;

					.join-button, .edit-button {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						height: 48px;
						border-radius: 100px;
						padding: 18px 24px;
						transition: 150ms color, 150ms background-color, 60ms transform;
						@include button-greenroom-shadow;

						span {
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 16px;
							display: flex;
							align-items: center;
							text-align: center;
							white-space: nowrap;
						}

						&:active {
							transform: scale(0.95);
						}
					}

					.join-button {
						margin: 0 8px 0 0;
						background-color: $button-gray;

						&:not(.presenter) {
							background-color: $pale-green;
							color: #121114;
							cursor: pointer;
						}

						&.presenter {
							background-color: $button-gray;
							color: $text-light;
							cursor: default;
						}

						.is-live {
							background-color: $pale-green;
							color: #121114;
							cursor: pointer;
						}
					}

					.edit-button {
						background-color: $element-background-highlight-gray;
						color: $white;
					}
				}

				.details-button {
					display: flex;
					align-items: center;
					background: none;
					border: none;
					outline: none;
					padding: 0;
					margin: 0;
					transition: 150ms color, 60ms transform;
					transform: scale(1);

					@include button-greenroom-shadow;
					&:active {
						transform: scale(0.95);
					}

					&:hover {
						fill: $near-white;
					}
				}
			}
		}
	}
}

.destination-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 16px 24px;
	margin-bottom: 16px;
	border-radius: $rem12px;
	background: $bl-grayscale-800;
	transition: all $transition-fast;
	overflow: hidden;

	img {
		width: 24px;
		height: 24px;
		margin-right: 12px;

		&.logo {
			display: none;
		}
	}

	.destination-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 0;
		width: 100%;

		span {
			@include line-clamp(1);
			padding-right: 8px;
		}

		>* {
			align-items: center;
			display: flex;
		}

		.destination-controls {
			button {
				background: none;
				border: none;
				padding: none;
			}

			.switch-wrapper {
				transition: opacity $transition-med;
			}
		}
	}

	.destination-card-edit {
		width: 100%;

		img {
			margin-right: 8px;
		}

		.destination-input {
			width: 100%;
			display: flex;
			align-items: center;
			margin: 0;
			padding: 8px 16px;
			border-radius: $rem100px;
			border: 1px solid $bl-grayscale-400;
			background: transparent;
			color: $bl-grayscale-200;
			font-size: $rem12px;
			line-height: $rem16px;
			font-weight: $semi;
			transition: border-color $transition-fast;

			&::placeholder {
				color: $bl-grayscale-400;
			}

			&:hover, &:focus {
				border: 1px solid $bl-grayscale-200;
			}
		}

		.destination-card-edit-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			&:last-of-type {
				margin-bottom: 0;
			}

			

			.rtmp-input {
				width: 285px;
				padding: 12px 16px
			}

			.destination-dropdown {
				.select-label {
					width: 96px;
					border-radius: $rem100px;
					border: 1px solid $bl-grayscale-400;
					justify-content: space-between;
					width: 96px;
					padding: 8px 16px !important;
					font-size: $rem12px !important;
					font-weight: $semi !important;
					line-height: $rem16px !important;
					letter-spacing: 0.24px;
					transition: border-color $transition-fast;

					&:hover {
						border: 1px solid $bl-grayscale-200;
					}
				}

				.select-body {
					border-top: none;
				}

				label {
					font-size: $rem12px;
					line-height: $rem16px;
				}
			}
		}
	}
}