.modal-background {
	background-color: rgba(0,0,0, .6);
	height: 100%;
	width: 100%;
	position: fixed; 
	top: 0;
	left: 0;
	z-index: 100;
	scrollbar-gutter: stable;
	@include custom-scrollbar;
}

.modal-container{
	display: flex;
	justify-content: center;
}

.new-meeting-modal{
	width: 640px;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	position: relative;
	background: #26242B;
	padding: 24px 40px;
	margin-top: 100px;
	margin-bottom:100px;
	max-height: 100%;

	.header{
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title{
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			color: #FFFFFF;
		}
	}

	.inputs-row{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
		
	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 16px;

		&.name{
			margin-top: 24px;
		}

		&.type{
			width: fit-content;
			.select-small{
				.select-label {
					height: 48px;
					background: #1C1C1E;
					border: $rem1px solid #54545A;
					box-sizing: border-box;
					border-radius: $rem100px;
					font-size: 14px;
					line-height: 20px;
					padding-left: 16px !important;
					color: #FFFFFF;
					margin: 0;
				}
			}
		}

		&.channel {
			max-width: 70%;
			margin-top: 0px;
			justify-content: right;
		}
		
		.label{
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			color: #908CA0;
			margin-left: 16px;
			margin-bottom: 4px;
			margin-right: 12px;
		}

		.label::after{
			content: attr(data-end) ;
			color: #FFFFFF ;
		}

		.input-form {
			display: flex;
			align-items: center;
			height: 48px;
			padding: 12px;
			background: #1A1A1F;
			border: $rem1px solid #4F4C57;
			box-sizing: border-box;
			border-radius: $rem100px;

			.input-form-icon {
				@include flex-center;
				cursor: pointer;
				margin-left: 8px;
			}

			input {
				margin: 0;
				background: none;
				border: none;
				padding: 8px;
				color: $near-white;
			}

			input::placeholder {
				color: #908CA0;
			}
				
			.select-small{
				right: -11px;

				.select-label {
					height: 44px;
					background: #1C1C1E;
					box-sizing: border-box;
					border-radius: 100px;
					font-size: 14px;
					line-height: 20px;
					padding-left: 16px !important;
					color: #FFFFFF;
					margin: 0;
				}
			}
        }

		.text-container{
			position: relative;
			width: 100%;
		}

		.input{
			height: 48px;
			width: 100%;
			background: #1A1A1F;
			border: $rem1px solid #4F4C57;
			border-radius: $rem100px;
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #FFFFFF;
			margin: 0px;

			&.short{
				width: 271px;
			}
		}

		.input::placeholder {
			color: #908CA0;
		}
	}
	
	 .invite-presenters-list {
		height: 100%;
		width: 100%;
		margin-top: 4px;
		margin-bottom: 24px;

		&::-webkit-scrollbar {
			width: 0;
		}

		.invite-presenter-row {
			display: flex;
			align-items: center;
			width: 100%;
			height: 32px;
			margin-top: 8px;
			margin-right: 0px;

			.avatar {
				margin-right: 16px;
			}

			.presenter-name {
				width: 150px;
				margin-right: 8px;
				font-weight: bold;
				color: #BFBBCF;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				span{
					color: #908CA0;
				}
			}

			.presenter-email {
				display: flex;
				align-items: center;
				width: 200px;
				margin-right: 6px;
				color: #BFBBCF;

				span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.presenter-controls {
				display: flex;
				justify-content: flex-end;
				flex: 1;
				align-items: center;
				
				span{ 
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #BFBBCF;
				}

				button {
					display: flex;
					align-items: center;
					background: none;
					border: none;
					margin: 0;
					padding: 0;
					margin-left: 25px;
				}
			}
		}
	}

	.select-small {
		.select-label {
			justify-content: space-between;
			border: none;
			padding: 0 8px 0 0 !important;
			color: #BFBBCF !important;

			&.dropdown {
				svg {
					margin-left: 5.5px;

					path {
						fill: #BFBBCF !important;
					}
				}
			}
		}

		.select-body {
			background-color: $border-gray;
			border-radius: 12px;
			width: auto;
			max-width: 500px;
			min-width: 250px;

			.select-option {
				justify-content: flex-start;
				height: 60px;
				flex-direction: row;

				.option-with-description {
					display: flex;
					flex-direction: column;
				}

				label {
					font-weight: 500;
					line-height: 20px;
					overflow: hidden;
					white-space: pre;
					text-overflow: ellipsis;
				}

				span {
					color: $text-light;
					pointer-events: none;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.4px;
				}

				svg {
					margin-left: 1px;
					margin-right: 9px;
					width: 10px;
					height: 10px;
				}

				&:hover {
					background-color: #4F4C57;

					span {
						color: $color-white;
					}
				}

				&.selected {
					background-color: #4F4C57;

					span {
						color: $color-white;
					}
				}
			}

			&.open {
				max-height: 300px;
			}
		}
	}

	.add-rehearsal-button{
		width: 100%;
		height: 72px;
		display: flex;
		align-items: center;
		background: $border-gray;
		border-radius: 12px;
		cursor: pointer;

		.green-circle{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			border-radius: 16px;
			background-color: #A6FF79;
			margin-left: 24px;
		}

		.message-container{
			display: flex;
			flex-direction: column;
			margin-left: 16px;

			.title{
				font-family: Poppins;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: #E5E4EC;
			}

			.message{
				font-family: Poppins;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #BFBBCF;
				letter-spacing: 0.4px;
				margin-top: 3px;
			}
		}
	}

	.session{
		display: flex;
		align-items: center;
		width: 100%;
		height: 88px;
		background: $border-gray;
		border-radius: 16px;
		margin-bottom: 8px;

		.date-title-container{
			display: flex;
			flex-direction: column;
			margin-left: 24px;
			width:323px;

			.date{
				font-family: Poppins;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #BFBBCF;
			}

			.dot{
				margin-left: 5px;
				margin-right: 5px;
			}

			.title-tag-container{
				display: flex;
				margin-top: 2px;

				span{
					font-family: Poppins;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 22px;
					color: #FFFFFF;

					text-overflow: ellipsis;
    				white-space: nowrap;
					overflow: hidden;
				}

				.tag{
					display: flex;
					justify-content: center;
					align-items: center;
					height: 24px;
					border-radius: 20px;
					margin-left:8px;

					&.broadcast {
						background: #A6FF79;

						span {
							color: #121114;
						}
					}

					&.recording {
						background: #CE810A;
					}

					&.tech.check{
						background: #4F4C57;
					}

					&.rehearsal {
						background: #763A95;
					}

					span{
						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.4px;
						padding-left: 8px;
						padding-right: 8px;
						text-transform: capitalize;
					}

					
				}
			}
		}

		.divider{
			width: 1px;
			height: 56px;
			background: #4F4C57;
			
		}

		.right-side-container{
			display: flex;
			justify-content: space-between;
			flex: 1;
			margin-left: 16px;
			margin-right: 16px;

			.buttons{
				display: flex;

				button{
					background-color: Transparent;
					background-repeat:no-repeat;
					cursor:pointer;
					overflow: hidden;
					outline:none;
					border-width: 0px;

					padding: 0px;
					width: 36px;
					height: 40px;
				}
			}
		}
	}

	.add-rehearsal-card{
		width: 100%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		background: $border-gray;
		border-radius: 12px;

		&.editing{
			margin-bottom: 8px;
		}
		
		.header{
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title{
				font-family: Poppins;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: #E5E4EC;
			}

			.buttons{
				display: flex;
				align-items: center;

				.button{
					height: 32px;
					width: 72px;
					border-radius: 100px;
					font-family: Poppins;
					align-items: center;
					justify-content: center;
					padding: 0px;

					&.cancel{
						background: #4F4C57;
						margin-right: 8px;
						color: #E5E4EC;
					}

					&.save{
						background: #A6FF79;
						color: #121114;
					}

					span{
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						line-height: 16px;
						
					}

				}
			}
		}
	}

	.bottom-buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 24px;

		button {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			border-radius: 100px;
		}

		.gray {
			width: 91px;
			height: 48px;
			background: $med-gray;
			color: $white-presenter;
			margin-right: 16px;
			transition: 150ms color, 150ms background-color, 60ms transform;
			@include button-greenroom-shadow;

			&:active {
				transform: scale(0.95);
			}

			&.remove {
				width: 182px;
			}
		}

		.green {
			width: 79px;
			height: 48px;
			background: $pale-green;
			color: $button-gray;
			transition: 150ms color, 150ms background-color, 60ms transform;
			@include button-greenroom-shadow;

			&:active {
				transform: scale(0.95);
			}

			&.remove {
				width: 91px;
			}
		}
	}

}