.layout-selector-wrapper {
	border-radius: $rem8px;
	border: $rem1px solid rgba(255,255,255,0.5);
	height: 54px;
	width: 96px;
	position: relative;

	.layout-selector {
		overflow: hidden;
		display: grid;
		gap: 2px;
		border-radius: $rem8px;
		border: $rem2px solid $border-dark-gray;
		transition: 200ms border, 60ms transform;
		cursor: pointer;
		transform: scale(1);
		height: 100%;
		width: 100%;

		.layout-selector-subscript-container {
			width: 100%;
			height: 100%;
			background: rgba(60,58,66, 0.45);
			position: absolute;
		}

		.layout-selector-subscript {
			position: absolute;
			top: 44px;
			left: 84px;
			text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 20px;
		}
	
		.video-block {
			position: absolute;
			height: 100%;
			width: 100%;

			&:nth-child(1) {
				grid-area: first;
			}

			&:nth-child(2) {
				grid-area: second;
			}

			&:nth-child(3) {
				grid-area: third;
			}

			&:nth-child(4) {
				grid-area: fourth;
			}

			&:nth-child(5) {
				grid-area: fifth;
			}

			&:nth-child(6) {
				grid-area: sixth;
			}

			&:nth-child(7) {
				grid-area: seventh;
			}

			&:nth-child(8) {
				grid-area: eighth;
			}

			span {
				@include absolute-fill;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $dark-gray;
				font-size: 12px;
			}
		
			img {
				@include absolute-fill;
				object-fit: cover;
				height: 100%;
				width: 100%;
				opacity: 0.5;
				transition: 200ms opacity;
			}

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				color: $darker-gray;
				transform: translate(-50%, -50%);
				font-size: 21px;
			}
		}

		&.cover {
			img {
				object-fit: cover;
			}
		}

		&.contain {
			img {
				object-fit: contain;
			}
		}

		&.single-padding {
			grid-template-areas: ". . ."
				". first ."
				". . .";
			grid-template-columns: 0.045fr 1fr 0.045fr;
			grid-template-rows: 0.045fr 1fr 0.045fr;
		}

		&.vertical {
			&.two {
				grid-template-areas: "first second";
				grid-template-columns: auto auto;
			}

			&.three {
				grid-template-areas: "first second third";
				grid-template-columns: auto auto auto;
			}

			&.four {
				grid-template-areas: "first second third fourth";
				grid-template-columns: auto auto auto auto;
			}
		}

		&.horizontal {
			&.two {
				grid-template-areas: "first"
									"second";
				grid-template-rows: auto auto;

				&.spaced {
					grid-template-areas: ". . . . . . ." 
										". first first . second second . "
										". first first . second second . "
										". . . . . . ." ;
					grid-template-rows: 1fr 1fr 1fr 1fr;
					grid-template-columns: 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr;
				}
			}

			&.three.spaced {
					grid-template-areas: ". . . . . . . . . ." 
						". first first . second second . third third . "
						". . . . . . . . . ." ;
					grid-template-rows: 1fr 3fr 1fr;
					grid-template-columns: 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr;
			}

			&.two-shifted {
				grid-template-areas: ". first first first . ."
									". . second second second .";
				grid-template-rows: auto auto;
			}

			

			&.spaced-alt {
				grid-template-areas: ". . . . . ." 
									". first first second second . "
									". first first second second . "
									". . . . . ." ;
				grid-template-rows: 0.3fr 1fr 1fr 0.3fr;
				grid-template-columns: 0.6fr 1fr 1fr 1fr 1fr 0.6fr;
			}
		}

		&.grid {
			&.four {
				grid-template-areas: "first second"
									"third fourth";
			}

			&.six {
				grid-template-areas: "first second third"
									"fourth fifth sixth";
			}

			&.eight{
				grid-template-areas:"first second third fourth"
									"fifth sixth seventh eighth";

				grid-template-rows: 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		&.spiral {
			&.four {
				grid-template-areas: 
					"first 	first 	first 	first 	first 	. 		. 		. 		."
					"first 	first 	first 	first 	first 	second	second	second 	second"
					"first 	first 	first 	first 	first 	second	second	second 	second"
					"first 	first 	first 	first 	first 	second	second	second 	second"
					"third 	third	third 	third 	fourth	fourth	fourth 	fourth 	fourth"
					"third 	third 	third 	third 	fourth	fourth	fourth 	fourth 	fourth"
					"third 	third 	third 	third	fourth	fourth	fourth 	fourth 	fourth"
					". 		. 		. 		.		fourth	fourth	fourth 	fourth 	fourth";
			}
		}

		////////////////////////////////////////////////////////////////////////////////////////////////////////////
		//
		// All of these media layouts have very specific percentages. The idea is that there are 1/2/3 presenters on
		// the left side of the media. The media is displayed on the right side and keeps it's proportion (either 16:9
		// or 4:3). We disable manual spacing for these layouts because we don't want black bars to show up on the media
		// when they aren't 16:9/4:3 anymore.
		//
		// **** EDIT BETTER METHOD: I thought about this more and I may have over engineered it. Take the 3:4 on the left and 16:9 on the right as an 
		// example. I think we can just make the 16:9 16fr width, 9fr height. Then we take the 3:4 and scale it up to match 16:9,
		// so, 6.75fr and 9fr. Then because the gaps are all the same, we can throw in any small number fr and adjust the gap size
		// in the future without making a bunch of calculations, just change the gap fr numbers which also shrinks/increases the size of the 
		// content. **** (I left the old calculations below, but would do this moving forward if we need to change anything like the gaps sizes for example)
		//
		// **** CURRENT WAY: The way it works is I see the layout in Figma (look at Figma to see what I'm talking about), lets take ".two" & ".sixteen-by-nine"
		// for example. So the big green rectangle on the right is the 16:9 media. This layout was created by Eli with a width and height that is large but
		// still leaves some room for borders (just eyeballed), while keeping 16:9 ratio. The left is a 3:4 presenter, same height as the media. So I start off by taking the width of
		// media and dividing it by the width of the entire canvas. Then I take that percentage and multiply it by 100 and assign that to the
		// media width. Then I do the same for the presenter width, divide it by the canvas width, multiply it by 100 assign that to the
		// presenter width. Now that we have a percentage width out of 100 assigned to those rectangles we can compute the percentage heights using 
		// basic algebra proportions. And then we can figure out all the spacing percentages horizontally by substracing the widths of the rectangles
		//  from 100, and dividing by 3. We can do the same for the height, subtract height of rectangle from 56.25, and then divide by 2. I use
		// 100 and 56.25 as my base width/height to do these calculations because 100/56.25 = 16:9 ratio which is what our main video canvas is.
		// Some of the layouts are a little more challenging (ex: three red presenters left w/ 16:9 ratio right, presenters go slightly above/below
		// height of media, but the idea is the same for calulating.) ****
		//
		// TLDR CURRENT WAY version: Look at figma media layout. Divide width of media item by width of layout canvas. Multiply by 100. That is media percentage width.
		// Everything else can be calulcated like/from this. Height is based off 56.26 though (100/56.25 === 16:9 ratio which matches our main video proportion)
		//
		////////////////////////////////////////////////////////////////////////////////////////////////////////////

		&.media {
			&.two {
				&.four-by-three {
					grid-template-areas:". . . . ."
										". first . second ."
										". . . . .";
									
					grid-template-columns: 6.765fr 29.257fr 6.765fr 50.447fr 6.765fr;
					grid-template-rows: 9.2075fr 37.835fr 9.2075fr;
				}

				&.sixteen-by-nine {
					grid-template-areas:". . . . ."
										". first . second ."
										". . . . .";

					grid-template-columns: .0836805fr .415625fr .0836805fr 1fr .0836805fr;
					grid-template-rows: .33333333fr 1fr .33333333fr;
				}
			}
			&.three {
				&.four-by-three {
					grid-template-areas:". . . . ."
										". first . third ."
										". . . third ."
										". second . third ."
										". . . . .";
									
					grid-template-columns: 7.4081fr 15.3257fr 7.4081fr 62.45fr 7.4081fr;
					grid-template-rows: 4.70625fr 20.4343fr 5.9679fr 20.4343fr 4.70625fr;
				}
			}
			&.four {
				&.four-by-three {
					grid-template-areas:". . . . ."
										". first . fourth ."
										". . . fourth ."
										". second . fourth ."
										". . . fourth ."
										". third . fourth ."
										". . . . .";

					grid-template-columns: 8.2147fr 16.986fr 8.2147fr 58.37fr 8.2147fr;
					grid-template-rows: 6.23625fr 12.7395fr 2.7795fr 12.7395fr 2.7795fr 12.7395fr 6.23625fr;
				}

				&.sixteen-by-nine {
					grid-template-areas:". . . . ."
										". first . . ."
										". first . fourth ."
										". . . fourth ."
										". second . fourth ."
										". . . fourth ."
										". third . fourth ."
										". third . . ."
										". . . . .";

					grid-template-columns: 4.7238fr 14.0485fr 4.7238fr 71.78fr 4.7238fr;
					grid-template-rows: 3.52615fr 4.410725fr 9.637775fr 3.52615fr 14.0485fr 3.52615fr 9.637775fr 4.410725fr 3.52615fr;
				}
			}
		}

		&.stack {
			&.two {
				&.left {
					grid-template-areas: "first second second"
										"first second second";
				}

				&.right {
					grid-template-areas:  "first second second"
										"first second second";
				}
			}

			&.three {
				&.left {
					grid-template-areas: "first second second"
										"first third third";
				}

				&.right {
					grid-template-areas: "first first second"
										"third third second";
				}

				&.top {
					grid-template-areas: ". first first ."
										"second second third third";
				}
			}

			&.five {
				&.right {
					grid-template-areas: "first second fifth"
										"third fourth fifth";
				}

				&.top-left {
					grid-template-areas: "first first second"
										"third fourth fifth";
				}

				&.two-left {
					grid-template-areas: "first first first third third"
										"first first first third third"
										"first first first fourth fourth"
										"second second second fourth fourth"
										"second second second fifth fifth"
										"second second second fifth fifth";
				}

				&.four-top {
					grid-template-areas: "second third fourth fifth"
										". first first .";
				}
			}

			&.six {
				&.right {
					grid-template-areas: "first second third third"
										"fourth fifth sixth sixth";
				}

				&.left {
					grid-template-areas: "first first second third"
										"fourth fourth fifth sixth";
				}

				&.random {
					grid-template-areas: "first second third third"
										"first fourth fifth sixth";
				}
			}
		}
	}

	&.selected {
		.video-block {
			img {
				opacity: 1;
			}
		}
		// .layout-selector-subscript {
		// 	display: none;
		// }
		.layout-selector-subscript-container {
			display: none;
		}
		border: 1px solid #ffffff;
	}


	&:active {
		transform: scale(0.96);
	}

	&:hover {
		img {
			opacity: 0.9;
		}
		// .layout-selector-subscript {
		// 	display: none;
		// }
		.layout-selector-subscript-container {
			display: none;
		}
		border: 1px solid rgba(255,255,255,0.9);
	}

}

