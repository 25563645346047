
//************************************************************
// Placeholder styles for pending events
//************************************************************

.video-placeholder {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	color: $color-white;

	.video-placeholder-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.65;
		background-size: cover;
		background-position: center;
	}

	.time-container {
		background-color: $color-video-placeholder;
		border-radius: $radius-medium;
		padding: 25px 30px;
		text-align: center;
		position: relative;
		z-index: 1;

		> span {
			display: block;
		}

		.line-1 {
			font-size: $rem32px;
			line-height: $rem32px;
			font-weight: $xbold;
			letter-spacing: -1px;
			margin-bottom: 10px;
		}

		.line-2 {
			font-size: $rem16px;
			font-weight: $bold;
		}

		.video-countdown-container {
			display: flex;
			justify-content: center;

			.time-data-wrapper {
				display: flex;
				flex-direction: column;
				//width: 40px;

				span {
					font-size: $rem32px;

					&.hour {
						&::after {
							content: "Hrs";
							display: block;
							font-size: $rem16px;
							font-weight: $semi-bold;
							margin-right: -5px;
						}
					}

					&.mins {
						&::after {
							content: "Min";
							display: block;
							font-size: $rem16px;
							font-weight: $semi-bold;
						}
					}

					&.secs {
						&::after {
							content: "Sec";
							display: block;
							font-size: $rem16px;
							font-weight: $semi-bold;
						}
					}
				}
			}

			.time-separator {
				&::after {
					content: ":";
					font-size: $rem28px;
					width: 20px;
					display: block;
				}
			}
		}
	}
}
