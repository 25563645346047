.dark-input-container {
	position: relative;
	input {
		border-radius: $rem16px;
		background: $dark-gray;
		border: $rem1px solid rgba(135, 135, 147, 0.5);
		height: 46px;
		font-size: 14px;
		color: $color-white;

		&:focus {
			background: $dark-gray;
		}

		&:active {
			background: $dark-gray;
		}

		&:hover {
			background: $dark-gray;
		}
	}

	.dark-input-valid {
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding-right: 10px;
		opacity: 0;
		transition: 150ms opacity;

		&.valid {
			opacity: 1;

			img {
				transform: scale(1);
			}
		}

		img {
			width: 19px;
			transform: scale(0.8);
			transition: 100ms transform;
			transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.35);
		}
	}
}