.duration-container {
    position: relative;
    &:focus-within:after {
        content: attr(title);
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 100%;
        width: max-content;
        opacity: 1;
    }
}
.duration-picker {
    justify-content: center;
    box-sizing: border-box;
    height: 32px;
    border: 1px solid #54545A;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    margin-right: 0;
    border-radius: 100px;

    input {
        width: 56px;
        height: 28px;
		background: none;
        border: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.224px;
        color: $bl-grayscale-200;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        padding: 8px 0;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
        &[type=text]:focus {
            border:none;
            &::placeholder {
                color: transparent;
            }
        }
        &[type=text] {
            -moz-appearance: textfield;
        }
        &:disabled {
            background: none;
        }
    }

    &:hover {
        border-color: $bl-grayscale-200;
        color: $bl-grayscale-400;
    }

    &:focus-within {
        color: $bl-grayscale-200;
        border: 2px solid $pale-green;
        outline: none;
        // to stop other element jiggling
        // when border goes from 1px -> 2px
        margin-left: -1px;
    }

} 