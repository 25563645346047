.help-wrapper {
    position: absolute;
    top: 37px;
    right: 14px;
    border-radius: 24px;
    z-index: $z-index-modal;

    .help-menu {
        background-color: $border-gray;
        border-radius: 16px;
        overflow: hidden;

        button,
        .link-button {
            display: block;
            border: none;
            padding: 12px 24px;
            width: 100%;
            border-radius: 0;
            text-align: left;
            font-weight: normal;
            color: $white;
            background: transparent;
            white-space: nowrap;
            transition: 150ms background, 150ms color;

            &:active,
            &:focus {
                box-shadow: none;
            }

            &:hover {
                background: #3c3b44;
            }
        }
    }

    .help-submenu {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        max-width: 600px;
        border-radius: 24px;
        overflow: hidden;
        
        &.support {
            min-width: 360px;
        }
        
        &.shortcuts {
            min-width: 632px;
            max-height: 700px;
        }

        .help-submenu-title,
        .help-submenu-content {
            padding: 30px 40px 20px 40px;

            .support-list {
                list-style: none;
                color: $white;
                padding: 0;
                margin: 0;

                dt {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .image-container {
                    display: inline-flex;
                    border-radius: 100px;
                    background-color: $element-background-gray;
                    width: 34px;
                    height: 34px;
                    padding: 10px;
                    margin-right: 0.75rem;

                    img {
                        color: $med-gray;
                        display: inline-block;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .help-submenu-title {
            font-weight: $semi-bold;
            margin: 0;
            background-color: #26232b;
        }

        .help-submenu-content {
            flex: 1;
            background-color: #3c3a41;
            overflow-y: auto;
            @include custom-scrollbar;

            h6 {
                font-weight: $semi-bold;
            }

            .keyboard-group {
                display: block;
                width: 100%;
                white-space: nowrap;
            }

            .keyboard-key {
                display: inline-block;
                margin: 0 5px;
                padding: 5px 8px;
                border-radius: 4px;
                font-size: $rem12px;
                color: $white;
                background-color: $element-background-subtle-text;
            }
        }

        dl {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            color: $text-light-gray;
        }

        dt {
            grid-column-start: 1;
        }

        dd {
            grid-column-start: 2;
            text-align: right;
        }

        dt,
        dd {
            margin: 0;
        }
    }
}

.help-center-container {
    position: relative;
}
