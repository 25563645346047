.avatar {
    height: 54px;
    width: 54px;
    min-height: 54px;
    min-width: 54px;
    border-radius: 100%;
    overflow: hidden;
    border: $rem1px solid $element-background-highlight-gray;
    color: $near-white;
    transition: 150ms border, 200ms box-shadow;
    box-shadow: 0 0 0 0 transparent;

    .audio-avatar {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }

    .video {
        background-color: #259265;
        border-radius: 100%;
    }

    .recording {
        background-color: #e55d56;
        border-radius: 100%;
    }

    .music {
        background-color: #6663f9;
        border-radius: 100%;

        svg {
            width: 10px;
        }
    }

    .audio {
        border-radius: 100%;
    }

    .screenshare {
        background-color: #3b9dff;
    }

    &.selected {
        border: $rem1px solid $dark-gray;
        box-shadow: 0 0 0 2px $pale-green;
    }

    img {
        border-radius: 100%;
        height: 100%;
        width: 100%;
        object-fit: fill;
    }

    .avatar-text {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        height: 100%;
        width: 100%;
    }

    &.small {
        height: 42px;
        width: 42px;
        min-height: 42px;
        min-width: 42px;
    }

    &.medium {
        height: 37px;
        width: 37px;
        min-height: 37px;
        min-width: 37px;
    }

    &.xsmall {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        border: $rem2px solid $border-gray;

        .avatar-text {
            font-size: 12px;
        }
    }

    &.title-card-4 {
        width: 62px;
        height: 62px;
        min-width: 62px;
        min-height: 62px;
    }

    &.sixtyfour {
        min-height: 64px;
        height: 64px;
        min-width: 64px;
        width: 64px;
        border: $rem1px solid $border-gray; //1 rem = 14 px
        font-size: $rem16px;
    }
    
    &.fortyeight {
        min-height: 48px;
        height: 48px;
        min-width: 48px;
        width: 48px;
        border: $rem1px solid $border-gray; //1 rem = 14 px
        font-size: $rem14px;
    }

    &.thirtytwo {
        min-height: 32px;
        height: 32px;
        min-width: 32px;
        width: 32px;
        border: $rem1px solid $border-gray; //1 rem = 14 px
        font-size: $rem12px;
    }

    &.twentyfour {
        min-height: 24px;
        height: 24px;
        min-width: 24px;
        width: 24px;
        background-color: $border-gray;
        border: $rem1px solid $border-gray;

        .avatar-text {
            font-size: 12px;
        }
    }
    
    &.hundred {
        min-height: 100px;
        height: 100px;
        min-width: 100px;
        width: 100px;

        .avatar-text {
            font-size: 48px;
        }
    }

    &.chat {
        min-height: 32px;
        height: 32px;
        min-width: 32px;
        width: 32px;
    }

    &.no-border {
        border: none;
    }

    .loading-background {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .header-popover {
        background: $dark-gray;
        padding: 16px;
        border-radius: 16px;

        button {
            background: $red;
            border-radius: 100px;
            color: $near-white;
        }
    }

    .popover {
        top: 64px;
        right: 16px;
        z-index: 10000;
    }

    &.in-meeting {
        margin: 0 24px;

        &.host {
            margin: 0 20px;
        }
    }
}

.avatar-bubble-row {
    display: flex;
    justify-content: flex-start;
    margin-right: 8px;
    // margin-right: 16px; when three dots are reintroduced
    position: relative;

    &.align-end {
        justify-content: flex-end;
    }

    .avatar-container {
        margin-right: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .avatar-placeholder {
            width: 32px;
            height: 32px;
            border: $rem1px solid $border-dark-gray;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $border-gray;
            color: $text-gray;
            font-weight: 500;

            &.xsmall {
                width: 28px;
                height: 28px;
                font-size: 10px;
            }

            &.twentyfour {
                width: 24px;
                height: 24px;
                font-size: 12px;
            }

            &.thirtytwo {
                font-size: $rem12px;
            }
            
            &.hundred {
                font-size: 48px;
            }
        }
    }

    &.session {
        .avatar-container {
            border-color: $border-gray;

            .avatar-placeholder {
                background-color: $element-background-highlight-gray;
                color: #e5e4ec;
                border-color: $border-gray;
            }
        }
    }

    @keyframes showAll {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes slideOut {
        from {
            margin-right: -12px;
        }

        to {
            margin-right: -8px;
        }
    }

    @keyframes slideIn {
        from {
            margin-right: -8px;
        }

        to {
            margin-right: -12px;
        }
    }

    .show-all-container {
        position: absolute;
        display: flex;
        height: 100%;
        right: 0px;
        opacity: 1;
        animation: 150ms showAll;
        transition: 150ms opacity;

        .avatar-container {
            animation: 150ms slideOut;
        }

        &.hiding {
            opacity: 0;

            .avatar-container {
                animation: 150ms slideIn;
            }
        }
    }
}
