.content-card-section {
    .content-title {
        text-align: left;
        margin-top: 12px;
        margin-bottom: 8px;
        color: $bl-grayscale-200;
    }

    .content-layout {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, $content-card-m-width));
        grid-auto-rows: $content-card-m-height;
        gap: 8px;
    }
}
