$shot-thumbnail-menu-offset: 6px;

.shot-thumbnail-overlay {
	z-index: $z-index-shot-thumbnail-overlay;
}

.showboard-drawer {
	display: flex;
	flex-direction: column;
	min-width: 100%;
	background: #26242B;
	min-height: 64px;
	transition: min-height 0.2s ease;
	border-top: 0.5px solid #3C3A42;

	&:hover {
		.showboard-top-row {
			.scroll-arrow {
				opacity: 1;

				&.disabled {
					opacity: 0;
					background: #A6FF79;
					border-color: none;
				}
			}
		}
	}

	.showboard-top-row {
		.top-right-gradient {
			position: absolute;
			right: 119px;
			width: 70px;
			height: 64px;
			background: linear-gradient(to right, transparent, 50%, #26242B 100%);
			z-index: 3;
			pointer-events: none;

			&.showtime {
				right: 415px;
				padding-right: 10px;
			}
		}

		.top-right {
			top: 16px;
			right: 16px;
			z-index: 1;
		}

		.open-button {
			display: flex;
			position: absolute;
			top: 16px;
			left: 16px;
			padding: 8px;
			z-index: 1;
			background-color: #26242B;
		}

		.clear-shot {
			@include button-greenroom-shadow;
			margin-right: 20px;
		}

		.add-shot {
			@include button-greenroom-shadow;

			&.showtime {
				margin-right: 22px;
			}
		}

		.scroll-arrow {
			top: 16px;
			width: 32px;
			height: 32px;
			padding: 0;
			opacity: 0;
			transition: opacity 150ms;
			z-index: 4;

			&.left {
				left: 52px;

				&.producer {
					left: 110px;

					&.showtime {
						left: 52px;
					}
				}
			}

			&.right {
				right: 52px;

				&.producer {
					right: 110px;

					&.showtime {
						right: 415px;
					}
				}
			}
		}
	}

	.showboard-header {
		position: absolute;
		display: flex;
		align-items: center;
		top: 12px;
		left: 46px;
		height: 36px;
		padding-left: 2px;

		span {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			white-space: pre;
		}

		.showboard-title {
			margin-right: 8px;
			font-size: 16px;
			color: #FFFFFF;
		}

		.showboard-duration {
			letter-spacing: -0.5px;
			color: #908CA0;

			&.divider {
				font-size: $rem16px;
				margin-right: 8px;
			}

			&.open {
				font-size: 16px;
			}
		}

		.current-shot {
			width: 167px;
			margin-right: 9px;
			padding: 2px 0;
			z-index: 1;

			span {
				font-size: 16px;
				font-weight: 700;
				line-height: 20px;
				color: $text-platinum;
			}
		}

		button {
			margin-right: 12px;

			span {
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				color: $text-platinum;
			}
		}

		.next-shot {
			display: flex;
			align-items: center;
			width: 192px;
			margin: 0 3px;
			padding: 8px 0;
			font-size: 14px;
			color: $text-light-gray;

			&.pinned {
				span {
					font-size: 16px;
					font-weight: 700;
					line-height: 20px;
					color: $text-platinum;
					margin-right: 8px;
				}

				.next-countdown {
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 0.4px;
					color: $text-platinum;

					&.late {
						color: $text-dark-red;
					}

					&.ending {
						color: $text-orange;
					}
				}
			}

			&:not(.pinned) {
				span {
					font-size: 14px;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: 0.4px;
					color: $text-platinum;
				}

				.next-countdown {
					text-transform: lowercase;

					&.late {
						color: $text-dark-red;
					}

					&.ending {
						color: $text-orange;
					}
				}
			}
		}

		.final-shot {
			display: flex;
			align-items: center;
			width: 192px;
			margin: 0 3px;
			padding: 8px 0;
			font-size: 14px;
			color: $text-light-gray;

			.final-countdown {
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0.4px;
				color: $text-platinum;

				&.late {
					color: $text-dark-red;
				}

				&.ending {
					color: $text-orange;
				}
			}
		}
	}

	.showboard-content {
		position: relative;
		min-width: 330px;

		.shot-thumbnail-row {
			position: absolute;
			top: 0px;
			left: 119px;
			right: 119px;
			display: flex;
			padding: 7px 0 12px;
			overflow-x: scroll;
			overflow-y: hidden;
			scrollbar-width: none;
			animation: showboardClose 0.2s ease;

			@keyframes showboardClose {
				from {
					opacity: 0;
				}

				to {
					opacity: 1;
				}
			}

			&.showtime {
				left: 64px;
				right: 64px;

				&.pinned {
					.showboard-shot-item {
						transition: padding-right 50ms;

						&:last-of-type {
							padding-right: 100%;
						}
					}
				}

				&.producer {
					right: 415px;
					padding-right: 10px;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}

			.shot-thumbnail-wrapper {
				position: relative;
				margin-right: 0;
				min-width: 82px;
				padding-right: 0;

				&.draggable {
					width: 226px;
					padding: 12px 16px;
					top: -14px;
					left: -16px;
					cursor: grabbing;

					.showboard-header {
						top: -32px;
						left: 43px;

						&.next {
							left: 17px;
						}
					}

					.shot-thumbnail {
						&.selected {
							background-color: #26242B;
						}
					}
				}

				&.dragged {
					visibility: hidden;

					&:not(.over), .custom-graphics-canvas-container {
						display: none;
					}
				}

				&:last-of-type {
					.shot-thumbnail {

					}
				}

				.showboard-header {
					top: -44px;
					left: 27px;

					&.next {
						left: 1px;
					}
				}

				.shot-thumbnail {
					width: 82px;
					max-height: 50px;
					border: 0.15rem solid transparent;
					border-radius: 0.85rem;
					user-select: none;

					.shot-thumbnail-inside {
						overflow:visible;
						position: relative;
						width: 72px;
						height: 40px;
						margin: 3px;
						border-radius: $rem8px;
						z-index: 0;

						.custom-graphics-canvas-container {
							.custom-graphics-canvas {
								border-radius: $rem8px;
							}
						}

						&.selectable {
							cursor: pointer;

							&:active {
								transform: translate(1px, 1px);
							}
						}

						.shot-thumbnail-dots-wrap {
							position: absolute;
							top: $shot-thumbnail-menu-offset;
							right: $shot-thumbnail-menu-offset;
							z-index: $z-index-shot-thumbnail-menu;
							filter: $drop-shadow-context-menu;
							cursor: pointer;
						}

						.shot-thumbnail-overlay {
							overflow: visible;
							position: absolute;
							top: 0;
							left: 0;
							align-items: center;
							justify-content: center;
							width: 72px;
							height: 40px;
							border-radius: $rem8px;
							button {
								opacity: 0;
							}
							&:active {
								top: 0px;
								left: 0px;
							}

							.edit-shot {
								display: flex;
								width: 32px;
								height: 32px;
								background: #4F4C57;
								border-radius: $rem100px;
								padding: 10px;
							}

							.play-shot {
								z-index: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								height: 32px;
								border-radius: $rem100px;
								padding: 8px 11px;
								font-weight: bold;
								font-size: 12px;
								letter-spacing: 0.4px;
								color: #121114;

								span {
									white-space: nowrap;
								}

								&:active {
									transform: scale(0.95);
								}

								&.warning {
									color: #FFFFFF
								}

								&.open {
									padding: 8px 16px;
								}
							}
						}
					}

					&.pre-post-stream {
						cursor: pointer;

						&.live {
							border: 2px solid #A6FF79;
						}
					}

					.stream-media-thumbnail {
						width: 72px;
						height: 40px;
						margin: 3px;
						pointer-events: none;
						position: relative;
						border-radius: $rem8px;
						cursor: pointer;
						background-color: #151516;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
							border-radius: $rem8px;
						}

						video {
							width: 100%;
							height: 100%;
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
							border-radius: $rem8px;
						}
					}

					.main-video {
						position: relative;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-clip: padding-box;
						border-radius: 0.5714rem;

						&.layer2 {
							position: absolute;
						}

						.video-container-presenter {
							display: flex;
							justify-content: center;
							align-items: center;

							.empty-video {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: #1d1b21;
								height: 100%;
								width: 100%;

								.placeholder-icon {
									@include flex-center;
									max-width: 100%;
									min-width: 100%;
									padding-left: 35%;
									padding-right: 35%;
								}
							}

							.poster {
								.no-image-placeholder {
									display: flex;
									justify-content: center;
									align-items: center;
									height: 100%;
									width: 100%;

									span {
										font-size: calc(48px * .1);
										height: 10px;
										width: 10px;
									}
								}
							}

							.title-card {
								margin: calc(8px * .1);

								&.max-width {
									min-width: calc(100% - 16px * .1);
								}

								&.no-left-margin {
									margin-left: 0;
								}

								.card-3 {
									height: calc(42px * .1);

									.user-data {
										font-size: calc(12px * .1);
										margin-left: calc(8px * .1);
										text-shadow: none;

										.job-title, .location {
											font-size: calc(11px * .1);
										}
									}
								}
							}

							&.open {
								.title-card {
									margin: calc(8px * .25);

									&.max-width {
										min-width: calc(100% - 16px * .25);
									}

									&.no-left-margin {
										margin-left: 0;
									}

									.card-3 {
										height: calc(42px * .25);

										.user-data {
											font-size: calc(12px * .25);
											margin-left: calc(8px * .25);
											text-shadow: none;

											.job-title, .location {
												font-size: calc(11px * .25);
											}
										}
									}
								}

								.poster {
									.no-image-placeholder {
										span {
											font-size: calc(48px * .25);
											height: 25px;
											width: 25px;
										}
									}
								}
							}
						}
					}

					&.selected {
						border-color: $white;
						&:not(.pre-live) {
							border-color: $pale-green;
						}
					}

					&:hover {
						.shot-thumbnail-overlay {
							display: flex;
							transition: opacity 300ms;

							button {
								opacity: 1;
								z-index: 1;
							}

							&.show-background {
								background: rgba(26, 26, 31, 0.5);
							}
						}
					}

					.shot-thumbnail-overlay {
						display: flex;
						overflow: visible;
					}

					.duration-bar {
						position: absolute;
						opacity: 0;
						left: 99%;
						top: -7px;
						width: 2px;
						height: 56px;
						background: #A6FF79;
						box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
						border-radius: $rem3px;
						pointer-events: none;

						&.playing {
							opacity: 1!important;
							left: -1%;
							animation-name: movePlayLine;
							animation-timing-function: linear;
							animation-iteration-count: 1;

							@keyframes movePlayLine {
								from { left: -1%; }
								to { left: 99%; }
							}
						}
					}
				}

				.shot-thumbnail-bubble {
					.shot-thumbnail-bubble__image {
						object-fit: cover;
					}
				}

				.shot-thumbnail-below {
					display: flex;
					align-items: center;
					padding-top: 3px;

					.drag-ellipses {
						display: flex;
						align-items: center;
						height: 36px;
						padding-right: 8px;

						span {
							font-weight: 500;
							line-height: 20px;
							color: $text-light-gray;
							white-space: nowrap;
							margin-bottom: 1px;
						}

						&.active {
							cursor: grab;
						}
					}

					.shot-meta {
						display: flex;
						flex-direction: column;
						width: 100%;
						overflow: hidden;

						span {
							color: $white;
						}

						.shot-title {
							background: none;
							border: none;
							font-weight: 500;
							font-size: 14px;
							height: 20px;
							line-height: 20px;
							width: 100%;
							color: $near-white;
							margin: 0 !important;
							padding: 0 12px 0 0;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: pre;

							&.untitled {
								color: $color-text-light;
							}
						}

						.shot-duration {
							font-size: 12px;
							line-height: 20px;
							letter-spacing: 0.4px;
							white-space: pre-wrap;
							margin: 0 4px 2px 0;
						}
					}

					.avatar-bubble-row {
						position: relative;
						right: 5px;
						display: flex;
						justify-content: flex-end;
						align-items: center;

						.avatar-placeholder {
							border: 1px solid $border-gray;
						}
					}
				}

				.missing-feeds,
				.avatar-image-container {
					@include flex-center;
					position: absolute;
					top: -3px;
					z-index: $z-index-missing-feed;
					cursor: pointer;
					animation: 300ms showAll;
				}

				.avatar-image-container {
					left: -2px;
				}

				.missing-feeds {
					left: 61px;
					width: 24px;
					height: 24px;
					background: #FF375F;
					border-radius: 1.285rem;
					padding: 6px;
					box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
				}
			}
		}
	}

	&.open {
		min-height: 224px;

		.showboard-top-row {
			.scroll-arrow {
				top: 85px;
				width: 19px;
				height: 60px;

				&.left {
					left: 15px;

					&.showtime {
						left: 15px;
					}
				}

				&.right {
					right: 15px;

					&.showtime {
						right: 15px;
					}
				}
			}
		}

		.showboard-content {
			.shot-thumbnail-row {
				top: 49px;
				left: 0;
				right: 0;
				padding: 7px 32px 12px 32px;
				animation: showboardOpen 0.2s ease;

				@keyframes showboardOpen {
					from {
						opacity: 0;
						max-height: 0px;
					}
					to {
						opacity: 1;
						max-height: 225px;
					}
				}

				.shot-thumbnail-wrapper {
					min-width: 202px;

					.shot-thumbnail {
						width: 202px;
						max-height: 118px;

						.shot-thumbnail-inside {
							overflow: visible;
							width: 192px;
							height: 108px;

							.shot-thumbnail-overlay {
								width: 192px;
								height: 108px;
								display: flex;
								&:active {
									margin-left: 0;
								}
							}
						}

						.stream-media-thumbnail {
							width: 192px;
							height: 108px;
						}

						.duration-bar {
							top: -7px;
							width: 4px;
							height: 124px;
						}
					}

					.shot-thumbnail-below {
						width: 202px;
					}

					&:last-of-type {
						.shot-thumbnail {
							.duration-bar {
								margin-left: 0;
							}
						}
					}

					.missing-feeds {
						top: -6px;
						left: 186px;
					}
				}

				&.showtime {
					left: 0;
					right: 0;
					padding-right: 32px;
				}
			}
		}
	}
}

.scroll-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	position: absolute;
	padding: 18px;
	background: #A6FF79;
	border-radius: $rem100px;
	z-index: 3;
	transition: 150ms color, 150ms background-color, 60ms transform;
	@include button-greenroom-shadow;

	&:hover {
		background: #A6FF79;
	}

	&:active {
		transform: scale(0.95);
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.layout-modal {
	max-width: 866px;
	width: 75%;
	overflow: hidden;

	.layout-modal-top {
		height: 118px;
		width: 100%;
		padding-top: 20px;

		.layout-modal-header-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 26px;

			label {
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: $white;
			}

			.layout-modal-header-buttons {
				display: flex;

				button {
					height: 32px;
					border-radius: $rem100px;
					font-style: normal;
					font-weight: 500;
					display: flex;
					justify-content: center;
					align-items: center;
					white-space: nowrap;
					transition: 150ms color, 150ms background-color, 60ms transform;
					@include button-greenroom-shadow;

					&:active {
						transform: scale(0.95);
					}

					&.apply {
						width: 109px;
						background: $pale-green;
						color: #121114;
					}
				}
			}
		}

		.layout-modal-tabs-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 24px 26px 0 10px;
			position: relative;

			.sliding-menu-wrapper {
				.sliding-menu-option {
					padding-bottom: 12px;
				}
			}

			.tab-divider {
				position: absolute;
				left: 0;
				top: 117.5px;
				width: 882px;
				border-bottom: 1px solid #625E70;
				z-index: 1;
			}

			.layout-modal-amount-dropdown {
				display: flex;
				padding-bottom: 8px;

				label {
					margin-right: 8px;
					font-weight: 500;
					line-height: 16px;
					display: flex;
					align-items: center;
					text-align: right;
					color: #FFFFFF;
					white-space: nowrap;
				}
			}
		}
	}

	.layout-modal-main {
		height: 486px;
		margin-bottom: 24px;

		.layout-wrapper {
			@include custom-scrollbar;
			overflow-x: hidden;
			overflow-y: auto;
			height: 100%;
			padding-top: 24px;

			//this is where .layout-row-container was, moved outside of this scope for shot builder modal

			.no-layouts {
				margin: 32px 0 42px 0;
				color: #B2B2BF;
			}
		}

		&.single {
			height: 100%;
			margin-bottom: 0;

			.layout-wrapper {
				height: 100%;
				display: flex;
				justify-content: center;

				.layout-row-container {
					padding-right: 0;

					.layout-row {
						width: 808px;
						flex-wrap: wrap;
						margin-right: 0;

						.layout-selector-wrapper {
							margin-bottom: 24px;
						}

						.layout-selector-wrapper:nth-child(4n) {
							margin-right: 0;
						}
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.layout-row-container {
	margin-bottom: 24px;
	justify-content: center;
	position: relative;

	label {
		font-weight: bold;
		line-height: 18px;
		color: #BFBBCF;
		padding-left: 24px;
	}

	.layout-row {
		display: flex;
		margin: 12px 24px 0 24px;
		overflow-x: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		.scroll-arrow {
			display: none;
			top: 59.5px;
			right: 22px;

			&.left {
				right: 0;
				left: 22px;
			}
		}

		&:hover {
			.scroll-arrow {
				display: flex;
			}
		}

		.layout-selector-wrapper {
			width: 190px;
			height: 108px;
			border: 0;
			margin-right: 16px;

			&:last-of-type {
				margin-right: 0;
			}

			.layout-selector {
				border: 2px solid rgba(255, 255, 255, 0.5);
				width: 190px;
				outline: none;

				.video-block {
					img {
						opacity: 0.3;
					}
				}

				&:active {
					transform: scale(0.95);
				}

				&.selected {
					.video-block {
						img {
							opacity: 1;
						}
					}
					border: 2px solid #ffffff;
				}
			}

			&:active {
				transform: none;
			}
		}
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.shot-builder-modal-body {
	.layout-row-container {
		label {
			padding-left: 32px;
		}

		.shot-builder-layout-row {
			margin: 12px 16px 0 0;
			width: 100%;

			.layout-selector-wrapper {
				&:last-of-type {
					margin-right: 16px;
				}
			}
		}
	}
}

.layout-modal-amount-dropdown, .shot-builder-modal-header {
	.select-group {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;

		.select-label {
			margin: 0 8px 0 0;
			font-weight: 500;
			line-height: 16px;
			display: flex;
			align-items: center;
			text-align: right;
			color: #FFFFFF;
			white-space: nowrap;
		}

		.main-label {
			height: 32px;
			width: 158px;
			justify-content: space-between;
			background: #1A1A1F;
			border: 1px solid #4F4C57;
			border-radius: 2rem;
			padding: 0 16px 0 16px !important;
			color: #BFBBCF !important;

			.caret {
				display: none;
			}
		}

		.select-body {
			top: 0;
			right: 0;
			width: 158px;
			justify-content: space-between;
			background: #1A1A1F;
			border: 1px solid #4F4C57;
			min-width: 158px;

			.select-option {
				height: 32px;
				padding: 0 16px;

				label {
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #BFBBCF !important;
				}

				.checkmark {
					display: flex;
				}
			}
		}
	}
}

.add-shot-button {
	align-items: center;
	background-color: #3C3A42;
	border-radius: 8px;
	display: flex;
	color: #BFBBCF;
	cursor: pointer;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-left: 24px;
	margin-top: 5px;
	max-height: 108px;
	max-width: 192px;
	min-height: 108px;
	min-width: 192px;
	overflow: hidden;
	user-select: none;
	transition: opacity 225ms;
	span {
		color: #BFBBCF;
		margin-top: 6px;
		transition: color 225ms;
	}
	path {
		color: #BFBBCF !important;
	}
	&:hover {
		background-color: #4F4C57;
		opacity: 1;
		span {
			color: $text-platinum;
		}
		path {
			transition: fill 225ms;
			fill: $text-platinum !important;
		}
	}
	&.hide-add-shot-button {
		pointer-events: none;
		opacity: 0;
	}
}

.add-shot-button-short {
	align-items: center;
	background-color: #3C3A42;
	border-radius: 8px;
	display: flex;
	color: #BFBBCF;
	cursor: pointer;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-left: 24px;
	margin-top: 5px;
	max-height: 41px;
	max-width: 71px;
	min-height: 41px;
	min-width: 71px;
	overflow: hidden;
	user-select: none;
	transition: opacity 225ms;
	span {
		color: #BFBBCF;
		margin-top: 6px;
		transition: color 225ms;
	}
	path {
		color: #BFBBCF !important;
	}
	&:hover {
		background-color: #4F4C57;
		opacity: 1;
		span {
			color: $text-platinum;
		}
		path {
			transition: fill 225ms;
			fill: $text-platinum !important;
		}
	}
	&.hide-add-shot-button {
		pointer-events: none;
		opacity: 0;
	}
}

.showboard-shot-item {
	display: flex;
	transition: padding-right 1000ms;

	&.disable-transition {
		transition: none !important;
	}
}

.between-shot {
	border-radius: 8px;
	height: 100%;
	margin-right: 0;
	margin-top: 5px;
	max-height: 108px;
	max-width: 19px;
	min-height: 108px;
	min-width: 19px;
	overflow: hidden;
	transition: min-width 0.1s ease-out;
	.light-gray {
		color: $text-platinum !important;
	}
}

.between-shot-short {
	border-radius: 8px;
	height: 100%;
	margin-right: 0;
	margin-top: 5px;
	max-height: 40px;
	max-width: 19px;
	min-height: 40px;
	min-width: 19px;
	overflow: hidden;
	transition: min-width 0.1s ease-out;
	.light-gray {
		color: $text-platinum !important;
	}
}

@keyframes animate-in-add-shot-short {
	from {
		min-width: 6px;
		border-radius: 8px;
		height: 100%;
		margin-right: 0;
		margin-top: 5px;
	}
	to {
		min-width: 30px;
		border-radius: 8px;
		height: 100%;
		margin-right: 0;
		margin-top: 5px;
	}
}

@keyframes animate-in-add-shot {
	from {
		min-width: 6px;
	}
	to {
		min-width: 64px;
	}
}

.between-shot-hover {
	border-radius: 8px;
	cursor: pointer;
	margin-right: 0;
	margin-left: 0;
	min-width: 64px;
	max-width: 64px;
	animation: animate-in-add-shot 0.1s;
	.light-gray {
		color: $text-platinum !important;
	}
}

.between-shot-short-hover {
	border-radius: 8px;
	cursor: pointer;
	margin-right: 0;
	margin-left: 0;
	min-width: 30px;
	max-width: 30px;
	animation: animate-in-add-shot-short 0.1s;
	.light-gray {
		color: $text-platinum !important;
	}
}

.between-shot-icon {
	position: relative;
	max-height: 108px;
	min-height: 108px;
}

.between-margin-right {
	margin-right: 0 !important;
}

.between-shot-extra-space-on-hover-right {
	margin-right: 4px;
}

.between-shot-extra-space-on-hover-left {
	margin-left: 4px;
}

.play-button-width {
	width: 89px;
}

.play-button-arrow-width {
	width: 65px;
}