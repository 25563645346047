@include scrubbar-slider;

.settings {
  padding-bottom: 40px;

  .label-action {
    @include button-greenroom-basic;

		&.link-button {
		min-width: 78px;
		margin-left: 2px;
		margin-right: 2px;
    display: block;
    padding: 3px 0px 0px 3px;
		}
  }

  .live-warning {
    text-align: center;
    padding: 16px 0 32px 0;
    margin: 0 auto;
    border-bottom: 1px solid rgba($text-gray, 0.1);
    .red {
      color: $red;
    }
  }

  .border-container {
    border-bottom: 1px solid rgba($text-gray, 0.1);
    position: relative;

    &.link-container {
      font-weight: 700;
      display: flex;
      justify-content: center;
      text-decoration: underline;
      text-transform: uppercase;

      a {
        display: flex;
        align-items: center;

        span {
          margin-right: 8px;
        }
      }
    }

    &.overlay-visible {
      z-index: 1;
    }

    .layout-row-container {
      margin-top: 16px;
    }

    .layouts-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 8px;
      margin-bottom: 16px;
    }

    hr {
      width: 100%;
      background-color: rgba($text-gray, 0.1);
      margin: 8px auto 16px auto;
    }

    button.caret {
      @include button-greenroom-basic;
      background: none;
      padding: 5px 10px;
      outline: none;
      box-shadow: none;

      svg {
        margin-left: 8px;
      }
    }
  }

  .layouts-container {
    padding-top: 0px;
    padding-bottom: 0px;

    border-bottom: none;

    .layout-row-container {
      margin-top: 16px;
    }

    .layouts-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      margin-bottom: 16px;

      .layout-selector-wrapper {
        width: 100%;
        height: 0;
        padding-bottom: 56%;

        .layout-selector {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
      }
    }

    hr {
      width: 100%;
      background-color: rgba($text-gray, 0.1);
      margin: 8px auto 16px auto;
    }

    button.caret {
      @include button-greenroom-basic;
      background: none;
      padding: 5px 10px;
      outline: none;
      box-shadow: none;

      svg {
        margin-left: 8px;
      }
    }

    .presenter-number-label {
      color: $text-gray;
      margin-bottom: 16px;
    }

    .presenter-number-selection {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 32px;
			cursor: pointer;

      .number {
        @include button-greenroom-basic;
        background-color: $dark-gray;
        color: $text-gray;
        text-align: center;
        border: $rem1px solid $border-gray;
        height: 100%;
        width: 100%;
        padding: 8px;

        &.active {
          color: white;
          border: $rem1px solid $border-gray;
          background-color: $border-gray;
        }

        &.first {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &.last {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .wallpaper-container {
    margin: 16px 32px;
    padding: 0px;
    .background-color-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0 16px 0;

      label {
        color: $text-gray;
        font-weight: normal;
      }
    }

    .background-fill-options {
      display: flex;

      .background-image-container {
        padding: 8px;
        border: 1px solid #3C3A42;
        border-radius: $rem8px;
        @include flex-center;
        cursor: pointer;

        .background-image {
          min-width: 110px;
          aspect-ratio: 16/9;
          background-color: #000;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .color-background {
          min-width: 110px;
          aspect-ratio: 16/9;
          @include flex-center;
          color: $text-gray;
        }
      }

      .background-image-select {
        @include flex-center;
        @include button-greenroom-basic;
        flex-direction: column;
        padding-left: 32px;
        flex: 1;
      }
    }
  }

  .background-container {
    margin: 16px 32px;
    padding: 0px;
    .background-color-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0 16px 0;

      label {
        color: $text-gray;
        font-weight: normal;
      }
    }

    .background-fill-options {
      display: flex;

      .background-image-container {
        padding: 8px;
        border: 1px solid #3C3A42;
        border-radius: $rem8px;
        @include flex-center;
        cursor: pointer;

        .background-image {
          min-width: 110px;
          aspect-ratio: 16/9;
          background-color: #000;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .color-background {
          min-width: 110px;
          aspect-ratio: 16/9;
          @include flex-center;
          color: $text-gray;
        }
      }

      .background-image-select {
        @include flex-center;
        @include button-greenroom-basic;
        flex-direction: column;
        padding-left: 32px;
        flex: 1;
      }
    }
  }

  .wallpaper-container,
  .background-container {
    .image-picker-container {
      position: fixed;
      // Header height + slight margin
      top: #{72 + 1}px;
      // Tab width + right menu bar width + slight margin
      right: #{425 + 84 + 1}px;
      // Matches 80% when not fixed
      width: 332px;
    }
  }

  .btn-container {
    display: flex;
    margin: 24px 40px;
  }

  .greenroom-settings-container {
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 35px;
    padding-right: 35px;
    border-top: 1px solid rgba(135, 135, 147, 0.1);
    border-bottom: 1px solid rgba(135, 135, 147, 0.1);
    position: relative;

    .row-between {
      padding: 0px 5px;
      height: 40px;

      &.email-invites {
        margin-bottom: 16px;

        label {
          text-transform: none;
        }
      }
    }
  }

  .disabled-text {
    color: $text-gray;
  }

  .enabled-text {
    color: $dark-gray;
  }

  .apply-changes-buttons-container {
    display: flex;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 24px;

    .apply-changes-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 46px;
      border-radius: 23px;
      margin-left: 16px;
      background-color: $border-gray;
      cursor: default;

      &:hover {
        filter: brightness(100%);
      }

      &:hover.active {
				background-color: lighten($pale-green, 10%) !important;
			}

      &:active {
        filter: brightness(100%);
        background-color: $pale-green;
        color: $dark-gray;
      }

      .invite-badge-x-button {
        line-height: 0;
        cursor: pointer;
      }

      &.active {
        @include button-greenroom-shadow;
        background-color: $pale-green;
        color: $dark-gray;
        cursor: pointer;
      }

      > span {
        @include font-poppins-14-500;
        line-height: 16px;
        margin-left: 11px;
      }

      .disabled-text {
        color: $text-gray;
      }

      .enabled-text {
        color: $dark-gray
      }
    }

    .reset-btn {
      @include button-greenroom-shadow;
      height: 46px;
      width: 96px;
      border-radius: 23px;
      justify-content: center;
      align-items: center;
      background-color: $border-gray;
      cursor: auto;

      @include font-poppins-14-500;
      line-height: 16px;

      &:hover {
        filter: brightness(100%);
      }

      &.active {
        @include button-greenroom-shadow;
        filter: brightness(100%);
        background-color: #4f4c57;
        color: white;
        cursor: pointer;
      }
      &.active:hover {
        background-color: #BFBBCF;
      }
    }
  }

  hr {
    border-color: rgba($text-gray, 0.1);
  }

  .device-option {
    display: flex;
    background-color: $border-gray;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 26px 24px;
    margin-bottom: 19px;
    cursor: pointer;
    transition: background-color 150ms;

    &:hover {
      background-color: lighten($border-gray, 5%);
    }

    &.outline {
      background-color: transparent;
      border: $rem1px solid $border-gray;
    }

    .device-metadata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding-left: 26px;

      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 18.75px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: $text-light;
      }
    }

    .device-preview {
      display: flex;
      align-items: center;

      .device-preview-container {
        margin-right: 24px;
        font-size: 30px;
      }

      img {
        border-radius: 12px;
        width: 40px;
        height: 40px;
      }

      video {
        height: 42px;
        border-radius: 4px;
      }
    }
  }
}

.background-scale {
  display: flex;
  align-items: center;
  padding-top: 22px;

  label {
    font-weight: normal;
  }

  input {
    flex: 1;
    margin: 0 16px;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background: $med-gray;
    border-radius: 30px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      margin-top: -11px;
      width: 24px;
      height: 24px;
      background: $near-white;
      border-radius: 100%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      background: $near-white;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  span {
    display: block;
    padding: 10px 19px;
    border: $rem1px solid $border-gray;
    border-radius: $rem100px;
  }
}

.checkbox-settings-wrapper {
  label {
    color: $text-light;
    font-weight: normal;
    pointer-events: none;
    margin-bottom: 0;
  }

  .checkbox-settings {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkbox-settings-outer {
      margin-right: 8px;
      background-color: transparent;
      border: $rem2px solid $border-gray;
      border-radius: $rem3px;
      height: 16px;
      width: 16px;

      svg {
        font-size: 12px;
        color: $dark-gray;
        margin-left: 0;
        position: relative;
        top: -4px;
      }
    }

    label {
      margin-right: 32px;
      white-space: nowrap;
    }

    svg {
      margin-left: 8px;
      opacity: 0;
      transition: 60ms opacity;
    }

    &.selected {
      .checkbox-settings-outer {
        background-color: $near-white;
        border: $rem2px solid $near-white;
      }

      svg {
        opacity: 1;
      }
    }
  }
}

.show-info-card {
  background-color: $border-dark-gray;
  min-height: 100px;
  border-radius: 8px;
  padding: 16px;
  color: $near-white;

  h1 {
    margin: 8px 0;
    font-size: 24px;
    font-weight: bold;
  }

  h3 {
    color: $text-gray;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

.change-destination-btn {
  height: 30px;
  border-radius: 15px;
  align-items: center;
  background-color: #019c00 !important;
  color: $near-white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  z-index: 2;

  span {
    padding-left: 18px;
    padding-right: 18px;
  }
}

#rtmp-destination-popover {
  border-radius: 8px;
  background: #1a1a1f;
  opacity: 0;
  overflow-y: auto;
  z-index: 1;
  flex-direction: column;
  margin-top: 16px;

  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;

  .input-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #b2b2bf;
    margin-top: 20px;
  }

  input {
    border: $rem1px solid #4f4c57;
    box-sizing: border-box;
    border-radius: $rem12px;
    margin: 0px;
    margin-top: 8px;
    background-color: transparent;
    height: 32px;
    color: $near-white;
  }

  .input-copy-btn-row {
    display: flex;
    margin-top: 8px;

    input {
      margin-top: 0px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $border-gray;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      opacity: 0.4;
      margin-left: 8px;
    }
  }

  .bottom-btns {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 164px;
      height: 40px;
      border-radius: 82px;
      margin-top: 24px;

      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }

    .cancel {
      background: $border-gray;
      color: #b2b2bf;
    }

    .save {
      background: #019c00;
      color: $near-white;

      span {
        margin-left: 8px;
      }
    }
  }
}

.settings-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(21, 21, 22, 0.7);
  z-index: 0;
}

.change-destination {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dark-gray;
  border-radius: $radius-medium;
  margin-bottom: 16px;
  padding: 0 16px;
  cursor: pointer;
  transition: 150ms background;
  border: $rem1px dashed $med-gray;
  color: $text-light;
  font-weight: 500;
  svg {
    margin-right: 8px;
  }
}

.settings-container {
  padding: 16px 32px;
  position: relative;

  &.overlay-visible {
    z-index: 1;
  }

  &.destination {
    padding: 16px 24px;
    border-bottom: none;
    position: relative;

    .destination-settings-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .add-button {
      height: 72px;
      margin: 0 0 8px 0;
      background-color: transparent;
      cursor: default;

      &:hover, &:active {
        background-color: transparent;
      }

      svg {
        margin-right: 8px;
      }

      .content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $rem12px;
        background-color: $bl-grayscale-1000;
        cursor: pointer;

        label {
          color: $bl-grayscale-300;
          cursor: pointer;
        }

        &:hover, &:active, &:disabled {
          background-color: lighten($bl-grayscale-1000, 1%);
          opacity: 1;
        }
      }
    }

    .destination-card {
      margin-bottom: 8px;
    }
  }

  h3 {
    color: $white;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  label {
    font-size: 14px;
    line-height: 16px;
    display: inline-block;

    &.section-title {
      text-transform: capitalize;
      font-weight: bold;
    }

    &.subsection-title {
      font-weight: normal;

      &.spacing {
        margin-top: 16px;
      }
    }
  }

  .tab-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    margin: 0;
    position: relative;
    padding: 16px 0;
  }

  .fixed-spacing-label {
    color: $red;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .row-inline {
    width: 100%;
    display: grid;
    height: 30px;
    grid-template-columns: 1fr 1fr;

    label {
      margin-bottom: initial;
      font-weight: normal;
    }
  }

  &.spacing-container {
    padding-top: 8px;

    .settings-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 16px;

      .spacing {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .label-wrapper {
          display: flex;
          align-items: center;

          svg {
            width: 16px;
            margin-right: 8px;
          }

          label {
            font-size: 14px;
            color: $text-gray;
            font-weight: normal;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.color-container {
    .background-actions {
      position: relative;
      .background-option {
        display: flex;
        margin-bottom: 16px;

        label {
          flex: 1;
          display: flex;
          align-items: center;

          button {
            display: flex;
            align-items: center;

            padding: 0;
            margin: 0;
            background: none;
            border: none;
            outline: none;
            box-shadow: none;
            margin-left: 8px;
          }
        }

        .background-popover {
          padding: 20px;
          z-index: 2;
          background-color: $border-dark-gray;
          border-radius: 24px;
          max-width: 250px;
        }
      }
    }
  }

  .color-toggle {
    @include button-greenroom-basic;
    display: flex;
    background-color: $dark-gray;
    border: $rem1px solid $border-dark-gray;
    align-items: center;
    height: 32px;
    border-radius: 15px;
    cursor: pointer;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

    span {
      display: block;
      margin: 0 12px 0 3px;
      width: 66px;
      white-space: nowrap;
      max-width: 66px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .color-swatch {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darker-gray;
    }
  }

  &.presenter-video-border-container {
    padding: 0 0 16px 0;
    border-bottom: none;

    .border-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-top: 16px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .select-small {
        .select-label {
          padding: 11px 10px 10px 16px !important;
        }
      }

      .color-group {
        display: flex;

        .select-small {
          height: 32px;

          .select-label {
            @include button-greenroom-basic;
            padding: 7px 20px 7px 20px !important;
            border-radius: 0;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            min-width: initial;
          }
        }

        .color-toggle {
          border-radius: 0;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-right-width: 0;
        }
      }

      .border-size {
        margin-right: 8px;
        margin-left: auto;
      }
    }
  }

  &.presenter-video-radius-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 8px 0;
    border-bottom: none;

    .border-radius-options {
      white-space: nowrap;
    }
  }

  &.animation-container {
    padding-bottom: 50px;
  }

  &.titles-container,
  &.animation-container {
    padding-top: 0;
  }

  &.titles-container,  &.animation-container {

    .tab-row {
      margin-bottom: 16px;
    }

    .titles-options {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .color-picker-container {
        width: 80%;
        margin: 0 40px;
        overflow-y: hidden;
      }
    }

    .title-checkboxes {
      display: flex;
      padding: 16px 0;
    }

    .select-full {
      .select-small {
        width: 100%;
      }
    }

    .titles-row, .animation-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .titles-row-group {
        font-weight: normal;
      }

      .select-small {
        .select-label {
          justify-content: space-between;
          border: 1px solid $element-border-gray;
          background: $background-gray;
          padding: 10px 15px !important;
        }

        .select-option {
          label {
            line-height: 20px;
            margin-left: 0px;
            color: #ffffff;
          }
        }
      }
    }

    .animation-row {
      color: $text-gray;
      margin-bottom: 8px;
      .background-scale {
        padding: 0 !important;
        position: initial !important;
        top: 0 !important;
      }
      input {
        margin: 0 0 0 8px;
      }
    }

    hr {
      margin: 16px auto 24px auto;
      background-color: $border-gray;
    }

    .card-scale {
      display: flex;
      align-items: center;
      padding-top: 22px;

      label {
        font-weight: normal;
      }

      input {
        flex: 1;
        margin: 0 16px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        background: $med-gray;
        height: 3px;
        border-radius: 30px;

        &::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          width: 24px;
          height: 24px;
          background: $near-white;
          border-radius: 100%;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 24px;
          height: 24px;
          background: $near-white;
          border-radius: 100%;
          cursor: pointer;
        }
      }

      span {
        display: block;
        padding: 10px 19px;
        border: 1px solid $border-gray;
        border-radius: 100px;
      }
    }

    .corner-markers {
      display: flex;
      border-radius: 8px;
      border: 1px solid $border-gray;
      width: 144px;
      height: 36px;
      overflow: hidden;

      .corner-marker {
        @include button-greenroom-basic;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 3px 5px;
        font-size: 11px;
        color: $text-gray;
        cursor: pointer;
        transition: 150ms background-color, 150ms color;

        &.active {
          background-color: $white;
          color: $dark-gray;
        }

        &.bottom-left {
          transform: rotate(90deg);
          border-top: 1px solid $border-gray;
          padding: 3px 7px;
        }

        &.top-left {
          transform: rotate(180deg);
          border-left: 1px solid $border-gray;
        }

        &.top-right {
          transform: rotate(270deg);
          border-bottom: 1px solid $border-gray;
        }
      }
    }
  }

  &.event-settings-container {
    padding: 8px 24px;
    .tab-row {
      display: flex;
      margin-bottom: 0;
      height: initial;
      justify-content: space-between;

      &.schedule {
        display: block;
        padding: 0;
      }

      &.ask-to-join {
        label {
          max-width: 320px;
        }
      }

      .presenters-text {
        color: $text-gray;
        font-weight: 600;
      }

      &.no-border {
        border-bottom: none;
      }
    }

    .greenroom-settings {
      display: flex;
      align-items: center;

      .header {
        width: 100%;
      }

      .accordion-indicator {
        margin-right: 8px;
      }

      .select-small {
        width: 282px;

        .select-label {
          justify-content: space-between;
          border: 1px solid $element-border-gray;
          background: $background-gray;
          padding: 10px 15px !important;
        }

        .select-option {
          label {
            line-height: 20px;
            margin-left: 0px;
            color: #ffffff;
          }
        }
      }
    }

    .greenroom-settings {
      .select-small {
        width: 229px;

        .select-label-text {
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .invite-badge {
      display: flex;
      padding: 0;
      background-color: $border-gray;
      color: $text-light;
      align-items: center;
      border-radius: 100px;
      height: 28px;
      transition: background-color 150ms;
      cursor: pointer;

      span {
        padding-left: 9px;
        padding-right: 9px;
        font-size: 12px;
        font-weight: bold;
        color: $text-light;
      }

      .invite-badge-x-button {
        line-height: 0;
        cursor: pointer;
      }
    }

    .invite-container {
      border-bottom: 1px solid $border-gray;
      .invite-row {
        display: flex;
        align-items: center;
        margin: 0 20px;
        padding: 20px 20px;
        border-top: 1px solid $border-gray;
        min-height: 40px;

        .invite-row-info {
          margin-left: 8px;
        }

        .uninvite-button {
          margin-left: auto;
          line-height: 0;
          cursor: pointer;
        }
      }
    }

    
  }
}

.preview-button {
  cursor: pointer;
}

.preview-button-text {
  color: #A6FF79;
  font-weight: bold;
  margin-right: 0.4rem;
}

.directional-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  cursor: pointer;

  .number {
    @include button-greenroom-basic;
    background-color: $dark-gray;
    border: $rem1px solid $border-gray;
    color: $text-gray;
    text-align: center;
    height: 100%;
    width: 100%;
    padding: 8px;

    &.active {
      color: black;
      background-color: $white;
    }

    &.first {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.last {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .animation-slider { width: 120px; }
  .animation-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 1799px) {
  .animation-slider { width: 100px; }
  .animation-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 1599px) {
  .animation-slider { width: 90px; }
  .animation-container {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 1399px) {
  .animation-slider { width: 80px; }
  .animation-container {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 1199px) {
  .animation-slider { width: 70px; }
  .animation-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 999px) {
  .animation-slider { width: 60px; }
  .animation-container {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media screen and (max-width: 899px) {
  .animation-slider { width: 50px; }
  .animation-container {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.format-hex{
  text-transform: uppercase;
}