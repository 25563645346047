.show-details-wrapper {
	display: flex;
	flex-direction: column;

	.show-details-above-content {
		display: flex;
		width: 1024px;
		height: 282px;
		margin: 32px 0 40px 0;
		background: #26242B;
		border-radius: 16px;

		.showboard {
			width: 501.33px;
			height: 282px;
			border-radius: 12px;
			background-position: center;
			background-repeat: no-repeat;

			.showboard-controls {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				height: 64px;
				padding: 16px;
				position: relative;
				bottom: 64px;
				z-index: $z-index-shot-thumbnail-overlay;

				.showboard-buttons {
					display: flex;

					button {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 32px;
						height: 32px;
						background: #4F4C57;
						border-radius: 100px;
						padding: 0;
					}

					.current-shot-container {
						display: flex;
						align-items: center;
						margin-left: 16px;
						margin-right: 16px;
						color: $light-gray;
						white-space: nowrap;

						.current-shot {
							color: $near-white;
						}
					}
				}
			}

			.shot-thumbnail-wrapper {
				position: relative;
				width: 100%;
				height: 100%;

				.shot-thumbnail {
					width: 100%;
					height: 100%;
					
					.shot-thumbnail-inside {
						width: 100%;
						height: 100%;
						border-radius: 12px;
						overflow: hidden;

						.shot-thumbnail-overlay {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							align-items: center;
							justify-content: center;
							border-radius: 8px;
							background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(9, 9, 10, 0.2) 0.01%, rgba(9, 9, 10, 0.7) 100%);
						}

						.custom-graphics-canvas-container {
							.custom-graphics-canvas {
								border-radius: $rem8px;
							}
						}
					}

					.main-video {
						position: relative;
						border-radius: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-clip: padding-box;

						&.layer2 {
							position: absolute;
						}

						.video-container-presenter {
							display: flex;
							justify-content: center;
							align-items: center;

							.empty-video {
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: #26242B;
								height: 100%;
								width: 100%;

								.placeholder-icon {
									@include flex-center;
									max-width: 100%;
									min-width: 100%;
									padding-left: 35%;
									padding-right: 35%;
								}
							}

							.poster {
								.no-image-placeholder {
									span {
										font-size: calc(48px * .7);
										height: 70px;
										width: 70px;
									}
								}
							}

							.title-card {
								margin-bottom: calc(8px * .7);
							}
						}
					}

					&.selected {
						border-color: $pale-green;
					}

					&:hover {
						.shot-thumbnail-overlay {
							display: flex;
							transition: opacity 300ms;
							opacity: 1;
						}
					}
				}
			}

			.loading-container {
				@include flex-center;
				width: 100%;
				height: 100%;
			}
		}

		.card{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin: 24px 0 28px 0;
			padding: 0 40px;
			max-height: 230px;
			width: 522px;

			h2 {
				color: $white;
				font-weight: 700;
				font-size: 26px;
				line-height: 32px;
				margin: 16px 0 8px 0;
				padding: 0;
				width: 100%;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
			}

			h3 {
				color: $text-light;
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				margin: 0;
				padding: 0;
				width: 100%;
				text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
			}

			.card-badges {
				.countdown-badge {
					border-radius: 20px;
					padding: 4px 8px 4px 8px;
					background: rgba(38, 36, 43, 0.7);
				}
			}

			.card-bottom {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 206px;

				.card-bottom-meta {
					h2 {
						@include line-clamp(3);

						max-height: 96px;
					}
				}

				.card-bottom-row {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;

					.card-buttons {
						display: flex;
						align-items: center;

						.join-button, .edit-button {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							height: 48px;
							border-radius: 100px;
							padding: 18px 24px;
							margin: 0 8px 0 0;
							background-color: $button-gray;
							transition: 150ms color, 150ms background-color, 60ms transform;
							@include button-greenroom-shadow;

							span {
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 16px;
								display: flex;
								align-items: center;
								text-align: center;
								white-space: nowrap;
							}

							&:active {
								transform: scale(0.95);
							}

							&:not(.presenter) {
								background-color: $pale-green;
								color: #121114;
								cursor: pointer;
							}

							&.presenter {
								background-color: $button-gray;
								color: $text-light;
								cursor: default;
							}

							&.is-live {
								background-color: $pale-green;
								color: #121114;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}

	.show-details-below-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 1024px;

		.show-details-menu {
			display: flex;
			width: 192px;
			height: 100%;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			gap: 4px;

			button {
				width: 192px;
				height: 40px;
				display: flex;
				align-items: center;
				transition: 150ms background, 150ms color;

				&.selected {
					background: #26242B;
					color: $near-white;
				}
			}

			svg {
				margin-right: 8px;
				transition: 150ms background, 150ms color;
			}

			span {
				font-weight: 500;
			}
		}

		.show-details-section-container {
			width: 784px;

			.section-header {
				display: flex;
				justify-content: space-between;
				margin-bottom: 16px;

				&.rehearsals {
					margin-top: 24px;
				}

				&.advanced {
					margin-top: 20px;
				}

				label {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 20px;
					display: flex;
					align-items: center;
					color: $bl-grayscale-400;
				}

				.add-destination {
					background: linear-gradient(90deg, #FFFD62 0%, #FF6AE6 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					cursor: pointer;
				}

				.section-buttons {
					display: flex;
					justify-content: flex-end;
					
					.popover {
						top: 32px;

						&.destination-options {
							min-width: 212px;

							.option {
								padding: 12px 16px;

								span {
									font-weight: 500;
								}
							}
						}
					}
				}
			}

			.show-details-info {
				.loading-container {
					display: flex;
					align-items: center;
					min-height: 286px;
				}

				.presenters-list {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-template-rows: 1fr;
					margin: 0px 1px;

					.presenter {
						position: relative;
						display: flex;
						align-items: center;
						width: 250px;
						height: 72px;
						padding: 8px 16px;
						margin: 0 16px 16px 0;
						background: #26242B;
						border-radius: 12px;

						.avatar {
							border: 1px solid $border-dark-gray;
						}

						.online {
							position: absolute;
							top: 46px;
							left: 62px;
							width: 8px;
							height: 8px;
							border-radius: 100%;
							background: $ready-green-highlight;
							border: 1px solid #26242B;
						}

						.presenter-meta {
							width: 122px;
							display: flex;
							flex-direction: column;
							flex: 1;
							margin: 0 12px;

							label {
								font-size: 14px;
								font-weight: 500;
								line-height: 20px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							span {
								color: $light-gray;
								font-size: 12px;
								font-style: normal;
								font-weight: normal;
								line-height: 14px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}

						.x-button {
							background: none;
							border: none;
							margin: 0;
							padding: 0;
							margin-left: 8px;
						}
					}

					.presenter:nth-child(3n) {
						margin-right: 0;
					}
				}

				.no-results {
					text-align: center;
					color: $text-light;
					margin: 50px 0;
				}

				.show-more {
					display: flex;
					justify-content: center;
					padding-bottom: 16px;

					button {
						display: flex;
						justify-content: center;
						align-items: center;
						background: none;
						padding: 0;

						span {
							color: $pale-green;
							font-size: 14px;
							line-height: 16px;
							font-weight: 500;
							border: none;
							outline: none;
							margin-right: 10px;
						}
					}
				}

				.rehearsals-list {
					margin-bottom: 40px;

					.event-row {
						cursor: pointer;
						display: grid;
						grid-template-columns: 130px 425px 1fr;
						grid-template-rows: 1fr;
						background: #26242B;
						padding: 16px 0;
						margin-bottom: 8px;
						border-radius: 16px;

						.event-time {
							text-align: right;
							padding: 11px 23px;
							border-right: 1px solid #4F4C57;

							span {
								display: inline-block;
								text-transform: capitalize;
							}

							.timestamp {
								font-size: 16px;
								font-weight: bold;
								line-height: 19px;
								color: $near-white;
							}

							.day {
								font-size: 14px;
								color: $text-gray;
								font-weight: 500;
								white-space: nowrap;
							}
						}

						.event-titles {
							display: flex;
							flex-direction: column;
							justify-content: center;
							padding: 2px 0;
							margin-left: 24px;

							h4 {
								padding: 0 24px 0 0;
								margin: 0 0 8px 0;
								font-weight: bold;
								font-size: 17px;
								line-height: 19px;
								color: $near-white;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}

						.presenters-actions {
							display: flex;
							justify-content: center;
							border-left: 1px solid transparent;

							&.show-border {
								border-left: 1px solid #4f4c57;
							}

							.awaiting-producer-container {
								padding: 0px 30px;
								@include flex-center;

								.awaiting-producer {
									display: flex;
									align-items: center;
									justify-content: center;
									background: $dark-gray;
									border-radius: 100px;
									padding: 16px;
									font-size: 14px;
									font-weight: 500;
									white-space: nowrap;
								}

								svg {
									margin-left: 6px;
								}
							}

							.avatar-bubble-row {
								flex: 1;
								display: flex;
								margin-left: 23px;
								position: relative;
								pointer-events: none;
							}

							.event-actions {
								flex: 1;
								display: flex;
								flex-direction: column;
								align-items: flex-end;
								padding-right: 22px;
								position: relative;

								.details-button {
									position: absolute;
									bottom: 0;
									padding: 0;

									&.disabled {
										pointer-events: none;
									}
								}

								.popover {
									top: 16px;
									right: 16px;
								}
							}
						}
					}
				}
			}

			.show-details-settings {
				.streaming-container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.main-destination {
						.destination-header {
							display: flex;
							justify-content: space-between;
							align-items: center;
							border-bottom: 1px solid #3C3A42;
							padding: 16px 24px 12px 24px;
					
							.destination-label {
								display: flex;
								align-items: center;
								font-weight: 500;
								font-size: 16px;
								line-height: 22px;
								color: $white;
								white-space: nowrap;
								overflow: hidden;
					
								img {
									width: 32px;
									height: 32px;
									border-radius: 8px;
									margin-right: 16px;
					
									&.logo {
										width: 100%;
										height: 24px;
									}
								}
					
								label {
									padding-right: 16px;
									overflow: hidden;
									text-overflow: ellipsis;
					
									&.logo-text {
										display: none;
									}
								}
							}
					
							.destination-controls {
								display: flex;
								align-items: center;

								.switch-wrapper {
									transition: opacity $transition-med;
								}
							}
						}
					}

					.main-destination {
						width: 784px;
						height: 217px;
						margin-bottom: 16px;
						background: #26242B;
						border-radius: 8px;

						&.disabled {
							pointer-events: none;
							opacity: 0.5;
						}

						.main-destination-meta {
							display: flex;
							width: 736px;
							height: 112px;
							margin: 21px 24px 24px 24px;
							background: $border-gray;
							border-radius: 12px;

							.main-destination-image {
								width: 200px;
								height: 112px;
								border-radius: 12px;
								background-position: center;
								background-repeat: no-repeat;
							}

							.main-destination-info {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								width: 338px;
								padding: 16px 0 16px 24px;

								label {
									font-weight: normal;
									font-size: 14px;
									line-height: 20px;
									margin-bottom: 4px;
									color: #BFBBCF;
									width: 100%;
									@include truncate();
								}

								span {
									font-weight: bold;
									font-size: 16px;
									line-height: 20px;
									margin-bottom: 12px;
									color: #FCFCFC;
									width: 100%;
									@include truncate();
								}

								.destination-status {
									display: flex;
									justify-content: center;
									align-items: center;
									height: 24px;
									padding: 0 8px;
									background: #3C3A42;
									border-radius: 24px;

									label {
										margin-bottom: 0;
									}

									svg {
										margin-right: 8.5px;
									}
								}
							}
						}
					}

					.custom-destinations {
						display: grid;
						grid-template-columns: 1fr 1fr;

						.destination-card {
							width: 382px;
							height: 192px;
							margin: 0 20px 16px 0;
							background: #26242B;

							.destination-card-meta {
								padding: 12px 24px 8px 24px;
								white-space: nowrap;

								label {
									display: flex;
									align-items: center;
									margin-bottom: 4px;
									font-weight: 500;
									font-size: 14px;
									line-height: 16px;
									letter-spacing: 0.4px;
									color: #BFBBCF;
								}

								span {
									display: flex;
									align-items: center;
									margin-bottom: 16px;
									font-weight: normal;
									font-size: 14px;
									line-height: 20px;
									color: $white;
									overflow: hidden;
								}
							}

							.rtmp-input {
								width: 234px;
							}
						}

						.destination-card:nth-child(2n) {
							margin-right: 0;
						}
					}

					.input-container {
						display: flex;
						flex-direction: column;
						justify-content: start;
						margin-bottom: 16px;

						&.main {
							padding: 40px 24px 16px 24px;
							margin-bottom: 0;

							.select-small {
								width: 150px !important;

								.select-body {
									min-width: 150px !important;
								}
							}
						}

						.input-header {
							margin: 0 0 8px 16px;

							span {
								font-weight: bold;
								font-size: 12px;
								line-height: 16px;
								display: flex;
								align-items: flex-end;
								letter-spacing: 0.4px;
								color: $text-gray;
							}
						}

						.input-body {
							display: flex;

							.select-small {
								width: 182px;

								.select-label {
									justify-content: flex-start;
									height: 32px;
									background: #1A1A1F;
									border: 1px solid #54545A;
									box-sizing: border-box;
									border-radius: 100px;
									font-size: 14px;
									line-height: 20px;
									padding-left: 16px !important;
									color: #FFFFFF;
									margin: 0;
								}

								.select-option {
									height: 32px;
									padding: 16px 17px;
								}

								.select-body {
									min-width: 182px;
								}
							}

							input {
								display: flex;
								align-items: center;
								height: 32px;
								width: 560px;
								padding: 0 16px !important;
								background: #1A1A1F !important;
								border: 1px solid #4F4C57 !important;
								box-sizing: border-box;
								border-radius: 100px;
								margin: 0;
								background: none;
								border: none;
								padding: 8px;
								color: $near-white;
							}

							.copy-button {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 32px;
								height: 32px;
								margin-left: 8px;
								padding: 0;
								background: #4F4C57;
								border-radius: 100px;
								transition: 150ms color, 150ms background-color, 60ms transform;
								@include button-greenroom-shadow;

								&:active {
									transform: scale(0.95);
								}

								svg {
									margin: 0 9px;
								}
							}
						}
					}
				}

				.advanced-settings-container {
					border-radius: 12px;
					margin-bottom: 40px;

					.inner-container {
						border-radius: 12px;
					}

					.select-small {
						width: 340px;

						.select-label {
							justify-content: space-between;
							border: 1px solid #BFBBCF;
							padding: 10px 15px !important;
						}

						.select-option {
							label {
								line-height: 20px;
								margin-left: 0px;
								color: #FFFFFF;
							}
						}
					}
				}
			}
		}
	}
}