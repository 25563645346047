@keyframes fadeIn {
	from {
		transform: translateY(-10px);
		visibility: hidden;
	}
	
	to {
		transform: translateY(0);
		visibility: visible;
	}
}


@keyframes fadeOut {
	from {
		transform: translateY(0);
		visibility: visible;
	}
	
	to {
		transform: translateY(-4px);
		visibility: hidden;
	}
}

.calendar-producer-only-bottom-spacer {
	min-height: 32px;
}

#fc-calendar-plugin {
	@include custom-scrollbar;
	width: 100%;
	height: 100%;
	padding-bottom: 36px;

	max-height: calc(100vh - 72px - 38px - 48px - 14px); //100 - header - select content header - sliding menu - buffer
	scrollbar-gutter: stable;
	
	.fc-daygrid-day-number { //day numbers month view
		color: #908CA0;
		font-weight: bold;
	}

	.fc-col-header-cell-cushion { //weekday titles month view
		color: #FFFFFF;
	}

	.fc-col-header-cell { //weekday title cells
		border: none;
		padding: 16px;
	}

	.fc-theme-standard td, .fc-theme-standard th { //borders on boxes
		border-bottom: none;
		border-right: none;
		border-color: $border-gray;
	}

	.fc .fc-timegrid-slot {
		height: 60px;
	}

	&.timeGridWeek, &.timeGridDay {
		.fc-theme-standard td, .fc-theme-standard th { 
			border-top: none;

			&.fc-day-mon {
				border: none;
			}
		}
	}

	&.timeGridDay {
		.fc-theme-standard td, .fc-theme-standard th { 
			border: none;
		}

		.fc-timegrid-event {
			pointer-events: none;
		}

		.fc-day-today {
			.fc-scrollgrid-sync-inner {
				display: none; //hides the day of the week in day view
			}
		}
	}

	.fc-daygrid-more-link {
		color: $text-light;
		font-weight: bold;
		font-size: 12px;
		padding-left: 4px;
	}

	.fc-popover {
		display: none;
		border-radius: 8px;
		background: #121114;
		border: none;
		
		.fc-popover-header {
			border-radius: 8px;
			background-color: #121114;
			padding-top: 12px;
			padding-left: 12px;
			color: $text-light;
			font-weight: bold;
		}
		
		.fc-popover-close {
			display: none;
		}
	}

	.fc-theme-standard .fc-scrollgrid, .fc-h-event, .fc-timegrid-slot{ //borders on the whole cal
		border: none;
	}

	.fc-daygrid-day-frame { //day cells month view
		min-height: 125px;
	}

	.fc-h-event, .fc-timegrid-event, .fc-v-event { //multi day event
		border: none;
		background-color: transparent;
	}

	.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror {
		box-shadow: none;
	}

	.fc-event-main {
		height: 100%;
		width: 100%;
	}

	.fc-timegrid-col {
		overflow: hidden;
	}

	.fc-daygrid-event {
		background: transparent;
	}

	.fc-timegrid-now-indicator-line {
		border-color: #A6FF79;
	}

	.fc-timegrid-now-indicator-arrow {
		display: none;
	}

	.fc-timegrid-slot-label-cushion, .fc-col-header-cell-cushion  {
		text-transform: capitalize;
		color: #908CA0;
	}
	
	.fc-day-today {
		background-color: transparent;

		.fc-daygrid-day-number {
			background-color: #A6FF79;
			color: black;
			border-radius: 80px;
			min-height: 24px;
			min-width: 24px;
			text-align: center;
			margin-right: 4px;
			margin-top: 4px;
			line-height: $rem16px;
		}
	}

	.event-container { //custom html defined in calendar.tsx
		background-color: #26242B;
		color: #BFBBCF;
		padding: 8px;
		clip-path: inset(0 0 0 0 round 8px);
		margin: 0 8px;
		
		.fc-cus-time {
			white-space: pre;
			overflow: hidden;
			text-overflow: none;
			margin-right: 8px;
		}
		
		.fc-cus-title {
			@include line-clamp(2);

			white-space:pre-wrap;
			text-overflow: clip;
			line-height: 1.2em;
			max-height: 2.4em;
		}

		&.month-view {
			white-space: nowrap;
  			overflow: hidden;
			text-overflow: ellipsis;
			min-width: calc(100% - 16px);
		}

		&.week-view {
			height: 100%;
			.fc-cus-time {
				font-size: 10px;
				max-width: 100%;
				margin: 0;
			}
			
			.fc-cus-title {
				font-size: 12px;
				font-weight: bold;
			}

			&.half-card {
				.fc-cus-title {
					height: 100%;
					max-height: 100%;
				}
			}
		}

		&.day-view {
			height: 100%;
			padding: 14px 6px 14px 14px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			clip-path: inset(0 0 0 0 round 16px);
			pointer-events: none;

			.fc-cus-interactive {
				display: flex;
				flex: 1;

				.fc-cus-time {
					flex: 1;
					font-size: 14px;
					max-width: 100%;
				}

				.fc-cus-day-menu {
					pointer-events: all;
					margin-right: 16px;

					.edit-ellipses-menu {
						margin-top: -25px;
						margin-right: 60px;
					}
				}
			}

			.fc-cus-title {
				font-size: 16px;
				font-weight: bold;
			}

			.fc-cus-day-bottom {
				display: flex;
				align-items: center;
				margin-top: 8px;
			}

			.fc-cus-day-end {
				position: relative;
				top: 16px;
				right: 10px;
				height: 100%;
				width: 38px;
				pointer-events: all;
			}

			.fc-cus-day-bottom-divider {
				margin: 0 16px;
			}
			
			.fc-cus-day-bottom-arrow {
				line-height: 0;
				margin-right: 16px;
				margin-left: auto;
				cursor: pointer;
				pointer-events: all;
			}

			&.half-card {
				flex-direction: row;
				align-items: center;

				.fc-cus-time-title-container {
					max-width: 80%;
					width: 80%;

					&.single-row {
						display: flex;
						align-items: center;
					}

					.fc-cus-title {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}


		.event-color-indicator {
			min-height: 100%;
			min-width: 2px;
			position: absolute;
			left: 9px;
			top: 0;

			&.green {
				background-color: #A6FF79;
			}

			&.gray {
				background-color: #979797;
			}

			&.orange {
				background-color: #CE810A;
			}

			&.purple {
				background-color: #763A95;
			}
		}
	}
}

.fc-custom-month-holder::-webkit-scrollbar {
	  display: none;
}

.fc-custom-month-holder {
	width: 100%;
	display: flex;
	overflow-x: scroll;
	white-space: nowrap;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	position: relative;

	.fc-custom-month-block {
		padding: 16px;
		font-weight: bold;
		color: #908CA0;
		min-width: 100px;
		text-align: center;
		
		&.selected {
			color: #FFFFFF;
		}
	}
}

.month-block-underline {
	height: 2px;
	background: #A6FF79;
	min-width: 60px;
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0 20px;
	transition: left 0.4s ease-out;
}

.month-popover-modal {
	position: fixed;
	background: #26242B;
	z-index: 10;
	border-radius: 16px;
	box-shadow: 2px 1px 3px rgba(0,0,0,0.2);
	min-height: 345px;
	min-width: 400px;
	max-width: 30%;

	transition: opacity 150ms ease;
	opacity: 0;
	animation: 150ms fadeOut;
	animation-fill-mode: forwards;
	&.open {
		animation: 150ms fadeIn;
		animation-fill-mode: forwards;
		opacity: 1;
	}
	
	.month-popover-modal-bottom {
		padding: 32px 24px;
	}

	.month-popover-modal-image {
		min-height: 170px;
		width: 100%;
		min-width: 250px;
		border-radius: 16px;
		background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
	}

	.month-popover-modal-top {
		position: absolute;
		top: 16px;
		width: 100%;
		padding: 0 16px;
		display: flex;
		justify-content: space-between;

		.card-presenters {
			display: flex;
			align-items: center;

			.edit-ellipses-container {
				padding: 10px 0 10px 10px;
			}
		}
	}

	.month-popover-modal-time {
		font-size: 16px;
		color: $text-light
	}

	.month-popover-modal-info {
		font-size: 24px;
		font-weight: bold;
		margin-top: 8px;
	}

	.month-popover-modal-buttons {
		display: flex;
		margin-top: 16px;
		.month-popover-button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			//height: 48px;
			border-radius: 100px;
			padding: 12px 24px;
			margin: 0 8px 0 0;
			background-color: $button-gray;
			transition: 150ms color, 150ms background-color, 60ms transform;
			cursor: pointer;
			@include button-greenroom-shadow;
			&:active {
				transform: scale(0.95);
			}

			span {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				display: flex;
				align-items: center;
				text-align: center;
				white-space: nowrap;
			}

			&.join {
				background-color: $pale-green;
				color: #121114;
			}
			
			&.setup {
				background-color: #4F4C57;
				color: $near-white;
			}
		}
	}
}

.month-extra-events-modal {
	position: absolute;
	background: #121114;
	z-index: 10;
	border-radius: 16px;
	box-shadow: 2px 1px 3px rgba(0,0,0,0.2);
	min-width: 400px;
	max-width: 30%;
	transition: opacity 150ms ease;
	opacity: 0;
	animation: 150ms fadeOut;
	animation-fill-mode: forwards;
	
	&.open {
		animation: 150ms fadeIn;
		animation-fill-mode: forwards;
		opacity: 1;
	}

	.extra-ev-modal-day {
		font-weight: bold;
		color: $near-white;
		font-size: 14px;
		padding: 16px 24px 0 24px;
	}
	
	.month-extra-events-modal-event-row {
		padding: 8px;
		background: #26242B;
		margin: 16px;
		font-weight: bold;
		color: $near-white;
		clip-path: inset(0 0 0 0 round 8px);
		display: flex;
		position: relative;

		.event-color-indicator {
			min-height: 100%;
			min-width: 3px;
			position: absolute;
			left: 0px;
			top: 0;
			&.green{background-color: #A6FF79;}
			&.gray{background-color: #979797;}
			&.orange{background-color: #CE810A;}
		}

		.extra-ev-date {
			font-weight: normal;
			color: $text-light
		}
	}
}