

.notification-bell {
	background: none;
	border: none;
	display: flex;
	justify-content: center;
	padding: 2px;
	margin-right: 40px;
	position: relative;

	&::after {
		content: "";
		height: 3px;
		width: 0px;
		background-color: $color-green;
		border-radius: 3px;
		position: absolute;
		transition: 150ms width, 150ms color;
		bottom: -28.5px;
		z-index: 10;
		transition-timing-function: cubic-bezier(0, 0.51, 0.28, 1.35);
	}

	&.active, &:hover {
		&::after {
			width: 20px;
		}
	}

	&.active {
		color: $near-white;
	}

	.new-notification-dot {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		background: #EC0C00;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.home-notification-center {
	position: absolute;
	width: 400px;
	top: 60px;
	right: 32px;
	background-color: $border-gray;
	z-index: $z-index-notification-drawer;
	border-radius: 16px;
	overflow-y: hidden;
	overflow-x: hidden;
	color: $text-light;

	
	.home-notification-body {
		height: 100%;
		scrollbar-gutter: stable;
		overflow-x: hidden;
		//padding: 16px 24px 40px 24px;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		max-height: 550px;

		.home-notification-header {
			padding-bottom: 8px;
			//border-bottom: 1px solid $divider;
			position: sticky;
			top: 0;
			background-color: $border-gray;
			height: 48px;
			//padding: 20px;
			position: sticky;
			top: 0px;
			margin-top: -8px;
			z-index: 103;
			display: flex;
			align-items: flex-end;

			span {
				padding-left: 32px;
				font-weight: 700;
				font-size: 16px;
			}
		}

		.home-notification-header-container {
			height: 56px; /* 48 + 8 */
			position: sticky;
			top: -8px; 
			z-index: 101;
		}

		.home-notification-header-container::before,
		.home-notification-header-container::after {
			content: '';
			display: block;
			height: 8px;
			position: sticky;
		}
			
		.home-notification-header-container::before {
			top: 40px; /* 48 - 8 */
			box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
		}
			
		.home-notification-header-container::after {
			background: linear-gradient($border-gray, $border-gray);
			top: 0;
			z-index: 102;
		}
		
		.see-more {
			padding-bottom: 60px;
			padding-top: 16px;
		}

		.telly-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 8px;
			margin-bottom: 16px;
			position: relative;
			border-bottom: 1px solid $divider;
			padding: 0 24px;

			img {display: block;}
		}
	}

	.home-notification-body::-webkit-scrollbar {
 		display: none;
	}

	.notification-row {
		margin: 0 12px;
		padding: 12px 12px;
		border-radius: 8px;
		background-color: $border-gray;
		&:hover {filter: brightness(110%);}
		&:active { filter: brightness(90%);}

		.home-notification-image {
			margin-right: 16px;
			position: relative;

			.home-notification-avatar {
				min-width: 48px;
				max-width: 48px;
				min-height: 48px;
				max-height: 48px;
				object-fit: cover;
				
				&.circle { border-radius: 99px;}
				&.square {border-radius: 8px;}
				&.telly {
					object-position: 95% 50%;
					-moz-transform: scaleX(-1);
					-o-transform: scaleX(-1);
					-webkit-transform: scaleX(-1);
					transform: scaleX(-1);
				}
				
			}

			.home-notification-icon-bubble {
				min-width: 20px;
				max-width: 20px;
				min-height: 20px;
				max-height: 20px;

				position: absolute;
				top: 34px;
				left: 34px;

				background: black;
				border-radius: 99px;

				display: flex;
				align-items: center;
				justify-content: center;

				&.blue {background: #3B9DFF;}
				&.red {background: #EC0C00;}
				&.orange {background: #FF9F0A;}
				&.green {background: #A6FF79;}
				&.gray {background: #625E70;}
			}
		}

		.home-notification-right {
			.home-notification-message{
				font-size: 14px;
				color: #FFFFFF;
				.bold {font-weight: bold;}
				p {
					line-height: normal;
					margin: 0;
				}
			}
			
			.home-notification-timing{
				padding-top: 4px;
				font-size: 12px;
				color: #BFBBCF;
			}
		}
	}
}

.home-notification-background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 1;
	animation: 250ms bgFadeIn;
	animation-fill-mode: forwards;
	background: rgba(21, 21, 22, 0.7);
	z-index: $z-index-notification-drawer;
}