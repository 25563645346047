.hidden-feed-indicator {
    display: flex;
    align-items: center;
    gap: 3px;
    overflow: hidden;

    .hidden-feed-indicator__image {
        display: flex;
        align-items: center;
        height: 24px;
        width: 24px;
        margin-right: 4px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .hidden-feed-indicator__title {
        font-size: 0.85rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
