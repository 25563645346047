.title-card {
	//border: 1px solid red;
	bottom: 0;
	left: 0;
	margin: 8px;

	svg {
		display: block;
	}

	&.max-width {
		min-width: calc(100% - 16px); //for titles that aren't positionable in corners
	}

	&.no-left-margin {
		margin-left: 0;
	}

	&.static-preview {
		position: static;
		min-width: calc(100% - 32px);
	}
}

.card-3 {
	height: 42px;
	display: flex;
	height: initial;
	display: block;

	.user-data {
		justify-content: center;
		display: flex;
		flex-direction: column;
		font-weight: 500;
		-webkit-filter: drop-shadow(0.5px 0.5px 2px rgba(0,0,0,0.7));
		filter: drop-shadow(0.5px 0.5px 2px rgba(0,0,0,0.7));

		.job-title, .location {
			font-size: 11px;
		}
	}
}


.titles-dropdown-wrapper {
	position: relative;
	min-width: 100%;
	margin-bottom: 24px;

	.titles-dropdown {
		@include button-greenroom-basic;
		border: $rem1px solid $border-gray;
		border-radius: $rem8px;
		background-color: $darker-gray;

		.arrow {
			margin-right: 8px;
			margin-left: auto;
		}
	}

	.titles-dropdown-active {
		min-width: 100%;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		z-index: 2;
		border: $rem1px solid $border-gray;
		border-radius: $rem8px;
		background-color: $darker-gray;
		margin-bottom: 32px;
	}

	.titles-dropdown-option {
		@include button-greenroom-basic;
		min-height: 72px;

		display: flex;
		align-items: center;
		border-top: $rem1px solid $border-dark-gray;
		background-color: $darker-gray;
		padding: 8px;

		&:first-of-type {
			border-top: 0;
			border-radius: 8px 8px 0 0;
		}

		&:last-of-type {
			border-radius: 0 0 8px 8px;
		}

		&.preview {
			border-radius: 8px;
		}
	}
}

.titles-dropdown-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
}

.title-overlay {
	@include absolute-fill;
	display: flex;
	padding: 0;

	@keyframes dissolve {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@keyframes dissolve-out {
		from { opacity: 1; }
		to { opacity: 0; }
	}

	@keyframes none-in {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@keyframes none-out {
		from { opacity: 1; }
		to { opacity: 0; }
	}

	@keyframes move-in {
		from {
			opacity: 0;
			transform: translateX(-300px);
		}

		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes move-in-right {
		from {
			opacity: 0;
			transform: translateX(300px);
		}

		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes move-out {
		from {
			transform: translateX(0);
			opacity: 1;
		}

		to {
			//max width of video element
			transform: translateX(-300px);
			opacity: 0;
		}
	}

	@keyframes move-out-right {
		from {
			transform: translateX(0);
			opacity: 1;
		}

		to {
			//max width of video element
			transform: translateX(300px);
			opacity: 0;
		}
	}

	&.dissolve {
		animation: dissolve 500ms;

		&.five {
			animation: dissolve 500ms, dissolve-out 1000ms;
			animation-delay: 0s, 5s;
			animation-fill-mode: forwards;
		}

		&.ten {
			animation: dissolve 500ms, dissolve-out 1000ms;
			animation-delay: 0s, 10s;
			animation-fill-mode: forwards;
		}
	}

	&.move-in {
		animation: move-in 500ms;
		animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);

		&.five {
			animation: move-in 500ms, move-out 1500ms;
			animation-delay: 0s, 5s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
		}

		&.ten {
			animation: move-in 500ms, move-out 1500ms;
			animation-delay: 0s, 10s;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
		}
	}

	&.none {
		animation: none-in 0ms;

		&.five {
			animation: none-in 0ms, none-out 0ms;
			animation-delay: 0s, 5s;
			animation-fill-mode: forwards;
		}

		&.ten {
			animation: none-in 0ms, none-out 0ms;
			animation-delay: 0s, 10s;
			animation-fill-mode: forwards;
		}
	}

	&.move-out {
		animation: move-out 1500ms;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
	}

	&.dissolve-out {
		animation:  dissolve-out 1000ms;
		animation-fill-mode: forwards;
	}

	&.none-out {
		animation:  none-out 0ms;
		animation-fill-mode: forwards;
	}

	&.bottom-left {
		align-items: flex-end;
	}

	&.top-right {
		justify-content: flex-end;

		&.move-in {
			animation: move-in-right 500ms;
			animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);

			&.five {
				animation: move-in-right 500ms, move-out-right 1500ms;
				animation-delay: 0s, 5s;
				animation-fill-mode: forwards;
				animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
			}

			&.ten {
				animation: move-in-right 500ms, move-out-right 1500ms;
				animation-delay: 0s, 10s;
				animation-fill-mode: forwards;
				animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
			}
		}
	}

	&.bottom-right {
		align-items: flex-end;
		justify-content: flex-end;

		&.move-in {
			animation: move-in-right 500ms;
			animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);

			&.five {
				animation: move-in-right 500ms, move-out-right 1500ms;
				animation-delay: 0s, 5s;
				animation-fill-mode: forwards;
				animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
			}

			&.ten {
				animation: move-in-right 500ms, move-out-right 1500ms;
				animation-delay: 0s, 10s;
				animation-fill-mode: forwards;
				animation-timing-function: cubic-bezier(0.01, 0.79, 0.3, 1);
			}
		}
	}
}
