//corresponding WIDTH_CONSTANT in trim-timeline.tsx
$width-contstant: 100px;
$height-constant: 56.25px;

@mixin grab-cursor {
	cursor: move;
	/* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;

	&:active {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
}

.video-editor-header {
	width: 100%;
	padding: 0 24px;
	background-color: $border-gray;

	.header-title {
		margin-right: 12px;
	}

	.header-badge {
		font-weight: 500;
		font-size: $rem12px;
		letter-spacing: $rem1px;
		text-transform: uppercase;
		white-space: nowrap;
		color: $text-light-gray;
	}
}

.video-editor-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.video-editor-body {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1 1;

		.video-editor-video-container {
			position: absolute;
			height: 100%;

			.media-video-container {
				height: 100%;

				video {
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
				}
			}
		}
	}
}

#video-editor-video-wrapper {
	flex: 1;
	position: relative;
	margin: 40px 24px 40px 24px;
}

.tt-header,
.tt-thumbnails,
.tth-media-controls,
.tth-play,
.tth-left,
.tth-center,
.tth-right {
	display: flex;
	align-items: center;
}

.tt-thumbnail,
.tth-btn {
	line-height: 0%;
}

.tth-btn {
	cursor: pointer;
}

.tt-header,
.tt-time-track {
	padding: 0 16px;
}

.tth-skip-back,
.tth-rewind {
	transform: scale(-1, 1);
}

.tth-left,
.tth-right {
	flex: 1,
}

.tt-wrap {
	background-color: $element-background-gray;
	color: $text-gray;
	position: relative;
	overflow-x: auto;
}

.tth-icon-btn {
	@include button-greenroom-basic;
}

.tt-scrollable-wrap {
	@include custom-scrollbar;
	overflow-x: auto;
	overflow-y: hidden;
	margin: 8px 0 0 8px;
	border-top: 1px solid $element-border-gray;
	max-width: calc(100% - 16px);
	scrollbar-color: $element-background-highlight-gray transparent;

	.scrollbar-divider {
		position: fixed;
		left: 0;
		height: 1px;
		width: 100%;
		background: $element-border-gray;
		z-index: $z-index-max;
	}

	&::-webkit-scrollbar {
		height: 16px;
	}

	&::-webkit-scrollbar-track {
		margin: 0 -3px;
	}

	&::-webkit-scrollbar-thumb {
		background: $element-background-highlight-gray;
		border: 4px solid transparent;
		background-clip: padding-box;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: lighten($element-background-highlight-gray, 5%);
		border: 4px solid transparent;
		background-clip: padding-box;
	}

	&::-webkit-scrollbar-thumb:active {
		background: lighten($element-background-highlight-gray, 5%);
		border: 4px solid transparent;
		background-clip: padding-box;
	}
}

.tt-header {
	justify-content: space-between;
	padding: 8px 16px 0 16px;
	position: sticky;
	top: 0;
	left: 0;

	.tth-left {
		font-weight: 600;
		font-size: $rem16px;
		color: $text-platinum;
		gap: 16px;

		.grey-divider {
			border-left: 1px solid $element-border-gray;
			height: 32px;
		}
	}

	.tth-media-controls {
		gap: 16px;
		margin: 0 16px;

		.tth-play {
			@include button-greenroom-circle;
			background-color: $element-background-highlight-gray;
			justify-content: center;
			border-radius: 100%;
			width: 48px;
			height: 48px;
		}
	}

	.tth-right {
		justify-content: flex-end;

		.tth-zoom-in {
			margin-left: 16px;
		}
	}
}

.tt-time-track {
	font-family: $font-family-roboto;
	display: flex;
	margin-bottom: 8px;
	padding: 0 16px;
	color: $element-background-subtle-text;
	
	
	.track-section {
		border-left: 1px solid $element-border-gray;
		flex: 1;
		position: relative;
		padding-left: 4px;
		padding-top: 2px;
		min-width: $width-contstant;
		max-width: $width-contstant;
		
		&:last-of-type{
			border-right: 1px solid $element-border-gray;
		}

		.mini-divider {
			position: absolute;
			border-left: 1px solid $element-border-gray;
			left: 50%;
			top: 0;
			height: 65%;
		}
	}
}

.tt-thumbnails {
	display: flex;
	margin: 0 16px 16px 16px;
	min-height: $height-constant;
	max-height: $height-constant;
	width: 100%;
	background-size: $width-contstant;
	opacity: 0.5;
	position: relative;
	border-radius: 8px;
	background-repeat: repeat-x;
}

.playhead-wrapper {
	position: absolute;
	display: flex;
	top: 0;
	width: calc(100% - 16px);
	height: 100%;
	box-sizing: border-box;
	margin-left: 16px;


	.playhead-container {
		position: absolute;
		z-index: $z-index-custom-graphics-menu;
		display: flex;
		flex-direction: column;
		align-items: center;
		pointer-events: none;

		.playhead-top {
			background-image: url("../../images/playhead-top.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			width: 19px;
			height: 16px;
		}

		.playhead-line {
			position: absolute;
			top: 14px;
			width: 3px;
			height: 100vh;
			background: $white;
		}
	}

	input[type=range] {
		-webkit-appearance: none;
		background: transparent;
		width: 100%;
		height: 24px;
		z-index: $z-index-media-container-virtuoso-scroller;

		&::-moz-focus-outer {
			border: 0;
		}

		&:focus {
			outline: 0;
		}

		&::-webkit-slider-runnable-track {
			border-radius: none;
			height: 100%;
			width: 100%;
			transition: all 0.2s ease;
			box-sizing: border-box;
		}

		&::-webkit-slider-thumb {
			border-radius: none;
			width: 0px;
			-webkit-appearance: none;
			box-sizing: border-box;
		}

		&::-moz-range-track {
			border-radius: none;
			height: 100%;
			width: 100%;
			transition: all 0.2s ease;
			box-sizing: border-box;
		}

		&::-moz-range-thumb {
			border-radius: none;
			width: 0px;
			-webkit-appearance: none;
			box-sizing: border-box;
		}

		&:disabled::-webkit-slider-thumb,
		&:disabled::-moz-range-thumb,
		&:disabled::-webkit-slider-runnable-track {
			cursor: not-allowed;
		}
	}
}

.trim-drag-box-wrap {
	width: 100%;
	height: 57px;
	position: absolute;
	left: 16px;
	top: 26.5px;
	margin: 0;
	
	.trim-drag-box {
		//box shadow border for easier calcs (no border px in calcs)
		-webkit-box-shadow: inset 0px 0px 0px 3px $pale-green;
		-moz-box-shadow: inset 0px 0px 0px 3px $pale-green;
		box-shadow: inset 0px 0px 0px 3px $pale-green;

		min-height: 100%;
		max-height: 100%;
		min-width: $width-contstant;
		width: $width-contstant;
		position: absolute;
		left: 0;
		padding: 0;
		
		.trim-drag-box-drag-area {
			@include grab-cursor();
			position: absolute;
			min-width: 100%;
			max-width: 100%;
			min-height: 100%;
			background-size: $width-contstant calc($height-constant - 2.25px);
			background-repeat: repeat-x;
			background-position-y: 2px;
		}
		
		.trim-resize-handle {
			@include grab-cursor();
			position: absolute;
			height: 100%;
			width: 16px;
			background-color: rgba(166, 255, 121, 0.3);//$pale-green
			display: flex;
			justify-content: space-evenly;
			border-top: 2px solid $pale-green;
			border-bottom: 2px solid $pale-green;
			
			&.left {
				border-left: 2px solid $pale-green;
				left: 0;
				border-radius: 8px 0 0 8px;
				margin-left: -16px;
			}
			
			&.right {
				border-right: 2px solid $pale-green;
				right: 0;
				border-radius: 0 8px 8px 0;
				margin-right: -16px;
			}

			.handle-bar {
				margin: auto 0;
				height: 30%;
				width: 2px;
				background-color: rgb(255, 255, 255, 0.9);
				border-radius: 99px;
			}
		}
	}
}

.tth-display-time {
	user-select: none;
	font-family: $font-family-roboto;
}

.trim-rename-dialog-wrap {
	background-color: rgba(0, 0, 0, 0.501);
	@include absolute-fill;
	display: flex;
	justify-content: center;
	align-items: center;

	.trim-rename-dialog {
		position: absolute;
		background-color: $element-background-gray;
		border-radius: 16px;
		padding: 24px 32px;
		min-width: 640px;

		.trim-rename-input {
			margin: 16px 0 44px 0;
		}

		.trd-header {
			font-weight: 500;
			font-size: 22px;
			line-height: 30px;
			margin-bottom: 44px;
		}
		
		.trd-title {
			color: $text-light;
			font-size: 12px;
			margin-left: 20px;
			font-weight: 600;
			margin-bottom: 4px;
		}
	}
}

.trim-button-original {
	display: flex;
	gap: 8px;
	margin: 0 32px;
	font-weight: 500;

	&,
	:hover,
	:active,
	:focus {
		background: none !important;
	}

	:hover,
	:active,
	:focus {
		color: $white;
	}
}
