.meeting-wrapper {
	@include absolute-fill;
	@include custom-scrollbar;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.hide {
		display: none;
	}
}

.meeting-header {
	height: 72px;
	width: 100%;
}

.meeting-body {
	@include custom-scrollbar;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
}