.main-video-settings-wrapper {
	position: relative;

	.main-video-settings-background {
		@include fixed-fill;
	}

	.main-video-settings-container {
		position: absolute;
		padding: 40px;
		background-color: $border-dark-gray;
		border-radius: $radius-medium;
		opacity: 0;
		transition: 150ms opacity;
		z-index: 100;
		width: 488px;
		bottom: 0;
		transform: translate(10px, 0px);

		section {
			p {
				padding: 0;
				margin: 0;
				margin-bottom: 17px;
			}

			.option-row {

				.option-row-buttons {
					display: flex;
				}
			}
		}

		&.open {
			opacity: 1;
		}
	}
}