.button-close {
	.icon path {
		transition: fill $transition-very-fast;
	}

	/* Commenting out until we have a better solution for the close button */
	//position: fixed;
	//top: 108px;
	//right: 104px;
	//z-index: 3000;
}
