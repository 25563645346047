.text-input-wrapper {
	width: 100%;
	position: relative;
	
	background: none;
	z-index: 0;
	padding: 16px 0;

	.label {
		color: $text-gray;
		position: absolute;
		transform: translateY(0);
		pointer-events: none;
		transition: 150ms all;
		font-weight: 600;
		font-size: 12px;
		left: 16px;

		&.behind-box {
			top: calc(50% - 8px);
			display: none;
			
			&.is-double-label {
				z-index: -1;
				display: block;
			}
			
			&.active,
			&.entered {
				top: -4px;
			}
		}

		&.in-box {
			top: calc(50% - 8px);
			
			&.is-double-label {
				left: 40px;
			}

			&.active,
			&.entered {
				top: -20px;
			}

		}		
		
	}

	.hide-label-wrapper {
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		&.is-double-label {
			overflow: hidden;

		}

		input {
			margin: 0;
			z-index: 0;
			color: $white;
			background-color: #1A1A1F;
			border: 1px solid #4F4C57;
			border-radius: 100px;
			padding: 12px 16px;
			font-size: 14px;
			line-height: 20px;
			box-shadow: none !important;

			&:hover, &:focus, &:active {
				border-color: $text-light;
				outline: none;
			}

			//needed to trick the autofill into triggering the focused state
			@keyframes autofill {
				100% {
					transform: translate(0);
				}
			}
					
			&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active, &:-internal-autofill-selected {
				color: $white !important;
				-webkit-transition-delay: 9999s;
				-webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
				-webkit-text-fill-color: $white !important;
				font-size: 14px !important;
				font-kerning: inherit;
				//needed to trick the autofill into triggering the focused state
				animation: autofill 0s forwards;

				&:-webkit-autofill:not(:focus) {
					border-color: #4F4C57;
				}
			}

			&:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
				border-color: $text-light;
			}
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			transition: background-color 5000s ease-in-out 0s, border 0.15s ease-in-out;
			-webkit-text-fill-color: #fff !important;
		}

		span {
			position: absolute;
			top: 15px;
			color: $text-gray;
			
			transition: opacity 250ms;

			&:hover {
				color: $text-light;	
			}

			&.icon-wrapper {
				line-height: 0;
			}
		}

		.clickable {
			cursor: pointer;
		}

		svg {
			path {
				fill: #C0CBC5;
			}
		}
	}
}