//************************************************************
// Alerts
//************************************************************

.alerts-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: $z-index-alert;
    justify-content: flex-end;
    width: 360px;

    &.auto-width {
        width: auto;
    }

    @include break-max($break-med) {
        top: 0;
        left: 5px;
        right: 5px;
    }

    @include break-min($break-med + 1) {
        top: 15px;
        right: 0;
    }

    .alert.media-uploaded {
        background: $element-background-gray;
        border: 1px solid $element-background-highlight-gray;
        height: 48px;
        margin: 0;
        align-self: end;

        .name {
            justify-content: flex-start;

            span {
                color: $text-platinum;
            }
        }

        .edit-button {
            cursor: pointer;
            color: $pale-green;
            white-space: nowrap;

            .icon {
                margin-right: 4px;
            }
        }

        .show-on-edit {
            display: none;
        }

        &.editing {
            input {
                background: $dark-gray;
                color: $text-platinum;
                font-weight: bold;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 1rem;
                border: 1px solid $element-background-highlight-gray;
                border-radius: 100px;
                padding: 8px 16px;
                width: 100%;
            }

            .cancel {
                color: $text-gray;
            }

            .hide-on-edit {
                display: none;
            }

            .show-on-edit {
                display: block;
            }
        }
    }

    &:not(.auto-width) {
        .media-title-error,
        .general-notification {
            @media screen and (min-width: 769px) {
                right: 120px;
            }
        }
    }
}

.alert {
    display: flex;
    background: #a6ff79;
    border-radius: $rem16px;
    box-shadow: $shadow-alert;
    color: #1a1a1f;
    width: 100%;
    position: relative; //top: $header-size + 20;
    z-index: $z-index-alert;
    overflow: hidden;
    opacity: 0;
    max-height: 500px;
    margin-bottom: 5px;
    border: $rem1px solid $border-dark-gray;
    padding: 12px 16px;
    transition: transform $alert-transition-duration,
        opacity $alert-transition-duration,
        max-height $alert-transition-duration,
        margin-bottom $alert-transition-duration, background 150ms;

    &.error {
        background: $red;

        &.clickable {
            cursor: pointer;

            &:hover {
                background: lighten($red, 5%);
            }
        }
    }

    @include break-max($break-med) {
        // top: -100%;
        transform: translateY(-100%);

        &.show {
            transform: translateY(5px);
        }

        &.hide {
            transform: translateY(-100%);
        }
    }

    @include break-min($break-med + 1) {
        margin-bottom: 15px;
        max-width: 360px;
        transform: translateX(100%);

        &.show {
            transform: translateX(-20px);
        }

        &.hide {
            transform: translateX(100%);
        }

        &.media-device, &.speaker-update, &.mic-update, &.cam-update {
            max-width: 484px;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-width: 360px;
    }

    &.show {
        opacity: 1;
    }

    &.hide {
        opacity: 0;
        max-height: 0;
        margin-bottom: 0;
    }

    .alert-icon {
        display: flex;
        justify-content: center;
        width: 42px;

        > i {
            font-size: 20px;
            position: relative;
            top: 15px;
        }
    }

    .alert-image {
        padding: 15px 0 0 15px;

        img {
            height: 32px;
            width: 32px;
        }
    }

    .clear-alert {
        display: block;
        color: $color-text-lighter;
        position: absolute;
        right: 12px;
        top: 12px;
        line-height: 1;

        .icon-close-circle {
            font-size: 18px;
        }

        &:hover {
            color: $color-text;
        }
    }

    .notification-icon,
    .media-device-error-icon {
        width: 48px;
        height: 48px;
        padding: 10px;
        margin-right: 24px;
        border-radius: 100%;
        
        background: #ec0c00;
        display: flex;
        align-items: center;
        justify-content: center;
        &.happy {
            background: $bl-secondary-green-600;
        }

        &.blue {
            background: #3b9dff;
        }
    }

    .alert-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-basis: 0px;
        }
        line-height: normal;

        .alert-title {
            font-size: $rem14px;
            font-weight: $bold;
            max-width: 240px;
            min-width: 115px;
        }

        .alert-description-wrap {
            margin-top: 6px;
            display: flex;
            align-items: center;

            @include line-clamp(3);

            .alert-spinner {
                margin-right: 14px;
            }

            .alert-description {
                font-size: $rem13px;
                color: #1a1a1f;
                word-wrap: break-word; //added because path of converted videos can be very long single words, so this should allow word wrapping in paths
                max-width: 90%; //added because path of converted videos can be very long single words, so this should allow word wrapping in paths
                max-height: 300px;
                overflow-y: auto;
            }

            * {
                margin: 0;
                padding: 0;
                font-size: 14px;
            }

            a {
                color: #1a1a1f;
            }
        }
    }

    &.warning {
		a {
            text-decoration: underline;
        }

		.alert-icon {
			color: $color-alert-warn !important;
		}
	}

    &.error {
        color: #ffffff;

        a {
            color: #ffffff !important;
            text-decoration: underline;
        }

        .alert-content {
            padding: 14px;
        }

        .alert-icon {
            background: $color-alert-bg-warn;
            color: #ffffff !important;
        }
    }

    &.neutral .alert-icon {
        color: #1a1a1f;
    }

    &.positive .alert-icon {
        color: #1a1a1f;
    }

    &.scheduled-stream-schedule-error,
    &.scheduled-stream-notification,
    &.presenter-cap {
        background: $bl-grayscale-900;

        .close-alert {
            display: none;
        }
    }

    &.general-notification {
        @include alert-modern($width: 480px, $force-width: true);
        background: $bl-grayscale-800;

        .alert-content {
            .alert-title {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: $white;
            }

            .alert-description-wrap p {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.24px;
                color: $bl-grayscale-400;
            }
        }

        .close-alert {
            .close-click-area {
                svg {
                    width: 20px;
                    height: 20px;

                    path {
                        fill: $bl-grayscale-400;
                    }
                }
            }
        }
    }


    &.scheduled-stream-notification {
        @include alert-modern($width: 476px);

        .media-device-error-icon {
            &.happy {
                background: linear-gradient(140deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 97.92%) !important;
            }
            &.sad {
                background:$bl-primary-red-500 !important;
            }
        }
    }

    &.autoplay-notification {
        .notification-icon {
            background: linear-gradient(
                108.66deg,
                #FF6AE6 -4.67%,
                #FFF85C 69.07%,
                #A6FF79 120.95%,
            );
        }
    }

    &.duration-notification {
        .notification-icon {
            background: $bl-primary-red-500;
        }
    }

    &.autoplay-notification,
    &.scheduled-stream-schedule-error,
    &.duration-notification,
    &.presenter-cap {
        @include alert-modern(
                $width: 412px,
                $force-width: true,
        );
    }

    &.media-title-error {
        @include alert-modern(
            $width: 480px,
            $force-width: true
        );
    }

    &.scheduled-stream-schedule-error,
    &.presenter-cap {
        width: 500px !important;
    }

    &.media-device, &.speaker-update, &.mic-update, &.cam-update {
        background: #26242b;
        padding: 16px;

        .close-alert {
            height: 16px;
            width: auto;
            margin-left: 4px;

            .close-click-area {
                display: flex;
                position: static;
                padding: 0px;
            }
        }

        .alert-content {
            span {
                max-width: 280px;
                color: $white;
            }

            span:first-letter {
                text-transform: capitalize;
            }

            p {
                color: #908ca0;
            }

            a {
                color: #908ca0;
                text-decoration: underline;
            }
        }
    }

    .alert-buttons {
        display: flex;
        margin-top: 14px;

        button {
            margin: 0 10px 0 0;
        }
    }

    &.has-children {
        padding: 0;
        background-color: transparent;
        border: none;

        .alert-title {
            display: none;
        }
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background: lighten(#a6ff79, 5%);
        }
    }

    .close-alert {
        display: flex;
        width: 39px;
        cursor: pointer;

        .close-click-area {
            display: flex;
            position: absolute;
            top: 10px;
            right: 14px;
            padding: 2px;
        }
    }
}
