.drawer-wrapper {
	position: fixed;
	top: 70px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $z-index-user-menu;

	@keyframes bgFadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes bgFadeOut {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	@keyframes drawerSlideIn {
		from {
			transform: translateX(100%)
		}

		to {
			transform: translateX(0);
		}
	}

	@keyframes drawerSlideOut {
		from {
			transform: translateX(0);
		}

		to {
			transform: translateX(100%);
		}
	}

	.drawer-background {
		position: fixed;
		top: 72px;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 1;
		animation: 250ms bgFadeIn;
		animation-fill-mode: forwards;
		background: rgba(21, 21, 22, 0.7);
	}

	.drawer-container {
		position: fixed;
		top: 72px;
		right: 0;
		bottom: 0;
		width: 456px;
		transform: translateX(100%);
		animation: 250ms drawerSlideIn;
		animation-fill-mode: forwards;
		background: $darker-gray;
		z-index: 1;
		overflow-y: auto;
	}

	&.closing {
		.drawer-background {
			animation: 300ms bgFadeOut;
			animation-fill-mode: forwards;
		}

		.drawer-container {
			animation: 300ms drawerSlideOut;
			animation-fill-mode: forwards;
		}
	}
}