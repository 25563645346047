@import "../base/mixins";
@import "../base/colors";
@import "../base/sizing";
@import "../base/mixins-global";
@import "../base/vars-font";

.app-entry {
	@include absolute-fill;
	display: grid;
	grid-template-columns: 456px 1fr;
	grid-template-rows: max-content;
	overflow-y: auto;
	overflow-x: hidden;
	
	.centered-axis-xy {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}

	&.full {
		display: grid;
		grid-template-columns: 1fr;
	}

	&.presenter,
	&.producer {
		background-color: $dark-gray;
	}

	.entry-header {
		display: flex;
		align-items: center;
		grid-column: 1 / span 2;
		height: $sizing-header-onboarding;
		width: 100%;
		padding: 0;
		background-color: $border-gray;

		@include telly-bubble(auto, 52px, 8px 0);
	}

	.image-container {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		clip-path: inset(0 0);
		position: relative;
		overflow: hidden;

		.image-full-height {
			display: flex;
			align-items: center;
			justify-content: center;
			height: calc(100vh - #{$sizing-header-onboarding});
		}

		img.lights {
			position: absolute;
			top: -5px;
			height: 295px;
			left: 156px;
			max-height: 37%;
		}
		
		img {
			max-width: 100%;
		}

		&.presenter{
			background-color: #1C1D1F;
		}

		&.producer{
			background-color: #343330;
		}

		.image-wrapper {
			position: relative;


			.time-container {
				position: absolute;
				top: 4.5%;
				left: 44.4%;
				width: 11.2%;
				height: 4.2%;
				display: flex;
				justify-content: center;
				align-items: center;
				white-space: pre;
			
				.current-time {
					color: rgb(15, 15, 15);
					font-size: 1.45vw;
					font-family: calculator;
				}
			}

			.intro-vid-wrapper {
				position: absolute;
				top: 16%;
				left: 39.4%;
				max-width: 24.2%;
				height: 19%;
				background-color: rgb(0, 0, 0);
				
				.triangle {
					position: absolute;
					bottom: -4.9%;
					left: 0;
					width: 34%;
				}

				.intro-vid {
					width: 100%;
				}

				.intro-vid-play {
					background-color: rgba(0, 0, 0, 0.4);
					display: table;
					height: 100%;
					position: absolute;
					top: 0;
					width: 100%;
					user-select: none;
					cursor: pointer;

					.intro-vid-play-cell {
						display: table-cell;
						text-align: center;
						vertical-align: middle;

						.intro-vid-play-text {
							font-size: 1.5vw;
						}
					}
				}
			}

			@keyframes slide-from-right {
				0% {right: -30%;}
				100% {right: 19%;}
			}

			@keyframes slide-from-left {
				0% {left: -30%;}
				100% {left: 25%;}
			}

			@keyframes pulse1 {
				50% {transform: translate(2px, 2px) scale(0.98) rotate(2deg);}
			}

			@keyframes pulse2 {
				50% {transform: translate(2px, 2px) scale(0.98) rotate(-2deg);}
			}

			@keyframes pulse3 {
				50% {transform: translate(-1px, -1px) scale(0.95) rotate(5deg);}
			}

			.tv-left {
				position: absolute;
				top: 12%;
				left: 14.9%;
				width: 23.2%;
				height: 24%;

				.tellyLeft1 {
					position: absolute;
					top: 1%;
					left: 10%;
					width: 22%;
					animation: pulse1 2s ease-in-out infinite;
				}
				.tellyLeft2 {
					position: absolute;
					top: 16%;
					left: 66%;
					width: 20%;
					animation: pulse2 5s ease-in-out infinite;
				}
				.tellyLeft3 {
					position: absolute;
					top: 70%;
					left: 43%;
					width: 13%;
					animation: pulse3 3s ease-in-out infinite;
				}
			}
			
			.tv-right {
				position: absolute;
				top: 12%;
				left: 62.5%;
				width: 23.2%;
				height: 24%;
				
				.tellyRight1 {
					position: absolute;
					top: 10%;
					right: 10%;
					width: 25%;
					animation: pulse1 6s ease-in-out infinite;
				}
				.tellyRight2 {
					position: absolute;
					top: 60%;
					left: 20%;
					width: 15%;
					animation: pulse2 3s ease-in-out infinite;
				}
				.tellyRight3 {
					position: absolute;
					top: 3%;
					left: 22%;
					width: 10%;
					animation: pulse3 4s ease-in-out infinite;
				}
			}
		}

	}

	.form-container {
		background: $purple;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: $global-padding;
		position: relative;

		form {
			position: relative;
			background-color: $darker-gray;
			padding-left: 30px;
			padding-right: 30px;
			width: 100%;
			border-radius: $rem20px;

			.logo-container {
				position: absolute;
				left: 0;
				right: 0;
				top: -140px;
   				display: flex;

				img {
					max-width: 37.7%;
					margin: auto;
				}
			}

			h3 {
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				margin: 0;
			}

			button {
				width: 100%;
				border-radius: 16px;
				height: 46px;
			}
		}
	}

	

	.onboarding-tabs-profile-update,
	.onboarding-tabs {
		width: 100%;
		height: 100%;
		background: $element-background-gray;
		overflow: hidden;
		display: flex;

		.waiting-message {
			position: absolute;
			bottom: 120px;
			font-weight: 500;
			text-align: center;
			width: 100%;
			color: rgba($near-white, 0.7);
			padding: 0 40px;
		}


		.tabs-container {
			width: 456px;


			&.one {
				.one {
					transform: translateX(0);
					opacity: 1;
				}
			}

			&.two {
				.one {
					transform: translateX(-456px * 2);
				}

				.two {
					transform: translateX(-456px * 1);
					opacity: 1;
				}
			}

			&.three {
				.one {
					transform: translateX(-456px * 2)
				}

				.two {
					transform: translateX(-456px * 2)
				}

				.three {
					transform: translateX(-456px * 2);
					opacity: 1;
				}

				.four {
					transform: translateX(-456px * 2);
					opacity: 1;
				}
			}

			&.four {
				.one {
					transform: translateX(-456px * 3)
				}

				.two {
					transform: translateX(-456px * 3)
				}

				.three {
					transform: translateX(-456px * 3);
					opacity: 1;
				}

				.four {
					transform: translateX(-456px * 3);
					opacity: 1;
				}
			}

			.tab-wrapper {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 456px;
				transition: 250ms transform, 100ms opacity;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 20px;
				opacity: 0;

				.scroll-container {
					padding: 0 40px;
					background: transparent;
				}

				&.two {
					left: 100%;
					height: 100%;
				}

				&.three {
					left: 200%;
				}

				&.four {
					left: 300%;
				}
			}
		}

		.onboarding-tab-skip-button {
			position: absolute;
			bottom: 40px;
			right: 40px;
		}
		.onboarding-tab-back-button {
			position: absolute;
			bottom: 40px;
			left: 40px;
		}

		.onboarding-graph-container {
			@media screen and (max-height: 570px) {
				transform: scale(0.8) translateY(-50px);
				margin-bottom: -50px;
			}
		}

		.tab-navigation {
			position: absolute;
			bottom: 40px;
			left: 0;
			right: 0;
			width: 456px;
			display: grid;
			align-items: center;
			justify-content: space-between;
			grid-template-columns: 2fr 1fr 2fr;

			div.placeholder {
				flex: 1;
			}

			button.next {
				color: #121114;
				padding: 16px 24px;
				border-radius: 100px;
				line-height: 16px;
			}

			button.arrow {
				@include button-greenroom-circle;
				width: 48px;
				height: 48px;
				background-color: $border-gray;
				border-radius: 100%;
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: width 300ms, background-color 300ms;
				z-index: 1;

				&.done {
					width: initial;
					border-radius: 100px;
					background-color: $green;
					padding: 11px 20px;

					.waiting-indicator {
						transform: scale(1);
					}
				}
			}

			button.text-button {
				@include button-greenroom-basic;
				background: none;
				margin-right: 8px;
				color: $med-gray;
			}

			.current-tab-indicator {
				display: flex;
				justify-content: center;
				align-items: center;
				
				.indicator {
					background-color: $near-white;
					width: 8px;
					height: 8px;
					margin: 8px;
					border-radius: 100%;
					opacity: 0.5;
					transition: 250ms opacity;

					&.active {
						opacity: 1;
					}
				}
			}
		}
	}

	.onboarding-tabs-profile-update {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $bl-grayscale-1200;

		button {
			text-wrap: nowrap;
		}

		.tab-navigation {
			display: flex;
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
			align-items: center;
			.current-tab-indicator {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				
				.indicator {
					background-color: #FCFCFC;
					width: 32px;
					height: 4px;
					margin: 0 2px;
					border-radius: 4px;
					opacity: 0.125;
					transition: 250ms opacity;

					&.active {
						opacity: 0.25;
					}
				}
			}
		}

		.gradient-container-container {
			position: absolute;
			top: 0;
			left: 0;
			right: 10%;
			bottom: 15%;
			display: flex;
			align-items: center;
			justify-content: center;

			.gradient-offset {
				width: 45%;
				max-width: 45%;
			}

			.gradient-container {
				position: relative;
				width: 50%;
				height: 100%;
				max-height: 320px;
				max-width: 560px;
				margin-left: auto;

				.gradient {
					position: absolute;
					
					width: 100%;
					border-radius: 744px;
					opacity: 0.2;
					filter: blur(130px);

					&.purple {
						// height: 285px;
						height: 80%;
						background: #FF6AE6;
						transform: translate(30%, -30%);
					}
					
					&.yellow {
						height: 100%;
						background: #FFF85C;
						transform: translate(-5%, 5%);
					}
				}
			}

			&.centered {
				right: 0;
				bottom: 0;
				.gradient-offset {
					width: 0;
					max-width: 0;
				}	
				.gradient-container {
					margin-left:0;
				}
				.gradient {
					&.yellow {
						transform: translate(-10%, 10%);
					}
					&.purple {
						transform: translate(15%, -10%);
					}
				}
			}
		}

		.tabs-container {
			@include absolute-fill;
			background-color: transparent;

			

			&.one {
				.one {
					transform: translateX(0);
				}
			}

			&.two {
				.one {
					transform: translateX(-100vw * 2);
				}

				.two {
					transform: translateX(-100vw * 1);
				}
			}

			&.three {
				.one {
					transform: translateX(-100vw * 2)
				}

				.two {
					transform: translateX(-100vw * 2)
				}

				.three {
					transform: translateX(-100vw * 2);
				}

				.four {
					transform: translateX(-100vw * 2);
				}
			}

			&.four {
				.one {
					transform: translateX(-100vw * 3)
				}

				.two {
					transform: translateX(-100vw * 3)
				}

				.three {
					transform: translateX(-100vw * 3);
				}

				.four {
					transform: translateX(-100vw * 3);
				}
			}

			.tab-wrapper {
				width: 100vw;
				background-color: transparent;

				.scroll-container {
					padding: 0 40px;
					background: transparent;

					.button-container {
						display:flex;
						align-items: center;
						button {
							border-radius: 6.143rem;
							padding: 16px 24px;
							//styleName: L1;
							font-family: Poppins;
							font-size: 13px;
							font-weight: 600;
							line-height: 16px;
							text-align: center;

						}
						button.next {
							background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%);
							margin-left: 16px;
							color: #121114;
						}
					}

					.panel {
						align-items: center;

						.header {
							-webkit-text-fill-color: transparent;
							background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%) text;
						}
					}
				}

				&.two {
					left: 100vw;
					height: 100%;
				}

				&.three {
					left: 200vw;
				}

				&.four {
					left: 300vw;
				}
			}
		}
		.tab-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex: 1;
			transition: 250ms transform;
			.button-container {
				display:flex;
				align-items: center;
				button {
					border-radius: 6.143rem;
					padding: 16px 24px;
					//styleName: L1;
					font-family: Poppins;
					font-size: 13px;
					font-weight: 600;
					line-height: 16px;
					text-align: center;

				}
				button.next {
					background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%);
					margin-left: 16px;
				}
			}
			img {
				margin: 24px auto 0 auto;
				height: 40px;
			}
		}
	}

	button.onboarding {
		@include button-greenroom-shadow;
		@include font-poppins-14-500;

		background: $pale-green;
		padding: 14px 24px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		color: #121114;
		transition: opacity 150ms;

		&:disabled {
			opacity: 0.7;
		}
	}
}

.option-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	canvas {
		width: 30px;
	}

	.option-row-buttons {
		width: 70px;
		height: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 0 auto;

		button {
			background: none;
			border: none;
			color: $text-light;
			font-size: 12px;
			line-height: 14px;
			font-weight: normal;
			padding: 0;
			outline: none;
			box-shadow: none;
			text-transform: none;
			margin-left: 5px;

			&:hover {
				color: lighten($text-light, 10%);
			}
		}

	}

	.select-small {
		flex: 1;
		border-radius: 100px;

		.select-label.dropdown {
			justify-content: flex-start;
			padding: 14px 16px;
			border: $rem1px solid $element-background-highlight-gray;
			background-color: $background-gray;

			.select-label-text {
				position: relative;
				flex: 1;
				overflow: hidden;
				width: 0;
			}
		}
	}
}

.onboarding-tab-bottom-indicator {
	// some devices are too small and we must hide
	// the bottom logo to make room for the buttons in the tabs
	@media screen and (max-height: 750px) {
		display: none;
		pointer-events: none;
		max-height: 0;
		max-width: 0;
		opacity: 0;
		padding: 0;
	}
}

.scroll-container {
	margin: auto;
	height: 100%;
	
	overflow: auto;
	@include custom-scrollbar;
}

.panel {
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&.purple {
		background: $purple;
	}

	.header{
		font-family: Poppins;
		font-weight: bold;
		font-size: 28px;
		margin: 0 0 38px 0;
		font-size: 24px;
		line-height: 28px;
		color: $white-presenter;
	}

	&.teal-green,
	&.producer {
		background: transparent;
	}

	&.teal-green, &.audio-video-check {
		display: flex;
	}

	&.producer {
		&.select-app {
			.back-button{
				margin-top: 40px
			}

			.no-accounts{
				text-align: center;
				font-weight: 500;
				color: #F2F6F5;
				transition: 150ms color;
				font-size: 18px;
			}

			.loading-container{
				margin-top: 100px;
			}
		}
	}

	.presenter-message-container{
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 64px;

		h2 {
			text-align: left;
		}
	}

	.producer-message-container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 112px;
		margin-top: 100px;
		margin-bottom:132px;
	}

	.producer-logo-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 80px;

		&.create-account-page {
			align-items: flex-start;
			margin-bottom: 0;

			.titles{
				display: flex;
				flex-direction: column;
			}
		}

		.title-container{
			display: flex;
			justify-content: center;
		}

		.title {
			font-family: Poppins-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: -1px;
			color: $near-white;

			&.green {
				color: #BAF19A;
			}

			&.create-account-page{
				margin-top: -10px;
			}
		}

		.sub-title{
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			color: #FCFCFC;
			margin-top: -5px;
		}

		.congrats-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			left: 0;
			right: 0;
			top: 80px;
			

			.message {
				font-family: Poppins;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				color: #FFFFFF;
			}
		}

		&.verify-account {
			justify-content: center;
			height: 523px;

			.message-container{
				height: 200px;
				display: flex;
				flex-direction: column;
				margin-bottom: 100px;
				span{
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					color: #FFFFFF;

					align-self: center;
					margin-top: 40px;
				}
			}

		
		}
	}

	.create-account-page {
		.title {
			font-family: Poppins-Bold;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 36px;
			letter-spacing: -1px;
			color: $near-white;
	
			&.green {
				color: #BAF19A;
			}
		}
	}

	.email-input{
		margin-top: 100px;
	}

	.text-inputs{
		display: flex;
		flex-direction: column;
	}

	.apps-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;

		.app-button {
			display: flex;
			flex-direction: row;
			height: 70px;
			background: $element-background-highlight-gray;
			border-radius: 20px;
			margin-top: 18px;
			justify-content: flex-start;
			align-items: center;
			padding: 0;

			&.first {
				margin-top: 0;
			}

			.logo{
				width: initial;
				margin: initial;

				&.showrooms,
				&.allhands {
					margin-left: 18px;
				}

				&.events {
					margin-left: 22px;
					height: 48px;
				}

				&.brandlive {
					margin-left: 10px;
					height: 32px;
				}

				&.text-logo {
					height: 24px;
				}

				&.custom-destination {
					margin-top: 6px;
					margin-left: 15px;
				}
			}

			.title{
				font-family: Poppins-Bold;
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				line-height: 36px;
				letter-spacing: -1px;
				color: $near-white;

				&.showrooms,
				&.allhands {
					margin-left: 16px;
				}

				&.events {
					margin-left: 20px;
				}

				&.custom-destination {
					margin-left: 12px;
				}
			}
		}
	}

	h2 {
		font-size: 28px;
		font-weight: bold;
		line-height: 33px;
		text-align: center;
		margin-bottom: 0;
	}

	h3 {
		font-size: 14px;
		text-transform: uppercase;
		color: $text-gray;
		font-weight: bold;
		letter-spacing: 0.5px;
	}
}

.sign-in {
	display: flex;
	align-items: center;
	padding: 10px 40px;
	flex-direction: column;
	justify-content: center;

	// Remove arrows from "number" text input
	input[type='number'] {
		-moz-appearance:textfield;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	img {
		width: 140px;
		margin-bottom: 60px;
	}

	form {
		width: 100%;

		&.create-account-form {
			margin-top: 10px;
		}
	}

	.password-reset {
		ul {
			margin-top: 8px;
		}
		
		.red {
			color: $red;
		}
	}

	button.onboarding {
		width: 100%;
		margin-top: 40px;
		transition: 150ms background-color;

		@include button-greenroom-shadow;

		&.producer {
			margin-top: 30px;
		}

		&.resend-verification {
			margin-top: 0;
		}

		&.create-account {
			margin-top: 0;
		}
	}

	.requirements-popover {
		position: absolute;
		padding: 24px;
		background-color: $dark-gray;
		color: $near-white;
		transition: 150ms opacity, transform 150ms;
		z-index: 10;
		border-radius: 24px;
		transform: translateX(50%) translateY(10px);
		opacity: 0;
		margin-top: -160px;

		&.hidden {
			display: none;
		}

		&.open {
			display: block;
			opacity: 1;
			transform: translateX(50%) translateY(0);
			transition: 150ms opacity, transform 150ms;
		}

		span {
			font-weight: bold;
		}

		ul {
			margin: 0;
			margin-top: 6px;
			color: $text-light;
		}

		&::after {
			content: " ";
			position: absolute;
			height: 20px;
			width: 20px;
			background-color: $dark-gray;
			transform: rotate(45deg);
			right: 44px;
			bottom: -5px;
		}
	}

	.password-requirements {
		font-size: 12px;
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		color: $gray-presenter;
		font-weight: 500;
		transition: 150ms color;
		text-align: center;
		justify-content: center;
		margin-top: 15px;

		&:hover {
			color: $near-white;
			svg {
				path {
					fill: $near-white !important;
				}
			}
		}

		svg {
			margin-left: 5px;
			path {
				transition: 150ms fill;
				fill: $gray-presenter;
			}
		}

		&.met {
			svg {
				path {
					fill: $green !important;
				}
			}
		}
	}

	.forgot-password {
		text-align: center;
		width: 100%;
		color: $gray-presenter;
		margin-top: -10px;

		span {
			display: inline-block;

			&.clickable {
				cursor: pointer;
			}
		}
		
		&.clickable {
			cursor: pointer;
			font-weight: 500;
			color: $white-presenter;
			transition: 150ms color;

			&:hover {
				color: $near-white;
			}

		}
	}

	.back-button{
		text-align: center;
		margin-top: 30px;
		font-weight: 500;
		color: #F2F6F5;
		transition: 150ms color;
		cursor: pointer;
	}

	.create-account {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		width: 100%;
		color: $gray-presenter;
		margin-top: 24px;

		span {
			display: inline-block;
		}

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			background: $pale-green;
			padding: 8px 16px;
			font-size: 12px;
			border-radius: 100px;
			color: #121114;
		}
	}

	@keyframes popout {
		from {
			max-height: 0;
			opacity: 0;
		}

		to {
			max-height: 30px;
			opacity: 1;
		}
	}

	.signin-error {
		text-align: center;
		margin-top: 20px;
		color: $red;
		font-weight: $bold;
	}

	&.producer {
		button.onboarding {
			@include button-greenroom-shadow;

			.margin-80 {
				margin-top: 80px;
			}
		}
	}
}

.avatar-container {
	//removed margin-top from this line because it was affecting ALL avatars in the app
	button {
		margin: 0;
		padding: 0;
		height: 24px;
		width: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $element-background-highlight-gray;
		color: #000000;
		border-radius: 100%;
		position: relative;
		top: -23px;
		left: 36px;
	}
}

.confirm-app-entry {
	@include absolute-fill;

	background-color: $dark-gray;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	button {
		@include button-greenroom-shadow;
		background: $red;
		padding: 14px 24px;
		font-size: 16px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		color: $white;
		transition: opacity 150ms;

		&:disabled {
			opacity: 0.7;
		}
	}
}

.audio-video-check{
	.header{
		font-family: Poppins;
		font-weight: bold;
		text-align: center;
		font-size: 24px;
		line-height: 28px;
		color: $white-presenter;
	}

	.header-text {
		@include font-poppins-14-bold;
		color: #BFBBCF;
		margin-left: 8px;
	}

	h2 {
		margin-bottom: 38px;
	}

	h3 {
		display: flex;
		align-items: center;
		text-transform: none;
	}

	.video-section{
		margin-top: 10px;
	}
}

.device-selector {
	.header {
		text-align: left;
		margin-bottom: 16px;
	}
	.context {
		color: $pale-green;
		font-weight: 400;
		margin-top: 0;
	}

	.device-options-container {
		.device-option {
			display: flex;
			background-color: $border-gray;
			border-radius: 8px;
			align-items: center;
			justify-content: space-between;
			padding: 26px 24px;
			margin-bottom: 19px;
			cursor: pointer;
			transition: background-color 150ms;

			&:hover {
				background-color: lighten($border-gray, 5%);
			}

			.device-metadata {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex: 1;
				padding-left: 26px;
				

				label {
					font-weight: 500;
					font-size: 16px;
					line-height: 18.75px;
				}

				span {
					font-size: 14px;
					line-height: 20px;
					color: $text-light;
				}
			}

			.device-preview {
				display: flex;
				align-items: center;

				.device-preview-container {
					margin-right: 24px;
					font-size: 30px;
				}

				img {
					border-radius: 12px;
					width: 40px;
					height: 40px;
				}
			}
		}
	}
}

.ios-setup {
	p.header {
		text-align: left;
		margin: 0 0 24px;
	}

	.ios-back-button {
		@include flex-center;
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		margin-bottom: 24px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;

		svg {
			margin-right: 8px;
		}
	}

	.setup-container {
		position: relative;
		
		.app-icon {
			display: flex;
			align-items: center;

			img {
				width: 59px;
				margin-bottom: -9px;
				margin-left: -6px;
			}

			label {
				font-size: 24px;
				font-weight: 700;
				margin: 0;
			}
		}

		p {
			font-size: 16px;
			font-weight: 500;
			color: $text-light;
			line-height: 20px;
		}

		h3 {
			font-size: 18px;
			line-height: 21px;
			color: #ffffff;
			text-transform: none;
		}

		h4 {
			font-size: 18px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 20px;
			color: $near-white;
		}

		.button-row {
			display: flex;

			button {
				border-radius: 50px;
				background-color: $border-gray;
				color: $text-light;
				margin-right: 13px;
				transition: background-color 150ms;

				&:hover {
					background-color: lighten($border-gray, 5%);
				}
			}
		}

		.qr-code-tiger {
			position: absolute;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 0;
		}

		.qr-code-tiget-grey {
			background-image: url("../../images/tiget-circle-large-grey.svg");
			width: 125px;
			height: 125px;
			left: 4em;
			bottom: -2em;
		}

		.qr-code-tiget-green {
			background-image: url("../../images/tiger-circle-small.svg");
			width: 64px;
			height: 64px;
			right: 4em;
			bottom: 6em;
		}

		.entry-row {
			display: flex;
			margin-bottom: 16px;
			align-items: center;

			input {
				width: 210px;
				background: none;
				border: none;
				border-bottom: 1px solid $border-gray;
				padding: 8px;
				margin-right: 16px;
				color: $near-white;
			}

			button {
				border-radius: 50px;
				background-color: $border-gray;
				color: $text-light;
				margin-right: 13px;
				transition: background-color 150ms;
				height: 40px;
				display: flex;
				align-items: center;
				flex: 1;
				justify-content: center;

				svg {
					margin-right: 8px;
				}

				&:hover {
					background-color: lighten($border-gray, 5%);
				}
			}
		}

		.qr-code-container {
			@include flex-center;
			height: 199px;
			width: 199px;
			border-radius: 16px;
			background: #ffffff;
			margin-top: 42px;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			z-index: 1;
		}
	}
}