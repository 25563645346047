.edit-ellipses-container {
    .edit-ellipses-menu {
        display: flex;
        flex-direction: column;
        width: fit-content;
        border-radius: 12px;

        &,
        .option {
            background-color: $border-gray;
            overflow: hidden;
        }

        .option {
            padding: 10px 18px;
            border-radius: 0;

            &:hover {
                background-color: $element-background-highlight-gray;
            }
        }

        .text {
            color: #ffffff;
            font-weight: 400;

            &.red {
                color: $text-red;
            }
        }
    }
}
