
//************************************************************
// Global Body Styles
//************************************************************

html, body {
	// Ensure html and body get default scrolling behavior on mobile devices 1024 and below
	@include break-max($break-lrg) {
		overflow: initial !important;
	}
}

html {
	font-family: $font-family-poppins;
    font-size: $font-size-body;
    line-height: $line-height-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale !important;
	word-wrap: break-word;
	word-break: break-word;
}

body {
	background-color: $color-theme-body-default;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	background-attachment: fixed;
    color: $color-text-dark;
	margin: 0;
	
	@include break-max($break-med) {
		padding-top: $header-size-mobile;
		padding-bottom: $mobile-tabbar-size;
	}
}

//************************************************************
// Global Text Styles
//************************************************************

b,
strong {
    font-weight: $bold;
}

h1, h2, h3, h4, h5, h6 {
	margin: $margin-heading;
	line-height: normal;
	font-weight: 300;

	&:first-child {
		margin-top: 0;
	}

	> b {
		font-weight: $bold;
	}
}

h1 {
	font-size: $font-size-h1;
	letter-spacing: -1px;
}
h2 {
	font-size: $font-size-h2;
	letter-spacing: -0.90px;
}
h3 {
	font-size: $font-size-h3;
	letter-spacing: -0.80px;
}
h4 {
	font-size: $font-size-h4;
	letter-spacing: -0.70px;
}
h5 {
	font-size: $font-size-h5;
	letter-spacing: -0.60px;
}
h6 {
	font-size: $font-size-h6;
	letter-spacing: -0.5px;
}

p {
	font-size: $font-size-p;
	line-height: $line-height-p;
	margin: $margin-text;

	small {
		font-size: $font-size-p-small;
	}

	i {
		margin: 0 5px;
	}
}

a {
	color: $color-link;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	transition: 0.1s color;
	-webkit-appearance: none;

	&:hover {
		color: $color-link-hover;
		text-decoration: none;
	}

	&.neutral {
		color: $color-text-light;

		&:hover {
			color: $color-link;
		}
	}

	&:focus {
		box-shadow: 0 0 3px 1px $color-blue;
	}
}

ul, ol {
	margin: $margin-list;
	padding: $padding-list;
}

hr {
	border: none;
	border-bottom: 1px solid $color-border-lighter;
	margin: 30px 0;

	&.light {
		border-bottom-color: $color-border-light;
	}
	&.lighter {
		border-bottom-color: $color-border-lighter;
	}

	@include break-min($break-xlrg) {
		margin: 40px 0;
	}
}