.main-video,
.layout-selector {
	&.single {
		grid-template-areas: "first";
		grid-template-columns: auto;
	}

	&.single-padding {
        grid-template-areas: "first";
        grid-template-columns: auto;
	}

	&.half {
		grid-template-areas: ". first .";
		grid-template-columns: 1fr 2fr 1fr;
	}

    &.five {
        &.four-by-three {
            grid-template-areas:    ". . . . . ."
                                    "first first second second third third"
                                    ". fourth fourth fifth fifth ."
                                    ". . . . . .";
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: 1fr 7.995fr 7.995fr 1fr;
        }
    }

    &.grid {
        &.seven {
            grid-template-areas:    ". . . . . . . ."
                                    "first first second second third third fourth fourth"
                                    ". fifth fifth sixth sixth seventh seventh ."
                                    ". . . . . . . .";
            grid-template-columns: repeat(8, minmax(auto, 1fr));
            grid-template-rows: 1fr 8fr 8fr 1fr;
        }
    }

    &.stack {
		// Seven has 3 small squares on each side with one large square in the middle
		&.seven.center {
			grid-template-areas:". second first first first fifth ."
								". third first first first sixth ."
								". fourth first first first seventh .";

			grid-template-rows: 1fr 1fr 1fr;
			grid-template-columns: 3.0651fr 18.77396fr 18.77396fr 18.77396fr 18.77396fr 18.77396fr 3.0651fr;
		}
    }

    &.media {
        &.sixteen-by-nine {
            grid-template-areas:". . . . ."
                                ". first . third ."
                                ". . . third ."
                                ". second . third ."
                                ". . . . .";

            grid-template-columns: 2fr 19.4fr 2fr 67.75fr 2fr;
            grid-template-rows: 7.1fr 18fr 2fr 18fr 7.1fr;
        }
    }

    &.picture-in-picture {
        &.video-background,
        &.layer1 {
            grid-template-areas: "first";
            grid-template-columns: auto;
        }

        &.video-overlay,
        &.layer2 {
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;

            &.top.left {
                grid-template-areas: 	". . . ."
                                        ". first . ."
                                        ". . . ."
                                        ". . . .";
                grid-template-columns: 50fr 164.45fr 520fr 50fr;
                grid-template-rows: 43fr 123fr 231fr 43fr;
            }

            &.bottom.right {
                grid-template-areas: 	". . . ."
                                        ". . . ."
                                        ". . first ."
                                        ". . . .";
                grid-template-columns: 50fr 520fr 164.45fr 50fr;
                grid-template-rows: 43fr 231fr 123fr 43fr;
            }

            .video-block {
                outline: 2px solid $border-dark-gray;

                img {
                    opacity: 0;
                }
            }
        }
    }

    &.asl {
        &.three {
            &.double-four-by-three {
                grid-template-areas:
                    ". . . . . . . . . . . . . . . ."
                    ". first first first first first first . . second second second second second second ."
                    ". first first first first first first . . second second second second second second ."
                    ". first first first first first first . . second second second second second second ."
                    ". . . . . . . . . . . . . . . ."
                    ". . . . . . . . . . . . third third third ."
                    ". . . . . . . . . . . . . . . .";
                grid-template-columns: 0.2025fr repeat(3, calc(0.713fr / 3)) repeat(3, calc(1fr / 3)) repeat(2, calc(0.2fr / 2)) repeat(3, calc(1fr / 3)) repeat(3, calc(0.713fr / 3)) 0.2025fr;
                grid-template-rows: 0.45fr repeat(3, calc(2.7fr / 3)) 0.45fr 1fr 0.15fr;
            }
        }

        &.four {
            &.three-by-four {
                grid-template-areas:
                    ". . . . . . . . . . . . . . . ."
                    ". first first first first . second second second second . third third third third ."
                    ". first first first first . second second second second . third third third third ."
                    ". first first first first . second second second second . third third third third ."
                    ". . . . . . . . . . . . . . . ."
                    ". . . . . . . . . . . . fourth fourth fourth ."
                    ". . . . . . . . . . . . . . . .";
                grid-template-columns: 0.1915fr repeat(3, calc(0.6685fr / 3)) 0.328fr 0.206fr repeat(4, calc(0.998fr / 4)) 0.206fr 0.328fr repeat(3, calc(0.6685fr / 3)) 0.1915fr;
                grid-template-rows: 0.4fr repeat(3, calc(2.95fr / 3)) 0.25fr 1fr 0.15fr;
            }
            &.sixteen-by-nine {
                grid-template-areas:
                    ". . . . . . . . . . . . . . . ."
                    ". first first first . third third third third third third third third third third ."
                    ". . . . . third third third third third third third third third third ."
                    ". second second second . third third third third third third third third third third ."
                    ". . . . . . . . . . . . . . . ."
                    ". . . . . . . . . . . . fourth fourth fourth ."
                    ". . . . . . . . . . . . . . . .";
                grid-template-columns: 0.1fr repeat(3, calc(0.335fr / 3)) 0.094fr repeat(7, calc(1fr / 7)) repeat(3, calc(0.3495fr / 3)) 0.1fr;
                grid-template-rows: 0.2fr 1.425fr 0.4fr 1.425fr 0.15fr 1fr 0.15fr;
            }
        }

        &.five {
            &.nine-by-sixteen {
                grid-template-areas:
                    ". . . . . . . . . . . . . . . . . . . . ."
                    ". first first first first . second second second second . third third third third . fourth fourth fourth fourth ."
                    ". first first first first . second second second second . third third third third . fourth fourth fourth fourth ."
                    ". first first first first . second second second second . third third third third . fourth fourth fourth fourth ."
                    ". . . . . . . . . . . . . . . . . . . . ."
                    ". . . . . . . . . . . . . . . . . fifth fifth fifth ."
                    ". . . . . . . . . . . . . . . . . . . . .";
                    grid-template-columns: 0.2585fr repeat(3, calc(0.91fr / 3)) 0.1075fr 0.2fr repeat(4, calc(1fr / 4)) 0.2fr repeat(4, calc(1fr / 4)) 0.2fr 0.1075fr repeat(3, calc(0.91fr / 3)) 0.2585fr;
                    grid-template-rows: 0.35fr repeat(3, calc(2.95fr / 3)) 0.34fr 1.01fr 0.15fr;
            }
        }
    }
}
