.debug-panel-container {
    background-color: rgb(14, 1, 23);
    border: 2px solid #53043a;

    color: black;
    font-weight: 700;

    transition: height 0.2s ease, width 0.2s ease;

    z-index: $z-index-debug-panel;
    position: fixed;
    top: 72px;
    left: 72px;
    padding-bottom: 16px;

    height: 76px;
    width: 120px;
    overflow: hidden;

    a {
        color: rgb(43, 255, 248);
    }

    &.expanded {
        height: 80vh;
        width: 500px;

        &.expanded-horizontally {
            height: 96vh;
        }
    }

    &.expanded-horizontally {
        width: 90vw;
    }

    button {
        margin: 8px 0;
    }

    .debug-panel {
        color: white;
        font-weight: normal;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 8px;
        height: calc(100% - 72px);
        @include custom-scrollbar(24px, 1);
        
        .debug-panel-below-menu {
            overflow: auto;
            gap: 16px;
            padding: 0 8px 0 16px;
            height: 100%;

            .section {
                margin-top: 16px;

                h5 {
                    color: #A6FF79;
                    font-weight: 600;
                    margin: 0 0 5px 0;
                }

                &:nth-last-child(2) {
                    flex: 1;
                }

                &.settings {
                    padding: 0;

                    .advanced-settings-container {
                        .vertical-padding-small {
                            padding: 8px 0;
                        }
                    }
                }
            }

            &.srt {
                scrollbar-gutter: stable;
                padding: 0 8px 0 24px;
            }
        }

        pre.code {
            background-color: rgb(166, 255, 121);
            font-size: 10pt;
            color: $darker-gray;
            padding: 10px;
            font-weight: 700;
        }
    }

    .debug-panel-header-controls {
        display: flex;
        color: white;
        flex-wrap: wrap;

        .debug-expander {
            justify-content: center;
            align-items: center;
            user-select: none;
            display: flex;
            cursor: pointer;
            min-height: 48px;

            &.vertical {
                display: flex;
                flex: 1;
                user-select: none;
                background-color: rgb(38, 236, 134);
                min-height: 24px;
                @include button-greenroom-shadow;
            }

            &.horizontal {
                @include button-greenroom-shadow;
                display: flex;
                flex: 1;
                background-color: rgb(38, 236, 134);
                min-height: 24px;

                &.do-hide {
                    max-width: 0;
                }
            }
            
            &.handle {
                border-bottom: 3px solid black;
                display: flex;
                flex: 1;
                background-color: rgb(246, 238, 255);
                cursor: grab;
                min-width: 100%;
                justify-content: space-between;

                h6 {
                    margin: 0;
                }
            }
        }
    }

    .display-none {
        display: none;
    }

    .scheduled-stream {
        .scheduled-stream__row {
            display: flex;
            gap: 16px;

            &.status-row {
                margin-top: 8px;

                .status-value {
                    padding: 0 8px;
                    border-radius: 100px;
                    font-weight: 700;
                    background-color: $bl-gr-primary-500;
                    color: $bl-grayscale-1200;
                }
            }

            .scheduled-stream__row__controls {
                display: flex;
                gap: 12px;
                height: 16px;
                flex: 1;

                button {
                    background: transparent;
                }
            }
        }
    }

    .accordion-debug-panel {
        width: 100%;
        border: 3px solid rgb(0, 126, 61);
        border-radius: 8px;
        padding: 8px 16px;



        .accordion-title-panel {
            cursor: pointer;

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            h5 {
                color: rgb(74, 249, 255) !important;
                padding: 4px 0 0 16px;
            }
        }

        .accordion-body-panel {
            height: 0;
            transition: all 150ms, opacity 1ms;
            overflow: hidden;

            &.expanded {
                height: auto;
            }
        }

    }
}

.avui-debug-panel {
    z-index: $z-index-debug-panel;
    position: absolute;
    top: 72px;
    left: 602px;

    .avui-box {
        display: flex;
        border-radius: 99px;
        background-color: $bl-grayscale-1000;
        padding: 4px 8px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition: padding 0.2s ease-out;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }

        &.allMuted {
            border: 2px solid red;
        }

        button {
            transition: padding 0.2s ease-out;
        }

        .avui-dot {
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 50%;
            background-color: $pale-green;
            position: fixed;
            left: calc(50% - 0.2rem);
            top: 10%;
            transition: opacity 0.1s ease;
            opacity: 1;

            &.allMuted {
                opacity: 0;
            }
        }
    }
}