.header-left-controls {
    display: flex;
    align-items: center;
    overflow: hidden;

    .header-left {
        .header-logo-container {
            margin-right: 16px;
        }

        .header-title {
            color: $bl-grayscale-200;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }
    }
}
