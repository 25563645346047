.setup-conference-room {
	// .tab-wrapper {
	// 	.gradient {
	// 		z-index: 1;
	// 	}
	// }

	.scroll-container {
		width: fit-content;
		align-items: center;
		display: flex;
		flex-direction: column;
		height: unset;
		padding: unset !important;
		z-index: 1;
	}

	.purple-gradient-2 {
		width: 804px;
		height: 396px;
		position: absolute;
		z-index: -1;
		background: #35052D;
		filter: blur(120px);
		opacity: 0.6;
	}

	.conference-room-background-image {
		min-height: 100vh;
		max-height: 100vh;
		position: absolute;
	}

	.setup-conference-room-header {
		background: linear-gradient(104.42deg, #ff6ae6 -4.67%, #fff85c 69.07%, #a6ff79 120.95%);
		background-clip: text;
		-webkit-text-fill-color: transparent;	

		h1 {
			font-weight: 700;
			font-size: 30px;
		}

		&.sip-connection-status-success {
			background: linear-gradient(91.27deg, #5AE4FF -2.35%, #A6FF79 67.26%, #FFF85C 107.71%);
			background-clip: text;
			-webkit-text-fill-color: transparent;	
		}
	}

	.setup-conference-room-sub-header {
		display: flex;
		align-items: flex-start;
		margin-top: -16px;

		h2 {
			font-size: 20px;
			font-weight: 600;
			margin-right: 6px;
		}

		div {
			cursor: pointer;
		}
	}

	.establishing-connection-sub-header {
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 24px;

		&.error {
			color: #FF5F7F;
		}
	}

	.establishing-connection-video-placeholder {
		width: 560px;
		height: 316px;
		border-radius: 16px;
		background: #09090A4D;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;

		img {
			width: 316px;
    		height: 316px;
		}
	}

	.name-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		position: relative;
		gap: 8px;
		
		.text-input-wrapper {
			width: 408px;
		}

		.connection-name-count {
			position: absolute;
			font-size: 11px;
			font-weight: 500;
			color: #908CA0;
			right: 170px;
    		bottom: -4px;
		}

		.select {
			width: 144px;
		}
	}

	.background-image-text {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: flex-start;
		width: 100%;
		margin-bottom: 16px;

		svg {
			margin-left: 6px;
		}
	}

	.background-image-upload-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.background-image-upload-container-left {
			display: flex;
			align-items: center;
		}

		.image-upload-file-name {
			@include line-clamp(1);
			max-width: 330px;
			font-size: 12px;
			font-weight: 500;
			color: #BFBBCF;
		}

		.upload-buttons-container {
			display: flex;

			.replace-button {
				width: 106px;
				height: 32px;
				padding: 8px 16px 8px 16px;
				border-radius: 100px;
				background-color: #4F4C57;
				color: white;
				margin-left: 12px;
				font-size: 11px;
				font-weight: 600;
			}

			.delete-button {
				width: 32px;
				height: 32px;
				padding: 10px;
				border-radius: 100px;
				background: #4F4C57;
				margin-left: 8px;
			}
		}
	}

	.advanced-toggle {
		font-size: 14px;
		font-weight: 600;
		color: #908CA0;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		cursor: pointer;

		&.expanded {
			margin-bottom: 0px;
		}

		svg {
			margin-left: 6px;
		}
	}

	.left-labeled-input-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		&.advanced {
			&.username {
				height: 40px;
				margin-top: 16px;
			}

			.text-input-wrapper {
				width: 320px;
			}

			&.password {
				.icon-wrapper {
					width: unset;
				}
			}
		}

		span {
			font-size: 12px;
			font-weight: 500;
			width: 240px;
		}
	}

	.connection-passcode-container {
		display: flex;
		width: 368px;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		.code-auth-box {
			width: 64px;
			height: 80px;
			padding: 14px 16px 14px 16px;
			gap: 8px;
			border-radius: 16px;
			border: none;
			outline: none;
			font-weight: 700;
			font-size: 30px;
			text-align: center;

			&:focus {
				border: 1px solid white;
			}
		}
	}

	.join-options-header {
		font-size: 20px;
		font-weight: 600;
		margin: 48px 0 16px;
	}

	.sip-join-options {
		display: flex;
		width: 456px;
		justify-content: space-between;
		
		.sip-join-option {
			cursor: pointer;
			display: flex;
			align-items: center;
			border-radius: 100px;
			background-color: #09090A;
			font-size: 13px;
			font-weight: 600;
			padding: 16px 24px;
			height: 48px;

			img {
				margin-right: 8px;
			}
		}
	}

	.back-button {
		font-size: 13px;
		font-weight: 600;
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 60px;
		left: 60px;
		cursor: pointer;

		svg {
			margin-right: 8px;
		}
	}

	.brandlive-logo {
		position: absolute;
		bottom: 50px;
		left: calc(50% - 52px);
		opacity: 0.3;
		height: 40px;
		width: 104px;
	}

	.setup-help-link {
		background: linear-gradient(104.42deg, #ff6ae6 -4.67%, #fff85c 69.07%, #a6ff79 120.95%);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 13px;
		font-weight: 600;
		position: absolute;
		bottom: 60px;
		right: 60px;
	}

	.button-container {
		margin-top: 24px;

		.success {
			background: #FFFFFF0D;
			color: #E5E4EC;
		}

		.back {
			background: #4F4C57;
			color: #E5E4EC;
		}

		.next {
			color: black;
		}

		svg {
			margin-right: 8px;
		}
	}
}
