.meta-sharing {
	@include custom-scrollbar;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	height: 100%;

	.meta-share-tabs {
		position: relative;
		display: flex;
		align-items: center;
		overflow-y: auto;
	}

	.meta-share-invite {
		background-color: #26242B;
		flex-direction: column;
		z-index: 100;
		display: flex;
		padding: 24px;

		.meta-sharing-header {
			margin-bottom: 16px;

			label {
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				color: #FFFFFF;
			}
		}

		.meta-sharing-sub-header {
			margin-bottom: 8px;

			span {
				color: #BFBBCF;
			}
		}

		.share-link-wrapper {
			border-bottom: 1px solid #3C3A42;

			.share-link-container {
				.link {
					display: flex;
					align-items: center;
					height: 32px;
					background: #1A1A1F;
					border: 1px solid #4F4C57;
					box-sizing: border-box;
					border-radius: 100px;
					line-height: 20px;
					letter-spacing: -0.224px;
					color: #FFFFFF;
					padding: 6px 16px;
					white-space: nowrap;
					overflow: hidden;
					margin-bottom: 16px;
				}

				.share-link-controls {
					display: flex;
					justify-content: flex-end;
					margin-bottom: 24px;

					button {
						@include flex-center;
						height: 32px;
						background: #4F4C57;
						color: $near-white;
						border-radius: 100px;
						padding: 10px 16px;
						white-space: nowrap;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						line-height: 16px;
						display: flex;
						align-items: center;
						letter-spacing: 0.4px;
						color: #FFFFFF;
						transition: 150ms transform;

						&:active {
							transform: scale(0.97);
						}

						svg {
							margin-right: 8px;
							path {
								fill: $near-white !important;
							}
						}
					}
				}
			}
		}

		.invite-user-wrapper {
			margin-top: 24px;
			
			.input-form {
				display: flex;
				align-items: center;
				height: 32px;
				padding: 0 16px;
				background: #1A1A1F;
				border: 1px solid #4F4C57;
				box-sizing: border-box;
				border-radius: 100px;

				.input-form-icon {
					@include flex-center;
					cursor: pointer;
					margin-left: 8px;
				}

				input {
					margin: 0;
					background: none;
					border: none;
					padding: 6px 16px 6px 0;
					color: #FFFFFF;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: -0.224px;
				}
			}
		}

		.select-small {
			.select-label {
				height: 32px;
				justify-content: flex-end;
				border: none;
				padding: 6px 0 6px 0 !important;
				color: #BFBBCF !important;

				&.dropdown {
					svg {
						margin-left: 5.5px;

						path {
							fill: #BFBBCF !important;
						}
					}
				}
			}

			.select-body {
				background-color: $border-gray;
				width: 240px;
				border-radius: 12px;

				.select-option {
					justify-content: flex-start;
					height: 60px;
					flex-direction: row;

					.option-with-description {
						display: flex;
						flex-direction: column;
					}

					label {
						font-weight: 500;
						line-height: 20px;
					}

					span {
						color: $text-light;
						pointer-events: none;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.4px;
					}

					svg {
						margin-left: 1px;
						margin-right: 9px;
						width: 10px;
						height: 10px;
					}

					&:hover, .selected {
						background-color: #4F4C57;

						label {
							color: $color-white;
						}

						span {
							color: $color-white;
						}
					}
				}

				&.open {
					max-height: 300px;
				}
			}
		}

		.team-list {
			width: 100%;
			margin-top: 22px;

			.invite-presenter-row {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 34px;
				margin-bottom: 20px;
				margin-right: 0px;
				max-width: 100%;

				&:last-of-type {
					margin-bottom: 0px;
				}

				.online {
					position: absolute;
					left: 24px;
					bottom: 3px;
					width: 8px;
					height: 8px;
					border-radius: 100%;
					background: $ready-green-highlight;
					border: 1px solid #26242B;
				}

				.presenter-meta {
					display: flex;
					width: 302px;
					min-width: 0;
					align-items: center;

					.avatar {
						margin-right: 8px;
					}

					.presenter-info {
						display: flex;
						flex-direction: column;
						justify-content: center;
						margin-right: 12px;
						overflow: hidden;

						label {
							font-weight: bold;
							color: #BFBBCF;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						span {
							color: #BFBBCF;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.presenter-controls {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					margin-left: 0;

					label {
						border: none;
						color: #BFBBCF;
						white-space: nowrap;
					}

					button {
						display: flex;
						align-items: center;
						background: none;
						border: none;
						margin: 0;
						padding: 0;
						margin-left: 8px;
					}
				}
			}
		}

		.send-invite-button {
			@include flex-center;
			width: 100%;
			height: 32px;
			margin-top: 22px;
			background: $pale-green;
			border-radius: 100px;
			transition: 150ms transform;

			&:active {
				transform: scale(0.97);
			}

			span {
				margin-left: 9px;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.4px;
				color: $button-gray;
			}
		}

		.presenters-list, .watchers-list {
			margin-top: 24px;

			.heading {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-left: 11px;
				padding-right: 22px;

				label {
					font-size: 14px;
					font-weight: bold;
					color: $near-white;
					text-transform: uppercase;
				}

				span {
					justify-content: center;
					font-size: 14px;
					font-weight: bold;
					align-items: center;
				}

		
				.num-list {
					font-size: 15px;
					color: #545459;
				}

				.online-users-container {
					display: flex;
					align-items: center;
					color: $text-light;

					.online {
						margin-right: 6px;
					}
				}
			}

			.online {
				width: 10px;
				height: 10px;
				background: $pale-green;
				border-radius: 100%;
			}

			.offline {
				width: 10px;
				height: 10px;
				background: $border-gray;
				border-radius: 100%;
			}
		}
	}
	
	.scrolled-meta-container {
		@include custom-scrollbar(24px, 1);
		scrollbar-gutter: stable;
		overflow-y: auto;
		flex: 1;
		.meta-share-invite,
		.settings-container {
			padding: 24px 8px 24px 24px;
		}
	}

	.pending-requests {
		padding: 32px 24px;
		
		.bulk-actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 16px;

			button {
				@include flex-center;
				font-weight: 700;
				padding: 14px 24px;
				border-radius: 100px;

				svg {
					margin-right: 6px;
				}
			}
			.decline-all {
				background: #4F4C57;
				color: $near-white;
				margin-right: 16px;
			}

			.accept-all {
				background: $pale-green;
				color: $button-gray;
				flex: 1;
			}
		}

		.no-pending-requests {
			@include flex-center;
			font-weight: 500;
			color: #BFBBCF;

			p {
				margin: 0;
				margin-block-start: 0;
				margin-block-end: 0;
			}
		}
	}
}

.checkbox {
	flex-direction: row;
	display: flex;
	margin-bottom: 12px;

	label {
		color: $text-light;
		font-weight: normal;
		pointer-events: none;
		margin-bottom: 0;
		margin-left: 8px;
		transition: 60ms color;
		font-size: 14px;
	}

	.checkbox-outer {
		background-color: transparent;
		border: 1px solid $light-gray;
		border-radius: 3px;
		height: 16px;
		width: 16px;
		transition: 60ms background-color, 60ms border;

		svg {
			font-size: 12px;
			color: $dark-gray;
			margin-left: 0;
			position: relative;
			top: -1px;
			left: 1px;
			opacity: 0;
			transition: 60ms opacity;
			path {
				fill: $darker-gray !important;
			}
		}
	}

	&.checked {
		label {
			color: $color-white;
		}

		.checkbox-outer {
			background-color: $near-white;
			border: 1px solid $near-white;
		}

		svg {
			opacity: 1;
		
		}
	}
}

.invitations-tabs {
	border-bottom: 1px solid $border-gray;
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
	padding: 16px 32px;
	width: 100%;

	button {
		display: inline-block;
		padding: 0;
		flex: 1;
		position: relative;
		font-size: 16px;

		&.requests {
			@keyframes popIn {
				0% {
					transform: scale(0)
				}

				80% {
					transform: scale(1.2);
				}

				100% {
					transform: scale(1)
				}
			}

			&.has-requests {
				&::before {
					content: " ";
					height: 5px;
					width: 5px;
					background-color: $red;
					border-radius: 100%;
					position: absolute;
					margin-left: -13px;
					margin-top: 5px;
					animation: 150ms popIn;
					animation-timing-function: cubic-bezier(0.63, -0.01, 0.38, 1.4);
				}
			}
		}

		&.active {
			color: $white;
			
			.underline {
				border-radius: 100px;
				cursor: initial;
				height: 1px;
				border: 2px solid #A6FF79;
				position: absolute;
				width: 100%;
				bottom: -20px;
			}
		}
	}
}