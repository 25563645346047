.icon {
    path {
        transition: fill $transition-fast;
    }

    &.black {
        path {
            fill: $button-gray;
        }
    }
    &.gray {
        path {
            fill: $light-gray;
        }
    }
    &.light-black {
        path {
            fill: $darker-gray;
        }
    }
    &.green {
        path {
            fill: $dark-green;
        }
    }
    &.white {
        path {
            fill: $white;
        }
    }
    &.gray-presenter {
        path {
            fill: $gray-presenter;
        }
    }
    &.pale-green {
        path {
            fill: $pale-green;
        }
    }
    &.gray-placeholder {
        path {
            fill: $bl-grayscale-600;
        }
    }
    &.red {
        path {
            fill: $bl-semantic-red-400;
        }
    }
    &.dark-gray {
        path {
            fill: $bl-grayscale-500;
        }
    }
    &.dark-gray-2 {
        path {
            fill: $bl-grayscale-400;
        }
    }
    &.grayscale-100-disabled {
        path {
            fill: $bl-grayscale-300;
        }
    }
    &.grayscale-100 {
        path {
            fill: $bl-grayscale-100;
        }
    }
    &.grayscale-300 {
        path {
            fill: $bl-grayscale-300;
        }
    }
    &.grayscale-400 {
        path {
            fill: $bl-grayscale-400;
        }
    }
    &.grayscale-500 {
        path {
            fill: $bl-grayscale-500;
        }
    }
    &.grayscale-600 {
        path {
            fill: $bl-grayscale-600;
        }
    }
    &.grayscale-700 {
        path {
            fill: $bl-grayscale-700;
        }
    }
    &.grayscale-800 {
        path {
            fill: $bl-grayscale-800;
        }
    }
    &.grayscale-900 {
        path {
            fill: $bl-grayscale-900;
        }
    }
    &.grayscale-1000 {
        path {
            fill: $bl-grayscale-1000;
        }
    }
    &.grayscale-200, &.light-gray {

        path {
            fill: $bl-grayscale-200;
        }
    }
    &.text-light-gray {
        path {
            fill: $text-light-gray;
        }
    }
    &.text-light-gray2 {
        path {
            fill: $text-light-gray2;
        }
    }
    &.default-gray {
        path {
            fill: $default-gray;
        }
    }
    &.grayscale-1200 {
        path {
            fill: $bl-grayscale-1200;
        }
    }
    &.semantic-red-400 {
        path {
            fill: $bl-semantic-red-400;
        }
    }
    &.semantic-red-500 {
        path {
            fill: $bl-semantic-red-500;
        }
    }
    &.bl-hot-pink {
        path {
            fill: $bl-hot-pink;
        }
    }
    &.sr-primary-400 {
        path {
            fill: $bl-sr-primary-400;
        }
    }
}

////////////////////////////////////////////////////////
// Icons that also have other styles/adjustments
////////////////////////////////////////////////////////

#icon-check,
#icon-settings {
    rect {
        // Transparent background (checkmark is in a square)
        fill: #ffffff00;
    }
}

#icon-three-dots-vertical {
    transform: rotate(90deg);
}

// Rotate arrow-left to make up/down
#icon-arrow-up {
    transform: rotate(90deg);
}
#icon-arrow-down {
    transform: rotate(270deg);
}

#icon-titles {
    &.white {
        @include svg-fill($white);
    }
    &.default-gray {
        @include svg-fill($default-gray);
    }
}

.icon-previews {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: $bl-grayscale-1000;
    display:inline-block;
    overflow-y: auto;

    .icon-previews-preview {
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 4px;
        padding: 8px;
        border: 1px solid $bl-grayscale-300;
        border-radius: 12px;
        width: 200px;
        text-align: center;
        position: relative;
        height: fit-content;

        .icon-name {
            margin-left: 8px;
        }
    }

    .icon-previews-preview:nth-child(even) {
        background-color: $bl-grayscale-700;
    }

    .icon-previews-preview:nth-child(odd) {
        background-color: $bl-grayscale-900;
    }

    &.compact {
        background-color: $bl-grayscale-1100;
        display: flex;
        overflow-y: auto;
        flex-wrap: wrap;
        
        .icon-previews-preview {
            width: fit-content;
            .icon-name {
                display: none;
                position: absolute;
                width: fit-content;
                text-wrap: nowrap;
                background-color: $pale-green;
                border-radius: 99px;
                color: $bl-grayscale-1100;
                z-index: 99;
                padding: 8px 16px;
                font-weight: 600;
            }
    
            &:hover {
                .icon-name {
                    display: block;
                }
            }
        }

        .icon-previews-preview:nth-child(even) {
            background-color: $bl-grayscale-1100;
        }
        .icon-previews-preview:nth-child(odd) {
            background-color: $bl-grayscale-1100;
        }
    }
}