.share-sharebar {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	span {
		color: $text-gray;
		font-weight: 500;
		margin-right: 6px;
		z-index: 101;

		&.open {
			color: $near-white;
		}
	}

	button {
		z-index: 101;
	}

	button.open-share-popover {
		display: flex;
		justify-content: center;
		align-items: center;
		background: $border-gray;
		border-radius: 100%;
		padding: 0;
		margin-left: -4px;
		height: 32px;
		width: 32px;
		transition: background-color 150ms, color 150ms;

		&:hover {
			background: lighten($border-gray, 10%);
			color: $near-white;

			svg{
				path{
					fill: $near-white !important;
				}
			}
		}

		&.open {
			color: $near-white;
		}
	}

	.presenters {
		display: flex;
		align-items: center;
		flex: 1;
		position: relative;

		.avatar-container {
			margin-left: -4px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 0;
			
			.avatar-placeholder {
				width: 32px;
				height: 32px;
				border: $rem1px solid $border-dark-gray;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $border-gray;
				color: $text-gray;
				font-size: $rem12px;
				font-weight: 500;
				cursor: pointer;
				transition: 150ms background-color;
	
				&.popover-open{
					color: $near-white;
				}
	
				&:hover {
					background-color: lighten($border-gray, 10%);
				}
			}
		}

		@keyframes showAll {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		@keyframes shareSlideOut {
			from {
				margin-right: -6px;
			}

			to {
				margin-right: 0px;
			}
		}

		@keyframes shareSlideIn {
			from {
				margin-right: 0px;
			}

			to {
				margin-right: -6px;
			}
		}
	
		.show-all-container {
			position: absolute;
			display: flex;
			height: 100%;
			right: 0px;
			opacity: 1;
			animation: 150ms showAll;
			transition: 150ms opacity;

			.avatar-container {
				animation: 150ms shareSlideOut;
			}

			&.hiding {
				opacity: 0;

				.avatar-container {
					animation: 150ms shareSlideIn;
				}
			}
		}

		.dots {
			margin-left: 6px;
			@include flex-center;
			line-height: 0;
			padding: 0;
			border-radius: 100%;
			height: 27px;
			width: 27px;
			border: $rem1px solid $text-gray;
			background: transparent;
			font-size: 20px;
			transition: 150ms color, 150ms border;

			&:hover {
				color: $near-white;
				border-color: $near-white;
			}
	
			span {
				position: relative;
				top: -5px;
				text-align: center;
				flex: 1;
				display: block;
				align-self: center;
				left: 3px;
				color: inherit;
			}
		}
	}

	&.notes {
		position: relative;

		.share-popover {
			z-index: 38;
			top: 56px;
			right: -4px;
		}

		.presenters {
			.avatar-container {
				.avatar-placeholder {
					border: none;
				}
			}
		}

		button {
			z-index: 3;
		}

		span {
			z-index: 3;
		}
	}
}

.media-modal-top {
	.share-popover {
		right: 33px;
	}
}

.share-popover {
	box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
	position: absolute;
	width: fit-content;
	background-color: #26242B;
	display: flex;
	flex-direction: column;
	right: -32px;
	top: 64px;
	border-radius: 16px;
	z-index: 101;
	padding: 0 16px 16px 16px;
	opacity: 0;
	transition: transform 150ms ease-out, opacity 150ms;

	&.open {
		opacity: 1;
	}

	&.hidden {
		display: none;
	}

	&.off-screen {
		bottom: 0;

		.share-people-container {
			overflow-y: auto;

			.share-people {
				padding-right: 16px;
			}
		}
	}

	.search-bar {
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $border-gray;

		input[type="text"] {
			background-color: transparent;
			border: none;
			height: 39px;
			color: $near-white;
			margin: 0px;
			font-weight: normal;
			font-size: 14px;
			&::placeholder {
				color: $text-gray;
			}
		}
	}

	.icon-wrapper {
		height: 32px;
		width: 32px;
		background: $green;
		border-radius: 32px;
		@include flex-center;
	}

	.share-people-header {
		padding-top: 24px;
		padding-bottom: 16px;
		display: flex;
		justify-content: space-between;

		label {
			color: $text-gray;
			font-weight: bold;
		}

		.radio-buttons{
			display: flex;
		}
	}

	.share-people-container {
		padding-bottom: 30px;

		.share-people {
			@include custom-scrollbar;
			box-sizing: content-box;
			height: 100%;
			max-width: 366px;
			min-width: 343px;
			overflow-x: hidden;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 10px;
			}

			.share-person {
				display: flex;
				justify-content: space-between;
				max-width: 300px;
				height: 64px;
				border-radius: 16px;
				background-color: $border-gray;
				margin-bottom: 16px;
				align-items: center;
				padding-left: 22px;
				padding-right: 22px;
				transition: 150ms background-color;
				cursor: pointer;

				&:hover {
					background-color: lighten($border-gray, 10%);
				}

				.share-person-metadata {
					flex: 1;
					padding-left: 12px;
					padding-right: 12px;
					overflow: hidden;

					p {
						margin: 0;
						padding: 0;
						white-space: nowrap;
						text-overflow: ellipsis;
						max-width: 230px;
						overflow: hidden;
					}

					span {
						display: inline-block;
						max-width: 212px;
						font-size: 12px;
					}
				}

				.share-checkbox {
					border: 1px solid $text-light;
					border-radius: 3px;
					height: 11px;
					width: 11px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: 150ms border;

					&.selected {
						border: 1px solid $near-white;

						svg {
							opacity: 1;
						}
					}

					svg {
						font-size: 8px;
						position: relative;
						left: 0px;
						opacity: 0;
						transition: 150ms opacity;
					}
				}
			}
		}
	}
	
	button.share-note {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $red;
		color: $near-white;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 46px;
		@include flex-center;

		svg {
			margin-right: 6px;
		}
	}
}