@mixin pill-active {
	background-color: $border-gray;
	color: $near-white;
}

.pill-tabs {
	display: flex;
	width: 100%;
	padding: 24px 40px;

	&.width-full {
		padding: 24px 0;
	}

	button {
		@include button-greenroom-basic;
		background-color: $dark-gray;
		color: $text-gray;
		height: 36px;
		border-radius: 0;
		border: $rem1px solid $border-gray;
		outline: none;
		box-shadow: none;
		padding: 0;
		margin: 0;
		flex: 1;

		&.layouts, &.my-notes {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		&.titles, &.layouts, &.event {
			border-right: none;
		}

		
		&.titles, &.shared-with-me {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}

		&.titles {
			&.broadcastDisabled {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				border-right: 1px solid $border-gray;
			}
		}
	}

	&.event {
		.event {
			@include pill-active;
			
		}
	}

	&.layouts {
		.layouts {
			@include pill-active;
		}
	}
	
	&.titles {
		.titles {
			@include pill-active;
		}
	}

	&.titles {
		.titles {
			@include pill-active;
		}
	}

	&.streaming {
		.streaming {
			@include pill-active;
		}
	}

	&.my-notes {
		.my-notes {
			@include pill-active;
		}
	}

	&.shared-with-me {
		.shared-with-me {
			@include pill-active;
		}
	}
}

.join-waiting {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	border-radius: 100px;
	padding: 16px;
	background: $dark-gray;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		white-space: nowrap;
	}

	svg {
		margin-left: 6px;
	}
}

.round-green-outline {
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: $element-background-gray;
    border: 1px solid $pale-green;
    border-radius: 50%;
	height: 24px;
	width: 24px;
    overflow: hidden;

	&,
	* {
		cursor: pointer;
	}
}
