.chat-wrapper {
	.chat-list {
		max-height: calc(100vh - 150px);
		padding: 0 8px;
	}

	.chat-back-button {
		display: flex;
		background: none;
		border: none;
		padding: 0;
		margin-bottom: 15px;
		color: #ffffff;
		text-transform: uppercase;
		transition: 150ms color;

		svg {
			path {
				transition: 150ms fill; 
			}
		}

		&:hover {
			color: $near-white;

			svg {
				path {
					fill: $near-white;
				}
			}
		}
		span {
			margin-left: 6px
		}
	}

	.chat-group {
		@include custom-scrollbar;
		overflow-y: auto;
	}

	.chat-link-wrapper {
		transition: 150ms background;
		flex-direction: column;
		z-index: $z-index-chat-link-wrapper;
		height: 64px;

		&.open {
			justify-content: flex-start;
		}

		&.no-expand {
			cursor: initial;

			&:hover {
				background-color: $border-gray;
			}
		}

		.chat-link-container {
			.chat-icon {
				.icon-wrapper {
					background-color: #A6FF79;
					color: #1A1A1F;
					height: 32px;
					width: 32px;
					border-radius: 100%;
					@include flex-center;
				}
			}

			.chat-title {				
				max-height: 48px;
				line-height: 16px;

				.name {
					font-weight: 500;
				}

				.message, .name {
					width: calc(100% - 48px);
					font-size: 12px;
				}
			}

			.chat-right {
				span {
					text-transform: uppercase;
					padding: 5px 9px;
					background-color: $dark-gray;
					border-radius: $rem100px;
					font-size: 11px;
					white-space: nowrap;
					font-weight: 500;

					&.unseen {
						background-color: #A6FF79;
						font-size: 10px;
						border-radius: $rem100px;
						color: #1A1A1F;
						padding: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 16px;
						height: 16px;
					}
				}

				#icon-arrow-right{
					margin-left: 10px;
				}

				#icon-keyboard-arrow-up{
					margin-left: 13px;
				}
				#icon-keyboard-arrow-down{
					margin-left: 13px;
				}
			}
		}

		.chat-link-dropdown {
			max-height: 0;
			opacity: 0;
			transition: 150ms max-height, 150ms opacity;

			&.open {
				opacity: 1;
				max-height: 100%;
			}
		}	
	}

	.room-participants {
		display: flex;
		flex-wrap: wrap;

		.invite-badge {
			height: 28px;
			transition: background-color 150ms;
			max-width: 48%;
			
			span {
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				padding-left: 9px;
				padding-right: 9px;
				font-size: 12px;
				font-weight: bold;
				color: $text-light;
			}
		}
	}

	.room-wrapper {
		background-color: #26242B;
		padding: 24px 0 24px 24px;

		.chat-top {
			padding-right: 24px;
			
			.chat-link-wrapper {
				box-shadow: 0 0 0 rgba(0,0,0,0);
				transition: 150ms box-shadow;
	
				&.open {
					height: fit-content;
					box-shadow: $shadow-popover;
					max-height: 100%;
				}
			}
		}

		.chat-container {
			overflow-anchor: none;
			
			&.comments {
				height: 100%;
				padding-top: 0;
			}

			.messages-container {
				display: flex;
				flex-direction: column-reverse;
				overflow-y: auto;
				@include custom-scrollbar;
			}
		}

		.chat-bottom {
			padding-right: 24px;
		}

		.chat-form {
			display: flex;
			min-height: 36px;
			align-items: flex-end;

			.avatar-wrapper {
				padding-bottom: 3px;
			}

			.input-wrapper {
				display: flex;
				flex-direction: column;
				background-color: $dark-gray;
				border: $rem1px solid $border-gray;
				border-radius: $rem16px;
				min-height: 36px;
				margin-left: 8px;
				width: 100%;
				padding: 5px 0;
				max-width: calc(100% - 56px);

				.input-container {
					flex: 1;
					display: flex;
					align-items: center;
					position: relative;

					.chat-input {
						[contenteditable=true]:empty::before {
							 content: attr(placeholder);
						}
					}
					
					label {
						position: absolute;
						left: 8px;
						color: $text-gray;
						display: flex;
						align-items: center;
						top: 0;
						bottom: 0;
						pointer-events: none;
					}

					span {
						background: none;
						border: none;
						color: $near-white;
						flex: 1;
						padding: 0;
						padding: 0;
						margin: 0;
						margin-left: 8px;
						align-self: center;
						min-height: 16px;
					}

					.form-actions {
						display: flex;

						button {
							@include button-greenroom-basic;
							display: flex;
							background: none;
							border: none;
							padding: 0;
							margin: 0;
							color: $text-light;
							font-size: 24px;
							margin-right: 12px;
							outline: none;
							box-shadow: none;

							svg {
								height: 20px;
							}

							&.send {
								color: $med-gray;

								&.entered {
									color: $text-light;

									&:hover {
										color: lighten($text-light, 10%);
									}
								}
							}

							&:hover {
								color: lighten($text-light, 10%);
							}
						}
					}
				}
			}
		}
	}

	.chat-message-container {
		display: flex;

		&.reply {
			margin-left: 40px;
		}

		&.events-message {
			.avatar > img {
				object-fit: cover;
			}
		}

		.chat-message {
			margin-left: 8px;
			margin-bottom: 10px;
			background: $border-gray;
			padding: 12px 16px;
			border-radius: $rem16px;
			position: relative;
			overflow: hidden;

			.chat-message-meta {
				color: $text-light;
				margin-bottom: 2px;

				label {
					font-weight: 500;
				}

				span {
					font-size: 11px;
					margin-left: 5px;
				}
			}

			.chat-message-content {
				p {
					line-height: 16px;
					padding: 0;
					margin: 0;
				}

				strong {
					color: $red;
					font-weight: bold;
					text-transform: capitalize;
				}
			}

			img {
				max-width: 100%;
			}
		}

		.avatar-spacer {
			width: 32px;
			height: 32px;
			min-width: 32px;
			min-height: 32px;
		}

		.delete-message {
			display: flex;
			padding: 10px;
			cursor: pointer;
			opacity: 0;
			transition: opacity $transition-fast;

			svg {
				path {
					transition: fill $transition-fast;
				}
			}

			&:hover {
				svg {
					path {
						fill: $white;
					}
				}
			}
		}

		&:hover {
			.delete-message {
				opacity: 1;
			}
		}
	}

	.create-room-wrapper {
		

		.click-background {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 87;
		}
		
		.create-room-container {
			@include shadow-popover();
			@include custom-scrollbar;

			position: absolute;
			max-height: 0;
			opacity: 0;
			z-index: $z-index-header;
			background-color: $element-background-gray;
			padding: 0;
			pointer-events: none;
			border-radius: $rem16px;
			top: 72px;
			left: 32px;
			right: 32px;
			transition: opacity 150ms, max-height 150ms;
			overflow-y: auto;
			overscroll-behavior: none;
			
			.create-chat-room{
				@include button-greenroom-basic;
				background-color: $border-gray;
				
				span{
					color: $near-white;
					margin-left: 6px;
					
					@include font-poppins-14-500;
					line-height: 16px;
				}
			}
		}

		.create-room-form {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 16px 16px 0;

			input {
				padding: 0;
				margin: 0;
				background: none;
				border: none;
				color: $near-white;
				margin-left: 16px;
			}

			.icon-container {
				background: #A6FF79;
				border-radius: 100%;
				height: 32px;
				min-width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.new-room-participants {
			display: flex;
			flex-wrap: wrap;
			border-radius: $rem16px;
			margin: 16px;

			.invite-badge {
				display: flex;
				background-color: $border-gray;
				color: $text-light;
				align-items: center;
				border-radius: $rem100px;
				padding: 0 8px 0 0px;
				margin-right: 8px;
				margin-bottom: 8px;
				height: 28px;
				transition: background-color 150ms;
				cursor: pointer;

				span {
					padding-left: 8px;
					padding-right: 8px;
					font-size: 12px;
					font-weight: bold;
					color: $text-light;
					transition: 150ms color;
				}

				&.selected {
					span {
						color: $near-white;
					}
				}
			}

			.add-close-button {
				line-height: 0;
				svg {
					transition: 150ms transform;
				}
			}

			.add-close-button{
				line-height: 0;
				&.close-btn {
					svg {	
						transform: rotate(45deg);
					}
				}
			}
		}

		button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $border-gray;
			height: 40px;
			border-bottom-right-radius: 16px;
			border-bottom-left-radius: 16px;
			transition: 150ms color;

			&.ready {
				color: $near-white;
			}
		}

		&.open {
			@include absolute-fill;

			.dark-background {
				@include absolute-fill;
				background: rgba($darker-gray, 0.6);
				z-index: 10;
				animation: fadeIn 150ms;
			}
			
			.create-room-container {
				max-height: 70%;
				opacity: 1;
				pointer-events: initial;
			}
		}
	}

	.doc-wrapper {
		padding: 16px;
		width: 100%;

		.doc-background {
			border: none;
			margin: 0;
			border-radius: $rem8px;
			overflow: hidden;
			outline: none;
			box-shadow: none;
			width: 100%;
			position: relative;
			background: $darker-gray;
			text-align: center;

			.remove-attachment {
				position: absolute;
				right: 8px;
				top: 8px;
				cursor: pointer;
				font-size: 22px;

				svg {
					transform: rotate(45deg);
				}
			}

			img {
				height: 157px;
				object-fit: cover;
				pointer-events: none;
			}

			.attachment-waiting {
				height: 159px;
				width: 100%;
				background: $darker-gray;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.doc-title {
				display: flex;
				color: $near-white;
				height: 41px;
				pointer-events: none;
				background-color: $border-gray;

				.doc-type {
					transition: 150ms background-color;
					background-color: $med-gray;
					text-transform: uppercase;
					font-weight: 500;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 3px;
					white-space: nowrap;

					&.icon-file-pdf {
						background-color: $color-pdf;
					}

					&.icon-file-excel {
						background-color: $color-excel;
					}

					&.icon-file-powerpoint {
						background-color: $color-powerpoint;
					}

					&.icon-file-word {
						background-color: $color-word;
					}
				}

				.doc-name {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: pre;
    				display: block;
					transition: 150ms background-color;
					padding: 10px 0 10px 10px;
				}
				
				.doc-extension {
					white-space: nowrap;
					transition: 150ms background-color;
					padding: 10px 16px 10px 0;
				}
			}
		}

		&.in-message {
			padding: 0;
			&:hover {
				.doc-type {
					background-color: lighten($red, 5%);
				}
				
				.doc-title {
					background-color: lighten($border-gray, 5%);
				}
			}

			button {
				padding: 0;
			}
		}
	}
}

.chat-link-menu {
	background-color: $bl-grayscale-800;
	border-radius: $rem12px;

	button {
		border: none;
		width: 100%;
		border-radius: 0;
		text-align: left;
		color: #FFFFFF;
		background: transparent;
		white-space: nowrap;
		transition: 150ms background, 150ms color;

		&:hover {
			background: #3c3b44;
		}

		&:first-child {
			border-top-left-radius: $rem12px;
			border-top-right-radius: $rem12px;
		}

		&:last-child {
			border-bottom-left-radius: $rem12px;
			border-bottom-right-radius: $rem12px;
		}
	}
}