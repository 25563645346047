.invitations-alert {
	position: fixed;
	top: 16px;
	right: 16px;
	min-width: 250px;
	padding: 16px;
	z-index: 100;
	box-shadow: 0 5px 15px 5px rgba(0,0,0,0.1);
	transform: translateX(279px);
	transition: 150ms opacity, 150ms transform;
	background-color: rgba(33, 33, 33, 0.71);
	backdrop-filter: blur(10px);
	border-radius: $rem12px;
	opacity: 0;
	pointer-events: none;

	&.isOpen {
		opacity: 1;
		transform: translateX(0);
		pointer-events: all;
	}

	.invitation-alert-header {
		@include flex-center;
		justify-content: space-between;
		margin-bottom: 16px;

		span {
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			color: $text-light;
		}

		.dismiss {
			border-radius: $rem100px;
			background-color: $border-gray;
			color: $text-light;
			margin: 0;
			padding: 9px 16px;
			height: 27px;
			font-size: 12px;
			@include flex-center;
		}
	}

	.invites {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}