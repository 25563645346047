.content-generator-processing {
    background-color: $bl-grayscale-800;
    border-radius: $rem12px;
    color: $bl-grayscale-300;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */

    position: absolute;
    right: 16px;

    //added to make look a little better
    padding: 24px 24px;
    width: 38%;
    //end added

    z-index: 11;
    transition: 500ms top ease-in, 200ms opacity linear;

    svg {
        animation: 2s rotate forwards infinite linear;
    }

    .content-generator-processing-label {
        margin-left: 8px;
    }

    top: -120px;

    opacity: 0;

    &.processing {
        opacity: 1;
        top: 16px;
    }
}

.generate-content-title-title {
    padding: 4px 8px;
    margin: -6px 0 -6px -8px;
    box-sizing: content-box;

    width: 335px !important;
    min-width: 335px !important;
    max-width: 335px !important;

    border: 2px solid rgba(0,0,0,0);

    transition: border 0.1s, background-color 0.3s;
    cursor: text;
}

.generate-content-title-hover {
    border-radius: $rem24px;
    background-color: $bl-grayscale-800;
    user-select: none;
}

.generate-content-title-normal {
    border-radius: $rem24px;
    //background-color: $bl-grayscale-800;
    user-select: none;
}

.generate-content-title-pencil {
    position: absolute;
    margin-top: -18px;
    left: 340px;
    cursor: pointer;
}

.generate-content-title-title:active {
    background-color: $bl-grayscale-900;
    border: 2px solid $bl-grayscale-400 !important;
}

.generate-content-title-editing {
    background-color: $bl-grayscale-900;
    border: 2px solid $bl-grayscale-400 !important;
    border-radius: $rem24px;

    input {
        padding: 0;
        margin: 0 !important;
        border: none !important;
        background-color: transparent;
        user-select: none;
        max-width: 335px;
        color: $white;
        outline: none;
    }

    .generate-content-title-checkmark {
        position: absolute;
        margin-top: -18px;
        left: 340px;
        cursor: pointer;
        z-index: 22;
    }
}

.generate-content-title-text {
    max-width: 335px;
    input {

        /* H5 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.6px;
    }
}
