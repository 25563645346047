.video-thumbs {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	width: 100%;
	grid-template-rows: min-content;
	margin-bottom: 82px;

	.video-thumb {
		width: 100%;
		position: relative;
		border-radius: $radius-medium;
		overflow: hidden;
		opacity: 1;
		transform: translate(0, 0);

		&.highlight{
			z-index: 2;
		}

		&.is-dragging {
			display: none;
		}

		.video-wrapper {
			position: relative;
			height: 0;
			padding-bottom: 56%;
			border-radius: $radius-medium;

			.media-thumbnail-img-container {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				box-shadow: none;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					height: 100%;
					min-width: calc(100% + 0.5px);
					min-height: calc(100% + 0.5px);
					object-fit: contain;
				}

				&.fill {
					img {
						object-fit: cover;
					}
				}
			}
			
			.srt-thumbnail-img-container {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				min-width: calc(100% + 0.5px);
				min-height: calc(100% + 0.5px);
				object-fit: cover;
			}
	
			video {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 16px;
			}

			.video-overlay {
				@include absolute-fill;
				//border-radius: $radius-medium; //'glitchy' corners on video feeds
				overflow: hidden;

				.onsite-feed-menu {
					display: flex;
					position: absolute;
					top: 9px;
					right: 8px;
					z-index: 39;
					filter: $drop-shadow-context-menu;
					cursor: pointer;
				}

				.onsite-feed-controls {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;
					width: 100%;
					height: 100%;

					button {
						height: 32px;
						width: 32px;
						background-color: $bl-grayscale-600;
						z-index: $z-index-media-container-virtuoso-scroller;
						transition: 250ms all;

						&:hover, &:active {
							background-color: $bl-grayscale-500;
						}

						&.disabled {
							pointer-events: none;
							background-color: $bl-grayscale-700;

							svg {
								opacity: 0.4;

								path {
									fill: $white;
								}
							}
						}
					}
				}

				.poster {
					@include absolute-fill;

					background-color: $color-black;

					img {
						object-fit: cover;
						min-height: 101%;
						max-height: 101%;
						max-width: 101%;
						min-width: 101%;
					}

					.no-image-placeholder {
						@include absolute-fill;
						@include flex-center;
						font-size: 18px;
						font-weight: bold;
						background-color: #000;

						span {
							height: 48px;
							width: 48px;
							min-height: 48px;
							min-width: 48px;
							border-radius: 60px;
							@include flex-center;
						}
					}
				}

				.video-overlay-bottom {
					@include absolute-fill;
					top: 58.42%;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(1, 1, 1, 0.0001) 0%, #000000 93.16%);
					color: $color-white;
					pointer-events: none;

					label {
						position: absolute;
						bottom: 8px;
						white-space:nowrap;
						width: 90%;
						overflow: hidden;
						text-overflow: ellipsis;
						line-height: 18px;
						padding-left: 15px;

						&.is-producer{
							font-size: 14px;
							bottom: 30px;
						}
					}

					button {
						border: none;
						background: none;
						padding: 0;
						margin: 0;
						color: $near-white;
						position: absolute;
						bottom: 8px;
						right: 12px;
						font-size: 14px;
					}

					.producer-chat-button{
						//display: flex;
						display: none;
						justify-content: center;
						align-items: center;
						width: 46px;
						height: 46px;
						background: #54545A;
						opacity: 0.8;
						border-radius: 23px;
						bottom: 16px;
						right: 16px;
					}

					.onsite-location {
						display: flex;
						position: absolute;
						bottom: 8px;
						width: 100%;
						padding-left: 8px;

						label {
							position: relative;
							bottom: 0;
							padding-left: 4px;
							width: calc(90% - 12px);
						}
					}
				}

				.video-options {
					position: absolute;
					right: 6px;
					top: 6px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					justify-content: space-between;
					padding: 3px;
					border-radius: 99px;
					background-color: rgba(23, 23, 23, 0.7);

					&.media {
						min-height: 0;
					}

					button {
						padding: 0;
						width: 24px;
						height: 24px;
						border-radius: 100%;
						border: none;
						background-color: transparent;
						transition: 300ms background-color;
						
						svg {
							path {
								transition: 150ms fill;
								fill: $near-white;
							}
						}

						&:hover {
							
							svg {
								path {
									fill: $near-white;
								}
							}
						}

						&.muted {
							background-color: $red;
							svg {
								path {
									fill: $near-white;
								}
							}
						}
					}
				}
				
				&.selected {
					.selection-overlay {
						@include absolute-fill;
						background-color: rgba(0,0,0,0.5);
						border-radius: $radius-medium;
					}
				}

				.debug-ui {
					position: absolute;
					top: 4px;
					left: 4px;
					background-color: rgba(23, 23, 23, 0.7);
					border-radius: 12px;
					padding: 4px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					overflow: hidden;
					z-index: $z-index-video-debug;

					span {
						font-style: normal;
						font-weight: 900;
						font-size: 15px;
						color: $white;
						-webkit-text-stroke: 1px $darker-gray;
						letter-spacing: -0.05em;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					#icon-copy-filled {
						transform: scaleY(-1);
					}
				}
			}

			.selection-border {
				@include absolute-fill;
				border-radius: $radius-medium;
				pointer-events: none;

				&.in-shot {
					border: $rem3px solid $pale-green;
				}
				
				&.selected {
					border: $rem3px solid $red;
				}

			}

			.opaque-bar-left{
				position: absolute;
				left: 0%;
				top: 0%;
				bottom: 0%;
				background: #000000;
				mix-blend-mode: normal;
				opacity: 0.6;

				&.full {
					right: 100%;
				}

				&.sixth {
					right: 83.33%;
				}

				&.quarter {
					right: 75%;
				}

				&.third {
					right: 66.67%;
				}

				&.three-eighths {
					right: 63%;
				}

				&.three-by-four {
					right: 71.09%;
				}

				&.one-by-one {
					right: 78.125%;
				}

				&.four-by-three {
					right: 87.5%
				}

				&.three-by-two {
					right: 83.33%;
				}

				&.nine-by-sixteen {
					right: 65.73%;
				}
			}

			.opaque-bar-right{
				position: absolute;
				right: 0%;
				top: 0%;
				bottom: 0%;
				background: #000000;
				mix-blend-mode: normal;
				opacity: 0.6;

				&.full {
					left: 100%;
				}

				&.sixth {
					left: 83.33%;
				}

				&.quarter {
					left: 75%;
				}

				&.third {
					left: 66.67%;
				}

				&.three-eighths {
					left: 63%;
				}

				&.three-by-four {
					left: 71.09%;
				}

				&.one-by-one {
					left: 78.125%;
				}

				&.four-by-three {
					left: 87.5%
				}

				&.three-by-two {
					left: 83.33%;
				}

				&.nine-by-sixteen {
					left: 65.73%;
				}
			}
		}	
		
		&.selected {
			border: 2px solid green;
		}

		&.onsite-location {
			.video-overlay-bottom {
				opacity: 1;
				transition: 250ms opacity;
			}

			.onsite-feed-menu, 
			.onsite-feed-controls>button {
				opacity: 0;
				transition: 250ms opacity;
				pointer-events: none;

				&.off {
					background-color: $red;

					&:hover, &:active {
						background-color: $red-active;
					}
				}
			}

			.poster {
				opacity: 0;

				&.active {
					opacity: 1;
				}
			}

			.onsite-location-thumbnail-gradient {
				position: absolute;
				inset: 0;
				pointer-events: none;
				opacity: 0;
				transition: 250ms opacity;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.5) 100%),
					linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
			}

			:hover, 
			&.showing-menu {
				.video-overlay-bottom {
					opacity: 0;
				}

				.onsite-feed-menu, 
				.onsite-feed-controls>button {
					opacity: 1;
					pointer-events: auto;
				}

				.onsite-location-thumbnail-gradient {
					opacity: 1;
				}
			}
		}
	}

	.restricted-user-tooltip-wrapper {
		position: absolute;
		z-index: 2;

		.restricted-user-tooltip {
			border-radius: 4px;
			padding: 8px;
			background: $element-background-highlight-gray;
			@include flex-center;

			span {
				font-size: 14px;
				line-height: 20px;
			}
		}

		.restricted-user-triangle {
			position: absolute;
			top: -4px;
			left: calc(50% - 4px);
			width: 8px;
			height: 8px;
			transform: rotate(-45deg);
			background: #4F4C57;
		}
	}
}

.video-thumb-draggable {
	width: 150px;
	position: relative;
	border-radius: 16px;
	overflow: hidden;
	background-color: #000;

	.video-wrapper-draggable {
		position: relative;
		height: 0;
		padding-bottom: 56%;
		border-radius: 16px;

		.media-thumbnail-img-container {
			border-radius: 0px;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			box-shadow: none;
			display: flex;
			justify-content: center;
			border-radius: 8px;

			img{
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 16px;
		}

		.video-overlay {
			@include absolute-fill;
			//border-radius: $radius-medium; //'glitchy' corners on video feeds
			overflow: hidden;

			.poster {
				@include absolute-fill;

				img {
					object-fit: cover;
					min-height: 101%;
					max-height: 101%;
					max-width: 101%;
					min-width: 101%;
				}

				.no-image-placeholder {
					@include absolute-fill;
					@include flex-center;
					font-size: 18px;
					font-weight: bold;
					background-color: #000;

					span {
						height: 48px;
						width: 48px;
						min-height: 48px;
						min-width: 48px;
						border-radius: 60px;
						@include flex-center;
					}
				}
			}
		}
	}
}

.email-feed {
	.no-image-placeholder {
		@include absolute-fill;
		@include flex-center;
		font-size: 18px;
		font-weight: bold;
		background-color: #000;

		span {
			height: 48px;
			width: 48px;
			min-height: 48px;
			min-width: 48px;
			border-radius: 60px;
			@include flex-center;
		}
	}
}

.restricted-user {
	@include absolute-fill;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: rgba(26, 26, 31, 0.5);

	.restricted-user-icon {
		@include flex-center;
		width: 33px;
		height: 33px;
		background: #FF375F;
		border-radius: 100%;
	}
}

.video-thumb-drag-placeholder {
	min-width: 100%;
	padding-top: 56%;
	background-color: #151516;
	border-radius: 16px;
	box-shadow: inset 0 1px 7px #141415;
}