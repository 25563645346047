.app-entry-video-wrapper-outer {
	display: flex;
	flex-direction: column;
	height: calc(100vh - #{$sizing-header-onboarding});

	.app-entry-video-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
		padding: 40px;
		overflow: hidden;

		.app-entry-video-container {
			display: flex;
			flex-direction: column;
			
			.app-entry-video-container-wrapper {
				width: 560px;
				padding-top: 56.25%;
				position: relative;
				background: #000;
				border-radius: $rem16px;
				video {
					width: 100%;
					height: 100%;
					object-fit: contain;
					position: absolute;
					border-radius: $rem16px;
					top: 0;
				}
			}

			.controls {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 40px;

				div {
					display: grid;
					gap: 16px;
					grid-template-columns: 1fr 1fr;
					width: 48px + 48px + 16px;
					
					button {
						height: 48px;
						width: 48px;
						border-radius: 100%;
						color: $text-light;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
}
