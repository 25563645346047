.broadcast-container {
    display: flex;
    margin: 0 8px;

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes blink {
        from {
            opacity: 0.7;
            transform: scale(0.95);
        }

        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    .recording-button-container {
        &.live,
        &.cancel,
        &.starting {
            .select-small {
                opacity: 0;
            }
        }

        .broadcast-indicator {
            z-index: 1;
            border-radius: 100px 0 0 100px;
            width: 150px;
            min-width: initial;
            top: 0;
            left: 0;
            border-right: 1px solid $background-gray;
            background-color: $pale-green !important;
            justify-content: center;

            &:hover,
            &:active {
                background-color: lighten($pale-green, 10%) !important;
            }

            &.ready {
                cursor: pointer;
            }

            &.stopped {
                padding-right: 0;
            }

            &.live {
                background-color: $dark-gray !important;

                &.hovering {
                    background-color: $pale-green !important;

                    .broadcast-status {
                        color: $background-gray !important;
                    }
                }

                .broadcast-status {
                    color: $white !important;
                }

                .clock {
                    background-color: $border-gray;
                }
            }

            &.loading,
            &.live,
            &.cancel,
            &.starting {
                border-radius: 100px;
                border-right: 0 !important;
                width: 180px;

                &.hovering {
                    border-radius: 100px;
                    width: 180px;
                }
            }

            .broadcast-status {
                color: $background-gray !important;
                width: 100% !important;
                max-width: initial !important;
                justify-content: center;
            }

            .icon {
                path {
                    fill: $background-gray !important;
                }
            }
        }

        .select-small {
            width: 180px;
            padding: 0 !important;

            &.open {
                .select-body {
                    top: -1px;
                    left: -1px;
                }
            }

            .select-label {
                justify-content: right;
                color: $background-gray !important;
                background-color: $pale-green;
                font-weight: bold !important;
                padding: 8px !important;

                &:hover,
                &:active {
                    background-color: lighten($pale-green, 10%);
                }

                .icon {
                    margin-left: 20px;
                    margin-right: 3px;

                    path {
                        fill: $background-gray !important;
                    }
                }
            }

            .select-body {
                z-index: $z-index-recording-options;

                .select-option {
                    text-transform: capitalize;
                }
            }
        }

        &.record {
            .select-label,
            .broadcast-indicator {
                &:hover,
                &:active {
                    background: lighten($red, 10%) !important;
                }
            }

            .broadcast-indicator {
                border-right: 1px solid $white;

                &.live {
                    background-color: $dark-gray !important;

                    &.hovering {
                        background-color: $red !important;

                        .broadcast-status {
                            color: $white !important;
                        }
                    }

                    .broadcast-status {
                        color: $white !important;
                    }

                    .clock {
                        background-color: $border-gray;
                    }
                }
            }

            .select-label,
            .broadcast-indicator {
                background-color: $red !important;

                .broadcast-status {
                    color: $white !important;
                }

                .icon {
                    path {
                        fill: $white !important;
                    }
                }
            }
        }
    }

    .stream-state-badge {
        padding: 8px 16px;
        white-space: pre;
        border-radius: $rem50px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        font-weight: bold;
        background-color: $darker-gray;

        .live-dot {
            width: 6px;
            height: 6px;
            border-radius: $rem50px;
            background-color: $red;
            margin-right: 8px;
        }
    }

    .broadcast-indicator {
        height: 32px;
        display: flex;
        align-items: center;
        border-radius: $rem50px;
        transition: 150ms background-color, 150ms opacity;
        padding: 1px;
        min-width: 171px;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .broadcast-status {
            display: flex;
            align-items: center;
            padding: 0 8px;
            font-weight: 500;
            color: $near-white;
            transition: color 150ms;
            white-space: nowrap;

            svg {
                margin-right: 6px;

                path {
                    transition: 150ms fill;
                }
            }

            &.animate {
                svg {
                    animation: 2s rotate forwards infinite linear;
                }
            }
        }

        .clock {
            height: 100%;
            display: flex;
            align-items: center;
            border-radius: $rem50px;
            padding: 0 9px;
            transition: 150ms all;
            opacity: 1;
            box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.3);
            transform: scale(1);
            transform-origin: revert;
            white-space: nowrap;
            font-weight: 700;

            .header-time {
                color: $near-white;
                font-size: 14px;
                font-weight: 400;
            }

            .time-context {
                font-size: 10px;
                margin-right: 6px;
                text-transform: uppercase;
                font-weight: 400;
                position: relative;
                top: 1px;
            }
        }

        &.rehearsal {
            background-color: $eggplant;

            .broadcast-status {
                color: $lavendar;
            }

            .clock {
                background-color: $eggplant-light;
            }
        }

        &.loading {
            background-color: $loading-blue;
            justify-content: center;

            .broadcast-status {
                color: $blue-light;

                svg {
                    path {
                        fill: $blue-light;
                    }
                }
            }

            .clock {
                background-color: $loading-blue-light;
            }
        }

        &.stopped {
            background-color: $darker-gray;
            padding-right: 12px;
            justify-content: center;

            .live-circle {
                height: 8px;
                width: 8px;
                border-radius: 18px;
                background-color: $border-gray;
                margin-right: 8px;
                animation: 750ms blink alternate infinite linear;
            }

            .clock {
                max-width: 300px;
            }

            .broadcast-status {
                color: $near-white;
                max-width: 100px;
                transition: max-width 300ms 50ms cubic-bezier(0, 0.53, 0, 1.01);

                svg {
                    path {
                        fill: $near-white;
                    }
                }
            }

            &.hovering {
                background-color: $red;
                text-align: center;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                width: 150px;
                color: $near-white;

                .broadcast-status {
                    max-width: 200px;
                }

                .clock {
                    max-width: 0;
                    opacity: 0;
                    padding: 0;
                    transform: scale(0);
                }
            }
        }

        &.ready {
            @include button-greenroom-shadow;
            background-color: $ready-green;

            .broadcast-status {
                color: $green-light;

                &.hovering {
                    color: $near-white;
                }
            }

            .clock {
                background-color: $ready-green-light;
                max-width: 300px;
            }

            &.hovering {
                background-color: #ec0c00;
                text-align: center;
                cursor: pointer;
                align-items: center;
                justify-content: center;

                .clock {
                    max-width: 0;
                    opacity: 0;
                    padding: 0;
                    transform: scale(0);
                }
            }
        }

        @keyframes svgPop {
            from {
                opacity: 0;
                max-width: 0;
            }

            to {
                opacity: 1;
                max-width: 16px;
            }
        }

        svg {
            animation: svgPop 200ms;
        }

        &.live {
            background-color: $darker-gray;
            min-width: 113px;
            justify-content: space-between;

            .broadcast-status {
                color: $near-white;
                max-width: 100px;
                transition: max-width 300ms 50ms cubic-bezier(0, 0.53, 0, 1.01);

                svg {
                    path {
                        fill: $near-white;
                    }
                }
            }

            .live-circle {
                height: 8px;
                width: 8px;
                border-radius: 18px;
                background-color: $red;
                margin-right: 8px;
                animation: 750ms blink alternate infinite linear;
            }

            .clock {
                background-color: $dark-gray;
                max-width: 300px;
            }

            &.hovering {
                background-color: $red;
                text-align: center;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                width: 150px;
                color: $near-white;

                .broadcast-status {
                    max-width: 200px;
                }

                .clock {
                    max-width: 0;
                    opacity: 0;
                    padding: 0;
                    transform: scale(0);
                }
            }
        }

        &.error {
            background-color: $error-yellow;

            .broadcast-status {
                color: $near-white;

                svg {
                    path {
                        fill: $near-white;
                    }
                }
            }
            .clock {
                background-color: $error-yellow-light;
                color: $darker-gray;
            }
        }

        &.cancel {
            @include button-greenroom-shadow;
            background-color: $error-yellow;
            justify-content: center;

            .broadcast-status {
                color: $near-white;

                svg {
                    path {
                        fill: $near-white;
                    }
                }
            }

            &.hovering {
                cursor: pointer;
            }
        }
    }
}

.broadcasting-start-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;

    .broadcasting-start-modal {
        width: 50%;
        max-width: 620px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        position: absolute;
        overflow: visible;
        background-color: $gray;
        padding: 48px;
        justify-content: space-between;

        h4 {
            margin-bottom: 0;
        }

        .button-container {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            button {
                border-radius: 99px;
                color: white;
                min-width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    margin-right: 6px;
                }
                &.record {
                    background: $red;
                    color: $white;
                    svg {
                        path {
                            margin-right: 8px;
                            fill: $near-white;
                        }
                    }
                }
                &.stream {
                    background: $pale-green;
                    color: $dark-gray;
                }
                &.comms {
                    background: #666666;
                }
            }
        }
    }
}
