.create-note {
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $dark-gray;
	border-radius: $radius-medium;
	padding: 0 16px;
	cursor: pointer;
	transition: 150ms background;
	border: $rem1px dashed $med-gray;
	color: $text-light;
	font-weight: 500;
}

.notes-wrapper {
	.note-loading {
		@include absolute-fill;
		@include flex-center;
	}

	&.load-all > .note-container {
		.editor-controls {
			margin-right: 70px;
		}

		.note-sharebar {
			margin-right: 80px;

			.share-sharebar {
				margin-right: 5px;
			}
		}

		.note-editor {
			margin-right: 80px;

			.DraftEditor-root {
				padding-right: 0px;
			}
		}

	}

	.note-sharebar {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		span {
			color: $text-gray;
			font-weight: 500;
			margin-right: 6px;
		}

		button.open-share-popover {
			background: $border-gray;
			border-radius: 100%;
			padding: 0;
			height: 32px;
			width: 32px;
			transition: background-color 150ms, color 150ms;

			&:hover {
				background: lighten($border-gray, 10%);
				color: $near-white;
			}
		}
	}

	button.add-to-meeting {
		background: none;
		border: none;
		background-color: $border-gray;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $near-white;
		position: relative;
		left: 49px;
		font-size: 12px;
		padding: 3px 6px 3px 4px;

		svg {
			margin-right: 6px;
		}
	}
	
	.notes-container {
		min-height: calc(100% - 59px);
		padding-bottom: 24px;

		.note-link {
			min-height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: $border-gray;
			border-radius: $radius-medium;
			padding: 16px;
			cursor: pointer;
			transition: 150ms background;

			&:hover {
				background: #4F4C57;
			}

			.note-meta {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 80%;
				pointer-events: none;

				label {
					margin-bottom: 5px;
				}

				span {
					margin: 0 0 6px 0;
					padding: 0;
					font-size: 12px;
					line-height: 14px;
					color: $text-light;
				}
			}
		}

		.all-notes {
			.tab-row {
				display: flex;
				align-items: center;
				padding: 8px 0;
			}
		}
	}

	.note-container {
		@include custom-scrollbar;
		height: 100%;
		overflow-y: auto;

		.note-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 24px 24px 0 24px;

			.back {
				background: none;
			}

			input {
				padding: 0;
				margin-left: 8px;
			}
		}

		input {
			background-color: transparent;
			border: none;
			color: $near-white;
			font-weight: bold;
			margin: 0;

			&::placeholder {
				color: $med-gray;
			}
		}

		.editor-controls {
			position: sticky;
			bottom: 20px;
			left: 20px;
			border-radius: 100px;
			height: 56px;
			background-color: $border-gray;
			padding: 15px 40px;
			z-index: 2;
			margin-right: 16px;
			@include flex-center;

			button {
				@include flex-center;
				background: none;
				border: none;
				padding: 6px;
				margin: 0 3px;
				text-transform: uppercase;
				border-radius: 4px;
				transition: 150ms background;

				svg {
					path {
						transition: 150ms fill;
					}
				}

				&.active {
					background: $med-gray;

					svg {
						path {
							fill: $near-white !important;
						}
					}
				}

				&:hover {
					svg {
						path {
							fill: $near-white !important;
						}
					}
				}
			}

			.editor-controls-container {
				@include flex-center;
				flex: 1;

				&.editor-left {
					justify-content: flex-end;
				}

				&.editor-center {
					border-left: 1px solid #4F4C57;
					border-right: 1px solid $border-gray;
					padding: 0 6px;
				}

				&.editor-right {
					justify-content: flex-start;
					padding-left: 6px;
				}

				.font-popover {
					height: 112px;
					width: 267px;
					background-color: $border-dark-gray;
					border-radius: 12px;
					opacity: 0;
					transition: 250ms transform, 90ms opacity;
					transform: translateY(40px);
					position: absolute;
					flex-direction: column;
					bottom: 70px;
					right: 0px;
					z-index: 200;

					&.hidden {
						display: none;
					}

					.range-container {
						display: flex;
						justify-content: space-between;
						padding: 6px 10px 0;
						align-items: center;
						border-bottom: 1px solid $med-gray;
						height: 41px;

						span {
							color: $text-gray;
							font-weight: 500;
							padding: 6px 0;
							display: inline-block;
						}

						.font-small {
							font-size: 14px;
						}

						.input-wrapper {
							width: calc(100% - 80px);

							input {
								position: relative;
								top: -5px;
								flex: 1;
								margin: 0;
								-webkit-appearance: none;
								appearance: none;
								outline: none;
								background: $med-gray;
								height: 3px;
								border-radius: 30px;
								width: 100%;
								padding: 0;

								&::-webkit-slider-thumb {
									-webkit-appearance: none; /* Override default look */
									appearance: none;
									width: 24px;
									height: 24px;
									background: $near-white;
									border-radius: 100%;
									cursor: pointer;
								}

								&::-moz-range-thumb {
									width: 24px;
									height: 24px;
									background: $near-white;
									border-radius: 100%;
									cursor: pointer;
								}
							}
						}

						.font-large {
							font-size: 24px;
						}
					}

					.colors-container {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 12px 24px;
						border-top: 1px solid $med-gray;
						margin-top: 3px;
						button {
							height: 41px;
							width: 41px;
							border-radius: 100%;

							&.active {
								border: $rem2px solid $dark-gray;
								box-shadow: 0 0 0 $rem1px $near-white;
							}
						}
					}

					&.open {
						display: flex;
						opacity: 1;
						transform: translateY(0);

						&::after {
							content: " ";
							display: block;
							position: absolute;
							height: 17px;
							width: 17px;
							transform: rotate(45deg);
							right: 27px;
							bottom: -9px;
							background: $border-dark-gray;
						}
					}
				}
			}
		}

		.note-sharebar {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				color: $text-gray;
				font-weight: 500;
				margin-right: 6px;
			}

			button.add-to-meeting {
				background: none;
				border: none;
				background-color: $border-gray;
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $near-white;
				position: relative;
				left: 49px;
				font-size: 12px;
				padding: 3px 6px 3px 4px;

				svg {
					margin-right: 6px;
				}
			}

			button.open-share-popover {
				background: $border-gray;
				border-radius: 100%;
				padding: 0;
				height: 32px;
				width: 32px;
				transition: background-color 150ms, color 150ms;

				&:hover {
					background: lighten($border-gray, 10%);
					color: $near-white;
				}
			}


		}

		.note-editor {
			min-height: calc(100% - 120px);
			font-size: 18px;

			&.read-only {
				bottom: 0;
			}

			.DraftEditor-root {
				@include custom-scrollbar;
				padding: 24px 40px 24px 24px;
				overflow-y: auto;

				figure {
					margin: 0;
				}

				img {
					max-width: 100%;
					border-radius: 4px;
				}

				.align-right {
					* {
						text-align: right;
					}
				}

				.align-left {
					* {
						text-align: left;
					}
				}

				.align-center {
					* {
						text-align: center;
					}
				}

				li {
					margin-bottom: 8px;
				}
			}

			.public-DraftEditorPlaceholder-root {
				color: $med-gray;
				width: initial;
			}

			::-moz-selection { /* Code for Firefox */
				color: #fff;
				background: $green;
			}

			::selection {
				color: #fff;
				background: $green;
			}
		}


	}

	.presenters {
		display: flex;
		align-items: center;

		.avatar-container {
			margin-left: -4px;
		}

		.avatar-placeholder {
			width: 32px;
			height: 32px;
			border: 1px solid $border-dark-gray;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $border-gray;
			color: $text-gray;
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;
			transition: 150ms background-color;

			&:hover {
				background-color: lighten($border-gray, 10%);
			}
		}

		.dots {
			margin-left: 6px;
			@include flex-center;
			line-height: 0;
			padding: 0;
			border-radius: 100%;
			height: 27px;
			width: 27px;
			border: 1px solid $text-gray;
			background: transparent;
			font-size: 20px;
			transition: 150ms color, 150ms border;
			z-index: 1;

			&:hover {
				color: $near-white;
				border-color: $near-white;
			}

			span {
				position: relative;
				top: -5px;
				text-align: center;
				flex: 1;
				display: block;
				align-self: center;
				left: 3px;
				color: inherit;
				z-index: 1;
			}
		}
	}
}

.create-note-wrapper {
	position: sticky;
	bottom: 16px;
	margin: 0 24px 0 24px;
	width: inherit;

	.popover {
		top: -108px;
		right: auto;
		width: 100%;

		.option {
			padding: 14px 18px;
		}
	}
}

.notes-loading-container {
	@include flex-center;
	width: 100%;
	height: 100%;
}