// Base styles for popover - to be used elsewhere
.popover-overlay {
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-index-popover - 1;
}

//************************************************************
// Popover
//************************************************************

.popover {
	// clip-path: inset(0 0 0 0 round 12px);
	position: absolute;
	margin: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	z-index: $z-index-popover;
	min-width: fit-content;
	transition: $popover-transition-duration all,
	$popover-transition-duration opacity,
	$popover-transition-duration visibility;
	
	box-shadow: 0px 13px 30px 0px #00000040;
	
	border-radius: 12px;
		
	button {
		&.option {
			border-radius: 0;
		}
	}
		
	.option {
		@include button-greenroom-basic;
		transition: $hover-transition-duration all;

		background-color: $bl-grayscale-800;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 10px 16px;
		width: 100%;
		justify-content: left;

		.icon {
			margin-right: 8px;
		}

		&:first-child {
			border-radius: 12px 12px 0 0;
		}

		&:last-child {
			border-radius: 0 0 12px 12px;
		}
	}

	.text {
		white-space: nowrap;
	}

	.popover-header {
		color: $bl-grayscale-400;
		background-color: $bl-grayscale-800;
		border-radius: 12px 12px 0 0;
		font-size: 11px;
		font-weight: 600;
		line-height: 16px;
		padding: 8px 16px;
		text-align: left;
		letter-spacing: 1.3px;
		text-transform: uppercase;
		text-wrap: nowrap;
	}

	>p {
		color: $color-text-light;
		font-size: $rem12px;
		line-height: normal;
		margin: 0 0 10px;
		text-align: center;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			font-weight: $bold;
		}
	}

	&.center {
		left: 50%;
		transform: translateX(-50%);
	}

	&.active {
		opacity: 1;
		pointer-events: auto;
		visibility: visible;
	}

	.options {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
			line-height: 16px;
			max-width: 340px;
			position: relative;
			white-space: nowrap;
			text-align: left;
			letter-spacing: 0.02em;
			
			@include truncate();

			a {
				color: inherit;
			}

			&.selected {
				color: $color-link;
				font-weight: $bold;

				&:hover {
					background: none;
				}
			}

			&.delete {
				color: $color-red;
			}

			&.disabled {
				display: none;
			}

			.field-group {
				margin: 0;
			}

			i {
				font-size: $rem16px;
				margin-right: 8px;
				position: relative;
				top: 2px;
			}

			i.icon-close-circle {
				color: $color-text-lighter;
				font-size: $rem14px;
				position: absolute;
				right: 20px;

				&:hover {
					color: $color-text;
				}
			}
		}
	}

	.field-group.combo-inset {
		margin: 0 auto;
		width: calc(100% - 40px);

		a {
			font-size: $rem21px;
			right: 0;
		}
	}

	.field-group.typeahead {
		border-bottom: 1px solid $color-border-lighter;
		margin: 0 auto;
		width: calc(100% - 20px);

		input {
			font-size: $rem14px;
			padding-top: 11px;
			padding-bottom: 11px;
		}
	}

	.field-group.search {
		margin: 0 auto;
		width: calc(100% - 40px);
		margin-top: 9px;

		input {
			padding-top: 11px;
			padding-bottom: 11px;
			padding-left: 26px;
		}
	}

	&.has-caret {
		&.active {
			top: calc(100% + 15px);
		}

		&::before {
			@include caret(large);
			left: 50%;
			transform: translateX(-50%) rotate(-45deg);
		}

		// Added this as a hack. When the left side becomes fixed the giphy popover can become partially hidden becase
		// of z-index issues. Keep the giphy + emoji popovers in the comment section.
		&.giphy-popover {
			left: 93px !important;

			// caret on top of the popover
			&::before {
				left: 81px !important;
			}

			&.user-signed-in {
				left: 41px !important;

				&::before {
					left: 132px !important;
				}
			}
		}

		&.emoji-popover {
			left: 81px !important;

			// caret on top of the popover
			&::before {
				left: 116px
			}

			&.user-signed-in {
				left: 29px !important;

				&::before {
					left: 168px !important;
				}
			}
		}
	}

	&.destination-options {
		.option {
			.image {
				width: 100%;
				height: 100%;
				max-width: 24px;
				max-height: 24px;
			}
		}

		.divider {
			height: 1px;
			background-color: #09090A;
		}
	}

	&.chat-room-popover {
		top: 40px;
		right: 34px;
	}

	&.chat-user-popover {
		top: 40px;
		right: 9px;
	}
}

// This is for giphy + emoji popovers for reply comments only (which exist in comment-content-wrapper)
.comment-content-wrapper {
	.popover {
		&.has-caret {

			// Added this as a hack. When the left side becomes fixed the giphy popover can become partially hidden becase
			// of z-index issues.
			&.giphy-popover {
				left: 37px !important;

				&::before {
					left: 136px !important;
				}
			}

			// Added this as a hack. When the left side becomes fixed the giphy popover can become partially hidden becase
			// of z-index issues.
			&.emoji-popover {
				left: 25px !important;

				&::before {
					left: 172px !important;
				}
			}
		}
	}
}

//************************************************************
// Tooltip - Based on popover with adjust styles
//************************************************************

.tooltip-wrap {
	position: relative;
}

.tooltip,
%tooltip {
	@extend .popover;
	box-shadow: none;
	left: 50%;
	transform: translateX(-50%);
	padding: 10px 12px;

	p {
		text-align: center;
		font-weight: $semi-bold;
		font-size: $rem12px;
	}

	&::before {
		@include caret(small);
		top: -6px;
		left: 50%;
		transform: translateX(-50%) rotate(-45deg);
	}

	&.error {
		background: $color-alert-warn;
		border: none;

		p {
			color: $color-white;
			font-weight: $bold;
		}

		&::before {
			background: $color-alert-warn;
			border: none;
		}
	}
}

//************************************************************
// Helper - Info button with tooltip
//************************************************************

.helper {
	line-height: normal;
	margin-left: 5px;

	>span {
		font-size: $rem12px;
		font-weight: $semi-bold;

		+.tooltip-wrap {
			margin-left: 5px;
		}
	}

	i {
		color: $color-text-lighter;
		cursor: pointer;
		font-size: $rem16px;
		position: relative;
		top: 2px;
	}
}