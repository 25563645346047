.expanded-media-item {
    width: 379px;
    background-color: $bl-grayscale-800;
    z-index: $z-index-expanded-media-link;

    .expanded-media-contents {
        margin: $rem20px $rem16px 0 $rem16px;
        padding: 0;

        .canvas-recording {
            align-items: center;
            display: flex;
            position: relative;
            right: 0;
            left: 0;

            .thumbnail {
                .thumbnail-container {
                    border-radius: $rem8px;
                    height: 48px;
                    width: 84px;

                    img {
                        border-radius: $rem8px;
                        height: 48px;
                        object-fit: contain;
                        width: 84px;
                    }
                }
            }

            .canvas-label {
                margin-left: $rem12px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
            }

            .canvas-ellipses {
                position: absolute;
                right: 4px;
                cursor: pointer;
            }
        }
    }

    .expanded-media-header {
        align-items: center;
        color: $bl-grayscale-300;
        display: flex;
        font-weight: 600;
        font-size: $rem12px;
        letter-spacing: 0.02em;
        line-height: $rem20px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .iso-downloads-skeleton {
        .iso-row {
            display: flex;
            align-items: center;
            padding-bottom: $rem8px;
            padding-top: $rem8px;
            position: relative;
            right: 0;
            left: 0;

            .iso-column-1 {
                width: 75%;
                .iso-label {
                    max-width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis !important;
                    white-space: nowrap;
                    width: 90%;
                }
            }
            .iso-column-2 {
                position: absolute;
                right: 0;
                width: 24px;
            }
        }
    }

    .iso-downloads {
        .iso-row {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-bottom: $rem8px;
            padding-top: $rem8px;
            position: relative;
            right: 0;
            left: 0;

            .iso-column-1 {
                align-items: center;
                display: flex;
                width: 75%;

                .iso-label {
                    overflow: hidden;
                    text-overflow: ellipsis !important;
                    white-space: nowrap;
                    max-width: 85%;

                    /* font styling */
                    color: white;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                }

                .iso-tag {
                    margin-left: 4px;

                    /* font styling */
                    color: $bl-grayscale-400;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            .iso-column-2 {
                text-align: center;
                cursor: pointer;
                position: absolute;
                right: 4px;
                width: 20px;
            }
        }

        .iso-row:first-child {
            padding-top: 0;
        }
    }
}

.react-loading-skeleton {
    border-radius: 4px;
    height: $rem24px;
    --base-color: #3C3A42 !important; // color codes did not work here
    --highlight-color: #4F4C57 !important; // color codes did not work here
}