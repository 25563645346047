$gr-ios-btn-background-color: #A7FF79;
$scanned-dots-pulse-scale: 1.5;

.setup-container {

    .scanned-waiting-ios-stream {
        
        .scanned-waiting-texts {
            color: $gr-ios-btn-background-color;
            font-weight: 700;
            text-transform: capitalize;
        }
    
        .scanned-dots {
            margin: 0 4px;
    
            i {
                margin: 0 2px;
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: $gr-ios-btn-background-color;
                border-radius: 50%;
            }
    
            .scanned-dot-1 {
                animation: scanned-dot-1-pulse 2s linear infinite;
                -o-animation: scanned-dot-1-pulse 2s linear infinite;
                -moz-animation: scanned-dot-1-pulse 2s linear infinite;
                -webkit-animation: scanned-dot-1-pulse 2s linear infinite;
            }
    
            .scanned-dot-2 {
                animation: scanned-dot-2-pulse 2s linear infinite;
                -o-animation: scanned-dot-2-pulse 2s linear infinite;
                -moz-animation: scanned-dot-2-pulse 2s linear infinite;
                -webkit-animation: scanned-dot-2-pulse 2s linear infinite;
            }
    
            .scanned-dot-3 {
                animation: scanned-dot-3-pulse 2s linear infinite;
                -o-animation: scanned-dot-3-pulse 2s linear infinite;
                -moz-animation: scanned-dot-3-pulse 2s linear infinite;
                -webkit-animation: scanned-dot-3-pulse 2s linear infinite;
            }
    
            @keyframes scanned-dot-1-pulse {
                0% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
                25% {
                    transform: scale($scanned-dots-pulse-scale);
                    -webkit-transform: scale($scanned-dots-pulse-scale);
                    -o-transform: scale($scanned-dots-pulse-scale);
                    -moz-transform: scale($scanned-dots-pulse-scale);
                    -ms-transform: scale($scanned-dots-pulse-scale);
                }
    
                50% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
            }
    
            @keyframes scanned-dot-2-pulse {
                25% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
    
                50% {
                    transform: scale($scanned-dots-pulse-scale);
                    -webkit-transform: scale($scanned-dots-pulse-scale);
                    -o-transform: scale($scanned-dots-pulse-scale);
                    -moz-transform: scale($scanned-dots-pulse-scale);
                    -ms-transform: scale($scanned-dots-pulse-scale);
                }
    
                75% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
            }
    
            @keyframes scanned-dot-3-pulse {
                50% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
    
                75% {
                    transform: scale($scanned-dots-pulse-scale);
                    -webkit-transform: scale($scanned-dots-pulse-scale);
                    -o-transform: scale($scanned-dots-pulse-scale);
                    -moz-transform: scale($scanned-dots-pulse-scale);
                    -ms-transform: scale($scanned-dots-pulse-scale);
                }
    
                100% {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -o-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                }
            }
        }
    }

    .button-row {
        .button-ios {
            padding: 10px 15px !important;
            box-sizing: border-box;
            min-width: 55px;
        }

        .button-ios-yes {
            background-color: $gr-ios-btn-background-color !important;
            color: black !important;
        }

        .button-ios-no {
            margin: 0 !important;
        }
    }
}