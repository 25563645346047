.speed-test {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.header{
		font-family: Poppins;
		font-weight: bold;
		font-size: 28px;
		text-align: center;
		font-size: 24px;
		line-height: 28px;
		color: $white-presenter;
		margin: 0 40px 70px 40px;
	}

	.svg-container {
		position: relative;
	
		.text-overlay {
			@include absolute-fill;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			
			.meter {
				@include absolute-fill;
				color: $text-gray;

				span {
					color: $gray-presenter;
				}

				.meter-zero {
					position: absolute;
					left: -9px;
    				bottom: 1px;
				}

				.meter-one {
					position: absolute;
					left: -12px;
    				top: 41px;
				}

				.meter-two {
					position: absolute;
					top: -2px;
    				left: 29px;
				}

				.meter-three {
					position: absolute;
					top: -20px;
    				right: 45%;
				}

				.meter-four {
					position: absolute;
					top: -2px;
    				right: 25px;
				}

				.meter-five {
					position: absolute;    
					right: -21px;
    				top: 41px;
				}

				.meter-six {
					position: absolute;
					right: -32px;
					bottom: 1px;
				}
			}

			.total {
				font-size: 40px;
    			margin-top: 80px;
			}
		}
	}

	@keyframes scaleup {
		from {
			transform: scale(0);
			max-height: 0;
		}

		to {
			transform: scale(1);
			max-height: 46px;
		}
	}

	.done-actions {
    	top: 59px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;
		height: 0;
		
		svg {
			cursor: pointer;
			animation: scaleup 300ms;
			animation-timing-function: cubic-bezier(0.59, 0.01, 0.16, 1.57);
			transition: 150ms fill;

			&:hover {
				fill: lighten($green, 15%);
			}

			&.redo {
				&:hover {
					fill: lighten($red, 15%);
				}
			}
		}

		button {
			width: 46px;
			height: 46px;
			border-radius: 23px;
			display: flex;
			justify-content: center;
			align-items: center;

			&.redo{
				display: flex;
				background-color: $red;
			}

			&.success{
				display: flex;
				background-color: #019C00;
			}

			.redo{
				display: flex;
			}

			.success{
				display: flex;
			}
		}
	}

	.result-data {
		@include flex-center;
		border-top: 1px solid rgba($text-light, 0.15);
		height: 100px;
		margin-top: 60px;
		flex-direction: column;
		padding-top: 69px;
		margin-bottom: 50px;
		width: 100%;

		label {
			
			svg {
				margin-right: 8px;
			}

			color: $white-presenter;
			text-transform: uppercase;
			font-weight: bold;
			@include flex-center;
		}

		span {
			margin-top: 7px;
			color: $white-presenter;

			.waiting-indicator {
				position: relative;
				top: 5px;
				left: -5px;
			}

			.download-data {
				font-size: 40px;
				line-height: 47px;
				color: $near-white;
				margin-right: 4px;
			}
		}

	}

	.feedback {
		// border-top: 1px solid rgba($text-light, 0.15);
		// margin-top: 59px;
		// padding-top: 26px;
		z-index: 1;

		h5 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 6px;
		}

		p {
			padding: 0;
			margin: 0;
		}

		text-align: center;

		.poor {
			h5 {
				color: $red;
			}
		}

		.ok {
			h5 {
				color: $color-carrot;
			}
			
		}
		
		.good {
			h5 {
				color: $green;
			}
		}
	}
	
}