.sliding-menu-wrapper {
	padding-left: $rem8px;
	display: flex;

	.sliding-menu-options {
		display: flex;
		overflow-x: scroll;
		scrollbar-width: none;
	
		&::-webkit-scrollbar { 
			display: none;
		}
	}
	
	&.animate {
		.sliding-menu-underline {
			transition: left 0.4s ease-in, width 0.4s ease-in;
		}
	}

	.sliding-menu-option {
		cursor: pointer;
		position: relative;
		color: $med-gray;
		
		&.selected {
			color: #FFFFFF;
		}

		&::after {
			content: '';
			position: absolute;
			border-radius: $rem100px;
			height: 1px;
			width: 100%;
			background: $element-border-gray;
			left: 0;
			bottom: 1px;
		}

		.text {
			pointer-events: none;
		}
	}

	.sliding-menu-underline {
		border-radius: $rem100px;
		height: 3px;
		background: #A6FF79;
		left: 0;
		bottom: 0;
		z-index: 2;
	}
}

.sliding-menu-divider {
	position: absolute;
	left: 0;
	bottom: 1px;
	width: 100%;
	border-bottom: 1px solid #3C3A42;
}