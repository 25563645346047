.invite-presenters-wrapper {
	padding: 0px 40px 23px;
	z-index: 1;
	
	&:not(.open) {
		@include button-greenroom-basic;
	}

	.click-background {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 87;
	}

	.invite-presenters-container {
		background-color: $dark-gray;
		height: 46px;
		border-radius: 1.642rem;
		display: flex;
		align-items: center;
		position: relative;
		color: $text-light;	

		.label-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			cursor: pointer;
			padding: 16px;
			position: relative;
			
			transition: color 150ms;

			label {
				display: block;
			}

			svg {
				display: block;
			}
		}
	}

	.invite-body {
		@include shadow-popover();
		position: absolute;
		max-height: 0;
		opacity: 0;
		z-index: $z-index-overlay;
		width: 100%;
		background-color: $dark-gray;
		padding: 0;
		pointer-events: none;
		padding-top: 46px;
		border-radius: 1.642rem 1.642rem $rem16px $rem16px;
		top: 0;
		transition: opacity 150ms, max-height 150ms;

		.invites-list {
			background: $darker-gray;
			padding: 10px;
			border: 1px solid $border-dark-gray;
			display: flex;
			flex-wrap: wrap;
			border-radius: $rem16px;
			margin: 0 16px 16px 16px;

			.invite-badge {
				display: flex;
				background-color: $border-gray;
				color: $text-light;
				align-items: center;
				border-radius: $rem100px;
				padding: 0 8px 0 0;
				margin-right: 8px;
				margin-bottom: 8px;
				height: 28px;
				transition: background-color 150ms;
				cursor: pointer;

				span {
					padding-left: 8px;
					padding-right: 8px;
					font-size: 12px;
					font-weight: bold;
					color: $text-light;
				}

				svg {
				}

				&:hover {
					background-color: rgba($near-white, 0.15);
				}

				.add-close-button{
					line-height: 0;
					&.close-btn {
						svg {	
							transform: rotate(45deg);
						}
					}
				}

				.invite-badge-x-button {
					line-height: 0;
					cursor: pointer;
					padding: 0;
				}
			}

			form {
				width: 100%;
			
				input {
					background-color: transparent !important;
					padding: 0;
					color: $near-white;
					margin: 0;
					border: none !important;
					width: 100%;

					&.invitees-exist{
						margin-top: 6px;
					}
				}
			}				
		}

		.copy-link {
			display: flex;
			align-items: center;
			justify-content: center;
			@include button-greenroom-basic;

			span{
				color: $light-gray;
			}
		}

		.link-data {
			display: flex;
			flex-direction: column;
			padding: 0 16px 16px 16px;
			font-size: 14px;
			line-height: 16px;

			a {
				color: $white;
				text-decoration: none;
			}

			span {
				color: $white;
				margin-top: 10px;
			}
		}

		button {
			margin: 0;
			width: 100%;
			border-radius: 0;
			border-bottom-left-radius: 16px;
			border-bottom-right-radius: 16px;
			background-color: $border-gray;
			color: $near-white;
			font-weight: 500;
			font-size: 14px;

			svg {
				margin-right: 6px;
			}
		}
	}

	&.open {
		.invite-presenters-overlay {	
			@include absolute-fill;
			background: rgba($darker-gray, 0.6);
			z-index: 10;
			animation: fadeIn 150ms;
		}
		
		.label-row {
			color: $near-white;
			font-weight: 500;
			z-index: $z-index-overlay + 1;
		}

		.invite-body {
			max-height: 300px;
			opacity: 1;
			pointer-events: initial;
		}
	}
}