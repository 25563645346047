@import "vars-global";
@import "vars-colors";
@import "mixins-global";
@import "mixins";

//************************************************************
// Global Classes	
//************************************************************	

a.white {
	color: #878793;

	&:hover, &:active {
		color: #b3b3b3;
	}
}

.meta {	
	font-size: $rem12px;	
	line-height: normal;	
}	

.module-title {	
	display: block;	
	color: $color-gray;	
	font-weight: $xbold;	
	letter-spacing: -0.5px;	
	margin: 0 0 calc($global-padding / 2);	
}	

// Global Color classes	

.warning {	
	color: $color-warn;	
	font-weight: $semi-bold;	
}	

.unapproved {	
	color: $color-carrot;	
	font-weight: $semi-bold;	
}	

.required {	
	color: $color-required;	
}	

.attempts {	
	color: $color-text-lighter !important;	
	font-weight: $semi-bold;	
}	

.unique-views {	
	color: $color-text-lighter !important;	
	font-weight: $semi-bold;	
}	

.not-taken {	
	color: $color-text-lighter !important;	
	font-weight: $semi-bold;	
}	

.watched {	
	color: $color-text-lighter !important;	
	font-weight: $semi-bold;	
}

.completions {	
	color: $color-med-blue !important;	
	font-weight: $semi-bold;	
}	

.passed {	
	color: $color-passed !important;	
	font-weight: $semi-bold;	
}	

.failed {	
	color: $color-failed !important;	
	font-weight: $semi-bold;	
}	

.nottaken {	
	color: $color-text-lighter !important;	
	font-weight: $semi-bold;	
}	

.color-brand-orange {	
	color: $color-brand-orange;	
}	

.color-green {	
	color: $color-green;	
}	

.color-blue {	
	color: $color-blue;	
}	

.color-med-gray {	
	color: $color-med-gray;	
}	

.icon-gift {	
	color: $color-gift;	
}	

// Icon overrides	

i {	
	&.icon-file-powerpoint {	
		color: $color-powerpoint;	
	}	

	&.icon-file-pdf {	
		color: $color-pdf;	
	}	

	&.icon-file-excel {	
		color: $color-excel;	
	}	

	&.icon-file-word {	
		color: $color-word;	
	}	
}	

// Prevent scrolling	

.no-scroll {	
	overflow: hidden;	
}	

// User image - Avatar and default	

.avatar {	
	border-radius: 31%;	
	display: flex;	
	align-items: center;	
	justify-content: center;	
	width: $avatar-size-sm;	
	height: $avatar-size-sm;	

	> span {	
		color: $color-white;	
		display: block;	
		font-size: $rem12px;	
		font-weight: $bold;	
		line-height: 1.2;	
		word-break: normal;	
		word-wrap: normal;	
		transition: transform $popover-transition-duration ease-out;	
	}	

	&.medium {	
		width: $avatar-size-md;	
		height: $avatar-size-md;	

		> span {	
			font-size: $rem16px;	
		}	
	}	

	&.large {	
		width: $avatar-size-lg;	
		height: $avatar-size-lg;	

		> span {	
			font-size: $rem24px;	
		}	
	}	
}	

img.avatar {	
	background-color: transparent;	
	text-indent: -9999px;	
}	

// Empty state	

.empty-state {	
	align-items: center;	
	display: flex;	
	color: $color-metric;	
	flex-direction: column;	
	margin: ($global-padding * 2) auto;	
	max-width: 450px;	
	text-align: center;	

	> img {	
		display: block;	
		height: 160px;	
		width: auto;	
		margin: 0 0 $global-padding;	
	}	

	h3 {	
		margin: 0;	
	}	

	p {	
		margin: calc(($global-padding / 2)) 0;	
		font-size: $rem16px;	
		line-height: $rem26px;	
	}	

	button {	
		margin: 0;	
		min-width: 220px;	
	}	
}	

// Clear uploaded items	

.upload-clear {	
	color: $color-text-light;	
	line-height: 1;	
	font-size: $rem16px;	
	position: absolute;	
	right: 5px;	
	top: 5px;	
}	

// Close button (seen in drawers and modals)	

.close,	
.backtotop {	
	color: $color-text-light;	
	display: flex;	
	align-items: center;	
	justify-content: center;	
	font-size: 18px;	
	line-height: normal;	
	width: 36px;	
	height: 36px;	
	position: relative;	

	&::after {	
		background: $color-dark-gray;	
		border-radius: 50%;	
		display: block;	
		content: '';	
		position: absolute;	
		top: 0;	
		right: 0;	
		bottom: 0;	
		left: 0;	
		z-index: 1;	
		transform: scale(0);	
		transition: transform $hover-transition-duration;	
	}	

	> i {	
		display: block;	
		position: relative;	
		z-index: 2;	
	}	

	&:hover {	
		color: $color-white;	

		&::after {	
			transform: scale(1);	
		}	
	}	

	&.dark {	
		color: $color-gray;	

		&:hover {	
			color: $color-white;	
		}	
	}	

	&.small {	
		font-size: 14px;	
		width: 28px;	
		height: 28px;	
	}	

	// Correction for weird line height issue in font rendering for "X" icon	
	&:not(.small) > i[class*=icon-close] {	
		top: 1px; 	
	}	
}	

.width-full {
	width: 100%;
}

.width-calc-full {
	width: calc(100%);
}

.width-view-full {
	width: 100vw;
}

.height-full {
	height: 100%;
}

.height-calc-full {
	height: calc(100%);
}

.height-view-full {
	height: 100vh;
}

// Back button	

.navback {	
	font-size: $rem12px;	
	font-weight: $semi-bold;	
	line-height: normal;	
}	

// Delete Button
.delete-btn, .edit-btn {
	// position: absolute;
	// right: -5px;
	// top: -5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $border-dark-gray;
	border-radius: 100%;
	padding: 0;
	min-height: 31.8px;
	min-width: 31.8px;
	transition: background-color 150ms, color 150ms;

	&:hover {
		background: lighten($border-gray, 10%);
		color: $near-white;

		svg {
			path {
				fill: $near-white !important;
			}
		}
	}
}

// Disabled state	

[disabled],	
.disabled {	
	// cursor: not-allowed;	
	opacity: 0.45; // background: $color-off-white;	
	pointer-events: none;	

	&.clickable {	
		pointer-events: initial;	
	}	
}

// Hidden state	
[hidden],	
.hidden {	
	visibility: hidden !important;
} 

.align-items-center {
	display: flex;
	align-items: center;
}

.background-none {
	background: none;
}

.background-darker {
	background-color: #121114;
}

.background-dark {
	background-color: #1A1A1F;
}

.background-gray-hover {
	background: $element-background-highlight-gray;
	transition: 150ms background-color;

	&:hover, &:active {
		background-color: lighten($element-background-highlight-gray, 5%);
	}
}

.background-green {
	background-color: #A6FF79;
}

.background-highlight {
	transition: 150ms background-color;

	&:hover, &:active {
		background-color: #4F4C57;
	}
}

.background-light {
	background-color: $border-gray;
}

.background-lighter,
.background-grayscale-600 {
	background-color: $bl-grayscale-600;
}

.background-gray-600 {
	background-color: $bl-grayscale-600;
}

.background-grayscale-800 {
	background-color: $bl-grayscale-800;
}

.background-light-medium {
	background-color: rgba(255, 255, 255, 0.07);
}

.background-medium {
	background-color: #26242B;
}

.background-red {
	background-color: $red;

	&:hover, &:active {
		background-color: $red-active;
	}
}

.background-transparent {
	background-color: rgba(255, 255, 255, 0.07);
}

.background-warning {
	background-color: #EC0C00;
}

.border-none {
	border: none !important;
}

.border-light {
	border: $rem1px solid $border-gray;
}

.border-radius-small {
	border-radius: $rem8px;
}

.border-radius-medium-small {
	border-radius: 12px;
}

.border-radius-medium {
	border-radius: $radius-medium;
}

.border-radius-large {
	border-radius: $radius-large;
}

.border-radius-12 {
	border-radius: $rem12px;
}

.border-radius-100 {
	border-radius: $rem100px;
}

.button-greenroom-basic {
	@include button-greenroom-basic();
}

.button-background-shading {
	@include button-greenroom-basic();

	box-shadow: 		inset 0 -2px 3px rgba(0,0,0,.1);
	-moz-box-shadow: 	inset 0 -2px 3px rgba(0,0,0,.1);
	-webkit-box-shadow: inset 0 -2px 3px rgba(0,0,0,.1);

	&:active {
		-webkit-box-shadow:	inset 0 1px 3px 0 rgba(0,0,0,0.1);
		-moz-box-shadow: 	inset 0 1px 3px 0 rgba(0,0,0,0.1);
		box-shadow: 		inset 0 1px 3px 0 rgba(0,0,0,0.1);
	}
}

.button-active-shading {
	transition: box-shadow $transition-fast;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	-webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	-moz-box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
		-webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
		-moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	}
	
	&:active {
		box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
	}
}

.button-imprint-shading {	
	&:active {
		box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
	}
}

.corner-round-small {
	border-radius: $rem4px;
}

.corner-round-medium {
	border-radius: $rem8px;
}

.corner-round-large {
	border-radius: $rem16px;
}

.corner-round-massive {
	border-radius: 71.357rem;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-grab {
	cursor: grab;
}

.display-flex {
	display: flex;
}

.display-inline-block {
	display: inline-block;
}

.display-none {
	display: none !important;
}

.draggable {
	cursor: move;
	position: absolute;
}

.flex-flow-column {
	flex-flow: column;
}

.flex-grow {
	flex-grow: 100;
}

.font-size-48 {
	font-size: 48px;
}

.font-size-32 {
	font-size: 32px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-13 {
	font-size: 13px;
}

.font-size-12 {
	font-size: 12px;
}

.font-weight-bold {
	font-weight: 700;
}

.font-weight-normal {
	font-weight: 400;
}

.font-weight-heavy {
	font-weight: 500;
}

.slightly-bold {
	font-weight: 600;
}

.flex {
	display: flex;
}

.flex-1 {
	flex: 1;
}

.flex-align-center {
	display: flex;
	align-items: center;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-justify-center{
	display: flex;
	justify-content: center;
}

.flex-justify-flex-end{
	display: flex;
	justify-content: flex-end;
}

.font-size-large {
	font-size: 14px;
}

.gap-small {
	gap: 8px;
}

.height-32 {
	height: 32px;
	min-height: 32px;
	max-height: 32px;
}

.height-64 {
	height: 64px;
	min-height: 64px;
	max-height: 64px;
}

.height-fit-content {
	height: fit-content;
}

.justify-content-center {
	display: flex;
	justify-content: center;
}

.justify-content-end {
	display: flex;
	justify-content: flex-end;
}

.justify-content-left {
	display: flex;
	justify-content: left;
}

.justify-content-right {
	display: flex;
	justify-content: right;
}

.justify-content-space-around {
	display: flex;
	justify-content: space-around;
}

.justify-content-space-between {
	display: flex;
	justify-content: space-between;
}

.justify-content-space-evenly {
	display: flex;
	justify-content: space-evenly;
}

.line-height-16 {
	line-height: $rem16px;
}

.line-height-20 {
	line-height: $rem20px;
}

.margin-auto {
	margin: auto;
}

.margin-none {
	margin: 0;
}

.margin-small {
	margin: 8px;
}

.margin-medium {
	margin: 16px;
}

.margin-large {
	margin: 32px;
}

.margin-bottom-24 {
	margin-bottom: 24px;
}

.margin-bottom-tiny {
	margin-bottom: 2px;
}

.margin-bottom-extra-small {
	margin-bottom: 4px;
}

.margin-bottom-small {
	margin-bottom: 8px;
}

.margin-bottom-12 {
	margin-bottom: 12px;
}

.margin-bottom-medium {
	margin-bottom: 16px;
}

.margin-bottom-medium-large {
	margin-bottom: 24px;
}

.margin-bottom-large {
	margin-bottom: 32px;
}

.margin-left-xsmall {
	margin-left: 4px;
}

.margin-left-small {
	margin-left: 8px;
}

.margin-left-medium {
	margin-left: 16px;
}

.margin-left-large {
	margin-left: 32px;
}

.margin-left-auto {
	margin-left: auto;
}

.margin-right-extra-small {
	margin-right: 4px;
}

.margin-right-small {
	margin-right: 8px;
}

.margin-right-medium {
	margin-right: 16px;
}

.margin-right-large {
	margin-right: 32px;
}

.margin-right-12 {
	margin-right: 12px;
}

.margin-top-small {
	margin-top: 8px;
}

.margin-top-medium {
	margin-top: 16px;
}

.margin-top-large {
	margin-top: 32px;
}

.max-height-full {
	max-height: 100%;
}

.max-height-half {
	max-height: 50%;
}

.max-width-half {
	max-width: 50%;
}

.object-fit-contain {
	object-fit: contain;
}

.object-fit-cover {
	object-fit: cover;
}

.opacity-none {
	opacity: 0;
}

.overflow-hidden {
	overflow: hidden;
}
.overflow-scroll {
	overflow: scroll;
}

.overflow-hidden-x {
	overflow-x: hidden;
}

.overflow-scroll-y {
	overflow-y: auto;
	@include custom-scrollbar;
}

.padding-none {
	padding: 0;
}

.padding-small {
	padding: 8px;
}

.padding-medium {
	padding: 16px;
}

.padding-large {
	padding: 32px;
}

.padding-bottom-small {
	padding-bottom: 8px;
}

.padding-bottom-medium {
	padding-bottom: 16px;
}

.padding-bottom-large {
	padding-bottom: 32px;
}

.padding-button-small {
	padding: 8px 16px;
}

.padding-button-small-wide {
	padding: 8px 24px;
}

.padding-button-medium {
	padding: 12px 16px;
}

.padding-button-wide {
	padding: 12px 24px;
}

.padding-button-medium-wide {
	padding: 16px 24px;
}

.padding-button-large {
	padding: 18px 22px;
}

.padding-left-small {
	padding-left: 8px;
}

.padding-left-medium {
	padding-left: 16px;
}

.padding-left-large {
	padding-left: 32px;
}

.padding-right-small {
	padding-right: 8px;
}

.padding-right-medium {
	padding-right: 16px;
}

.padding-right-24px {
	padding-right: 24px;
}

.padding-right-large {
	padding-right: 32px;
}

.padding-top-none {
	padding-top: 0;
}

.padding-top-extra-small {
	padding-top: 4px;
}

.padding-top-small {
	padding-top: 8px;
}

.padding-top-medium {
	padding-top: 16px;
}

.padding-top-large {
	padding-top: 32px;
}

.pointer-events-none {
	pointer-events: none;
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
} 

.position-fixed {
	position: fixed;
}

.position-sticky {
	position: sticky;
}

.position-top {
	top: 0;
}

.position-bottom {
	bottom: 0;
}

.position-left {
	left: 0;
}

.position-right {
	right: 0;
}

.text-align-center {
	text-align: center;
}

.text-align-left {
	text-align: left;
}

.text-align-right {
	text-align: right;
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-transform-capitalize {
	text-transform: capitalize;
}

.text-dark {
	color: #1A1A1F;

	.icon {
		path {
			fill: #1A1A1F !important;
		}
	}
}

.text-dark-gray {
	color: $text-gray;

	.icon {
		path {
			fill: $text-gray !important;
		}
	}
}

.text-light {
	color: #696969;

	.icon {
		path {
			fill: #696969 !important;
		}
	}
}

.text-gray {
	color: #BFBBCF;

	.icon {
		path {
			fill: #BFBBCF !important;
		}
	}
}

.text-light-gray,
.text-gray-200 {
	color: $bl-grayscale-200;

	.icon {
		path {
			fill: $bl-grayscale-200 !important;
		}
	}
}

.text-gray-400 {
	color: $bl-grayscale-400;

	.icon {
		path {
			fill: $bl-grayscale-400 !important;
		}
	}
}

.text-gray-1200 {
	color: $bl-grayscale-1200;

	.icon {
		path {
			fill: $bl-grayscale-1200 !important;
		}
	}
}

.text-light-gray2 {
	color: $text-light-gray2;

	.icon {
		path {
			fill: $text-light-gray2 !important;
		}
	}
}

.text-green {
	color: #A6FF79;

	.icon {
		path {
			fill: #A6FF79 !important;
		}
	}
}

.text-highlight {
	&:active, &:hover {
		color: #ffffff !important;

		.icon {
			path {
				fill: #ffffff !important;
			}
		}
	}
}

.text-white {
	color: #ffffff;

	.icon {
		path {
			fill: #ffffff !important;
		}
	}
}

.text-warning {
	color: #FF5F7F;

	.icon {
		path {
			fill: #FF5F7F !important;
		}
	}
}

.text-red {
	color: #FF0000;

	.icon {
		path {
			fill: #FF0000 !important;
		}
	}
}

.transform-none {
	transform: none !important;
}

.truncate-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-overflow-2-lines {
	@include line-clamp(2);
}

.white-space-nowrap {
	white-space: nowrap;
}

.width-fit-content {
	width: fit-content;
}

.overflow-visible {
	overflow: visible;
}

.no-select {
	user-select: none;
}

.border-radius-round {
	border-radius: 999px;
}

.badge-green {
	color: $bl-grayscale-900;
	background-color: $bl-gr-primary-500;
	box-shadow: 0px 0px 20px $glow-green;
	text-transform: uppercase;
	padding: 4px 12px;
	transition: background-color $transition-med, box-shadow $transition-med;

	&:hover {
		background-color: lighten($bl-gr-primary-500, 5%);
		box-shadow: 0px 0px 25px $glow-green;
	}
}

.fade-in-out-enter {
    opacity: 0;
}

.fade-in-out-enter-active {
    opacity: 1;
    transition: opacity $transition-fast;
}

.fade-in-out-exit {
    opacity: 1;
}

.fade-in-out-exit-active {
    opacity: 0;
    transition: opacity $transition-fast;
}

.transition-opacity {
	transition: opacity $transition-fast;
}

.scrub-click-area {
	position: relative;

	&::before {
		position: absolute;
		content: " ";
		left: 0;
		right: 0;
		height: 16px;
		top: -4px;
	}
}

.lighten-on-hover,
.light-dark {
	&:hover {
		filter: brightness(1.1);
	}	
}

.darken-on-click,
.light-dark {
	&:active {
		filter: brightness(0.9);
	}	
}

.mirror-element {
	transform: scaleX(-1);
}

.feathered-scroll-container {
	/* scroll bar width, for use in mask calculations */
    --scrollbar-width: 16px;

    /* mask fade distance, for use in mask calculations */
    --mask-height: 32px;
	--half-mask-height: calc(var(--mask-height) / 2);

    /* Need to make sure container has bottom space, otherwise content at the bottom is always faded out */
    padding-bottom: calc(var(--mask-height)/1.6);
	padding-right: calc(var(--scrollbar-width) / 4);
	padding-top: var(--half-mask-height);

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--half-mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );

    /* Here we scale the content gradient to the width of the container minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - (var(--scrollbar-width) / 2)) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar. The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    /* Position the content gradient in the top left, and the scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
}


.slider-input {
	display: flex;
	align-items: center;
	
	input {
		flex: 1;
		-webkit-appearance: none;
		appearance: none;
		outline: none;
		background: $med-gray;
		border-radius: 30px;
        
		&::-webkit-slider-thumb {
		  -webkit-appearance: none; /* Override default look */
		  appearance: none;
		  margin-top: -5px;
		  width: 14px;
		  height: 14px;
		  background: $near-white;
		  border-radius: 100%;
		  cursor: pointer;
		}
	
		&::-moz-range-thumb {
		  width: 14px;
		  height: 14px;
		  background: $near-white;
		  border-radius: 100%;
		  cursor: pointer;
		}
	  }
	  
}

.breaker {
	height: 1px;
	background-color: $bl-grayscale-600;
}