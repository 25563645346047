
//************************************************************
// Custom Field Groups and form styling
//************************************************************

// For use when it's necessary to group a field with other objects

.field-group {
	margin: $margin-field;
	position: relative;

	select,
	input,
	textarea,
	button,
	.button {
		margin: 0 !important;
	}

	// Label styles
	&.input,
	&.textarea {
		label {
			display: block;
			color: $color-text-placeholder;
			font-size: $rem11px;
			font-weight: $semi-bold;
			line-height: $rem11px;
			margin-bottom: 8px;
		}
	}

	+ .add-option {
		display: block;
		font-size: $rem11px;
		font-weight: $semi-bold;
		letter-spacing: 1px;
		line-height: normal;
		margin-top: -10px;
		text-transform: uppercase;
	}	

	.input-error {
		color: $color-text-light;
		font-size: $rem11px;
		@include animation(fadeIn 0.2s);
		@include position(middle);
		right: 15px;
		transition: opacity $field-transition-duration;
		opacity: 1;

		&.static {
			transform: translateY(0px);
			position: absolute;
			bottom: 25px;
			top: initial;
		}
	}

	select,
	input,
	textarea {
		&.required ~ .input-error {
			color: $color-alert-warn;
		}

		&.entered ~ .input-error {
			opacity: 0;
		}
	}
}

.field-meta {
	color: $color-text-placeholder;
	display: block;
	font-size: $rem12px;
	font-weight: $bold;
	line-height: normal;
	margin: 5px 0;
}
// Date picker

.field-group.date {
	> i.icon-calendar {
		color: $color-text-placeholder;
		font-size: $rem16px;
		position: absolute;
		@include position(middle);
		left: 20px;
		transition: $field-transition-duration color;
	}

	input[type="text"] {
		padding-left: 50px;

		&:focus ~ i {
			color: $color-blue;
		}
	}
}

// Date / Time combination

.date-time {
	max-width: 400px;
	margin: auto;

	.field-group.date {
		margin: 0 0 16px;
	}
	.field-group.select {
		margin: 0;
	}

	.add-time {
		display: block;
		font-size: $rem12px;
		font-weight: $semi-bold;
		text-align: center;
	}
}

// Dynamic Field

@mixin dynamic-label() {
	color: $color-text-placeholder;
	line-height: normal;
	font-size: $rem15px;
	transition: top $field-transition-duration,
				transform $field-transition-duration,
				font-size $field-transition-duration;
	@include position(middle);
	left: 15px;
	max-width: 100%;
	@include truncate();
	order: 1;
	pointer-events: none;
}

@mixin dynamic-label-focused() {
	color: $color-text-light;
	font-size: $rem11px;
	font-weight: $semi-bold;
	transform: translateY(0);
	top: 2px;
}

.field-group.input-dynamic,
.field-group.select-dynamic {
	display: flex;
	flex-direction: column;

	label {
		@include dynamic-label();
	}

	.input-error  {
		line-height: normal;
		transition: top $field-transition-duration,
					transform $field-transition-duration,
					font-size $field-transition-duration,
					opacity $field-transition-duration;
		pointer-events: none;
	}

	// Used when a text button is necessary over a field (example: Show password)
	> a {
		font-size: $rem12px;
		font-weight: $semi-bold;
		@include position(middle);
		right: 15px;
	}

	select {
		@include field-base();
		cursor: pointer;
	}

	input,
	select {
		order: 2;
		padding: $padding-field; // Reset input padding to override "small" class. Small size can't be supported with dynamic inputs
		height: 42px;

		&:focus,
		&.entered {
			padding: $padding-field-dynamic-focused;

			+ label,
			+ label + .input-error {
				@include dynamic-label-focused();
			}
		}

		&:-webkit-autofill {
			padding: $padding-field-dynamic-focused;

			+ label,
			+ label + .input-error {
				@include dynamic-label-focused();
			}
		}

		&.large {
			padding: $padding-field-large;
			height: 54px;

			+ label {
				left: 15px;
			}
			
			&:focus,
			&.entered {
				padding: $padding-field-large-dynamic-focused;

				+ label,
				+ label + .input-error {
					top: 6px;
				}
			}

			&:-webkit-autofill {
				padding: $padding-field-large-dynamic-focused;

				+ label,
				+ label + .input-error {
					top: 6px;
				}
			}
		}

		&.minimal {
			padding: $padding-field-minimal;
			height: 54px;

			+ label {
				left: 0;
			}

			+ label + .input-error {
				right: 0;
			}

			.input-error,
			~ a {
				right: 0;
			}
			
			&:focus,
			&.entered {
				padding: $padding-field-minimal;
				padding-top: 14px;

				+ label,
				+ label + .input-error {
					font-size: $rem12px; // make font a little larger for minimal fields
					top: 0;
				}
			}
			
			&:-webkit-autofill {
				padding: $padding-field-minimal;
				padding-top: 14px;

				+ label,
				+ label + .input-error {
					font-size: $rem12px;
					top: 0;
				}
			}
		}
	}

	textarea {
		padding: $padding-field-textarea-dynamic;

		+ label {
			transform: none;
			top: $padding-field-large-top;
			left: 15px;
		}

		&:focus,
		&.entered {
			padding-top: 21px;

			+ label,
			+ label + .input-error {
				font-size: $rem11px;
				transform: translateY(0);
				top: 5px;
			}
		}
	}
}

// Textarea field group - for dynamic text area placeholder text

.field-group.textarea {
	.textarea-placeholder {
		border: $rem1px solid transparent;
		color: $color-text-placeholder;
		display: block;
		font-size: $rem14px;
		line-height: 2rem;
		padding: $padding-field-textarea;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-height: 100%;
		overflow: scroll;
		z-index: 2;
	}

	textarea {
		position: relative;
		z-index: 1;

		&[ng-reflect-model] + .textarea-placeholder {
			display: none;
		}

		&[ng-reflect-model=""] + .textarea-placeholder {
			display: block;
		}
	}
}

// Replaced "typeahead" here with "search" instead.

.field-group.search {
	input {
		padding-left: 35px;

		&:hover,
		&:focus {
			box-shadow: none;
			border-color: $color-text-light;
		}

		&:focus + .icon-search {
			color: $color-text;
		}

		&.small + .icon-search {
			font-size: 14px;
		}
		
		&.large {
			padding-left: 45px;

			+ .icon-search {
				font-size: 20px;
			}
		}

		&.minimal {
			padding-left: 25px;

			+ .icon-search {
				left: 0;
			}
		}
	}

	.icon-search {
		color: $color-text-lighter;
		position: absolute;
		top: calc(50% + 1px);
		transform: translateY(-50%);
		left: 15px;
		font-size: 16px;
		z-index: 2;
		transition: $field-transition-duration color;
	}
}

// Time field

.time {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.field-group {
		flex-grow: 1;
	}

	> span {
		display: block;
		width: 16px;
		text-align: center;
	}

	+ .time {
		margin-bottom: 10px;
	}
}

// File input (basic)

.field-group.file {
	margin: $margin-field;

	.upload-file {
		position: relative;
	}

	.upload-file-btn {
		@include button-base();
		min-width: 0;
		margin: 0;
		position: relative;
		width: auto;

		input[type="file"] {
			display: none;
		}
	}

	button.cancel {
		width: 100%;
		margin-top: 15px !important;
	}
}

// File input (combo field + button)

.field-group.file-combo {
	display: flex;
	justify-content: center;
	margin: $margin-field;

	.upload-file-label {
		background: $color-off-white;
		border: $rem1px solid $color-field-border;
		border-right: 0;
		border-radius: $radius-field 0 0 $radius-field;
		flex: 2;
		font-size: $rem12px;
		padding: 12px 20px 0;
		line-height: 100%;
		@include truncate();

		&:empty,
		&:empty + .upload-file .upload-clear {
			display: none;
		}

		&:not(:empty) {
			+ .upload-file {
				.upload-clear {
					display: block;
				}
				.upload-file-btn {
					border-radius: 0 $radius-field $radius-field 0;
				}				
			}
		}
	}

	.upload-file {
		position: relative;
		flex: 1;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			flex-basis: 0px;
		}
		.upload-clear {
			@include position(middle);
			left: -24px;
			line-height: 0;
			width: 14px;
			height: 14px;

			i {
				font-size: $rem14px;
				color: $color-text-placeholder;
			}

			&:hover i {
				color: $color-link;
			}
		}

		.upload-file-btn {
			@include button-base();
			min-width: 0;
			margin: 0;
			position: relative;
			width: auto;
			
			input[type="file"] {
				display: none;
			}
		}
	}
}

// Drag and Drop

.field-group.drag-drop {
	background: $color-white;
	border-radius: $radius-container;
	border: $rem2px dashed $color-border-light;
	cursor: pointer;
	padding: 30px 15px;
	position: relative;
	text-align: center;
	transition: border-color $hover-transition-duration;

	&:hover {
		border-color: $color-link;
	}

	input[type="file"] {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 2;
	}

	.input-error {
		color: $color-alert-warn;
	}

	> span {
		color: $color-text-light;
		font-size: $rem16px;
		position: relative;
		padding-left: 42px;

		.icon-camera {
			color: $color-text-lighter;
			font-size: $rem32px;
			@include position(middle);
			left: 0;
		}

		b {
			color: $color-link;
			font-weight: $semi-bold;
		}
	}

	&.large {
		padding: 150px 15px;
	}

	&.entered {
		padding: 30px 15px;
	}

	&.video-thumb {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			border-radius: calc($radius-container / 2);
			display: block;
			width: 100%;
			height: auto;
			max-height: 100%;
			max-width: 100%;
			object-fit: cover;
		}

		> span {
			padding: 0;
		}
	}
}

// Field / Button combination

.field-group.combo {
	display: flex;

	> input {
		margin: 0;
	}

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="url"],
	input[type="tel"] {
		border-radius: $radius-field 0 0 $radius-field;
		border-right: 0;
		flex-grow: 1;

		&:focus {
			box-shadow: 0 0 6px 0 rgba($color-blue, .25);
			border-color: $color-blue;
		}
	}

	a.button,
	button,
	input[type="button"],
	input[type="submit"] {
		border-radius: 0 $radius-field $radius-field 0;
		min-width: initial;
	}
}

// Field / Button inset combo

.field-group.combo-inset {
	> input {
		margin: 0;
	}

	a {
		display: block;
		@include position(middle);
		right: 15px;
		font-size: $rem25px;
	}
}

//************************************************************
// Checkboxes, Radio buttons, and Switches
//************************************************************

// Checkbox / Radio Base styles
$checkbox-size: 22px;
$checkbox-size-lrg: 28px;
$switch-size: 20px;
$switch-size-sm: 18px;

.field-group.checkbox,
.field-group.radio {
	min-height: $checkbox-size;
	min-width: $checkbox-size;
	line-height: $checkbox-size;
	margin: 15px 0;
	position: relative;
	text-align: left;

	input[type="checkbox"],
	input[type="radio"],
	.checkbox-shape {
		display: block;
		height: $checkbox-size;
		width: $checkbox-size;
		line-height: $checkbox-size;
		position: absolute;
		left: 0;
		top: 0;
		// transform: translateY(-50%);
	}

	.checkbox-shape {
		background: rgba($color-white, 0.96);
		border: $rem1px solid $color-checkbox-empty;
		pointer-events: none;
		z-index: 1;
		transition: $field-transition-duration box-shadow;

		&::after {
			background: var(--color-accent);
			color: $color-white;
			content: '';
			display: block;
			font-family: 'brandlive';
			font-size: 18px;
			font-weight: normal;
			width: inherit;
			height: inherit;
			position: absolute;
			text-align: center;
			left: -1px;
			top: -1px;
			z-index: 2;
			opacity: 0;
			transition: $field-transition-duration opacity;
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		cursor: pointer;
		display: block;
		z-index: 2;
		opacity: 0; // Firefox workaround (clear-appearance only reverts to browser default)

		&.required {
			+ .checkbox-shape {
				box-shadow: 0 0 1px 1px $color-red;
			}

			&.entered + .checkbox-shape {
				box-shadow: none;
			}
		}
	}

	> label {
		color: $color-text-light;
		cursor: pointer;
		display: inline-block;
		font-weight: $semi-bold;
		line-height: normal;
		padding-left: $checkbox-size + 12;
		width: 100%;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.input-error {
		position: static;
		display: block;
		padding-left: $checkbox-size + 12;
		transform: none;
	}

	&.large {
		min-height: $checkbox-size-lrg;
		min-width: $checkbox-size-lrg;
		line-height: $checkbox-size-lrg;

		input[type="checkbox"],
		input[type="radio"],
		.checkbox-shape {
			font-size: $checkbox-size-lrg;
			width: $checkbox-size-lrg;
			height: $checkbox-size-lrg;
			line-height: $checkbox-size-lrg;
		}

		.checkbox-shape {
			&::after {
				font-size: 22px;
			}
		}

		> label {
			line-height: $checkbox-size-lrg;
			padding-left: $checkbox-size-lrg + 12;
		}
	}
}

// Checkbox

.field-group.checkbox {
	> span {
		border-radius: $radius-field;

		&::after {
			border-radius: $radius-field;
		}
	}

	input[type="checkbox"] {
		+ span::after {
			content: '\e909';
		}

		&:checked + span::after {
			opacity: 1;
		}
	}
}

// Radio

.field-group.radio {
	> span {
		border-radius: 50%;
		
		&::after {
			border-radius: 50%;
		}
	}

	input[type="radio"] {
		+ span::after {
			content: '\e909'; // Same glyph as checkbox (but radio functionality)
		}

		&:checked + span::after {
			opacity: 1;
		}
	}
}

// For groups of checkboxes or radio buttons that need two columns

.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.field-group {
		width: calc(50% - 20px);
		margin: 0 40px 15px 0;

		&:nth-of-type(even) {
			margin-right: 0;
		}
	}
}

// Switch

.switch-group {
	margin: 0 0 40px;

	.field-group.switch {
		margin: 0 0 30px;
	}

	h5 {
		margin: 0 0 30px;
		
		~ .field-group.switch {
			margin-left: $global-padding;
		}
	}
}

.field-group.switch {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 14px 0;
	border-bottom: 1px solid $color-border-light;

	label {
		color: $color-text-light;
		font-weight: $semi-bold;
		font-size: $rem15px;
		line-height: normal;
		margin-right: 15px;
	}

	.switch-wrap {
		width: ($switch-size * 2) + 2;
		height: calc($switch-size + 4px);
		position: relative;

		> span {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
		}

		.switch-bg {
			background: $color-switch-off;
			// border: 1px solid $color-border-lighter;
			border-radius: calc(($switch-size + 4px) / 2);
			width: inherit;
			height: inherit;
			z-index: 1;
		}

		.switch-btn {
			background: $color-white;
			border-radius: 50%;
			display: block;
			width: $switch-size;
			height: $switch-size;
			left: 2px;
			top: 2px;
			transition: $field-transition-duration left;
			z-index: 2;
		}

		input[type="checkbox"] {
			cursor: pointer;
			display: block;
			width: inherit;
			height: inherit;
			transition: $field-transition-duration background;
			position: relative;
			opacity: 0; // Firefox workaround (clear-appearance only revents to browser default)
			z-index: 3;

			&:checked {
				+ .switch-bg {
					background: $color-switch-on;
				}

				+ .switch-bg + .switch-btn {
					left: $switch-size;
				}
			}
		}
	}

	&.small {
		.switch-wrap {
			width: ($switch-size-sm * 2) + 2;
			height: calc($switch-size-sm + 4px);

			.switch-bg {
				border-radius: calc(($switch-size-sm + 4px) / 2);
			}

			.switch-btn {
				width: $switch-size-sm;
				height: $switch-size-sm;
			}

			input[type="checkbox"]:checked + .switch-bg + .switch-btn {
				left: $switch-size-sm;
			}
		}
	}
}

// Multi Button Selection

.field-group.multi-button-select {
	display: flex;
	z-index: 0;

	.button-option {
		position: relative;
		flex: 1;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			flex-basis: 0px;
		}
		margin-right: -1px;

		label.button {
			padding-left: 0;
			padding-right: 0;
			position: relative;
			z-index: 1;
		}

		input[type="radio"] {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			visibility: hidden;

			&:checked + label.button {
				z-index: 2;
				background: $color-blue;
				color: $color-white;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&:first-child label.button {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child label.button {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

// Chart toggle buttons (similar style to Multi Button Select)

.chart-toggle-buttons {
    display: flex;
    flex-direction: row;
	margin: $margin-field;

	> button {
		font-size: $rem11px;
        line-height: normal;
        height: auto;
		margin: 0 -1px 0 0;
        padding: 4px 15px;

		&:last-child {
			margin-right: 0;
		}

	    &.selected {
	        background-color: $color-blue;
	        color: $color-white;
	        z-index: 2;
	    }
	}

    .toggle-left {
        border-radius: 15px 0 0 15px;
    }

    .toggle-right {
        border-radius: 0 15px 15px 0;
    }
}

//************************************************************
// Other Fields
//************************************************************

// Select dropdowns

.field-group.select {
	z-index: 0;

	&::after {
		color: $color-text-placeholder;
		content: '\f107';
		display: block;
		font-family: 'Fontawesome';
		pointer-events: none;
		@include position(middle);
		right: 15px;
		z-index: 1;
	}

	select {
		@include field-base();
		cursor: pointer;
		padding-right: 40px;

		&.small {
			font-size: $rem11px;
			font-weight: $semi-bold;
			text-transform: uppercase;
			padding-right: 35px;
		}
	}
}

// Custom Dropdown

.field-group.dropdown {
	.button {
		color: $color-text;
		display: inline-block;
		height: $button-height-xsmall;
		min-width: 0;
		text-align: left;
		text-transform: uppercase;
		padding: $padding-button-dropdown;
		position: relative;

		&::after {
			color: $color-text-placeholder;
			content: '\f107';
			display: block;
			font-family: 'Fontawesome';
			font-size: inherit;
			@include position(middle);
			right: 15px;
			z-index: 2;
		}

		&:not(.basic).active {
			background: $color-off-white;
		}
	}

	button {
		&.basic {
			&.active {
				color: $color-link;
			}

			+ .popover {
				margin-top: 5px;
			}
		}
	}

	.popover {
		max-height: 600px;
	}
}

// Ellipses Dropdown

.field-group.ellipses {
	display: block;
	line-height: normal;

	.ellipses-button {
		background: transparent;
		border-radius: 3px;
		cursor: pointer;
		display: block;
		font-size: 16px;
		padding: 0 10px;
		transition: background $hover-transition-duration;

		&:hover {
			background: $color-gray;
			color: $color-white;
		}

		&.dark {
			color: $color-white;

			&:hover {
				background: $color-button-dark;
			}
		}
	}

	.popover.active + .ellipses-button {
		background: $color-gray;
		color: $color-white;

		&.dark {
			background: darken($color-button-dark, 5%);
		}
	}
}

// Option List - Multiple text fields grouped together

.option-list {
	padding: 0 0 0 20px;

	.option-list-title {
		color: $color-text-light;
		font-weight: $semi-bold;
		margin-bottom: 10px;
	}

	.option {
		margin-bottom: 4px;
		position: relative;
		width: calc(100% - 25px);

		input[type="text"] {
			margin: 0;
			border-radius: 0;
			font-size: $rem13px;
		}

		&:first-child {
			border-radius: $radius-field $radius-field 0 0;
			input[type="text"] {
				border-radius: $radius-field $radius-field 0 0;
			}
		}

		&:last-child {
			border-radius: 0 0 $radius-field $radius-field;
			input[type="text"] {
				border-radius: 0 0 $radius-field $radius-field;
			}
		}

		a.remove-option {
			color: $color-text-lighter;
			@include position(middle);
			right: -25px;

			&:hover {
				color: $color-link;
			}
		}
	}

	a.add-option {
		font-size: $rem11px;
		font-weight: $semi-bold;
		line-height: normal;
		padding: 10px 0;
		text-transform: uppercase;
	}
}

// Color Picker

.color-picker {
	.color-picker-swatch {
		display: flex;

		input[type="text"] {
			background: $color-off-white;
			border: none;
			color: $color-text-light;
			font-family: monospace, monospace;
			letter-spacing: 1px;
			margin: 0 0 0 10px;
			padding: 0;
			width: 110px;
			text-align: center;

			&:focus {
				box-shadow: 0 0 0 3px $color-border-light;
			}
		}

		+ .color-transparency {
			margin-top: 15px;
		}
	}

	.color-transparency {
		display: flex;
		margin-top: 4px;
		position: relative;

		input[type="range"] {
			display: block;
		}

		.value {
			color: $color-text-light;
			display: block;
			font-weight: $bold;
			line-height: $rem12px;
			margin: -1px 0 0 10px;
			text-align: left;
		}
	}
}

// Range Slider (transparency in Theme Editor)

.slider {
	// Browser reset

	input[type="range"] {
		@include clear-appearance();
		width: 150px;
		background: transparent;

		&:focus {
			outline: none;
		}
	}

	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	input[type="range"]::-ms-track {
		width: 100%;
		cursor: pointer;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}

	// Slider thumb

	input[type="range"] {
		@include range-slider-thumb() {
			@include slider-thumb();
			margin: 0;
			position: relative;
			top: -7px;
			transition: border-color $hover-transition-duration,
						background $hover-transition-duration;
		}

		@include range-slider-track() {
			background: $color-white;
			border-radius: $rem4px;
			border: $rem1px solid $color-border-light;
			cursor: pointer;
			width: 100%;
			height: 8px;
			transition: border-color $hover-transition-duration;
		}

		&:hover {
			@include range-slider-thumb {
				border-color: $color-gray;
			}
			@include range-slider-track {
				border-color: $color-gray;
			}
		}

		&:active {
			@include range-slider-thumb {
				background: $color-blue;
				border-color: $color-blue;
			}
			@include range-slider-track {
				border-color: $color-blue;
			}
		}
	}
}

// Alternate video uploader - Seen in Create Event Settings and Library page builder

.alternate-video {
	display: flex;
	justify-content: center;
	max-width: 600px;
	margin: auto;

	.field-group {
		width: 100%;
		max-width: 300px;
		margin: 0;

		button {
			width: 100%;
		}

		button.cancel {
			margin-top: 15px !important;
		}

		+ .field-group {
			margin-left: $global-padding;
		}
	}

	.field-group.drag-drop {
		width: 100%;
		height: 170px;
		padding: 15px;
	}
}

//************************************************************
// List selections
//************************************************************

// Selection List

.selection-list {
	margin: 15px 0;
	padding: 0;
	list-style: none;

	li {
		align-items: center;
		background: $color-light-gray;
		border-radius: $radius-field;
		display: flex;
		font-size: $rem15px;
		line-height: normal;
		margin: 0 0 10px;
		padding: 11px 15px;
		@include clearfix();

		> span {
			@include truncate();
			flex: 1;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				flex-basis: 0px;
			}
			margin-right: auto;
			max-width: 80%;
		}

		a {
			color: $color-text-lighter;
			margin: 0 0 0 15px;

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				color: $color-link;
			}
		}

		.field-group {
			margin: 0;
		}

		.field-group.checkbox {
			margin-right: 15px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Tabicons (Example in Create Event - Products layout)

.tabicons {
	display: flex;
	margin-bottom: 20px;

	> a {
		background: transparent;
		border: 1px solid $color-border-lighter;
		border-radius: 3px;
		color: $color-text-lighter;
		display: block;
		font-size: 64px;
		padding: 10px;
		margin-right: 10px;
		transition: color $hover-transition-duration,
					background $hover-transition-duration,
					border $hover-transition-duration;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background: $color-light-gray;
			border-color: $color-border-light;
			color: $color-text-light;
		}

		&.active {
			background: $color-white;
			border-color: $color-link;
			color: $color-link;
		}
	}
}

.buttons-container {
	width: 100%;
	padding: 0 15px 30px 0;

	.buttons-right {
		display: flex;
		justify-content: flex-end;
		right: 0;
		top: 12px;

		button {
			margin: 0 0 0 10px;
		}
	}
}
