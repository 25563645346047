@function unicode($str)
{
	@return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

.modal-large-overlay {
	background-color: rgba(0,0,0, .6);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: $z-index-modal;
	overflow: hidden;
}

.modal-large {
	position: absolute;
	background: $border-gray;
	border-radius: 16px;

	&.rtmp-validation-override {
		.confirmation-dialog {
			.modal-header-container .modal-header-text {
				font-style: normal !important;
				font-weight: 600 !important;
				font-size: 20px !important;
				line-height: 32px !important;
				letter-spacing: -0.01em;
			}

			.modal-header-container {
				margin-bottom: 12px;
			}

			.modal-body-container {
				margin-bottom: 24px !important;
			}

			.modal-body-container div {
				letter-spacing: 0.02em;
				line-height: 1.5rem;
			}

			.modal-button-div-right {
				button {
					padding: 18px 24px;
				}
			}
		}
	}
}

#modal {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0, .6);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-modal;
	scrollbar-gutter: stable;
	@include custom-scrollbar;

	&.video-editor {
		overflow: hidden;
	}

	&.multi-account-logged-in {
		z-index: $z-index-multi-account-modal;
	}

	&.no-room-access {
		z-index: $z-index-alert;
	}

	&.confirmation-modal {
		z-index: $z-index-lightbox;
	}

	.content-modal {
		@include custom-scrollbar(24px, 1);
	}

	.info-modal {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 640px;
		padding: 24px 40px;
		border-radius: 24px;
		background: #26242B;

		&.remove {
			width: 440px;
			height: 252px;
		}

		&.invite {
			position: absolute;
			top: 337px;
		}

		&.other {
			padding: 24px 40px 24px 36px;

			.info-modal-header {
				margin-left: 4px;
			}
		}

		&.confirmation-dialog {
			width: 360px;
		}

		&.multi-account-login-confirmation,
		&.confirmation-modal {
			padding: 24px 40px !important;

			position: relative;
			width: 640px;
			//height: 232px !important;

			/* Grayscale / 900 */

			background: $bl-grayscale-900 !important;
			border-radius: 16px;

			&.confirm-stream {
				width: 480px;
			}

			.modal-header-text {
				width: 360px !important;
				height: 32px !important;

				/* H4 */

				font-family: 'Poppins';
				font-style: normal !important;
				font-weight: 600 !important;
				font-size: 20px !important;
				line-height: 32px !important;
				/* identical to box height, or 160% */

				letter-spacing: -0.01em;

				/* Grayscale / 100 white */

				color: $white;
			}

			.modal-header-container {
				margin-bottom: 32px;
			}

			.modal-body-container {
				margin-bottom: 16px;
			}

			.modal-body-text {
				width: 360px !important;
				height: 72px !important;

				/* P1 */

				font-family: 'Poppins';
				font-style: normal !important;
				font-weight: 500 !important;
				font-size: 14px !important;
				line-height: 24px !important;
				/* or 171% */


				/* Grayscale / 100 white */

				color: $white;
			}

			.modal-first-button-text {
				// width: 92px !important;
				height: 16px !important;

				/* L1 */

				font-family: 'Poppins';
				font-style: normal !important;
				font-weight: 600 !important;
				font-size: 13px !important;
				line-height: 16px !important;
				/* identical to box height, or 123% */

				display: flex;
				align-items: center;
				text-align: center;

				/* Grayscale/100 white */

				color: $white;
				user-select: none;
			}

			.modal-second-button-text {
				// width: 120px !important;
				height: 16px !important;

				/* L1 */

				font-family: 'Poppins';
				font-style: normal !important;
				font-weight: 600 !important;
				font-size: 13px !important;
				line-height: 16px !important;
				/* identical to box height, or 123% */

				display: flex;
				align-items: center;
				text-align: center;

				/* Grayscale/1100 */

				color: $bl-grayscale-1100;
				user-select: none;
			}

			.modal-first-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 18px 24px;
				gap: 8px;

				width: 140px;
				height: 48px;

				/* Grayscale/600 */

				background: $bl-grayscale-600 !important;
				border-radius: 100px;
			}

			.modal-second-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 18px 24px;
				gap: 8px;

				height: 48px;

				/* Greenroom green */

				background: $bl-gr-primary-500;
				border-radius: 100px;

				/* Custom */

				// Makes the auto width animation possible
				white-space: nowrap;
				transition:
					width $transition-fast,
					background $transition-fast;

				&.initializing {
					background: $bl-gr-primary-600;
					cursor: default;
				}
			}

			.modal-second-button-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 6px;
			}
		}

		&.multi-onsite-confirmation {
			width: 440px;

			.modal-header-container {
				.modal-header-text {
					color: $bl-grayscale-100;
					font-size: 20px;
					font-weight: 600;
					line-height: 32px;
					letter-spacing: -0.2px;
				}
			}

			.modal-body-container {
				margin-bottom: 24px;

				.modal-body-text {
					color: $bl-grayscale-100;
					font-size: 14px;
					font-weight: 500;
					line-height: 24px;
				}
			}

			.modal-first-button {
				padding: 16px 24px;
				background: $bl-grayscale-600;

				.modal-first-button-text {
					color: $bl-grayscale-200;
					font-size: 13px;
					font-weight: 600;
					line-height: 16px;
				}
			}

			.modal-second-button {
				padding: 16px 24px;
				margin-left: 12px;

				.modal-second-button-text {
					color: $bl-grayscale-1200;
					font-size: 13px;
					font-weight: 600;
					line-height: 16px;
				}
			}
		}

		&.confirmation-modal {
			padding: 24px 0 !important;
			border-radius: 24px;
			max-height: calc(100% - 26px);

			&.custom-header {
				padding: 0 0 24px 0 !important;
			}

			&.confirm-error {
				.modal-custom-header-container-error,
				.modal-header-container {
					h4 {
						margin: 0;
					}
				}

				.modal-custom-header-container-error {
					margin: 24px 40px 0;
				}

				.modal-header-container {
					margin: 0;
				}

				.modal-body-container {
					margin: 24px 40px;
				}

				.modal-first-button {
					width: auto;
					max-width: none !important;
				}

				&.error-list {
					.modal-first-button {
						background: transparent !important;
					}
				}

				&.confirm-stream {
					.modal-first-button {
						margin-right: 8px;
					}
				}
			}

			&.error-list {
				.modal-body-container {
					position: relative;
					display: grid;
					margin: 0;
					overflow: hidden;
					@include custom-scrollbar(24px, 1);

					.error-scroll {
						margin: 24px 12px 24px 40px;
						overflow: auto;
						scrollbar-gutter: stable;

						&::before,
						&::after {
							content: '';
							position: absolute;
							left: 0;
							height: 32px;
							width: calc(100% - 40px);
							pointer-events: none;
							opacity: 0;
							transition: opacity $transition-fast;
						}

						&::before {
							top: 24px;
							background: linear-gradient(
								180deg,
								$bl-grayscale-900 0,
								transparent 32px,
							);
						}

						&::after {
							bottom: 24px;
							background: linear-gradient(
								180deg,
								transparent calc(100% - 32px),
								$bl-grayscale-900 100%,
							);
						}

						&.error-scroll--top::after,
						&.error-scroll--bottom::before {
							opacity: 1;
						}

						.error-container {
							width: calc(100% - 12px);
						}
					}
				}
			}

			.modal-header-container {
				padding: 0 40px;
				user-select: none;
			}

			.modal-body-container,
			.live-stream-destinations,
			.live-stream-destinations-body {
				@include flex-scroll;
			}

			.modal-custom-header-container {
				display: flex;
				align-items: center;
				align-self: stretch;
				gap: 8px;
				padding: 24px 40px;
				border-radius: 24px 24px 0px 0px;
				background:
					linear-gradient(
						0deg,
						rgba(255, 255, 255, 0.20) 0%,
						rgba(255, 255, 255, 0.20) 100%
					),
					linear-gradient(
						90deg,
						#FF6AE6 0%,
						#55D6F0 100%
					);
				user-select: none;

				.modal-custom-header-text {
					color: $bl-grayscale-1200;
					font-size: 20px;
					font-weight: 600;
					line-height: 32px; /* 160% */
					letter-spacing: -0.2px;
				}
			}

			.modal-first-button {
				max-width: 95px !important;
			}

			.sliding-button-group {
				isolation: isolate;
				height: 64px;
				background: $bl-grayscale-800;
				border-radius: 12px;
				margin: 0 40px;
				flex: 0 0 auto;

				.sliding-button-background,
				.sliding-button {
					border-radius: 12px;
				}

				.sliding-button-background {
					background: linear-gradient(
						108.66deg,
						#FF6AE6 -4.67%,
						#FFF85C 69.07%,
						#A6FF79 120.95%
					);

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						border-radius: 12px;
						background: linear-gradient(
							90deg,
							#FF6AE6 0%,
							#55D6F0 100%
						),
						linear-gradient(
							0deg,
							rgba(255, 255, 255, 0.2),
							rgba(255, 255, 255, 0.2)
						);
						transition: opacity $transition-fast;
					}

					&.active-button-1::before {
						opacity: 1;
					}
				}

				.sliding-button {
					justify-content: flex-start;
					padding: 0 24px;

					.sliding-button-icon {
						margin-right: 12px;
					}

					.sliding-button-text {
						text-align: left;

						.bl-font-header,
						.bl-font-s2 {
							margin: 0;
						}

						.bl-font-header {
							color: $bl-grayscale-400;
						}

						.bl-font-s2 {
							color: $bl-grayscale-500;
						}
					}

					&.sliding-button--active {
						.sliding-button-text {
							.bl-font-header {
								color: $bl-grayscale-1200;
							}
						}
					}
				}
			}

			.schedule-module {
				margin: 24px 40px 0 40px;

				.schedule-module__selector {
					display: flex;
					flex-direction: row;
					align-items: center;
					
					.schedule-module__selector-label {
						flex: 1;
						margin: 0;
						user-select: none;
					}

					.schedule-module__selector-dropdown {
						&,
						.select-label-text,
						.select-option label {
							font-weight: 500;
							font-size: 12px;
							line-height: 16px;
							letter-spacing: 0.02em;
							color: $bl-grayscale-400;
							transition: color $transition-fast;

							&:active,
							&:focus {
								color: $bl-grayscale-200;
							}
						}

						.select-label {
							justify-content: space-between;
							width: 164px;
							padding: 12px 16px !important;
							border: 1px solid $bl-grayscale-400;
							border-radius: 100px;
							transition: border-color $transition-fast;

							svg path {
								fill: $bl-grayscale-400;
								transition: fill $transition-fast;
							}

							&:focus,
							&:hover {
								border-color: $bl-grayscale-200;

								svg path {
									fill: $bl-grayscale-200;
								}
							}
						}

						.select-body {
							border-radius: 12px;
						}

						.select-option {
							border-radius: 0;

							&:hover {
								&:first-of-type {
									border-radius: 12px 12px 0 0;
								}
				
								&:last-of-type {
									border-radius: 0 0 12px 12px;
								}
							}

							svg {
								height: 10px;
								width: 10px;
							}
						}

						.select-label .select-label-text,
						.select-option label {
							color: $white;
						}
					}
				}

				.schedule-module__custom-time {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-top: 16px;
					padding: 16px 24px;
					gap: 24px;
					background: $bl-grayscale-800;
					border-radius: 12px;

					.schedule-module__custom-time-label {
						flex: 1;
						margin: 0;
					}
				}

				.react-datepicker-popper {
					z-index: $z-index-chat-link-wrapper;
				}
			}

			.time-module {			
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 24px 40px 0;
				padding: 16px 24px;
				gap: 24px;
				height: 96px;
				background: $bl-grayscale-800;
				border-radius: $rem12px;
				flex: 0 0 auto;

				.divider {
					height: 100%;
					border: 1px solid $bl-grayscale-700;
				}

				.bl-font-header {
					color: $bl-grayscale-400;
					margin: 0;

					&.start-now, &.schedule {
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}

					&.start-now {
						background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
							linear-gradient(90deg, #FFFD62 0%, #FF6AE6 100%);
					}

					&.schedule {
						background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
							linear-gradient(90deg, #FF6AE6 0%, #55D6F0 100%);
					}
				}
			}

			.live-stream-destinations {
				.bl-font-header, .bl-font-l1 {
					margin: 0;
				}

				.live-stream-destinations-title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 16px 40px 0;
					user-select: none;
				}

				.live-stream-destinations-body {
					position: relative;
					@include custom-scrollbar(8px, 1);

					.scroll-shadow {
						position: absolute;
						width: calc(100% - 80px);
						height: 16px;
						margin: 0 40px;
						background: linear-gradient(0deg, rgba(38, 36, 43, 0.00) 0%, $bl-grayscale-900 60%);
						transition: all $transition-very-fast;
						pointer-events: none;
						z-index: $z-index-modal-scroll-shadow;
					}

					.custom-destinations {
						padding-left: 40px; 
						margin-right: 12px;
						overflow-y: auto;
						overflow-x: hidden;
						scrollbar-gutter: stable;

						&:empty {
							height: 32px;
						}

						.destination-card {
							width: 560px;

							&:first-of-type {
								margin-top: 16px !important;
							}

							.rtmp-input {
								width: 384px;
							}
						}
					}
					
					.add-destination-button {
						display: flex;
						justify-content: center;
						align-items: center;
						background: transparent;
						opacity: 0;
						transition: all $transition-fast;
						overflow: hidden;
						user-select: none;

						&.showing {
							opacity: 1;
						}
					}
				}
			}

			.modal-button-div-right {
				width: auto;
				margin: 0 40px;

				.button-row-content {
					display: flex;
					align-items: center;
					flex: 1;

					.autoplay-notification {
						display: flex;
						gap: 2px;
						color: $bl-grayscale-300;

						&.fade-enter {
							opacity: 0;
						}

						&.fade-enter-active {
							opacity: 1;
						}

						&.fade-exit {
							opacity: 1;
						}

						&.fade-exit-active {
							opacity: 0;
						}

						&.fade-enter-active,
						&.fade-exit-active {
							transition: opacity $transition-fast;
						}
					}
				}
			}

			.error-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 8px;
				align-self: stretch;

				.error-item {
					display: flex;
					align-items: center;
					gap: 12px;
					align-self: stretch;
					padding: 8px 16px 8px 8px;
					border-radius: 12px;
					background: $bl-grayscale-800;

					.scheduled-stream-error__thumbnail,
					.scheduled-stream-error__action {
						user-select: none;
					}

					.scheduled-stream-error__thumbnail {
						position: relative;
						width: 96px;
						height: 54px;
						border-radius: 8px;
						overflow: hidden;

						.empty-video {
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: #1d1b21;
							height: 100%;
							width: 100%;

							.placeholder-icon {
								@include flex-center;

								max-width: 100%;
								min-width: 100%;
								padding-left: 35%;
								padding-right: 35%;
							}
						}
					}

					.scheduled-stream-error__description {
						flex: 1 0 0;
					}

					.scheduled-stream-error__action {
						display: flex;
						padding: 12px 20px;
						justify-content: center;
						align-items: center;
						gap: 8px;
						border-radius: 100px;
						color: $bl-grayscale-300;
						background-color: transparent;
					}

					&.destination-error {
						justify-content: space-between;
						padding: 24px 24px;
						min-height: 72px;

						span {
							@include line-clamp(1);
						}

						.logo {
							@include flex-center;
							margin-right: 12px;

							img {
								width: 24px;
								height: 24px;
								object-fit: contain;
							}
						}

						.error-icon {
							@include flex-center;
							width: 20px;
							height: 20px;
							padding: 5px;
							border-radius: 100px;
							background: $bl-semantic-red-500;
						}
					}
				}
			}

			.destination-options-container {
				position: absolute;
				display: flex;
				justify-content: center;
				bottom: 88px;
				width: 100%;

				.destination-options {
					bottom: 24px;
				}
			}
		}

		&.delete-chat-message-confirmation {
			width: 383px;

			.modal-header-text {
				font-weight: 600;
				font-size: 20px;
				line-height: 32px;
				letter-spacing: -0.01em;
				color: $white;
			}

			.modal-body-text {
				display: flex;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.02em;
				color: $bl-grayscale-200;
			}

			.modal-button-div {
				button {
					padding: 16px 24px;
				}

				.modal-first-button {
					background: $bl-grayscale-600;

					span {
						color: $bl-grayscale-200;
					}
				}
			}
		}

		&.avatar-photo {
			.info-modal-header {
				button {
					@include button-greenroom-shadow;
					&:hover {
						svg {
							path {
								fill: $bl-grayscale-100 !important;
							}
						}
					}
				}
			}
			.avatar-photo-modal-body {
				margin: 16px 0 24px;

				.upload-container {
					@include flex-center;
					flex-direction: column;
					width: 100%;
					height: 316px;
					background: $background-gray;
					border: $rem1px dashed #4F4C57;
					border-radius: $rem8px;
					transition: 150ms filter;

					&.dragging-over {
						filter: brightness(120%);

						button, span {
							pointer-events: none;
						}
					}

					button {
						width: 48px;
						height: 48px;
					}

					span {
						margin-top: 12px;
						color: $text-gray;
					}
				}

				.main-container {
					width: 100%;
					height: 316px;

					#crop-box-helper {
						position: absolute;
						@include flex-center;
						height: 28px;
						padding: 0 16px;
						background: #4F4C57;
						border-radius: 100px;
						font-size: 12px;
						font-weight: 700;
						line-height: 16px;
						letter-spacing: 0.4px;
						color: #E5E4EC;
						pointer-events: none;

						svg {
							margin-right: 8px;
						}
					}

					img {
						display: block;
						max-width: 100%;
					}

					video {
						width: 100%;
						height: 100%;
						object-fit: contain;
						border-radius: 8px;
					}
				}

				.crop-slider {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					margin-top: 18px;

					@include scale-slider;

					[type=range] {
						-webkit-appearance: none;
						background: transparent;
						width: 488px;
						height: 100%;
						padding: 10px 0;
						cursor: pointer;
					}

					[type=range]::-webkit-slider-runnable-track {
						border-radius: 100px;
						min-height: 3px;
						max-height: 3px;
						height: 3px;
						cursor: pointer;
					}

					[type=range]::-webkit-slider-thumb {
						height: 13px;
						width: 13px;
						margin-top: -5px;
						cursor: pointer;
					}

					[type=range]::-moz-range-track {
						min-height: 3px;
						max-height: 3px;
						height: 3px;
						cursor: pointer;
					}

					[type=range]::-moz-range-thumb {
						border-radius: 100px;
						height: 13px;
						width: 13px;
						cursor: pointer;
					}
				}
			}
		}

		&.copy-event {
			height: auto;
			width: 100%;
			max-width: 640px;

			.channel-selector {
				margin: 25px 0 10px 0;
			}

			h4 {
				font-weight: 500;
				margin: 0;
			}

			.page-one {
				display: flex;
				flex-direction: column;
			}

			.page-two,
			.page-three {
				h4 {
					margin: 30px 0 24px 0;
				}

				.text-content {
					font-size: 16px;

					.confirm-info {
						max-width: 525px;
					}

					ul {
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;
						padding-left: 10px;
						max-height: 80px;
						line-height: 25px;
						list-style: none;

						li {
							flex: 1;
							width: 50%;

							&::before {
								content: unicode('2022');
								margin-right: 8px;
							}
						}
					}
				}
			}

			.page-three {
				.text-content {
					margin: 25px 0 48px 0;
				}
			}

			.closed-option {
				flex: 1;

				&.channel {
					border-radius: 8px;
					background-color: $border-gray;
					padding: 10px;
				}

				&.event {
					input {
						margin: 0;
						padding: 14px 15px;
						font-size: 14px;
						background-color: $dark-gray;
						color: #ffffff; // Non-active: $text-light-gray
						border: 1px solid $med-gray;
						border-radius: 999px;
					}
				}
			}

			.title-container {
				display: flex;
				align-items: center;
				gap: 16px;

				.avatar-container.small img {
					height: 33px;
					width: 33px;
					min-height: 33px;
					min-width: 33px;
					border-radius: 8px;
				}

				.extra-room {
					margin-top: 16px;
				}
			}

			.avatar,
			.background,
			.telly-channel-bubble img {
				height: 48px;
				width: 48px;
				min-height: 48px;
				min-width: 48px;
				border: none;
				border-radius: 12px;
				object-fit: contain;

				&.gray {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $element-background-highlight-gray;

					.question-bg {
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						height: 20px;
						width: 20px;
						background-color: #e5e4ec;
						border-radius: 50%;
					}
				}
			}

			.avatar {
				background-color: #121114;
			}

			.telly-channel-bubble img {
				background-color: $pale-green;
			}

			.select-body {
				background-color: $border-gray;

				&.show {
					top: calc(100% + 8px);
				}

				&.open {
					.channel-option,
					.event-option {
						&:hover,
						&:active,
						&:focus,
						&.selected {
							&:not(.first-option) {
								background-color: #26242B;
							}
						}

						&.first-option {
							cursor: default;
						}
					}
				}
			}

			.channel-option {
				display: grid;
				grid-template-columns: 56px 1fr;
				grid-template-rows: 56px;
				align-items: center;

				.image-container {
					&,
					.telly-channel-bubble {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				.title {
					display: flex;
					align-items: center;
					padding-left: 12px;
					font-weight: 500;
				}

				&.first-option {
					color: $text-light-gray;
				}
			}

			.event-option-container {
				position: relative;

				&.first-past,
				&.first-upcoming {
					margin-top: 8px;
					padding-top: 24px;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 12px;
						color: $text-light-gray;
						font-weight: 500;
					}
				}

				&.first-past {
					&::before {
						content: 'Past sessions';
					}
				}

				&.first-upcoming {
					&::before {
						content: 'Upcoming sessions';
					}
				}
			}

			.event-option {
				padding: 12px;

				.title {
					&.no-margin {
						margin: 0;
					}
				}

				&.title-date {
					.title {
						margin-bottom: 6px;
						font-weight: 400;
					}

					.date {
						font-size: 12px;
						font-weight: 500;
						color: $text-light-gray;
					}
				}
			}

			.channel-option,
			.event-option {
				border-radius: 14px;
			}

			.more-info {
				font-size: 12px;
				margin: 12px 0 20px 0;

				a {
					text-decoration: underline;
					color: #ffffff;
				}
			}

			.page-action-row {
				display: flex;
				align-items: center;

				.page-status{
					flex: 1;
					font-size: 18px;

					.out-of {
						color: $med-gray;
					}
				}
			}
		}

		&.delete-event {
			width: 100%;
			max-width: 440px;

			h4 {
				font-size: 22px;
				font-weight: 500;
			}

			.text-content {
				margin: 6px 0 48px 0;
				font-size: 16px;
			}

			.button-group {
				justify-content: flex-end;
			}
		}

		&.broadcast-failed {
			width: 640px;

			.modal-body-container {
				margin-bottom: 0;

				span {
					display: block;
					margin-bottom: 16px;
				}
			}
		}

		.button-group {
			display: flex;
			gap: 16px;

			.gray,
			.green {
				padding: 16px 24px;
				border-radius: 999px;
			}

			.gray {
				background: $element-background-highlight-gray;
				color: #E5E4EC;
				transition: 150ms color;
				border-radius: $rem100px;

				&:hover {
					background-color: lighten($element-background-highlight-gray, 5%);
				}
			}

			.green {
				background-color: $pale-green;
				border-radius: $rem100px;
				color: #1a1a1f;
				transition: 150ms background-color;

				&:hover {
					background-color: lighten($pale-green, 5%);
				}
			}
		}

		.info-modal-header {

			h1 {
				font-weight: 500;
				font-size: 22px;
				line-height: 30px;
				display: flex;
				align-items: center;
				color: $white;
				margin: 0;
			}

			p {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				display: flex;
				align-items: center;
				color: $text-gray;
				margin: 0;
			}

			&.remove {
				width: 360px;
				margin-bottom: 8px;

				p {
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					color: $white;
					margin-top: 24px;
				}
			}

			&.destination {
				display: flex;
				align-items: center;

				img {
					width: 32px;
					height: 32px;
					margin-right: 16px;
				}
			}
		}

		.input-header {
			margin: 10px 0 4px 0;

			span {
				font-weight: bold;
				font-size: 16px;
				font-weight: 600;
				font-family: Poppins;
				line-height: 16px;
				display: flex;
				align-items: flex-end;
				letter-spacing: 0.4px;
				color: $text-gray;
				margin-left: 0;
			}
		}

		.destination-modal-body {
			display: flex;

			.destination-modal-logo {
				width: 120px;
				height: 120px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 24px;
				border-radius: $rem8px;
				cursor: pointer;
				transition: 150ms background;
				border: $rem1px dashed #4F4C57;
				color: #FFFFFF;
				font-weight: 500;
				transition: 150ms color, 150ms background-color, 60ms transform;
				@include button-greenroom-shadow;

				&:active {
					transform: scale(0.95);
				}

				svg {
					margin-right: 8px;

					&.waiting-indicator {
						margin-right: 0px;
					}
				}

				img {
					width: 120px;
					height: 120px;
					border-radius: 8px;
				}
			}

			.destination-inputs {
				width: 100%;

				&.other {
					width: 420px;
				}

				.input-container {
					margin-bottom: 24px;

					.input-body {
						display: flex;

						.copy-button {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 48px;
							height: 48px;
							margin-left: 8px;
							padding: 19px;
							background: #4F4C57;
							border-radius: 100px;
							transition: 150ms color, 150ms background-color, 60ms transform;
							@include button-greenroom-shadow;

							&:active {
								transform: scale(0.95);
							}
						}

						.select-small {
							width: 182px;

							.select-label {
								justify-content: flex-start;
							}

							.select-option {
								padding: 16px 17px;
							}

							.select-body {
								min-width: 182px;
							}
						}
					}
				}
			}
		}

		.info-modal-show-session {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			.start-end-container {
				display: flex;
				flex-direction: column;
				margin-top: 0;

				.input-header {
					display: grid;
					grid-template-columns: 292px 1fr;
				}

				.start-end {
					display: grid;
					grid-template-columns: 148px 120px 24px 148px 120px;

					input {
						height: 48px;
						background: $dark-gray;
						border: $rem1px solid $element-background-highlight-gray;
						box-sizing: border-box;
						border-radius: 100px;
						font-size: 14px;
						line-height: 20px;
						display: flex;
						align-items: center;
						color: $white;
						margin: 0;
					}

					.show-session-date {
						position: relative;
						height: 48px;

						input {
							width: 140.25px;
							margin-right: 7.75px;
						}

						svg {
							position: absolute;
							top: 18px;
							right: 27.33px;
						}
					}

					.show-session-time {
						position: relative;
						height: 48px;

						input {
							width: 120px;
						}

						svg {
							position: absolute;
							top: 17.33px;
							right: 17.33px;
						}
					}

					.start-end-divider {
						display: flex;
						justify-content: center;
						align-items: center;
						top: 10px;
						font-weight: normal;
						font-size: 14px;
						line-height: 20px;
						text-align: center;
						color: $text-gray;
					}
				}
			}
		}

		.loading-container {
			display: flex;
			align-items: center;
			margin-top: 48px;
		}

		.input-container {
			display: flex;
			flex-direction: column;
			justify-content: start;

			.input-body {
				.select-label {
					height: 48px;
					background: #1A1A1F;
					border: 1px solid #54545A;
					box-sizing: border-box;
					border-radius: 100px;
					font-size: 14px;
					line-height: 20px;
					padding-left: 16px !important;
					color: #FFFFFF;
					margin: 0;
				}

				.select-option {
					height: 48px;
					padding: 16px 17px;
				}

				input {
					display: flex;
					align-items: center;
					height: 48px;
					width: 560px;
					padding: 8px 16px !important;
					background: #1A1A1F !important;
					border: 1px solid #4F4C57 !important;
					box-sizing: border-box;
					border-radius: 100px;
					margin: 0;
					background: none;
					border: none;
					padding: 8px;
					color: $near-white;
				}
			}
		}

		.info-modal-invite {
			.invite-user-form {
				.input-form {
					display: flex;
					align-items: center;
					height: 48px;
					padding: 12px;
					background: #1A1A1F;
					border: 1px solid #4F4C57;
					box-sizing: border-box;
					border-radius: 100px;

					.input-form-icon {
						@include flex-center;
						cursor: pointer;
						margin-left: 8px;
					}

					input {
						margin: 0;
						background: none;
						border: none;
						padding: 8px;
						color: $near-white;
					}
				}
			}

			.invite-presenters-list {
				height: 100%;
				margin-top: 4px;

				&::-webkit-scrollbar {
					width: 0;
				}

				.invite-presenter-row {
					display: flex;
					align-items: center;
					width: 556px;
					height: 32px;
					margin-top: 8px;
					margin-right: 0px;

					.avatar {
						margin-right: 16px;
					}

					.presenter-name {
						width: 142px;
						margin-right: 16px;
						font-weight: bold;
						color: #BFBBCF;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						flex: 1;
					}

					.presenter-email {
						display: flex;
						align-items: center;
						width: 200px;
						margin-right: 42px;
						color: #BFBBCF;

						span {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					.presenter-controls {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						width: 118px;

						label {
							border: none;
							padding: 0 15px 0 0 !important;
							color: #BFBBCF !important;
						}

						button {
							display: flex;
							align-items: center;
							background: none;
							border: none;
							margin: 0;
							padding: 0;
							margin-left: 20px;
						}
					}
				}
			}

			.select-small {
				.select-label {
					justify-content: space-between;
					border: none;
					padding: 0 8px 0 0 !important;
					color: #BFBBCF !important;

					&.dropdown {
						svg {
							margin-left: 5.5px;

							path {
								fill: #BFBBCF !important;
							}
						}
					}
				}

				.select-body {
					background-color: $border-gray;
					width: 240px;
					border-radius: 12px;

					.select-option {
						justify-content: flex-start;
						height: 60px;
						flex-direction: row;

						.option-with-description {
							display: flex;
							flex-direction: column;
						}

						label {
							font-weight: 500;
							line-height: 20px;
						}

						span {
							color: $text-light;
							pointer-events: none;
							font-size: 12px;
							line-height: 16px;
							letter-spacing: 0.4px;
						}

						svg {
							margin-left: 1px;
							margin-right: 9px;
							width: 10px;
							height: 10px;
						}

						&:hover {
							background-color: #4F4C57;

							span {
								color: $color-white;
							}
						}

						&.selected {
							background-color: #4F4C57;

							span {
								color: $color-white;
							}
						}
					}

					&.open {
						max-height: 300px;
					}
				}
			}
		}

		.info-modal-bottom {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-end;
			margin-top: 24px;

			.info-modal-buttons {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				button {
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					border-radius: 100px;
				}

				.info-gray {
					height: 48px;
					background: $element-background-highlight-gray;
					color: $white-presenter;
					margin-right: 16px;
					padding: 16px 24px;
					transition: 150ms color, 150ms background-color, 60ms transform;
					@include button-greenroom-shadow;

					&.remove {
						width: 182px;
					}

					&.upload {
						margin-right: 0;
					}
				}

				.info-green {
					height: 48px;
					background: $pale-green;
					color: $button-gray;
					padding: 16px 24px;
					transition: 150ms color, 150ms background-color, 60ms transform;
					@include button-greenroom-shadow;
				}

				.info-gradient {
					height: 48px;
					background: $pale-green;
					color: $button-gray;
					padding: 16px 24px;
					background: linear-gradient(108.66deg, #FF6AE6 -4.67%, #FFF85C 69.07%, #A6FF79 120.95%);
					transition: 150ms color, 150ms background-color, 60ms transform;
					@include button-greenroom-shadow;
				}
				
				.info-extra {
					transition: 150ms color, 150ms background-color, 60ms transform;
					@include button-greenroom-shadow;
				}
			}
		}
	}

	.shot-builder-modal {
		//to get this on spec I had to remove padding for the underlined header (bleh)
		padding: 0;
		height: 85%;
		width: 85%;
		outline: none;

		.shot-builder-modal-header {
			border-bottom: 1px solid #3C3A42;
			height: 96px;
			width: 100%;

			.sliding-menu-wrapper {
				position: relative;
				bottom: -2px;
			}
		}

		.shot-builder-modal-body {
			@include custom-scrollbar;
			overflow-y: auto;
			height: 78%;
			flex: 1 1 auto;

			&.shot-builder-modal-layouts-page {
				height: 76%;
				padding: 32px 0;
			}
		}

		.shot-builder-modal-main-video-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			background-color: #1A1A1F;
		}

		.shot-builder-modal-right-drawer {
			@include custom-scrollbar;
			height: 100%;
			overflow-x: hidden;
			width: $global-drawer-width;
			border-left: 1px solid #3C3A42;
			position: relative;
		}

		.shot-builder-modal-right-drawer-no-scroll {
			overflow-y: hidden;
		}

		.shot-builder-modal-right-button-menu {
			height: 100%;
		}

		.shot-builder-modal-footer {
			border-top: 1px solid #3C3A42;
			height: 12%;
			width: 100%;
			position: relative;

			.shot-builder-page-number {
				width: 30px;
				height: 16px;
				position: absolute;
				left: calc(50% - 15px);
				top: calc(60% - 8px);
			}
		}
	}

	.program-feed-modal {
		font-family: Poppins;
		font-style: normal;

		.title-container {
			display: flex;
			align-items: center;

			.telly-channel-bubble {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				height: 33px;
				width: 33px;
				border-radius: 8px;
				border: none;
			
				img {
					height: 100%;
					width: 100%;
				}
			}

			span {
				font-weight: 500;
				font-size: 22px;
				line-height: 30px;
				color: #FFFFFF;
				margin-left: 16px;
			}
		}

		.header {
			font-weight: 500;
			font-size: 22px;
			line-height: 30px;
			color: #FFFFFF;
			margin-top: 36px;
		}

		.body {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			margin-top: 22px;

			.explanation-list {
				margin-top: 16px;
			}

			a {
				font-size: 13px;
				line-height: 24px;
				color: #FFFFFF;
				text-decoration-line: underline;
				margin-top: 16px;
			}	
		}

		.footer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 20px;

			.view-btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 18px 24px;
				gap: 8px;
				height: 48px;

				background-color: $pale-green;
				border-radius: $rem100px;
				color: #1a1a1f;

				&:hover {
					background-color: lighten($pale-green, 5%);
				}

				transition: 150ms color, 150ms background-color, 60ms transform;
				@include button-greenroom-shadow;
			}
		}
	}

	.onsite-location-modal {
		position: relative;
		justify-content: center;
		width: 480px;
		height: 173px;
		border-radius: 24px;
		box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.33);
		overflow: hidden;

		h4 {
			font-family: Poppins;
			font-size: 20px;
			font-weight: 600;
			line-height: 32px;
			letter-spacing: -0.2px;
			color: $white;
			text-align: center;
			margin: 0;
		}

		.gradient-purple {
			position: absolute;
			top: -85%;
			left: -25%;
			width: 233.098px;
			height: 234.74px;
			flex-shrink: 0;
			opacity: 0.4;
			background: #FF6AE6;
			filter: blur(75px);
		}

		.gradient-yellow {
			position: absolute;
			top: 29%;
			left: 56%;
			width: 256.196px;
			height: 258px;
			flex-shrink: 0;
			border-radius: 258px;
			opacity: 0.4;
			background: #FFF85C;
			filter: blur(75px);
		}
	}

	.no-room-access-modal {
		font-family: Poppins;
		font-style: normal;

		.title-container {
			display: flex;
			align-items: center;

			span {
				font-weight: 500;
				font-size: 22px;
				line-height: 30px;
				color: #FFFFFF;
			}
		}

		.header {
			font-weight: 500;
			font-size: 22px;
			line-height: 30px;
			color: #FFFFFF;
			margin-top: 36px;
		}

		.body {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			margin-top: 22px;

			.explanation-list {
				margin-top: 16px;
			}

			a {
				font-size: 13px;
				line-height: 24px;
				color: #FFFFFF;
				text-decoration-line: underline;
				margin-top: 16px;
			}
		}

		.footer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: 20px;

			.no-room-access-btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 18px 24px;
				gap: 8px;
				height: 48px;

				background-color: $pale-green;
				border-radius: $rem100px;
				color: #1a1a1f;

				&:hover {
					background-color: lighten($pale-green, 5%);
				}

				transition: 150ms color, 150ms background-color, 60ms transform;
				@include button-greenroom-shadow;
			}
		}
	}
}

.rtmp-validation-override {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 495px;
	border-radius: 24px;
	background: $bl-grayscale-900;

	.rtmp-validation-override-clickable {
		padding: 24px 40px;
	}

	.confirmation-dialog {
		width: auto !important;
	}

	.info-modal {
		&,
		.modal-header-container {
			padding: 0 !important;
		}

		.modal-button-div-right {
			margin: 0 !important;
		}
	}
}

#modal .info-modal.confirmation-modal .rtmp-validation-override .modal-first-button {
	max-width: none !important;
}
