$drawer-menu-width: 84px;
$button-width: 48px;
$button-help-size: 56px;

.right-drawer-menu {
    width: $drawer-menu-width;
    height: 100%;
    background: $element-background-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: $z-index-right-sidebar;
    border-left: 0.5px solid $element-border-gray;
    padding: 16px 0 16px 0;
    gap: 24px;
    position: relative;

    @include custom-scrollbar(24px, 1);
    overflow-y: auto;

    .right-drawer-option,
    .right-drawer-option-custom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .right-drawer-option {
        .right-drawer-button {
            cursor: pointer;
            width: $button-width;
            height: $button-width;
            border-radius: 16px;
            background: $border-gray;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .right-drawer-label {
            margin-top: 8px;
            color: $text-light-gray;
            font-weight: $semi;
            font-size: $rem11px;
        }

        &.active {
            .right-drawer-button {
                background-color: $element-background-highlight-gray;
            }

            .right-drawer-label {
                color: $white;
            }
        }
    }

    .right-drawer-option-custom.help-options {
        position: fixed;
        bottom: 0;
        right: 0;
        width: $drawer-menu-width;
        padding: $rem24px 0;

        .help-options-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: $button-help-size;
            width: $button-help-size;
            border-radius: 50%;
            background: linear-gradient(
                133.12deg,
                rgba(255, 248, 92, 1) 16.71%,
                rgba(255, 106, 230, 1) 100%
            );
            cursor: pointer;
        }
    }

    .unseen-messages {
        top: 0;
        right: -4px;
        border-radius: 100px;
        width: 16px;
        height: 16px;
        background-color: $pale-green;
        color: $dark-gray;
        font-size: 10px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        &.notification-count-0 {
            display: none;
        }
    }
}

.right-drawer-content {
    position: relative;
    width: $global-drawer-width;
    min-width: $global-drawer-width;
    max-width: $global-drawer-width;
    margin-right: -$global-drawer-width;
    transition: margin-right 0.25s;

    .volume-controls {
        width: $global-drawer-width;
        z-index: $z-index-media-controls - 2px; // -2px to allow border to be visible to the bottom of the page
    }

    &.open {
        border-left: 0.5px solid $element-border-gray;
        margin-right: 0;
    }

    &.feeds {
        padding-bottom: 66px;
    }

    &.shots {
        padding-bottom: 72px;
    }

    .mute-button {
        width: 50%;
        min-width: 135px;
        flex: 1;
    }

    .presenters-button {
        width: 50%;
        &.active {
            background: $element-border-gray;
            border: 2px solid $pale-green;
            padding: 14px;
        }
    }

    .controls-gradient {
        background: linear-gradient(
            180deg,
            rgba(38, 36, 43, 0) 0%,
            $element-background-gray 100%
        );
        height: 32px;
        bottom: 66px;
        pointer-events: none;
        z-index: $z-index-media-controls;
        width: $global-drawer-width - 12px; // 12px to prevent gradient from being applied to the scrollbar

        &.shot-drawer {
            bottom: 72px;
            margin-left: -24px;
            z-index: auto;
        }
    }

    button {
        &.sharing {
            background: $red;
            color: $near-white;
        }

        .button-content {
            height: 18px;
        }
    }
}

.right-drawer-wrapper {
    display: flex;
    height: fill-available;
}
