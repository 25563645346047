$snackbar-animation-duration: 250ms;
$snackbar-height: 32px;

.unmute-snackbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $snackbar-height;
    white-space: nowrap;
    padding: 12px 12px 12px 12px;
    gap: 12px;
    background: $bl-grayscale-900;
    box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    pointer-events: auto;

    .unmute-snackbar__action {
        color: $bl-gr-primary-500;
        font-size: 13px;
        height: $snackbar-height;
    
        &:hover,
        &:active,
        &:focus {
            color: $bl-gr-primary-300;
            background: inherit;
        }
    }

    &.snackbar-enter {
        opacity: 0;
    }

    &.snackbar-enter-active {
        opacity: 1;
        transition: opacity $snackbar-animation-duration;
    }

    &.snackbar-exit {
        opacity: 1;
    }

    &.snackbar-exit-active {
        opacity: 0;
        transition: opacity $snackbar-animation-duration;
    }
}
