.select-group {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 15px;
	flex: 1;

	.select-label {
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: $text-gray;
		margin-bottom: 7px;

		span {
			display: inline-block;
		}
	}

	span.main-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba($text-light, 0.15);
		padding: 6px 0;
		opacity: 1;
		transition: opacity 150ms;
		cursor: pointer;
		transition: border-bottom-color 150ms;
		
		svg {
			path {
				transition: 150ms fill;
			}
		}

		&:hover {
			border-bottom-color: rgba($text-light, 0.5);

			svg {
				path {
					fill: $text-light;
				}
			}
		}

		&.open {
			opacity: 0;
		}
	}

	&.invalid {
		.select-label {
			color: $red;
		}

		span.main-label {
			border-bottom: 1px solid $red;
		}
	}
	&.open {
		.select-background {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}

	.select-body {
		@include shadow-popover();
		max-height: 0;
		opacity: 0;
		position: absolute;
		background-color: $dark-gray;
		z-index: 0;
		transition: opacity 150ms, max-height 150ms;
		top: 30px;
		width: 100%;
		border-radius: $radius-medium;
		overflow: hidden;

		.select-option {
			padding: 16px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;

			label {
				color: $text-light;
				font-weight: normal;
				pointer-events: none;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			span {
				opacity: 0;
			}

			&:last-of-type {
				padding-bottom: 16px;
			}

			&:hover {
				background-color: rgba($near-white, 0.04);

				label {
					color: $color-white;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}

			&.selected {
				background-color: rgba($near-white, 0.04);

				label {
					color: $color-white;
				}

				span {
					opacity: 1;
					margin-left: 16px;
				}

				&:last-of-type {
					border-radius: 0 0 $radius-medium $radius-medium;
				}
			}
		}

		input.select-option {
			padding-top: 24px;
			background: none !important;
			border: none;
			color: $color-white;
			width: 100%;
			outline: none;
		}

		&.open {
			max-height: 300px;
			opacity: 1;
			z-index: 2;
		}
	}
}
