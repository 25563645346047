.button-comms {
    min-height: $drawer-action-button;
    flex: 1 1;
    
    &.defaults {
        padding: 0 16px;
        flex: 1;

        &.active {
            background: $element-border-gray;
            border: 2px solid $pale-green;
            padding: 0 14px;
        }
    }

    &.disabled {
        pointer-events: none;
    }

    &:not(.disabled) {
        pointer-events: auto;
    }
}
