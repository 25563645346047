.styled-input-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    display: flex;
    align-items: center;

    box-sizing: border-box;

    width: 100%;
    border-radius: 12px;
    
    min-height: 64px; //40 + (12 top pad + 12 bottom pad)
    max-height: 135px; //40 + (12 top pad + 12 bottom pad)

    overflow-x: hidden;
    overflow-y: scroll;

    cursor: text;
    
    margin: 8px 0 0 0;
    padding: 0 8px 0 16px;

    transition: background 0.3s, border 0.1s;
    background: $bl-grayscale-800;
    border: 1px solid rgba(0,0,0,0);
    .input-field-clear-btn {
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
    }
    &:hover {
        background: $bl-grayscale-700;
    }
    &:focus,
    &.is-focused {
        border: 1px solid $bl-grayscale-400;
        outline: none;
        background: $bl-grayscale-900;
    }
    &:hover,
    &focus {
        .can-show {
            pointer-events: all;
            opacity: 1;
        }
    }
}

textarea.stylepacks-input-base,
.stylepacks-input-base {
    border: none;
    
    resize: none;
    
    min-height: 20px;
    field-sizing: content;

    overflow: hidden;

    margin: 16px 0;
    padding: 0 16px 0 0;
    background: transparent;
    
    width: 100%; // subtract trash can button
    max-width: 100%; // subtract trash can button

    white-space: break-spaces;

    word-break: break-word;
    
    /* FONT */
    color: $bl-grayscale-200;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.gr-input-picture {
    margin-top: 24px;
    hr {
        margin: 24px 0 0 0 !important;
    }

    &.dragging {
        background-color: $bl-grayscale-900;
        border-radius: 12px;
        width: calc($global-drawer-width - 40px);
        cursor: grabbing;
        margin-left: -4px;
        padding: 0 4px 4px 4px;
    }

    .drag-holder {
        background-color: $bl-grayscale-1000;
        width: calc($global-drawer-width - 40px);
        margin-left: -4px;
        border-radius: 12px;
        height: 350px;
    }

    .dragging-over {
        width: 100%;
        min-height: 32px;
        max-height: 32px;

        margin: 16px 0 16px -12px;

        stroke-width: 1px;
        stroke: var(--primary-gr-500-key, $bl-gr-primary-500);
        filter: drop-shadow(0px 0px 20px $bl-gr-primary-500);
    }

    .gr-input-picture-description-label {
        color: $bl-grayscale-200;
        margin-bottom: 12px;
    }

    .gr-input-picture-row {
        display: flex;
        align-items: center;

        &.draggable {
            position: unset;
        }

        .gr-input-picture-thumbnail {
            width: 84px;
            max-width: 84px;
            height: 48px;
            max-height: 48px;
            border-radius: 8px !important;
            margin-right: 16px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $bl-grayscale-800;
            cursor: pointer;
            @include button-greenroom-basic();
        }

        .gr-input-picture-name {
            width: 108px;
            color: $bl-grayscale-200;
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 8px;
        }

        .gr-input-picture-button {
            @include button-greenroom-basic();
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            border-radius: 100px;
            height: 32px;

            background: $bl-grayscale-600;
            color: $bl-grayscale-200;

            user-select: none;
            cursor: pointer;

            font-feature-settings: 'clig' off, 'liga' off;
            /* L2 */
            font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 145.455% */
            letter-spacing: 0.22px;
        }
    }

    .gr-input-picture-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 8px 0 0;
        position: relative;
        width: 100%;
        min-height: 32px;
        max-height: 32px;

        
        color: $bl-grayscale-200;
        user-select: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        .gr-input-picture-drag {
            cursor: grab;
            display: flex;
            gap: 4px;
        }

        .gr-input-picture-label-drag {
            left: 8px;
            margin-top: 1px;
            background-color: $bl-grayscale-900;
        }

        .gr-input-picture-delete-button {
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            cursor: pointer;
            color: $bl-grayscale-400;
        }
    }
}

.gr-input-add-button {
    border-radius: 100px;
    position: fixed;
    bottom: 16px;
    right: 110px;
    background-color: $bl-grayscale-600;
    padding: 16px 24px;
    width: 377px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    cursor: pointer;
    user-select: none;

    /* L1 */
    color: var(--grayscale-200-light-grey, #E5E4EC);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}